import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class LargeformatpartsService {
  defaultUrlNew: string;
  cart = {};
	menu = {};

  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrlNew = this.util.getBaseUrl();
   }

   getPartList(param):Observable<any>{
     let initialUrl = this.defaultUrlNew+"parts/gpl";
     return this.http
     .post(initialUrl,param,httpOptions)
     .pipe(catchError(this.handleError("getPartList")))
   }

    getLFSPartsProductGroup(param):Observable<any>{
	    let initialUrl = this.defaultUrlNew+"parts/gpg";
     return this.http
     .post(initialUrl,param,httpOptions)
     .pipe(catchError(this.handleError("getLFSPartsProductGroup")))
	 }

   getPartPrice(param):Observable<any>{
    let initialUrl = this.defaultUrlNew+"parts/gpp";
   return this.http
   .post(initialUrl,param,httpOptions)
   .pipe(catchError(this.handleError("getPartPrice")))
  }

  getMultiplePartPrice(param):Observable<any>{
    let initialUrl = this.defaultUrlNew+"parts/gmpp";
   return this.http
   .post(initialUrl,param,httpOptions)
   .pipe(catchError(this.handleError("getMultiplePartPrice")))
  }
  
  updateFavoriteItem(param):Observable<any>{
    let initialUrl = this.defaultUrlNew+"supplies.htm/supplies/ufis";
   return this.http
   .post(initialUrl,param,httpOptions)
   .pipe(catchError(this.handleError("updateFavoriteItem")))
  }

   private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
 