import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  menuItems = [] as any;
  loggedUserName: string;
  constructor(private service: MenuService, private loginAuthenticationService: LoginAuthenticationService) { }

  ngOnInit() {
    this.loggedUserName = this.loginAuthenticationService.getAuthenticatedUser();
    this.service.updateMenu(this.loggedUserName).subscribe((results) => {
      if(results){
            this.menuItems = results.menu;            
          }
    })     
  }

  scrollToPgTop() {
    $('html, body').animate({
      scrollTop: $("#top").offset().top - 60
    }, 200);
  }
}


