import { NgModule } from '@angular/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { Subject, Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { SearchService } from './globalsearch.service';

@Component({
  selector: 'app-globalsearch',
  templateUrl: './globalsearch.component.html',
  styleUrls: ['./globalsearch.component.css']
})
export class GlobalsearchComponent implements OnInit {

  @ViewChild('suppliesPerPage') suppliesPerPage: ElementRef;
  @ViewChild('printersPerPage') printersPerPage: ElementRef;
  @ViewChild('partsPerPage') partsPerPage: ElementRef;


  showWaitDiv = false;
  searchData;
  callType = 'S';
  partsLoaded = false;

  entriesPerPage: any[] = [
    { value: 10, viewValue: 10 },
    { value: 25, viewValue: 25 },
    { value: 50, viewValue: 50 },
    { value: 100, viewValue: 100 },
  ];

  suppliesPageNum: number = 1;
  selSuppliesPerPage = 10;

  printersPageNum: number = 1;
  selPrintersPerPage = 10;

  partsPageNum: number = 1;
  selPartsPerPage = 10;

  notFoundMsg = "No Results Found. Please verify your search criteria or for further assistance call 800-355-1390";

  private subscription: Subscription;

  private accountNumber: string = "";
  private locationNumber: string = "";
  private isLFSOrPPSRoleUser: string = "";
  private searchText: string = "";


  constructor(private router: Router, private route: ActivatedRoute, private loginAuthenticationService: LoginAuthenticationService
    , private searchService: SearchService, private util: Util, private titleService: Title, private routing: Router
    , private viewportScroller: ViewportScroller) { }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        this.accountNumber = params['accountId'];
        this.locationNumber = params['locationId'];
        this.isLFSOrPPSRoleUser = params['isLFSorPPS'];
        this.searchText = params['searchTxt'];

        this.searchKeyword();
      }
    );

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  gotoAnchor(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  gotoUrl(navurl: string): void {
    this.router.navigateByUrl(navurl);
  }

  onSuppliesPerPageChange(evt) {
    this.selSuppliesPerPage = this.suppliesPerPage.nativeElement.value;
  }

  onPrintersPerPageChange(evt) {
    this.selPrintersPerPage = this.printersPerPage.nativeElement.value;
  }

  onPartsPerPageChange(evt) {
    this.selPartsPerPage = this.partsPerPage.nativeElement.value;
  }

  isLFSOrPPSUser() {
    return this.isLFSOrPPSRoleUser;

  }

  searchKeyword() {

    /*
     var param = {
      "accountNumber":"501328",
      "locationNumber":"3004577",
      "isLFSOrPPSUser":"Y",
      "searchText":  "STAPLE" , // "1008B001", //"STAPLE",
      "callType":"S"
     };
    */

    // {accountNumber=230154, locationNumber=3041851, isLFSOrPPSUser=Y, searchText=PIN, callType=S}
    /*
     var param = {
      "accountNumber": "230154",
      "locationNumber":"3041851",
      "isLFSOrPPSUser":"Y",
      "searchText":"PIN",
      "callType":"S"
     };
     */

    var param = {
      "accountNumber": this.accountNumber,
      "locationNumber": this.locationNumber,
      "isLFSOrPPSUser": this.isLFSOrPPSRoleUser,
      "searchText": this.searchText,
      "callType": "S"
    };


    console.log(param);
    this.showWaitDiv = true;

    this.searchService.getSearchResults(param).subscribe(data => {
      this.showWaitDiv = false;
      this.searchService.notifySearchCompleted('completed');
      if (data != null) {
        console.log('searchKeyword()-->', data);
        this.searchData = data;

        if ((this.searchData.partList.length + this.searchData.printerList.length + this.searchData.supplyList.length) == 1) {
          // redirect 
          var navurl = "";
          if (this.searchData.partList.length > 0) {
            navurl = this.searchData.partList[0].url;
          } else if (this.searchData.printerList.length > 0) {
            navurl = this.searchData.printerList[0].url;
          } else if (this.searchData.supplyList.length > 0) {
            navurl = this.searchData.supplyList[0].url;
          }
          console.log("Navigating to URL : " + navurl);
          this.router.navigateByUrl('/' + navurl);
        }

        this.partsLoaded = true;
      } else {
        console.log("NO data");
      }
    });


  }

}
