import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntermeterreadsComponent } from './entermeterreads/entermeterreads.component';

const routes: Routes = [
  {
    path: '',
    component: EntermeterreadsComponent,
    children: [{path:'entermeterreads', component:EntermeterreadsComponent, data: { showHeader: true, showSidebar: false, showFooter: true}}]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeterreadsRoutingModule { }
