import { Injectable } from '@angular/core';
import { Observable, Subject, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { Util } from '../../app.util';

const httpOptions = {
    headers: new HttpHeaders({
        
    }).set('Content-Type', 'application/json')
  };

@Injectable({
    providedIn: 'root'
})

export class SearchService {
        
    defaultUrl = 'search.htm/';
    defaultUrlNew : string;
    
    private searchSubject = new Subject<any>();
    searchObservable$ = this.searchSubject.asObservable();

    constructor(private http: HttpClient, private util : Util) {
        
          this.defaultUrlNew = this.util.getBaseUrl();  
    }

    getSearchResults(data): Observable<any> {
        let data1 = JSON.stringify(data);
        let initialUrl = this.defaultUrlNew+"gsd";
        //let initialUrl = this.defaultUrl;
       //console.log("in getOrderCsaList."+initialUrl);
      // console.log('fleet dealer data',data);
       return this.http
       .post(initialUrl,data1,httpOptions)
       //.get(initialUrl)
         .pipe(catchError(this.handleError("getOrderHistoryList")))
         // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
         //.pipe(map(data => this.toColleciton2(data)));
    }

    notifySearchCompleted(status){
      this.searchSubject.next(status);
    }

    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
          // TODO: send the error to remote logging infrastructure
          console.log("Error : " + error); // log to console instead
    
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }


}
