import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(input:any, splitChar:string, splitIndex:number): string {
    var returnVal = "";

    	if(input != null && input != 'undefined'){
	    	var splitArr = input.split(splitChar);
	    	
	    	if(splitArr.length >= splitIndex + 1){
	    		returnVal = splitArr[splitIndex];
			  } 
    	}
      return returnVal;
  }

}
