import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { OrderService } from 'src/app/service/common-service/order.service';
import { MenuService } from './menu.service';
import { OfficeProductModalComponent } from './office-product-modal/office-product-modal';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
    @ViewChild('headerNav', {read: ElementRef}) public headerNav!: ElementRef<any>;
    menuItems = [] as any;
    baseUrl: string;
  
  listing:any={};
  productSizeDetails:any={};
  private acceptAndProceesSubscription: Subscription;
  userAccepted:boolean =false;
  myCSAURL: string;
  selectedNavId : number = -1;

  constructor(private service: MenuService,
    private router :Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private util: Util,
    private orderService:OrderService,
    private commonService : CommonUtilsService) { 
      this.acceptAndProceesSubscription = this.service.acceptAndProceed$.subscribe((value) => {
        if(value) {
          this.userAccepted=true;
        }
      })
    }
  
  ngOnInit(): void {
    this.service.defaultCommonGuld();
    this.service.defaultCommonHomegfpdb();
    //this.defaultCommonHomegfpdb();
    this.service.defaultCommonProductgpsd();
    this.commonService.getMyCSAURL("MYCSA_URL").subscribe(
      data => {
        this.myCSAURL = data.myCSAURL;
      }
    )

    this.service.menuResponse.subscribe((results) => {
      if(results){
        this.menuItems = results.menu;
      }
      
    })
    
    this.setSubMenuPosition();
  }
  handleSubNav(subNavItem,parentName,subNav) {

    if(this.orderService.deleteTempCart){
      localStorage.removeItem('tempCart');
    }
    this.orderService.deleteTempCart=false;
  let cartData = this.orderService.getCart();

  this.selectedNavId = -1;
if((cartData.lfsSupplyRole =='Y'    || cartData.lfsPartsRole =='Y' || cartData.ppsSupplyRole =='Y') && !this.userAccepted){
return ;

}
    let queryParams:any={};

    let routeUrl = '';
    if(parentName=='SUPPLIES'){
      queryParams.category =subNavItem.name;
      if((subNavItem.description === 'Office Product' && subNavItem.levelNum === 2)|| (subNav.description && subNav.description == 'Office Product')) {
        this.modalService.open(OfficeProductModalComponent).result.then(() => {
          this.router.navigate(['/',subNavItem.route],{queryParams});
        });
      } else {
        this.router.navigate(['/',subNavItem.route],{queryParams});
      }
    }else{
      this.router.navigate(['/',subNavItem.route]);
    }
    
  }
  
  setSubMenuPosition() {
    const element = this.headerNav.nativeElement;
    const parents = document.getElementsByClassName('parent') as any;
    for(let i=0; i< parents.length; i++) {
        const leftPos = parents[i].offsetLeft;
        parents[i].getElementsByClassName('sub-wrapper')[0].style.insetInlineStart = (leftPos - element.scrollLeft)+'px';
    }
  }

  sideScroll(direction,speed,distance,step) {
    const element = this.headerNav.nativeElement;
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        if(direction === 'left') {
            element.scrollLeft -= step;
        } else {
            element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
            window.clearInterval(slideTimer);
            this.setSubMenuPosition();
        }
    }, speed);
  }

  scrollLeft() {
    this.sideScroll('left', 100,100,80);
  }
  scrollRight() {
    this.sideScroll('right', 100,100,80);
  }

  navigatetoroute(){
    console.log("navigate to route");
    //this.router.navigate( ['/'+menuitem.route]);

  }




  setCartLocalStorageDfaults() {
    throw new Error('Method not implemented.');
  }
  createCartObject() {
    throw new Error('Method not implemented.');
  }
  defaultCommonProductgpsd() {
    this.baseUrl = this.util.getBaseUrl();
    const httpOptions = {
      headers: new HttpHeaders({
      }).set('Content-Type', 'application/json')
  };
 
   return this.http.get(this.baseUrl+'product.htm/gpsd').pipe(
          map(data=> {
            this.productSizeDetails = data;
            console.log(this.productSizeDetails);
          }));
  }


  toggleSubMenu(nav){
    if(!nav){
      this.selectedNavId = -1;
    }else {
      if(this.selectedNavId == -1){
        this.selectedNavId = nav.id;
      }else{
        this.selectedNavId = -1;
      }
    }
    
    
  }

  


  

}

