import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-office-product',
    templateUrl: './office-product-modal.html',
    styleUrls: ['./office-product-modal.css']
  })
  export class OfficeProductModalComponent implements OnInit {
    constructor(
      public activeModal: NgbActiveModal,
    ) {
    }
  
    ngOnInit(): void {
  
    }
    closeModal() {
      this.activeModal.close();
    }


}