import { Component, OnInit } from '@angular/core';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { UserIdleService } from 'angular-user-idle';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/service/common-service/order.service';
import { AdminMessageComponent } from '../login/admin-message/admin-message-modal';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.css']  
})

export class TwoFactorAuthComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  errMsg: string = "";
  userAuthCode: string = "";
  errorMessage = '';  
  radBtnSelect=''; 
  username: string = "";
  invalidLogin = false;
  showLoader :boolean =false;
  loggedUserName = '';
  authCodeErrMsg = '';
  showDidNotGetCode:boolean = true;
  visible:boolean = false
  authCodeForm: FormGroup;
        
  constructor(
    private loginAuthenticationService: LoginAuthenticationService,
    private menuService: MenuService,   
    private userIdle: UserIdleService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private orderService : OrderService,
    private modalService: NgbModal,
  ) { }

 
  ngOnInit() {
    this.errorMessage = '';
    this.radBtnSelect = '';
    this.loggedUserName = this.loginAuthenticationService.getAuthenticatedUser();
    this.authCodeForm = new FormGroup({
      uAuthCode: new FormControl()
     
    });    
    this.authCodeForm.setValidators([Validators.required]);
         window.history.pushState(null, null, window.location.href);
         window.onpopstate = function () {
         window.history.go(1);
       };
  }
 
  verifyTwoFactorAuthCode(form: NgForm, e) { 
    if ( form.invalid) {
      return;
   }   
   
   this.blockUI.start();
      this.username =  this.loggedUserName;
      var oData = {
        "userAuthCode":	this.userAuthCode.trim(),
        "twoFactorAuthCode":localStorage.getItem("twoFactorAuthCode"),
        "radBtnSelect" :this.radBtnSelect,
        "otpRequestTime":localStorage.getItem("otpRequestTime")    			
      }

      this.loginAuthenticationService.verifyTwoFactorAuthCode(oData).subscribe
      (data => { 
            var errFlg = data.errFlg;          
            if(errFlg == "E"){
              this.blockUI.stop();   
              this.userAuthCode = '';   
              this.errorMessage = " Verification code expired. Please click on Resend Code for a new verification code.";   
              localStorage.removeItem("twoFactorAuthCode");
              localStorage.removeItem("otpRequestTime");   
            }else if(errFlg == "Y"){       
              this.blockUI.stop();         
              this.userAuthCode = '';     
              this.errorMessage = "Incorrect verification code. Please enter the correct verification code.";             
            }else{ 
                //Two Factor Authentication Successfull   
                this.menuService.updateMenu(this.loggedUserName)
                .subscribe(() => {
                    this.blockUI.stop(); 
                    this.userIdle.startWatching();
                    this.errorMessage = '';
                    this.invalidLogin = false;
                    this.closeLoginModal(this.loginAuthenticationService.getAuthenticatedUser());                    
                    let route = localStorage.getItem("route");
                    this.router.navigate([route]);
                    this.orderService.sendAutoLoginMessage('autologin');
                    this.loginAuthenticationService.getAdminMssages().subscribe((messages: any) => {
                          if (messages && messages.length > 0) {
                            const modalRef = this.modalService.open(AdminMessageComponent, {size: 'lg'});
                            modalRef.componentInstance.messages = messages;                    
                          }
                    })   
                                   
                }) 
          }     
      })    
  }

  yesRegisterDevice(){   
    this.radBtnSelect = "Yes";  
    
  }

  noRegisterDevice(){   
    this.radBtnSelect = "No";  
    
  }

  closeLoginModal(userName = '') {
    this.activeModal.close(userName);
  }

  numberValdtn(){
    this.userAuthCode = 	this.userAuthCode.trim().replace(/[^0-9]/g,''); 
  }

  backToLoginPage(){
    this.errorMessage = '';
    this.loginAuthenticationService.logout();   
  }

  toggle(){
    var didntGetTheCodeBtn = document.querySelector("#secure-2-auth-more-info-btn");
    var moreInfoSection = document.querySelector("#secure-2-auth-more-info-content-section");
    moreInfoSection.classList.toggle("secure-hidden");    
  }

  resendVerCode(){ 
    this.radBtnSelect = '';
    this.userAuthCode = '';
        this.loginAuthenticationService.generateVerCodeAndSendEmail() .subscribe(
              dataAuthCode => {   
                  this.blockUI.stop();
                  localStorage.setItem("twoFactorAuthCode", dataAuthCode.twoFactorAuthCode);
                  localStorage.setItem("otpRequestTime",  dataAuthCode.otpRequestTime); 
                  this.errorMessage = '';   
                  var errFlg = dataAuthCode.errFlg;          
                  if(errFlg == "Y"){                  
                    this.errorMessage = "Error in sending the Verification code email.";
                  }      
              },
              error => {
              console.log(error);    
              }
        );
  }


}
