import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { OrderService } from 'src/app/service/common-service/order.service';
import { ReviewService } from './review.service';


@Component({
  selector: 'app-cart-review',
  templateUrl: './cart-review.component.html',
  styleUrls: ['./cart-review.component.css']
})
export class CartReviewComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  @Input() isReadOnly = false;
  subTotal = 0;
  grandTax = 0;
  grandTotal = 0;
  totalMaint = 0;
  totalItems = 0;
  cart = {} as any;
  isEmptyContractSupplyMap = false;
  isEmptyBillableSupplyMap = false;
  isEmptyPreOwnedMachine = false;
  isEmptyCpcSupplyMap = false;
  isEmptyEpSupplyMap = false;
  isEmptyAzcoSupplyMap = false;
  isEmptyLfsSupplyMap = false;
  isEmptyPpsSupplyMap = false;
  isEmptyLfsPartsSupplyMap = false;
  totalCpcItems = 0;
  totalEpItems = 0;
  totalAzcoItems = 0;
  totalLfsItems = 0;
  totalPpsItems = 0;
  totalLfpItems = 0;
  isPrinterItem = 0;
  billSupplyShipCharge = null;
  cc = {} as any;
  error = {} as any;
  totalContractSupplyItems = 0;
  totalBillableSupplyItems = 0;
  totalPrewownedItems = 0;
  totalBillableItems = 0;
  totalContractBillableItems = 0;
  poNumber = null;
  paymentType = null;
  isChangePasswordRequired = false;
  checkOnlyBillableSupplyMap = false;

  constructor(
    private orderService: OrderService,
    private commonService: CommonUtilsService,
    private reviewService: ReviewService,
    private router: Router,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.blockUI.start();
    if (this.isReadOnly) {
      this.subTotal = this.orderService.subTotal();
      this.grandTax = this.orderService.grandTax();
      this.grandTotal = this.orderService.grandTotal();
      this.totalMaint = this.orderService.totalMaintenance();
     this.cart = this.orderService.getCart();
     let tempCartforcartcountdisplay = this.orderService.getCart();
      if (this.orderService.getTempCart() != null) {
        this.cart = this.orderService.getTempCart();
      }
      this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
      this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
      this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
      this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
      this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
      this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
      this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
      this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
      this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
      this.totalCpcItems = this.orderService.totalCpcItems();
      this.totalEpItems = this.orderService.totalEpItems();
      this.totalAzcoItems = this.orderService.totalAzcoItems();
      this.totalLfsItems = this.orderService.totalLfsItems();
      this.totalPpsItems = this.orderService.totalPpsItems();
      this.totalLfpItems = this.orderService.totalLfpItems();
      this.isPrinterItem = this.orderService.isPrinterItems();
      this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
      this.cart.sequenceNumber = this.orderService.getSequenceNum();
      this.totalContractSupplyItems = this.orderService.totalCSItems();
      this.totalBillableSupplyItems = this.orderService.totalBSItems();
      this.totalPrewownedItems = this.orderService.totalPDItems();
      this.totalBillableItems = this.orderService.totalBSItems() + this.totalPrewownedItems;
      this.totalContractBillableItems = this.totalBillableItems + this.totalContractSupplyItems;
      this.poNumber == this.cart.poNumber;
      this.paymentType = this.cart.paymentType;
     this.orderService.setCart(tempCartforcartcountdisplay);
    
    this.orderService.deleteTempCartFromLS(true);
    } else {
       localStorage.removeItem('tempCart');
      this.subTotal = this.orderService.subTotal();
      this.grandTax = this.orderService.grandTax();
      this.grandTotal = this.orderService.grandTotal();
      this.totalMaint = this.orderService.totalMaintenance();
      this.totalItems = this.orderService.totalItems();
      this.cart = this.orderService.getCart();
      this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
      this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
      this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
      this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
      this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
      this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
      this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
      this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
      this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
      this.totalCpcItems = this.orderService.totalCpcItems();
      this.totalEpItems = this.orderService.totalEpItems();
      this.totalAzcoItems = this.orderService.totalAzcoItems();
      this.totalLfsItems = this.orderService.totalLfsItems();
      this.totalPpsItems = this.orderService.totalPpsItems();
      this.totalLfpItems = this.orderService.totalLfpItems();
      this.isPrinterItem = this.orderService.isPrinterItems();
      this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
      this.cart.sequenceNumber = this.orderService.getSequenceNum();
      this.totalContractSupplyItems = this.orderService.totalCSItems();
      this.totalBillableSupplyItems = this.orderService.totalBSItems();
      this.totalPrewownedItems = this.orderService.totalPDItems();
      this.totalBillableItems = this.orderService.totalBSItems() + this.totalPrewownedItems;
      this.totalContractBillableItems = this.totalBillableItems + this.totalContractSupplyItems;
      this.poNumber == this.cart.poNumber;
      this.paymentType = this.cart.paymentType;
      //this.menuService.defaultCommonGuld();
      this.menuService.defaultCommonHomegfpdb();
      this.menuService.defaultCommonProductgpsd();
      //this.changePasswordRequired();
      if (this.isEmptyCpcSupplyMap && this.isEmptyEpSupplyMap && this.isEmptyLfsSupplyMap && this.isEmptyPpsSupplyMap && this.isEmptyLfsPartsSupplyMap && this.isEmptyAzcoSupplyMap) {
        if (!this.isEmptyBillableSupplyMap || !this.isEmptyPreOwnedMachine) {
          this.checkOnlyBillableSupplyMap = true;
        }
      }

    }

	    this.getInitialData();
	
    this.blockUI.stop();
  }
  changePasswordRequired() {
    this.commonService.changePasswordReq().subscribe(
      (result: any) => {
        if (result == true) {
          this.isChangePasswordRequired = true;
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  Range = (start, supply) => {
    let result = [];
    const maxVal = supply.maxQuantity;
    const mulVal = supply.orderIncrements;
    if (maxVal > 0) {
      for (let i = 0; i <= maxVal; i++) {
        let tmpQty = i * mulVal;
        if (((mulVal < maxVal) && (tmpQty <= maxVal)) || mulVal == maxVal) {
          result.push(tmpQty);
        } else if ((mulVal > maxVal - 1) && (tmpQty <= mulVal)) {
          result.push(tmpQty);
        }
      }
    }
    return result;
  };

  postProcessOfAction = () => {
    this.cart = this.orderService.getCart();
    this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
    this.subTotal = this.orderService.subTotal();
    this.grandTax = this.orderService.grandTax();
    this.grandTotal = this.orderService.grandTotal()
    this.totalMaint = this.orderService.totalMaintenance();
    this.totalContractSupplyItems = this.orderService.totalCSItems();
    this.totalBillableSupplyItems = this.orderService.totalBSItems();
    this.totalPrewownedItems = this.orderService.totalPDItems();
    this.totalBillableItems = this.orderService.totalBSItems() + this.totalPrewownedItems;
    this.totalContractBillableItems = this.totalBillableItems + this.totalContractSupplyItems;
    this.totalItems = this.orderService.totalItems();
  }
  // calculateBillSuppShipCharge = () => {
  //   this.orderService.calculateBillSuppShipCharge(this.cart).subscribe((result: any) => {
  //     this.cart = result;
  //     this.orderService.setCart(this.cart);
  //     this.postProcessOfAction();
  //     if (this.totalItems == 0) {
  //       window.location.href = 'supplies.htm?view=landingPage&category=SUPPLIES';
  //     }
  //     //// TODO: hidePleaseWait();
  //   }, (reason) => {
  //     console.log(reason);
  //     ////TODO: hidePleaseWait();
  //   });
  // }

  getInitialData = () => {
    if (!this.cart) {
      console.log("The cart details are missing.");
    } else {
      this.reviewService.getInitialData(this.cart).subscribe((results: any) => {
		
		        //console.log("CC:results:", results);
		
        if (results.status == "S") {
          this.cc.firstName = results.firstName;
          this.cc.lastName = results.lastName;
          this.cc.maskedCC = results.maskedCC;
          this.poNumber = results.poNumber;
        } else if (results.status == "E") {
          if (results.messageMap.noItems) {
            //window.location.href="supplies.htm?view=supplies";
          }//If the sequence number is missing
          else if (results.messageMap.ccError) {
            window.location.href = "billing.htm?view=cartBilling";
          } else if (results.messageMap.poError) {
            window.location.href = "billing.htm?view=cartBilling";
          }
        }
      }, (reason) => {
        console.log(reason);
      });
    }
  }

  submitOrder = function () {
    
    ////TODO: showPleaseWait();
    this.cart.accountUsername = this.accountUsername;
    this.cart.accountFirstName = this.accountFirstName;
    this.cart.accountLastName = this.accountLastName;

    this.blockUI.start();
    this.reviewService.submitOrder(this.cart).subscribe((results) => {
      ////TODO: hidePleaseWait();
	  	    //console.log("SO:results:", results);

      if (results.status == 'S') {
        this.orderService.setTempCart(results.cart);
        // Delete local storage
        this.orderService.clearLocalStorage(true);
        // Deleted session storage
        const userName = sessionStorage.getItem('userName');
        const userFullName = sessionStorage.getItem('userFullName');
        //sessionStorage.clear();
        sessionStorage.setItem('userName', userName);
        sessionStorage.setItem('userFullName', userFullName);
        const orderNumber = results.orderNumber ? results.orderNumber : "";
        let queryParams: any = {};
        queryParams.orderNumber = orderNumber;

        this.router.navigate(['cartconfirm'], { queryParams });



        // this.router.navigate(['cartconfirm'],{queryParams : {orderNumber:orderNumber}});

        //window.location.href = 'product.htm?view=cartConfirm&orderNumber=' + orderNumber;
      } else if (results.status = 'E') {
        ////TODO: $('#termsAndConditionsPop-up-modal').modal('hide');
        if (results.messageMap) {
          //If there are no items in the cart
          if (results.messageMap.noItems) {
            this.router.navigate(['supplieslanding'], { queryParams: { category: 'SUPPLIES' } });

          }
          //If the sequence number is missing
          else if (results.messageMap.ccError) {

            this.router.navigate(['billingaddress']);
          } else if (results.messageMap.poError) {
            this.router.navigate(['billingaddress']);
          }

          //Maint related error
          this.error.noMaintError = results.messageMap.noMaintError ? results.messageMap.noMaintError : {};

          //Item not available for purchase error
          this.error.availableErrors = results.messageMap.availableError ? results.messageMap.availableError : {};

          //Item cannot be shipped error
          this.error.noShippingError = results.messageMap.noShippingError ? results.messageMap.noShippingError : {};

          //Generic error
          this.error.generic = results.messageMap.generic ? results.messageMap.generic : '';

          //Billing address error
          this.error.csBAError = results.messageMap.csBAError ? results.messageMap.csBAError : '';

          //Shipping Address error
          this.error.shipAddrErrors = results.messageMap.shipAddrErrors ? results.messageMap.shipAddrErrors : '';

          //User error
          this.error.csUserError = results.messageMap.csUserError ? results.messageMap.csUserError : '';

          //Quantity error
          this.error.qtyErrors = results.messageMap.qtyErrors ? results.messageMap.qtyErrors : '';

          //MeterReads error
          this.error.csMRError = results.messageMap.csMRError ? results.messageMap.csMRError : '';

          //Promo error
          this.error.promoError = results.messageMap.promoError ? results.messageMap.promoError : '';
        }

      }
      this.blockUI.stop();
    }, (reason) => {
      console.log(reason);
      ////TODO: hidePleaseWait();
     // this.router.navigate(['cartconfirm'], { queryParams: { orderNumber: 12345 } });
    });
  }
  getFormattedAddress = (addr) => {
    return this.commonService.getFormattedAddress(addr);
  }
}
