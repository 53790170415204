export class Address {

    psn: string;

    firstName: string;
    lastName: string;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    phone: string;

    addressType: string;

    poReq: string;
    displayCC: string;

    accountNumber: string;
    locationId: string;
    newAddressId: string;

    attentionTo : string;
    errorExist: boolean;
    oldAddress2: string;
    oldAttentionTo: string;

    newAddress: boolean;
    
    error: any;

    activeFlag: string;

    dropShipFlag : string;

    constructor(){
      this.psn = '';

      this.firstName = '';
      this.lastName = '' ; 
      this.companyName = '';
      this.address1 = '';
      this.address2 = '';
      this.city = '';
      this.state = '';
      this.zipcode = '';
      this.phone = '';

      this.addressType = '';

      this.poReq = '';
      this.displayCC = '';

      this.accountNumber = '';
      this.locationId = '';
      this.newAddressId = '';

      this.attentionTo = '';
      this.errorExist = false;
      this.oldAddress2 = '';
      this.oldAttentionTo = '';

      this.newAddress = false;
    
      this.error = {};

      this.activeFlag = '';

      this.dropShipFlag = '';
    }
    
  }