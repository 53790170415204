import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingRoutingModule } from './billing-routing.module';
import { BillingAddressComponent } from './billing-address/billing-address.component';
import { FormsModule } from '@angular/forms';
import { ShopCommonModule } from '../shop-common/shop-common.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from '../shop-common/block-template.component';

@NgModule({
  declarations: [BillingAddressComponent],
  imports: [
    CommonModule,
    BillingRoutingModule,
    FormsModule,
    ShopCommonModule,
    NgxPaginationModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    })
  ],
  entryComponents :[BlockTemplateComponent]
})
export class BillingModule { }
