import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from '../app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  baseUrl: string;

  constructor(private http:HttpClient,private util:Util) {
    this.baseUrl = this.util.getBaseUrl();
  }

  getInitialData(billData) : Observable<any> {
    let url = this.baseUrl + 'billing.htm/gid';
		return this.http.post(url, billData, httpOptions).pipe(catchError(this.util.handleError("getInitialData")));

  }

  validateAddress(cart) : Observable<any>{
    let url = this.baseUrl + 'billing.htm/vba';
		return this.http.post(url, cart, httpOptions).pipe(catchError(this.util.handleError("validateAddress")));

  }

  getBillingAddrList() : Observable<any>{
    let url = this.baseUrl + 'billing.htm/gebdl';
		return this.http.post(url, '', httpOptions).pipe(catchError(this.util.handleError("getBillingAddrList")));
  }

  saveBillingPhoneNumber(data) : Observable<any>{
    let url = this.baseUrl + 'billing.htm/sbaphone';
		return this.http.post(url, data, httpOptions).pipe(catchError(this.util.handleError("getBillingAddrList")));
  }

  applyPromo(data) : Observable<any>{
    let url = this.baseUrl + 'billing.htm/apc';
		return this.http.post(url, data, httpOptions).pipe(catchError(this.util.handleError("applyPromo")));
  }

  removePromo(data) : Observable<any>{
    let url = this.baseUrl + 'billing.htm/rpc';
		return this.http.post(url, data, httpOptions).pipe(catchError(this.util.handleError("removePromo")));
  
  }

  savePaymentDetails(cart) : Observable<any>{
    let url = this.baseUrl + 'billing.htm/sp';
		return this.http.post(url, cart, httpOptions).pipe(catchError(this.util.handleError("savePaymentDetails")));

  }

  validateCCDetails(billData) : Observable<any>{
    let url = this.baseUrl + 'billing.htm/vccd';
		return this.http.post(url, billData, httpOptions).pipe(catchError(this.util.handleError("validateCCDetails")));

  }

  getCCDetails(billData) : Observable<any>{
    let url = this.baseUrl + 'billing.htm/gccd';
		return this.http.post(url, billData, httpOptions).pipe(catchError(this.util.handleError("getCCDetails")));

  }

}
