
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginComponent } from 'src/app/common/login/login.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { MenuService } from '../menu/menu.service';
import { LocalStorageService } from 'src/app/service/common-service/local-storage.service';
import { UserIdleService } from 'angular-user-idle';
import { CartRestartComponent } from './cart-restart/cart-restart.component';
import { OrderService } from 'src/app/service/common-service/order.service';
import { SearchService } from 'src/app/search/globalsearch/globalsearch.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  showModal = false;
  loggedUserName: any;
  cartCount: number;
  userFullName: string;
  considerTempCart:any;
  displayRestart : boolean = false;

  //@ViewChild('main_navbar_search_input') main_navbar_search_input: ElementRef;
  searchKeyword : string = '';
  private cartCountSubscription: Subscription;
  private logoutSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private loginAuthenticationService: LoginAuthenticationService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private orderService: OrderService,
    private userIdle: UserIdleService,
    private searchService : SearchService
  ) {

    this.loggedUserName = this.loginAuthenticationService.userName;
    this.userFullName = this.loginAuthenticationService.getAuthenticatedUserFullName();
    this.loggedUserName = sessionStorage.getItem('userName');
    this.displayRestart = this.isLFSPPSUser();
  }

  ngOnInit(): void {
    //this.userIdle.startWatching();
   
    this.cartCountSubscription = this.orderService.cartCountObservable$.subscribe(() => {
      this.cartCount = this.orderService.totalItems();
    })

    this.orderService.autoLoginObservable.subscribe(
      () => {
        this.loggedUserName = this.loginAuthenticationService.userName;
        this.userFullName = this.loginAuthenticationService.getAuthenticatedUserFullName();
        this.loggedUserName = sessionStorage.getItem('userName');
        this.displayRestart = this.isLFSPPSUser();
      }
    )

    this.orderService.sessionTimeoutObservable.subscribe(
      () => {
        console.log('Session timeout');
        this.logout();
      }
    )

    this.logoutSubscription = this.orderService.logoutObservable$.subscribe(()=>{
      this.openSignIn()
      this.loggedUserName = null;
      this.userFullName = null;
      this.displayRestart = this.isLFSPPSUser();
    })

    this.searchService.searchObservable$.subscribe(
      () => {
        this.searchKeyword = '';
      }
    )
    /* this.userIdle.onTimerStart().subscribe(
      count => {
        console.log("Time Starts" + count);
        //this.showModal = true;
      }
    ); */

    // Start watch when time is up.
    /* this.userIdle.onTimeout().subscribe(
      () => {
        console.log('Time is up!');
        //alert("Time is Up");
        this.userIdle.stopWatching();
        //this.showModal = false;
        this.logout();
      }); */
  }
  isLFSPPSUser() {
    var lfsPpsUser = false;
    var cart = this.orderService.getCart();
    var lfsSupplyRole = cart["lfsSupplyRole"];
    var lfsPartsRole = cart["lfsPartsRole"];
    var ppsSupplyRole = cart["ppsSupplyRole"];
    var displayRestart = localStorage.getItem('showRestartButton');

    if (((lfsSupplyRole && lfsSupplyRole == "Y") || (lfsPartsRole && lfsPartsRole == "Y") ||
      (ppsSupplyRole && ppsSupplyRole == "Y"))) {
      lfsPpsUser = true;
    }
    return lfsPpsUser;
  }
  openRestartModal() {
    const restartModalRef = this.modalService.open(CartRestartComponent);
    restartModalRef.result.then(val => {
      if (val) {
        let cart = this.orderService.getCart();
        cart.billableSupplyMap = {};
        cart.productMap = {};
        cart.contractSupplyMap = {};
        cart.cpcSupplyMap = {};
        cart.epSupplyMap = {};
        cart.lfsSupplyMap = {};
        cart.lfsPartsMap = {};
        cart.ppsSupplyMap = {};
        cart.azcoSupplyMap = {};

        this.orderService.setCart(cart);

        if (this.orderService.hasLFSSupplyRole() || this.orderService.hasPPSSupplyRole() || this.orderService.hasLFSPartsRole()) {
          this.router.navigate(['/','account']);
        } else if (this.orderService.hasOrderSupplyRole()) {
          this.router.navigate(['/','orderContractSupplies']);
        } else {
          this.router.navigate(['/']);
        }
      }
    })
  }

  openSignIn() {
    if(!this.modalService.hasOpenModals()){
      const loginModalRef = this.modalService.open(LoginComponent);
      loginModalRef.result.then(val => {
        if (val) {
          this.loggedUserName = val;
          this.userFullName = this.loginAuthenticationService.getAuthenticatedUserFullName();
        }
      })
    }
    
  }
  logout() {

    this.loginAuthenticationService.logout();
    this.loggedUserName = null;
    this.userFullName = null;

  }
  routeToCart() {
    if(this.cartCount > 0){
      this.router.navigate(['/', 'cart']);
    }
  }
  searchItem() {
    let existingCart = this.localStorageService.getCart();
    if (existingCart == null) {
      console.log("Cannot Search as cart is empty");
      return;
    }

    //var searchTxt = this.main_navbar_search_input.nativeElement.value.trim();
    var searchTxt = this.searchKeyword
    var accountId = '';
    var locationId = '';
    if (existingCart.accountInfo && existingCart.accountInfo.defaultBillTo) {
      accountId = existingCart.accountInfo.defaultBillTo.accountNumber;
      locationId = existingCart.accountInfo.defaultBillTo.locationId;
    }
    var isLFSorPPS = this.isLFSPPSUser();

    console.log("Search for :" + searchTxt);
    console.log("Account # : " + accountId);
    console.log("Location Id : " + locationId);
    console.log("isLFSorPPS : " + isLFSorPPS);

    if (!searchTxt || (isLFSorPPS && !accountId && !locationId)) {
      console.log("Search text, Account ID , Location ID are mandatory for search.");
      return;
    }

    let queryParams: any = {};
    queryParams.searchTxt = searchTxt;
    queryParams.accountId = accountId;
    queryParams.locationId = locationId;
    queryParams.isLFSorPPS = (isLFSorPPS == true) ? 'Y' : 'N';


    this.router.navigate(['globalsearch'], { queryParams });


  }

  hasLFSSupplyRole() {
    var cart = this.localStorageService.getCart();

    if (cart.lfsSupplyRole && cart.lfsSupplyRole == 'Y') {
      return true;
    } else {
      return false;
    }
  }

  hasPPSSupplyRole() {
    var cart = this.localStorageService.getCart();

    if (cart.ppsSupplyRole && cart.ppsSupplyRole == 'Y') {
      return true;
    } else {
      return false;
    }
  }


}