import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class OrdercontractsuppliesService {
  defaultUrlNew: string;
  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrlNew = this.util.getBaseUrl();
   }

  getAllMachineListAndCount(machineFilter):Observable<any>{
    let initialUrl = this.defaultUrlNew+"supplies.htm/mlistcnt";
    return this.http
    .post(initialUrl,machineFilter,httpOptions)
    .pipe(catchError(this.handleError("getAllMachineListAndCount")))
  }

  getUserAccountsAndModel():Observable<any>{
    let initialUrl = this.defaultUrlNew+"supplies.htm/mAcctModelList";
    return this.http
    .post(initialUrl,httpOptions)
    .pipe(catchError(this.handleError("getUserAccountsAndModel")))
  }

  getCSPurchaseDetails(param):Observable<any>  { 
    let initialUrl = this.defaultUrlNew+"supplies.htm/icsps";
    return this.http
    .post(initialUrl,param,httpOptions)
    .pipe(catchError(this.handleError("getCSPurchaseDetails")))
	}

   private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  getSerialMeterReadsData(srl,ctrId,ctrNum):Observable<any>{
    var params="serialNumber="+srl+"&contractId="+ctrId+"&cotractNum="+ctrNum;
     return this.http.get(this.defaultUrlNew +'meterreads.htm/getSrlMtrReads?'+params);
    
  }

  saveMeterReads(meterReads){
    var promise = this.http.post(this.defaultUrlNew +'meterreads.htm/smr',meterReads);
    return promise;
  }
  

  
}
