import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AccountService } from 'src/app/account/account.service';
import { ShopConstants } from 'src/app/common/shopConstants';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { OrderService } from 'src/app/service/common-service/order.service';
import { CartHelperService } from 'src/app/shipping/cart-helper-service';
import { BillingService } from '../billing.service';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.css']
})
export class BillingAddressComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
   
  //LFS/PPS Billing Address List
  @ViewChild('billToModal') billToTemplate: TemplateRef<any>;

  //ESS user Billing Address List
  @ViewChild('billingAddrListModal') essBillToTemplate: TemplateRef<any>;

  billToModalRef: BsModalRef;
  essBillToModalRef: BsModalRef;

  cart: any = {};
  billingAddress: any = {};
  addrEditable: string = 'N';
  showUpdateBillPhoneNum: boolean = false;
  newAddrAllowed: boolean = false;
  displayPromo: boolean = true;
  maintExists: boolean = false;
  displayCCPayment: boolean = false;
  displayInvoicePayment: boolean = false;
  displayPaymentOptions: boolean = false;
  displayAltAddr: boolean = false;
  showCheckout: boolean = false;


  pciURL: string = '';
  seqNum: string = '';
  showCreditCardDetails: boolean = false;

  error: any = {};
  success: any = {};
  states: any[] = ShopConstants.states;

  //List of address to be displayed in the popup
  existingAddressList: any[];
  billToAddrList: any[];

  //Pagination related
  pageNum: number = 1;
  recPerPage = 10;

  //Search related
  search: string;
  searchFilterPropertyNames: string[] = ['firstName', 'lastName', 'companyName', 'address1', 'address2', 'attentionTo', 'city', 'phone', 'state', 'zipcode'];

  showLoader: boolean = false;

  defaultAddressesAndRoles: any = {};

  promoCode: string = '';
  ts: number = 0;

  constructor(private orderService: OrderService, private commonService: CommonUtilsService,
    private router: Router, private billingService: BillingService,
    private accountService: AccountService, private modalService: BsModalService,
    private cartHelperService:CartHelperService) { }

  ngOnInit() {
    this.getInitialData();
  }
  //Get the data initially needed when the page is loaded
  getInitialData() {

    //showPleaseWait();

    this.blockUI.start();
    //var cart = $scope.cart;
    var cart = this.orderService.getCart();
    cart.sequenceNumber = this.orderService.getSequenceNum();

    this.cart = this.orderService.getCart();
    this.seqNum = this.orderService.getSequenceNum();


    if (!this.commonService.isEmptyObj(cart.promoDetails) && cart.promoDetails.applied) {
      this.displayPromo = false;
    }

    this.billingService.getInitialData(cart).subscribe(
      data => {
        if (data.status == "S") {
          this.billingAddress = data.billingAddr;
          this.cart.myCSAUserName = data.myCSAUserName;
          this.cart.billingAddress = data.billingAddr;
          this.maintExists = data.maintExists;
          this.displayCCPayment = data.displayCCPayment;
          this.displayInvoicePayment = data.displayInvoicePayment;
          this.newAddrAllowed = data.newAddrAllowed;
          this.displayPaymentOptions = data.displayPaymentOptions;
          this.cart.paymentType = data.paymentType;
          this.cart.poNumber = data.poNumber;
          this.cart.ccStatus = data.ccStatus;
          this.addrEditable = data.addrEditable;
          this.displayAltAddr = data.displayAltAddr;
          this.cart.invoicePaymentAvailable = data.invoicePaymentAvailable;

          //reset the sequence number as we save that in local storage
          //and we added it so that we can send those details
          this.cart.sequenceNumber = "";

          this.orderService.setCart(this.cart);

          //Check if we have the payment details to proceed to next page
          this.displayContinue();

          this.setCCDetails(data);

          this.defaultAddressesAndRoles = data.accountAddressDetail;

          // validate address
          this.cart.billingAddress = this.billingAddress;

          this.billingService.validateAddress(this.cart).subscribe(
            data => {
              //Anytime we save billing address the user needs to enter the cc details again
              this.showCheckout = false;
              this.cart.ccStatus = "";
              this.orderService.setCart(this.cart);

              //assign the values to the scope and local storage
              if (data.status == "S") {

                this.showCheckout = true;

                this.displayContinue();

                //get cc url details
                if (this.displayCCPayment) {
                  this.getCCDetails(false);
                }

                this.showUpdateBillPhoneNum = false;

              } else if (data.status == "E") {
                this.displayPaymentOptions = data.displayPaymentOptions;
                if (data.messageMap) {
                  this.error.phone = data.messageMap.phone ? data.messageMap.phone : "";

                  if (data.messageMap.phone) {
                    this.showUpdateBillPhoneNum = true;
                  } else {
                    this.showUpdateBillPhoneNum = false;
                  }
                }
              }
            }
          );



        } else if (data.status == "E") {
          //There are no items in the cart 
          if (data.messageMap.noItems) {
            this.router.navigate(['supplieslanding'], { queryParams: { category: 'SUPPLIES' } });
          }
        }

        this.blockUI.stop();
        //hidePleaseWait();
      }
    );
  }

  //Get the credit card details
  getCCDetails(sendSeqNum) {

    var billAddr = this.billingAddress;

    //TODO - Test param billData is sent correctly
    var billData = {
      "billingAddr": billAddr,
      "sequenceNumber": ''
    }

    if (sendSeqNum) {
      billData.sequenceNumber = this.orderService.getSequenceNum();
    }

    //Get the cc url details		
    this.billingService.getCCDetails(billData).subscribe(
      data => {
        //assign the values to the scope and local storage
        if (data.status == "S") {
          this.setCCDetails(data);
        }
      }
    );


  }

  displayContinue() {
    this.showCheckout = false;
    if (this.billingAddress == null) {
      this.showCheckout = false;
    } else {
      if (this.cart.paymentType === 'INVOICE') {
        this.showCheckout = true;
      }

      if (this.cart.paymentType === 'CC') {
        this.showCheckout = this.cart.ccStatus === 'E' ? true : false;
      }

      if (this.cart.paymentType === 'NR') {
        this.showCheckout = true;
      }
      if (this.cart.lfsSupplyRole == 'Y' || this.cart.lfsPartsRole == 'Y' || this.cart.ppsSupplyRole == 'Y') {
        this.showCheckout = true;
      }
    }
  }

  setCCDetails(data) {
    this.pciURL = data.pciURL;
    this.seqNum = data.sequenceNumber;

    if (this.seqNum !== null) {
      this.orderService.saveSequenceNum(this.seqNum);
    }

    this.cart = this.orderService.getCart();


    //If sequence number exists then we need to enable the credit card link
    if (this.orderService.getSequenceNum() && ((!$.isEmptyObject(this.billingAddress) || this.cart.myCSAUserName))) {
      this.showCreditCardDetails = true;
    }
  }

  savePaymentDetails() {

    var cart = this.cart;
    cart.sequenceNumber = this.orderService.getSequenceNum();

    this.billingService.savePaymentDetails(cart).subscribe(
      data => {
        //assign the values to the scope and local storage
        if (data.status == "S") {
          this.cart.paymentType = data.paymentType;
          this.cart.poNumber = data.poNumber;
          this.cart.ccStatus = data.ccStatus;
          this.orderService.setCart(this.cart);

          if (data.sequenceNumber === "") {
            this.seqNum = data.sequenceNumber;
            this.orderService.setCart(this.cart);
            this.orderService.saveSequenceNum(this.seqNum);
          }

          this.router.navigate(['cartreview']);
        } else {
          this.error.poNumber = data.messageMap.poNumber ? data.messageMap.poNumber : "";
        }
      }
    );


  }

  saveBillingPhoneOnBlur() {
    if (this.showUpdateBillPhoneNum) {
      this.saveBillingAddr(true);
    }
  }

  addNewAddress() {
    this.addrEditable = 'Y';
    this.billingAddress = {};
  }

  //Save the billing address details
  saveBillingAddr(existingAddr) {

    this.success = {};
    this.error = {};

    this.cart.billingAddress = this.billingAddress;
    this.billingService.validateAddress(this.cart).subscribe(
      data => {
        //Anytime we save billing address the user needs to enter the cc details again
        this.showCheckout = false;
        this.cart.ccStatus = "";
        this.orderService.setCart(this.cart);

        //assign the values to the scope and local storage
        if (data.status == "S") {

          this.showCheckout = true;
          this.success.successMsg = data.messageMap.successMsg ? data.messageMap.successMsg : "";
          //save the data to local storage
          if (!existingAddr) {
            this.billingAddress.psn = 'NB1';
          }

          this.cart = this.orderService.updateBillingAddress(this.billingAddress);
          this.displayCCPayment = data.displayCCPayment
          this.displayInvoicePayment = data.displayInvoicePayment
          this.displayPaymentOptions = data.displayPaymentOptions
          this.cart.paymentType = data.paymentType;
          this.orderService.setCart(this.cart);


          this.billingService.saveBillingPhoneNumber(this.cart).subscribe(
            data => {
              //assign the values to the scope and local storage
              if (data.status == "S") {

                this.showUpdateBillPhoneNum = false;

              } else if (data.status == "E") {
                this.displayPaymentOptions = data.displayPaymentOptions;
                if (data.messageMap) {
                  this.error.phone = data.messageMap.phone ? data.messageMap.phone : "";

                  if (data.messageMap.phone) {
                    this.showUpdateBillPhoneNum = true;
                  } else {
                    this.showUpdateBillPhoneNum = false;
                  }
                }
              }
            }
          );
          this.displayContinue();

          //get cc url details
          if (this.displayCCPayment) {
            this.getCCDetails(false);
          }

          //If the existing address needs to be saved then we need to hide the modal
          if (existingAddr) {
            this.essBillToModalRef.hide();
          }

          this.showUpdateBillPhoneNum = false;

        } else if (data.status == "E") {
          this.displayPaymentOptions = data.displayPaymentOptions;
          if (data.messageMap) {
            this.error.firstName = data.messageMap.firstName ? data.messageMap.firstName : "";
            this.error.lastName = data.messageMap.lastName ? data.messageMap.lastName : "";
            this.error.address1 = data.messageMap.address1 ? data.messageMap.address1 : "";
            this.error.city = data.messageMap.city ? data.messageMap.city : "";
            this.error.state = data.messageMap.state ? data.messageMap.state : "";
            this.error.zipcode = data.messageMap.zip ? data.messageMap.zip : "";
            this.error.phone = data.messageMap.phone ? data.messageMap.phone : "";
            this.error.generic = data.messageMap.generic ? data.messageMap.generic : "";

            if (data.messageMap.phone) {
              this.showUpdateBillPhoneNum = true;
            } else {
              this.showUpdateBillPhoneNum = false;
            }
          }
          if (existingAddr) {
            this.essBillToModalRef.hide();
          }
        }
      }
    );


  }

  showAddressPopUp(addressType, accountNumber, selectedLfsPpsAddrType) {

    if (addressType == 'B') {
      this.billToModalRef = this.modalService.show(this.billToTemplate);
    }

    //this.showLoader = true;
    this.blockUI.start();

    this.accountService.getExistingAddressList(addressType, accountNumber).subscribe(
      data => {
        console.log(data);
        this.existingAddressList = data;
        //console.log('this.existingAddressList:' + this.existingAddressList);
        //this.showLoader = false;
        this.blockUI.stop();
      }
    )

  }

  selectAddress(addressType, address) {
    if (addressType == 'B') {
      var param = {
        "selSoldTo": JSON.stringify(address),
        "moreShipToExist": this.defaultAddressesAndRoles.moreBillToExist
      }
      this.accountService.getBillToAndShipTo(param).subscribe(
        data => {
          this.billingAddress = address;
          this.billingAddress.showLoader = false;
          this.billingAddress.psn = 'NB1';

          this.cart = this.orderService.updateBillingAddress(this.billingAddress);

          this.displayContinue();

          //save the data to local storage
          this.billToModalRef.hide();
          this.addrEditable = 'N';

          // validate address
          this.cart.billingAddress = this.billingAddress;
          this.error.phone = ''

          this.billingService.validateAddress(this.cart).subscribe(
            data => {
              //Anytime we save billing address the user needs to enter the cc details again
              this.showCheckout = false;
              this.cart.ccStatus = "";
              //orderService.setCart($scope.cart);

              this.displayPaymentOptions = data.displayPaymentOptions;

              //assign the values to the scope and local storage
              if (data.status == "S") {

                this.showCheckout = true;

                //get cc url details
                if (this.displayCCPayment) {
                  this.getCCDetails(false);
                }

                this.showUpdateBillPhoneNum = false;

                this.success.successMsg = "Billing Address Saved";

              } else if (data.status == "E") {

                if (data.messageMap) {
                  this.error.phone = data.messageMap.phone ? data.messageMap.phone : "";

                  if (data.messageMap.phone) {
                    this.showUpdateBillPhoneNum = true;
                  } else {
                    this.showUpdateBillPhoneNum = false;
                  }
                }
              }
            }
          );
        }
      );

    }
  }

  pageChange(pgNo) {
    this.pageNum = pgNo;
  }

  closeModal(modalType: string) {
    if (modalType === 'billToModal') {
      this.billToModalRef.hide();
    } else if (modalType === 'billingAddrListModal') {
      this.essBillToModalRef.hide();
    }
  }

  selectBillToAddr(addr) {
    this.billingAddress = addr;
    this.saveBillingAddr(true);
  }

  //Show existing billing address
  showExistingBillingAddr() {

    //this.showLoader = true;
    this.blockUI.start();

    this.billingService.getBillingAddrList().subscribe(
      data => {
        this.billToAddrList = data;
        this.essBillToModalRef = this.modalService.show(this.essBillToTemplate);
        this.addrEditable = 'N';
        this.blockUI.stop();
        //this.showLoader = false;
      }
    );
  }

  applyPromo() {
    var cart = this.cart;

    var promoDetails = {
      "code": this.promoCode
    };

    cart.promoDetails = promoDetails;

    this.billingService.applyPromo(cart).subscribe(
      data => {
        var cart = data;
        this.cart = cart;

        if (!this.commonService.isEmptyObj(cart.promoDetails) && cart.promoDetails.applied) {
          this.displayPromo = false;
        }

        this.orderService.setCart(this.cart);
      }
    );

  }

  removePromo() {
    var cart = this.cart;

    this.billingService.removePromo(cart).subscribe(
      data => {
        var cart = data;
        this.cart = cart;
        this.displayPromo = true;
        this.promoCode = '';
        this.orderService.setCart(this.cart);
      }
    );

  }

  //open the PCI url
  openCardInfo() {
    var cart = this.orderService.getCart();
    var poFlag = cart.billingAddress.poReq;
    var error = "N";

    if (poFlag === "Y") {
      if (!this.cart.poNumber) {
        this.error.poNumber = "Please provide the PO Number.";
        error = "Y";
      } else if (this.cart.poNumber && this.cart.poNumber.length > 50) {
        this.error.poNumber = "PO Number cannot exceed 50 chars.";
        error = "Y";
      }
    }

    if (error === "N") {
      this.blockUI.start();
      this.checkProfile();
      window.open(this.pciURL);
    }
  }

  checkProfile() {
    setTimeout(
      () => {
        this.validatePaymentInfo();
      }, 3000);
  }

  //Check if credit card details are available
  validatePaymentInfo() {

    console.log("Inside validatePaymentInfo");
    var sequenceNumber = this.orderService.getSequenceNum();

    var data = {
      "sequenceNumber": sequenceNumber
    };


    //Check if CC details are available
    this.billingService.validateCCDetails(data).subscribe(
      data => {

        console.log(data.status);
        //assign the values to the scope and local storage
        if (data.status == "S") {
          this.cart.ccStatus = data.ccStatus;
          this.orderService.setCart(this.cart);
          if (data.ccStatus == "E") {
            this.showCheckout = true;
            this.blockUI.stop();
            this.router.navigate(['cartreview']);
          } else if (data.ccStatus == "C") {
            this.blockUI.stop();
          } else if (data.ccStatus == "W") {
            if (this.ts < 1440) { // 2 hrs
              this.ts++;
              //$scope.checkProfile();
              setTimeout(() => {this.validatePaymentInfo();}, 3000);
            }
          }
        } else if (data.status == "E") {
          console.log(data.errMsg);
          this.blockUI.stop();
        }
      }
    );

  }
}
