import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-cart-confirm',
  templateUrl: './cart-confirm.component.html',
  styleUrls: ['./cart-confirm.component.css']
})
export class CartConfirmComponent implements OnInit {
  orderNumber = '';
  @BlockUI() blockUI : NgBlockUI;
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
 
  this.blockUI.start();
    
    this.route.queryParams.subscribe(
      (params) => {
        this.orderNumber = params['orderNumber'];
      })
      this.blockUI.stop();
  }

}
