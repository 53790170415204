import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountSelectionComponent } from './account-selection/account-selection.component';


const routes: Routes = [

  //uncomment below for App component routing 
  {
    path: '',
    component: AccountSelectionComponent,
    children: [{ path: 'account/account-selection', component: AccountSelectionComponent }],
  }

  //uncomment below for Module level routing uncomment below
  /* {
    path: '',
    component: AccountSelectionComponent,
    children: [{path:'account', component:AccountSelectionComponent, data: { showHeader: true, showSidebar: false, showFooter: true }}]
  } */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
