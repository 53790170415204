import { Component, Input,Output, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { OrderService } from 'src/app/service/common-service/order.service';
import { CartHelperService } from 'src/app/shipping/cart-helper-service';
import { ReviewService } from 'src/app/shipping/cart-review/review.service';


@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.css']
})
export class CartSummaryComponent implements OnInit {
  isEmptyLfsSupplyMap = false;
  isEmptyContractSupplyMap = false;
  isEmptyBillableSupplyMap = false;
  isEmptyCpcSupplyMap = false;
  isEmptyEpSupplyMap = false;
  isEmptyAzcoSupplyMap = false;
  isEmptyLfsPartsSupplyMap = false;
  isEmptyPpsSupplyMap = false;
  isEmptyPreOwnedMachine = false;
  @Input() cart = {} as any;
  @Input() isChangePasswordRequired = false;
  @Input() checkOnlyBillableSupplyMap = false;
  @Input() totalItems = 0;
  @Input() qtyErrMsg = '';
  @Input() errMsg = '';
  @Input() isBillableSuppliesFormValid = false;
  @Input() subTotal = 0;
  @Input() grandTax = 0;
  @Input() getBillSupplyShipCharge = 0;
  @Input() grandTotal = 0;
  @Input() isReadOnly = false;
  @Input() canSubmit = false;
  @Output() handleSubmitOrder = new EventEmitter();
  @Output() handleShippingAddressSubmit = new EventEmitter();
  @Output() handleBillingAddressSubmit = new EventEmitter();

  @Input() isCartConfirm = false;
  @Input() isCartPage = false;

  @Input() isShippingAddress=false;
  @Input() isBillingAddress=false;
  @Input() showCheckout = false;

  private subscription: Subscription;
  isUserLoggedIn = false;
  constructor(
    private orderService: OrderService,
    private commonService: CommonUtilsService,
    private loginAuthenticationService: LoginAuthenticationService,
    private reviewService: ReviewService,
    private router :Router,
    private cartHelperService:CartHelperService
  ) {
    this.isUserLoggedIn = this.loginAuthenticationService.isUserLoggedIn()
  }
  ngOnInit(): void {
    console.log('value of isShippingAddress:'+this.isShippingAddress);
    this.updateValuesFromCart();
    this.subscription = this.orderService.notifyObservable$.subscribe(() => {
      this.updateValuesFromCart()
    })
  }
  updateValuesFromCart() {
    if(this.isCartConfirm){
      this.cart = this.orderService.getCart();
      let tempCartforcartcountdisplay = this.orderService.getCart();
      this.orderService.setCart(this.cart);
      if(this.orderService.getTempCart() != null){
        this.cart= this.orderService.getTempCart()
      }
      this.totalItems = this.orderService.totalItems();
      this.subTotal = this.orderService.subTotal();
      this.grandTax = this.orderService.grandTax();
      this.getBillSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
      this.grandTotal = this.orderService.grandTotal();
      this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
      this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
      this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
      this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
      this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
      this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
      this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
      this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
      this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
      this.orderService.setCart(tempCartforcartcountdisplay );
      
    }else{
      localStorage.removeItem('tempCart');
      this.cart = this.orderService.getCart();
      this.totalItems = this.orderService.totalItems();
      this.subTotal = this.orderService.subTotal();
      this.grandTax = this.orderService.grandTax();
      this.getBillSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
      this.grandTotal = this.orderService.grandTotal();
      this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
      this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
      this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
      this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
      this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
      this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
      this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
      this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
      this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
    }
   
  }
  continueShopping() {
    if(this.orderService.deleteTempCart){
      localStorage.removeItem('tempCart');
    }
    this.orderService.deleteTempCartFromLS(false);
    this.commonService.getPageUrl().subscribe((result) => {
      console.log(result);

      let resultroute = new Array() ;
      let categoryName = '';
      //supplieslanding?category=SUPPLIES
      if(result){
 
        resultroute= result.url.split("?");
   
        if(resultroute.length > 1){
          let category = resultroute[1].split('=');

          if(category.length > 1)
          categoryName = category[1];
        }
      }

      if(resultroute.length > 1){
        if(categoryName != ''){
          this.router.navigate(['/',resultroute[0]],{ queryParams: { category:  categoryName} });
        }else {
          this.router.navigate(['/home']);
        }
        
        //this.router.navigate(['supplieslanding'], { queryParams: { category: 'SUPPLIES' } });
      }else{
        this.router.navigate([result.url]);
      }
     
    }, (reason) => {
      console.log(reason);
    });
    return false;
  }
  submitOrder() {
    this.handleSubmitOrder.emit();
  }
  printOrder() {
    const cart = this.orderService.getTempCart();
    this.reviewService.printOrder(cart).subscribe((data: any) =>{			
			console.log(data.headers);
			let headers = data.headers;
			let blob = new Blob([data.body], {type:headers['content-type']});			
		//	const filename = headers['content-disposition'].split(';')[1].split('filename')[1].split('=')[1].trim();
    const filename='OrderConfirmationDetails.html';
			let anchor = document.createElement('a'); // Create a element
      anchor.setAttribute('href', URL.createObjectURL(blob));
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('download', filename);
      anchor.click();		
 		}, (reason) =>{
 			console.log(reason);
 		});
  }

  triggerSubmitforshippingAddress(){
this.handleShippingAddressSubmit.emit();
    //this.cartHelperService.sendShipping('ship');
  }

  triggerSubmitforBillingAddress(){
    this.handleBillingAddressSubmit.emit();
    //this.cartHelperService.setBilling(true);
  }


}
