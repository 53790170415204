import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {

  transform(phone:string): string {

    phone.replace(/[^\d]+/g, '').replace(/^(\d{3})(\d{3})(\d{4})+$/, "($1)$2-$3");

    return phone;
  }

}
