import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

import { LocalStorageService } from 'src/app/service/common-service/local-storage.service';

import { AccountService } from 'src/app/account/account.service';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnInit {
  menuSubject = new Subject<any>();
  menuResponse = this.menuSubject.asObservable();
  menuItems = [] as any;
  baseUrl: string;
  listing = [] as any;
   acceptAndProceed = new Subject<any>();
  acceptAndProceed$ = this.acceptAndProceed.asObservable();
  constructor(private http: HttpClient,
    private util: Util,
    private localStorageService: LocalStorageService,
    private accountService: AccountService) {
    this.baseUrl = this.util.getBaseUrl();
    this.getMenu({}).subscribe(results => {
      this.menuSubject.next(results);
      if(localStorage.getItem('showRestartButton')) {
        this.acceptAndProceed.next(true);
      }
    });
  }
  ngOnInit(): void {
    if(localStorage.getItem('showRestartButton')) {
      this.acceptAndProceed.next(true);
    }
  }
  getMenu(payload): Observable<any> {
    let url = this.baseUrl + 'getMenu';
    var canonMenuRequestDto = {
      "userName": payload.userName || "",
      "canonAccountDetail": payload.details || null

    };
    return this.http.post(url, canonMenuRequestDto, httpOptions).pipe(
      map(data => {
        this.menuItems = data;
        this.menuSubject.next(data);
      }))

  }
  updateMenu(userName): Observable<any> {
    return this.accountService.getDefaultAccount().pipe(
      switchMap(data =>
        this.getMenu({ userName, details: data.accountAddressDetail })
      )
    )
  }

  // To get latest menu items globally
  getMenuItems() {
    return this.menuItems;
  }

  defaultCommonGuld() {
    
    this.baseUrl = this.util.getBaseUrl();
    this.http.get<any>(this.baseUrl + 'common.htm/guld').subscribe(
      data  => {
        let cart = this.localStorageService.getCart();
        cart.myCSAUserName = data.myCSAUserName;
        cart.myCSAUserCompanyName = data.myCSAUserCompanyName;
        cart.lfsSupplyRole = data.lfsSupplyRole;
        cart.lfsPartsRole = data.lfsPartsRole;
        cart.ppsSupplyRole = data.ppsSupplyRole;
        cart.orderSupplyRole = data.orderSupplyRole;

        this.localStorageService.setCart(cart);

      });
  }

  defaultCommonHomegfpdb() {
    this.baseUrl = this.util.getBaseUrl();
    const canonEquipmentFilter = {
    }
    const httpOptions = {
      headers: new HttpHeaders({
      }).set('Content-Type', 'application/json')
    };
    return this.http.post(this.baseUrl + 'home.htm/gfpdb', canonEquipmentFilter).subscribe(data => {
      let existingCart = this.localStorageService.getCart();
      this.localStorageService.setCart({ ...data, ...existingCart });

    });
  }


  defaultCommonProductgpsd() {
    this.baseUrl = this.util.getBaseUrl();
    const httpOptions = {
      headers: new HttpHeaders({
      }).set('Content-Type', 'application/json')
    };
    return this.http.get<any>(this.baseUrl + 'product.htm/gpsd').subscribe(data => {

      let existingCart = this.localStorageService.getCart();
				existingCart.cartProductLockTime = data.cartProductLockTime;
			existingCart.cartMaxProductSize = data.cartMaxProductSize;
			this.localStorageService.setCart(existingCart);
			//this.localStorageService.setCart({ ...data, ...existingCart });
      

     

    });
  }
  // updateCommonGuld() {
  //   this.baseUrl = this.util.getBaseUrl();
  //   this.http.get(this.baseUrl + 'common.htm/guld').subscribe(
  //     data => {
  //       let existingCart = this.localStorageService.getCart();
  //       this.localStorageService.setCart({...data,...existingCart});
  //     });
  // }


}  