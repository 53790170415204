import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LargeformatpartsRoutingModule } from './largeformatparts-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { LargeformatpartsSelectionComponent } from './largeformatparts-selection/largeformatparts-selection.component';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from '../shop-common/block-template.component';
import { ShopCommonModule } from '../shop-common/shop-common.module';

@NgModule({
  declarations: [LargeformatpartsSelectionComponent],
  imports: [
	FormsModule,
    CommonModule,
    LargeformatpartsRoutingModule,
    NgxPaginationModule,
    ShopCommonModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    })
  ],
  entryComponents :[BlockTemplateComponent]
})
export class LargeformatpartsModule { }
