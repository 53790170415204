import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ShopConstants } from 'src/app/common/shopConstants';
//import { megaMenu } from 'src/app/common/megaMenuALUTHER';
//import { megaMenu } from 'src/app/common/megaMenuMELANIE';
//import { megaMenu } from 'src/app/common/megaMenuABELL';
//import { megaMenu } from 'src/app/common/megaMenuJOE';
//import { megaMenu } from 'src/app/common/megaMenu';
import { favParam } from 'src/app/largeformatparts/model/favParam';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { MeterreadModalComponent } from 'src/app/ordercontractsupplies/ordercontractsupplies-selection/meterread-modal/meterread-modal.component';
import { OrdercontractsuppliesService } from 'src/app/ordercontractsupplies/ordercontractsupplies.service';
import { SearchFilterPipe } from 'src/app/pipe/search-filter.pipe';
import { OrderService } from 'src/app/service/common-service/order.service';
import { SupplyService } from 'src/app/supplies/supply.service';

@Component({
  selector: 'app-supply-listing',
  templateUrl: './supply-listing.component.html',
  styleUrls: ['./supply-listing.component.css']
})
export class SupplyListingComponent implements OnInit {

  favParam : favParam = new favParam();

  @BlockUI() blockUI : NgBlockUI;

  @ViewChild('errorMsg') errTemplate:TemplateRef<any>;
  @ViewChild('successMsg') successTemplate:TemplateRef<any>;
  @ViewChild('pricingErrorMsg') pricingErrorTemplate:TemplateRef<any>;
  @ViewChild('multiSelect') multiSelectTemplate:TemplateRef<any>;
  @ViewChild('modelsListing') modelsListTemplate:TemplateRef<any>;
  @ViewChild('serialList') serialListTemplate:TemplateRef<any>;
	@ViewChild('tonerMsgModal')  tonerMsgTemplate:TemplateRef<any>;
	

  multiSelectModalRef: BsModalRef;
  errorModalRef: BsModalRef;
  successModalRef: BsModalRef;
  pricingModalRef: BsModalRef;
  modelsModalRef: BsModalRef;
  serialModalRef: BsModalRef;
  tonerMsgModalRef: BsModalRef	
  
  selectedSupplyType : any = {};
  selectedSupplyCategory : any = {};
  selectedTonerMediaType :string = '';
  selectedStapleType : string = '';
  selectedModel : string = '';
  selectedLFSProdGroup : string = '';
  selectedLFSProdSubGroup : string =  '';
  selectedPPSProdGroup : string = '';
  selectedPPSProdSubGroup : string = '';
 
  selectedCategory : string = '';
  
  selectedSBModel : string = '';

  selectedSortBy : any = {};

  tonerMediaTypes: string[] = [];
  stapleTypes: string[] = [];
  productGroups: any[] = [];
  productSubGroups: any[] = [];
  sbModels: string[] = [];
  megaMenuList : any[];
  newSupplies : any[] = [];
  breadcrumbList : any[] = [];
  modelsList : string[] = [];
  
  showTonerFilters: boolean = false;
  showTonerModelFilters: boolean = false;
  showStapleFilters: boolean  = false;
  showMediaFilters: boolean  = false;
  showLFSProdGroupFilters: boolean  = false;
  showLFSProdSubGroupFilters: boolean = false;
  showPPSProdGroupFilters: boolean = false;
  showPPSProdSubGroupFilters: boolean = false;
  showSBModelFilters: boolean = false;
  showCategoryFilters: boolean = false;

 
  serialNumber : string = '';
  errMsg: string = '';
  successMessage : string = ''

  machine : any = {};

  category : string;
  globalSearchFilter: string = '';


  displayPrice :boolean = false;
  priceErrMsg : string = '';

  suppliesPerPage :string = '24';
			
  sortBy : any = {};
  
  displayMP : string = 'N';

  isSuppliesLoaded : boolean = false;
			
	displayGrid : string = 'Y';
  
  // These were not defined at the top
  serialNumberList : any[];

  showLoader : boolean = false;

  parentCategory: string;

  totalRecords : number = 0;
  
  processingRequest : string = '';
  
  pleaseWaitCls : string = '';

  placeholder : any;

  priceMode: boolean = true;

  pageNum : number = 1;

  modelPageNum : number = 1;

  compatiblePageNum : number = 1;

  serialPageNum : number = 1;

  cart : any = {};

  searchFilterPropertyNames : string[] = ['description', 'eCommerceDesc', 'inventoryItemId', 'itemCd', 'mfrItemCode', 'supplyCatDesc'];

  serialSearchFilterPropertyNames : string[] = ['serialNumber', 'externalReference', 'model', 'address'];

  userOrdSuppRole : boolean = false;

  displaySerialNoFilter : boolean = false;
  compatibleModels: any[] = [];

  serialText : string = ''
  notFoundMsg: string = '';
  selectedPrinter: any;
  searchText: any;
  search: any;
  modelText: string = '';

  sortByOptions : any[] = ShopConstants.supplySortOptions;

  mouseOverSupplyItemCd : string  = '';

  displayTonerMsg = false;

 
  constructor(private route : ActivatedRoute, private searchPipe : SearchFilterPipe, 
    private orderService : OrderService, private supplyService: SupplyService, 
    private router : Router, private modalService: BsModalService, 
    private menuService: MenuService, private orderCSService: OrdercontractsuppliesService,
    private ngbModalService: NgbModal) { }

  ngAfterViewInit() {
    //console.log(this.left, this.right);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.category = params['category'];
        this.globalSearchFilter = params['itemCd'];

        this.loadInitialData();
      }
    )

    
   
  }

  loadInitialData(){
    //let allMenus = this.orderService.getMenu();
    //this.megaMenuList = megaMenu;

    this.resetFilterDetails();

    this.newSupplies = [];

    let menuItems = this.menuService.getMenuItems(); 

    this.megaMenuList = menuItems.menu;

    let menuList = this.searchPipe.transform(this.megaMenuList,this.category, ['name'], true);    

    if(menuList.length > 0){
      this.selectedSupplyCategory = menuList[0];

      this.breadcrumbList = this.selectedSupplyCategory.breadcrumbList;

      if(this.breadcrumbList.length == 3){
        let prodGroup = this.selectedSupplyCategory.name;
        this.parentCategory = this.breadcrumbList[1].name;
      }
      if(this.breadcrumbList.length == 2){
        //Get the 2nd level category
        this.parentCategory = this.breadcrumbList[1].name;
      }
      if(this.breadcrumbList.length == 1){
        //Get the 2nd level category
        this.parentCategory = this.selectedSupplyCategory.name;
      }
      if(this.orderService.hasLFSSupplyRole() || this.orderService.hasPPSSupplyRole() || this.orderService.hasLFSPartsRole()){
        this.displayGrid = 'N';
      }
  
      if(this.orderService.hasLFSSupplyRole() || this.orderService.hasPPSSupplyRole() || this.orderService.hasLFSPartsRole() || !this.isUserSignedIn()){
        this.displayPrice = false;
      }
  
  
      this.machine = this.orderService.getMachine();
  
      var param = {
        instanceId : this.machine.instanceId
      }
  
      this.orderService.getShippingInstructions(param).subscribe(
        data => {
          if(this.machine){
            //this.machine.shippingInstruction = data.shippingInstruction;
            this.machine.shippingInstruction = data["shippingInstruction"];
            }
        }
      );
  
      if(this.machine.serialNumber != null && this.machine.serialNumber != 'undefined'){
        this.selectedModel = this.machine.model;
        this.serialNumber = this.machine.serialNumber;
        this.orderService.removeMachine();
      }
        
      this.cart = this.orderService.getCart()
  
      this.setFilterDisplayDetails();
  
      this.getSupplyList();

    }else{
      //this.hidePleaseWait();
      this.pleaseWaitCls = '';
      this.isSuppliesLoaded = true;
      this.totalRecords = 0;
      
      this.breadcrumbList = [];

      this.selectedSupplyCategory = {};

      this.notFoundMsg = 'No Results Found. Please verify your search criteria or for further assistance call '+ (localStorage.getItem("contactPhone") ? localStorage.getItem("contactPhone") : '');

    }

    
  }

  isUserSignedIn() {

    if(this.orderService.getCart().myCSAUserName  || this.orderService.getTempCart() || sessionStorage.getItem('userName')){
      return true;
    }
    
    return false;
  };

  showSerialNumberList(){

    this.serialNumberList = [];
    this.serialText = '';
    this.serialModalRef = this.modalService.show(this.serialListTemplate, { class: 'serialNumModalDialog' });

    this.showLoader = true;

    this.supplyService.getSerialNumberList().subscribe(
      data => {
        this.serialNumberList = data.machineDetailList;
        this.showLoader = false;
      }
    );		
				
  }

  setFilterDisplayDetails () {

    this.showTonerFilters = false;
    this.showStapleFilters = false;
    this.showMediaFilters = false;
    this.showPPSProdGroupFilters = false;
    this.showPPSProdSubGroupFilters = false;
    this.showLFSProdGroupFilters = false;
    this.showLFSProdSubGroupFilters = false;
    this.showSBModelFilters = false;
    this.showCategoryFilters = false;
    this.showTonerModelFilters = false;
    
    var immediateParent = this.breadcrumbList[this.breadcrumbList.length -1];
    
    if(this.category == 'COLOR_PRINTER_SUPPLIES' || this.category == 'BLACK_WHITE_PRINTER_SUPPLIES'	 || this.category == 'FAX'
      || this.category == 'IMAGE_CLASS_SUPPLIES' || this.category == 'HEWLETT_PACKARD_INK' ){
        this.showTonerFilters = true;
      
      if(this.category != 'HEWLETT_PACKARD_INK'){
        this.showTonerModelFilters = true;
      }
      
        this.showStapleFilters = false;
        this.showMediaFilters = false;
        this.getTonerMediaTypes();

    }else if(this.category == 'OFFICE_MEDIA' || this.category == 'LFS_MEDIA'){
      this.showTonerFilters = false;
      this.showTonerModelFilters = false;
        this.showStapleFilters = false;
        this.showMediaFilters = true;
        this.getTonerMediaTypes();
    }else if(this.category == 'STAPLES'){
      this.showStapleFilters = true;
        this.showTonerFilters = false;
        this.showTonerModelFilters = false;
        this.showMediaFilters = false;
        this.getStapleTypes();
    }else if(this.category == 'IMAGEPROGRAF' || this.category == 'MEDIA'){
      this.showTonerFilters = true;
      this.showTonerModelFilters = true;
      this.getTonerMediaTypes();
    }else if(this.category == 'EASY_PAC' || this.category == 'COST_PER_COPY' || this.selectedSupplyCategory.name == 'ARIZONA_INK' || this.category == 'PRIVATE_LABEL'){
      this.showLFSProdGroupFilters = true;
      this.showLFSProdSubGroupFilters = false;
      this.getLFSProdGroupsSubGroups();
    }else if(immediateParent!=null && immediateParent.name == 'SHOP_BY_APPLICATION'){
      this.showLFSProdSubGroupFilters = true;
      this.getLFSSubGroups();
    }else if(immediateParent!=null && immediateParent.name == 'SHOP_BY_MODEL'){
      this.showSBModelFilters = true;
      this.getSBModels();
    }else if(immediateParent!=null && immediateParent.name == 'PRODUCTION_PRINTING_SUPPLIES'){
      this.showPPSProdSubGroupFilters = true;
      this.getPPSProductGroupSubgroups();
    }
    
    if(this.category == 'OFFICE_PRODUCT_SYSTEMS' || this.category == 'LARGE_FORMAT_SUPPLIES' || this.category == 'PRODUCTION_PRINTING_SUPPLIES'){
      this.showCategoryFilters = true;
    }
    //this.getSupplyList();
  };

  getTonerMediaTypes(){
				
    let menuList = this.searchPipe.transform(this.megaMenuList,this.category, ['name'], true); 

    if(menuList.length > 0){
      var category = menuList[0];
    }

    var param = {
      "category" : JSON.stringify(category)
    }
    
    this.supplyService.getTonerMediaTypes(param).subscribe(
      data => {
        this.tonerMediaTypes = data;
      }
    );

  }

  getStapleTypes(){
				
    this.supplyService.getStapleTypes().subscribe(
      data => {
        this.stapleTypes = data;
      }
    );
  }

  getLFSProdGroupsSubGroups(){
			
    //let cart = this.orderService.getCart();
    var param = {
        "accountNumber" : this.cart.accountInfo != null ? this.cart.accountInfo.defaultSoldTo.accountNumber : '',
        "supplyCategory" : this.selectedSupplyCategory == null ? null : this.selectedSupplyCategory.name		
    }
    this.supplyService.getLFSProductGroupSubgroups(param).subscribe(
      data => {
        this.productGroups = data.group;	
        this.productSubGroups = data.subGroup;

        //this.productGroups.unshift(ShopConstants.defaultProductGroup);
        //this.productGroups.splice(0, 0, defaultProdGroup);
        //this.selectedLFSProdGroup = this.productGroups[0];
        this.selectedLFSProdGroup = "";

        this.selectedLFSProdSubGroup = '';
      }
    );
    
  }

  getLFSSubGroups(){
				
    var param = {
        "group" : this.selectedSupplyCategory.name
    }
    this.supplyService.getLFSubgroups(param).subscribe(
      data => {
        this.productSubGroups = data.subGroup;
      }
    );
    
  }

  getSBModels = function(){
				
    this.supplyService.getSBModels().subscribe(
      data => {
        this.sbModels = data.modelList;  
      }
    );
      
  }

  getPPSProductGroupSubgroups = function(){
				
    this.supplyService.getPPSProductGroupSubgroups().subscribe(
      data => {
        this.productGroups = data.group;	
        this.productSubGroups = data.subGroup;
      }
    );
    
  }

  onCategoryChange(categoryName){
    //this.router.navigate(['supplies'], {queryParams : {category:category.name}})	
    this.category = categoryName;

    let menuList = this.searchPipe.transform(this.megaMenuList,this.category, ['name'], true);    

    if(menuList.length > 0){
      this.selectedSupplyCategory = menuList[0];

      if(this.selectedSupplyCategory.route === 'supplies'){
        //this.loadInitialData();
        this.router.navigate(['/supplies'], {queryParams : {category:this.selectedSupplyCategory.name}});
      }else{
        this.router.navigate([this.selectedSupplyCategory.route], {queryParams : {category:this.selectedSupplyCategory.name}});
      }

    }
    
  }

  onStapleTypeChange(){
    this.getStapleTypes();
    if(!this.isSerialPresent()){
      this.getSupplyList();
    }
  }

  showModelsList(){
				
    this.modelsList = [];
    this.modelText = '';
    this.modelsModalRef = this.modalService.show(this.modelsListTemplate);
    this.showLoader = true;
        
    let menuList = this.searchPipe.transform(this.megaMenuList,this.category, ['name'], true);  

    if(menuList.length > 0){
      var category = menuList[0];
    }
  
    var param = {
        "category" : JSON.stringify(category),
    }
    
    this.supplyService.getModelsList(param).subscribe(
      data => {
        this.modelsList = data;
        this.showLoader = false;
      }
    );
  }

  isSerialPresent(){
    if(this.serialNumber){
      return true;
    }				
    return false;	
  }

  sort(){

    let sortList = this.searchPipe.transform(this.sortByOptions,this.sortBy, ['sortKey'], true); 

    if(sortList.length > 0){
      this.selectedSortBy = sortList[0]; 	
    }
  }

  getSupplyList(){
				
    this.isSuppliesLoaded = false;
    this.totalRecords = -1;
    
    this.newSupplies = [];
    this.pleaseWaitCls = 'pleaseWaitCls';
    
    var prodGroup = '';
    var prodSubGroup = '';
    var selCat = this.selectedSupplyCategory == null ? null : this.selectedSupplyCategory.name;
    
    
    if(this.selectedSupplyCategory.name == 'EASY_PAC' || this.selectedSupplyCategory.name == 'COST_PER_COPY' ||this.selectedSupplyCategory.name == 'ARIZONA_INK' || this.selectedSupplyCategory.name == 'PRIVATE_LABEL'){
      prodGroup = this.selectedLFSProdGroup != '' ? this.selectedLFSProdGroup : '';
      prodSubGroup = this.selectedLFSProdSubGroup != '' ? this.selectedLFSProdSubGroup : '';
    }else {
      var immediateParent = this.breadcrumbList[this.breadcrumbList.length -1];
      
      if(immediateParent != null && immediateParent.name == 'SHOP_BY_APPLICATION'){
        prodGroup = this.selectedSupplyCategory != null ? this.selectedSupplyCategory.name : '';
        prodSubGroup = this.selectedLFSProdSubGroup != '' ? this.selectedLFSProdSubGroup : '';

        let supplyCatList = this.searchPipe.transform(this.megaMenuList,this.breadcrumbList[2].name, ['name'], true);    

        if(supplyCatList.length > 0){
          selCat = supplyCatList[0].name;	
        }
      } else if(immediateParent != null && immediateParent.name == 'SHOP_BY_MODEL'){
        prodGroup = this.selectedSupplyCategory != null ? this.selectedSupplyCategory.name : '';
        prodSubGroup = this.selectedSBModel != '' ? this.selectedSBModel : '';

        let supplyCatList = this.searchPipe.transform(this.megaMenuList,this.breadcrumbList[2].name, ['name'], true);    

        if(supplyCatList.length > 0){
          selCat = supplyCatList[0].name;	
        }
      } else if(immediateParent != null && immediateParent.name == 'PRODUCTION_PRINTING_SUPPLIES'){
        prodGroup = this.selectedSupplyCategory != null ? this.selectedSupplyCategory.name : '';
        prodSubGroup = this.selectedPPSProdSubGroup != '' ? this.selectedPPSProdSubGroup : '';

        let supplyCatList = this.searchPipe.transform(this.megaMenuList,this.breadcrumbList[1].name, ['name'], true);    

        if(supplyCatList.length > 0){
          selCat = supplyCatList[0].name;	
        }
      }
    }
    
    
    var param = {
        "serialNumber" : this.serialNumber,
        "supplyCategory" :  selCat,
        "parentCategory" : this.parentCategory == null ? null : this.parentCategory,
        "model" : this.selectedModel,
        "stapleType" : this.selectedStapleType,
        "tonerType" : this.selectedTonerMediaType,
        "accountNumber" : this.cart.accountInfo ? (this.cart.accountInfo.defaultSoldTo ? this.cart.accountInfo.defaultSoldTo.accountNumber : '' ): '',
        "locationNumber" :  this.cart.accountInfo ? (this.cart.accountInfo.defaultSoldTo ? this.cart.accountInfo.defaultSoldTo.locationId  : '') : '',
        "group" : prodGroup,		
        "subGroup" : prodSubGroup,
        "itemCode" : this.globalSearchFilter
    }
    //TODO
    /* if( p!='search'){
      this.searchText ="";
    } */

    
    
    this.supplyService.getSupplyList(param).subscribe(
      data => {
        if(data.status == "S"){
          this.newSupplies = data.Listing || [];
          this.displayMP = data.displayMP;
          
          this.sortByOptions = [];
          this.sortByOptions = ShopConstants.supplySortOptions
          this.sortBy = 'az';
          this.selectedSortBy = this.sortByOptions[0];
          
          if(this.displayMP === 'Y'){
            this.sortByOptions.push(ShopConstants.mostPopularSortOption);
            this.sortBy = 'mp';
            this.selectedSortBy = this.sortByOptions[this.sortByOptions.length-1];
          }

          this.userOrdSuppRole = data.orderSupplyRole;

          if(this.isUserSignedIn() && this.userOrdSuppRole){
            this.displaySerialNoFilter =  true;
          }else{
            this.displaySerialNoFilter =  false;
          }
          
          this.totalRecords = data.Listing != null ? data.Listing.length : 0;
          this.isSuppliesLoaded = true;
          this.search = this.searchText;
          
          this.newSupplies.forEach( 
            supply => {
              supply.price = supply.price;

              if(this.globalSearchFilter && this.newSupplies.length == 1){
                supply.selected = true;
              }
              console.log('supply.tonerFlg:' + supply.tonerFlg);
              console.log('supply.supplyCategory:' + supply.supplyCategory);
              
              //Start
              if(supply.tonerFlg=='N' && (supply.supplyCategory=='01'||supply.supplyCategory=='02'||supply.supplyCategory=='03'||supply.supplyCategory=='04')){
                this.displayTonerMsg = true;
              }
              //End
            }
          )

          if(this.displayTonerMsg){
            this.tonerMsgModalRef = this.modalService.show(this.tonerMsgTemplate);
          }
          

        }else if (data.status == "E"){
          this.totalRecords = 0;
          if(data.serialError == "Y"){
            window.location.href = this.selectedSupplyCategory.linkPage;
          }
        }
        
        this.pleaseWaitCls = '';
        //this.hidePleaseWait();
      }
    );
    
  }	
  onProductGroupChange(){
    this.selectedLFSProdSubGroup = '';
		
    if(this.selectedLFSProdGroup == ''){
      this.showLFSProdSubGroupFilters = false;
    }else{
      this.showLFSProdSubGroupFilters = true;
    }

    this.getSupplyList();	
  }

  changeDisplay(gridDisplay){
    this.displayGrid = gridDisplay;
  }

  resetFilterDetails(){
    this.serialNumber = '';
				
    this.selectedStapleType = '';
    this.selectedModel = '';
    this.selectedTonerMediaType = '';
    this.selectedSBModel = '';
    this.selectedLFSProdGroup = ''
    this.selectedLFSProdSubGroup = '';
    this.selectedPPSProdSubGroup = '';  
    this.selectedCategory = '';  
  }

  clearFilter(){

    this.resetFilterDetails();
    
    this.getSupplyList();
  }

  openMultipleOrderTypeModal(priceMode, template: TemplateRef<any>){
    this.priceMode = priceMode;
				
    if(!this.priceMode){
      //Loop through the selected items
      let selectedSupplies = this.searchPipe.transform(this.newSupplies,'true', ['selected']);    

      //Validate qty
      var qtyError = false;

      selectedSupplies.forEach(
        supply => {
          if(!this.validateQtyOnAddToCart(supply)){
            qtyError = true;
          }
        }
      )
            
      if(qtyError){
        return false;
      }
    }

    this.multiSelectModalRef = this.modalService.show(this.multiSelectTemplate);
				
  }
  
  addESSSuppliesToCart(){
    this.errMsg = '';
    let selectedSupplies = this.searchPipe.transform(this.newSupplies,true, ['selected']); 

    selectedSupplies.forEach(
      supply => {
        supply.selected = false;
        this.addSupplyToCart(supply,'');
      }
    )

    /* if(this.errMsg){
      this.errorModalRef = this.modalService.show(this.errTemplate);
    }else{
        let contactPhone = localStorage.getItem("contactPhone") ? localStorage.getItem("contactPhone") : '';
        this.successMessage = 'Item added in cart. Please note only ground shipping is available. If expedited shipping is required please call '+ contactPhone;
        this.successModalRef = this.modalService.show(this.successTemplate);
    } */

    if(!this.errMsg){
      let contactPhone = localStorage.getItem("contactPhone") ? localStorage.getItem("contactPhone") : '';
      this.successMessage = 'Item added in cart. Please note only ground shipping is available. If expedited shipping is required please call '+ contactPhone;
      this.successModalRef = this.modalService.show(this.successTemplate);
    }
  }
  addSupplyToCart(supply, orderType) {
    let data = {
      "machine" : this.machine
    }

    var addedSuccessfully = this.orderService.addSupplyToCart(supply, data, orderType);
      
    if(!addedSuccessfully && addedSuccessfully != undefined){
      
      if(supply.b_c_flag == 'B'){
        supply.qtyError = 'Y';
        supply.qtyErrMsg = 'Please select maximum ' + supply.maxQuantity;
        //TODO
        //this.newSupplies[index] = supply;
        
      }else{
        if(supply.supplyCategory=='01'){
          this.errMsg = this.errMsg + supply.bwMsg + " " + supply.dispQuantity;
        }else if(supply.supplyCategory=='02' || supply.supplyCategory=='03' || supply.supplyCategory=='04'){
          this.errMsg =  this.errMsg + supply.clrMsg + " " + supply.dispQuantity;
        }else{
          this.errMsg =  this.errMsg + "Item Code : " + supply.itemCd
          +" - Quantity selected exceeds a one month supply, please change the total quantity to a maximum of " + supply.dispQuantity;
        }
        this.errMsg = this.errMsg +"<BR>"
        if (supply.productCategory!='PR'){
         // $("#errorMsgModal").modal('show');
         this.errorModalRef = this.modalService.show(this.errTemplate);
        }
      }
      
    }
      

  }

  anySupplySelected(){

    let selectedSupplies = this.searchPipe.transform(this.newSupplies,true, ['selected']); 

	  if(selectedSupplies.length > 0){
      return false;
    }else{
      return true;
    }
  }

  //TODO
  redirectUrl(){
    this.router.navigate(['cart'])
  }
  
  handleMissingImage(supply: any){
    supply.image = supply.defaultImg;
  }

  listSupplyMouseOver(selectedItemCd : string){
    if(this.displayGrid == 'N'){
      this.mouseOverSupplyItemCd = selectedItemCd;
    }
    
  }

  listSupplyMouseLeave(){

    if(this.displayGrid == 'N'){
      this.mouseOverSupplyItemCd = '';
    }
  }

  getCompatibleModels(itemCd:string){

    this.compatibleModels = [];
    this.supplyService.getCompatibleModels(itemCd).subscribe(
      data => {
        this.compatibleModels = data;
      }
    );
      
				
  }

  validateQtyOnAddToCart(supply){
    supply.qtyError = 'N';
    supply.qtyErrMsg = '';

    var cartSupply = null;
    var cart = this.orderService.getCart();
    //Validate qty
    if(this.isCPCSupply(supply)){
      cartSupply = this.orderService.getItemFromCart(supply,'CPC');
    }else if(this.isEPSupply(supply)){
      cartSupply = this.orderService.getItemFromCart(supply,'EP');
    }else if(this.isAZCOInkSupply(supply)){
      cartSupply = this.orderService.getItemFromCart(supply,'AZCO');
    }else if(this.isPLSupply(supply) || this.isLFSSupply(supply)){
      cartSupply = this.orderService.getItemFromCart(supply,'LFS');
    }else if(this.isPPSSupply(supply)){
      cartSupply = this.orderService.getItemFromCart(supply,'PPS');
    }
    //Check if selected qty > min
    if((cartSupply == null && supply.selectedQty < supply.minReqQuantity) || (cartSupply != null && (cartSupply.selectedQty + supply.selectedQty) < supply.minReqQuantity)){
      supply.qtyError = 'Y';
      supply.qtyErrMsg = 'Please select minimum ' + supply.minReqQuantity;
      return false;
    }
    
    //Check if the selected qty < max
    if((cartSupply == null && supply.selectedQty > supply.maxQuantity) || (cartSupply != null && (cartSupply.selectedQty + supply.selectedQty) > supply.maxQuantity)){
      supply.qtyError = 'Y';
      supply.qtyErrMsg = 'Please select maximum ' + supply.maxQuantity;
      return false;
    }
    
    //Check if multiples is selected
    if (supply.selectedQty % supply.orderIncrements != 0){
      supply.qtyError = 'Y';
      supply.qtyErrMsg = 'Enter multiples of ' + supply.orderIncrements;
      return false;
    }
    
    if((cart.lfsSupplyRole == 'Y' || cart.lfsPartsRole == 'Y' || cart.ppsSupplyRole == 'Y') && supply.productCategory != 'PR'){
      if(!supply.selected){
        supply.selected = true;
      }		
    }
    return true;
  }

  onBlurQty(supply){
    if(supply.selectedQty == ""){
      supply.selectedQty = supply.orderIncrements;
    }/* else{
      this.placeholder = supply.selectedQty;
    } */
  }

  onFocusQty(supply){
    //this.placeholder = supply.selectedQty;
    supply.selectedQty = "";
  }

  validateQty(supply){
    if (supply.selectedQty % supply.orderIncrements !=0){
      supply.qtyError = 'Y';
      supply.qtyErrMsg = 'Enter multiples of ' + supply.orderIncrements;
    }else{
      supply.qtyError = 'N';
      supply.qtyErrMsg = '';
    }
				
    if(!supply.selected){
      supply.selected = true;
    }	
  }

  range = function(start, supply) {
    var result = [];
    var maxVal = supply.maxQuantity;
    var mulVal = supply.orderIncrements;
    if(maxVal>0){
      for(var i=1;i<=maxVal;i++){
        var tmpQty = i * mulVal;
        if (((mulVal<maxVal) && (tmpQty<=maxVal)) || mulVal == maxVal){
           result.push(tmpQty);
        }else if ((mulVal>maxVal-1) && (tmpQty<=mulVal)){
           result.push(tmpQty);
        }
      }
    }
    return result;
  }

  continueBtn(){
    if(this.orderService.totalItems()== 0) {
      return false;
    }
      return true;
  }

  selectModel(model){

    this.modelsModalRef.hide();

    this.selectedModel = model;
				
    if(!this.isSerialPresent()){
      this.getSupplyList();
    }
  }

  //hidePleaseWait(){}

  totalItems(){
    return this.orderService.totalItems();
  }

  getMultiplePriceOrGetMultiplePriceAndAdd(){

    this.blockUI.start();

    this.priceErrMsg = '';
    //this.processingRequest = 'processingRequest';
				
    let selectedSupplies = this.searchPipe.transform(this.newSupplies,'true', ['selected']);    

				
		let cart = this.orderService.getCart();
				
    var param = {
        "supply" : JSON.stringify(selectedSupplies),
        "shipType" : "STD",	
        "cart" : JSON.stringify(cart)	
    }
				
    this.supplyService.getMultipleSupplyPrice(param).subscribe(
      data => {
        this.multiSelectModalRef.hide();
							
        //this.hidePleaseWait();
        //this.processingRequest = '';
        
        var priceDetails = data;
          
        var priceSelectedSupplies = priceDetails.selectedItems;
          
        var lfsItems = priceDetails.lfsErrorItems;
          
        var ppsItems = priceDetails.ppsErrorItems;
         
        //Update the price for the selected supplies
        selectedSupplies.forEach(
          supply => {
            priceSelectedSupplies.forEach(
              supplyPrice => {
                if(supply.itemCd == supplyPrice.itemCd){
                  console.log('supply.itemCd:' + supply.itemCd + ' price:' + supplyPrice.price);
                  supply.price = supplyPrice.price;
                }
              }
            )
          }
        )
          
          

        //If user selected add to cart
        if(!this.priceMode){
          //Update the cart details for existing items
          let cart = priceDetails.cart;
          this.orderService.setCart(cart);

          //Add to cart the selected items
          selectedSupplies.forEach(
            currSupply => {
              currSupply.selected = false;
              if(currSupply.price != -1){
                this.addSupplyToCart(currSupply, currSupply.orderType);	
              }
            }
          )
        }

        this.blockUI.stop();

        //Check if there are any LFS items where the price is not available
        if(lfsItems.length > 0){
          this.priceErrMsg = 'Please call 1-800-323-4827 option 2 for assistance for item(s) <br>' + lfsItems.join("<br>");	
        }
          
        //Check if there are any PPS items where the price is not available
        if(ppsItems.length > 0){
          if(lfsItems.length > 0){
            this.priceErrMsg +=  "<br><br>";
          }
          this.priceErrMsg += 'Please call 1-866-472-7876 for assistance for item(s) <br>' + ppsItems.join("<br>");	
        }
          
        //If there are any pricing error show the details
        if(this.priceErrMsg != ''){
          this.pricingModalRef = this.modalService.show(this.pricingErrorTemplate);
        }

        
      },
      error => {
        this.blockUI.stop();
        console.log('Error occurred while fetching supply price:', error);
      }
    );
				
				
  }

  isLFSSupply(supply : any){
    if(supply.source =='LFS' ){
      return true;
    }else{
      return false;
    }
  }

  isPPSSupply(supply : any){
    if(supply.source =='PPS' ){
      return true;
    }else{
      return false;
    }
  }

  isCPCSupply(supply : any){
    if(supply.cpc && supply.cpc == 'Y'){
      return true;
    }else{
      return false;
    }
  }

  isEPSupply(supply : any){
    if(supply.easyPac && supply.easyPac =='Y'){
      return true;
    }else{
      return false;
    }
  }

  isAZCOInkSupply(supply : any){
    if(supply.azcoInk && supply.azcoInk =='Y'){
      return true;
    }else{
      return false;
    }
  }

  isPLSupply(supply){
    if(supply.privateLabel && supply.privateLabel == 'Y' ){
      return true;
    }else{
      return false;
    }
  }

  isOrderTypeReadOnly (supply, orderType){
				
    //PPS - Standard
    if(this.isPPSSupply(supply)){
      console.log('PPS disabled is false');
      return false;
      
    }else if (this.isCPCSupply(supply)){
      var cartSupplyCPC = null;
      var cartSupplyLFS = null;
      var disabled = false;
      
      if("STD" == orderType){
        cartSupplyCPC = this.orderService.getItemFromCart(supply,'CPC');
        
        if(cartSupplyCPC == null){
          console.log('CPC Standard disabled is false');
          disabled = false;
        }else{
          console.log('CPC Standard disabled is true');
          disabled = true;
        }
      }else{
        cartSupplyLFS = this.orderService.getItemFromCart(supply,'LFS');
        
        if(cartSupplyLFS == null){
          console.log('CPC CPC disabled is false');
          disabled =  false;
        }else{
          console.log('CPC CPC disabled is true');
          disabled = true;
        }
      }
      
      if(cartSupplyCPC != null){
        supply.orderType='CPC';
        console.log('supply.orderType:' + supply.orderType);
      }
      
      return disabled;
      
    }else if (this.isEPSupply(supply)){
      
      var cartSupplyEP = null;
      var cartSupplyLFS = null;
      var disabled = false;
      
      if("STD" == orderType){
        cartSupplyEP = this.orderService.getItemFromCart(supply,'EP');
        
        if(cartSupplyEP == null){
          console.log('EP Standard disabled is false');
          disabled =  false;
        }else{
          console.log('EP Standard disabled is true');
          disabled =  true;
        }
      }else{
        cartSupplyLFS = this.orderService.getItemFromCart(supply,'LFS');
        
        if(cartSupplyLFS == null){
          console.log('EP EP disabled is false');
          disabled =  false;
        }else{
          console.log('EP EP disabled is true');
          disabled =  true;
        }
      }
      
      if(cartSupplyEP != null){
        supply.orderType='EP';
        console.log('supply.orderType:' + supply.orderType);
      }/*else{
        supply.orderType='LFS';
        console.log('supply.orderType:' + supply.orderType);
      }*/
      return disabled;
      
    }else if(this.isLFSSupply(supply)){
      console.log('LFS disabled is false');
      return false;
    }
  }

  selectSerial(serial){
    
    //Check if meter reads are due

    var paramObj = {
			"serialNumber": serial.serialNumber,
			"contractNumber": serial.contractNum,
			"contractId": serial.contractId
		}
		this.orderCSService.getSerialMeterReadsData(paramObj.serialNumber, paramObj.contractId, paramObj.contractNumber).subscribe(
			data => {
				if (data.meterreads) {
					if (data.meterreads[0].actionRequired == 'Y') {
						const modalRef = this.ngbModalService.open(MeterreadModalComponent);
						modalRef.componentInstance.meterReads = data.meterreads;
						modalRef.result.then((isAccepted) => {
							//var objPromise = this.service.getCSPurchaseDetails(paramObj);
							if (isAccepted) {
								this.redirectToSupplies(serial);
							}
						})
					} else {
						this.redirectToSupplies(serial);
					}
				}
			}
		)
  }

  redirectToSupplies(serial){
    this.serialModalRef.hide();
    this.selectedModel = serial.model;
    this.serialNumber = serial.serialNumber;
    this.machine = serial;
    this.getSupplyList();
  }
 
  

  onPPSProductGroupChange() {
				
    this.selectedPPSProdSubGroup = '';
    
    if(this.selectedPPSProdGroup == ''){
      this.showPPSProdSubGroupFilters = false;
    }else{
      this.showPPSProdSubGroupFilters = true;
    }
    this.getSupplyList();	
      
  }

  multipleSelectionAvailable(){
				
    var cart = this.orderService.getCart();
    
    if(this.isSuppliesLoaded && (this.selectedSupplyType == null || (this.selectedSupplyType && this.selectedSupplyType.name != "PRINTERS")) && (cart.lfsSupplyRole == 'Y' || cart.lfsPartsRole == 'Y' || cart.ppsSupplyRole == 'Y')){
      return true;
    }else{
      return false;
    }
    
    
  }

  displayAddMore() {

    if(this.orderService.getCart().myCSAUserName && this.cart.lfsSupplyRole == 'N' && this.cart.lfsPartsRole == 'N' && this.cart.ppsSupplyRole == 'N' && (this.orderService.totalCSItems() > 0 || this.orderService.totalBSItems() > 0)){
      return true;
    }
    
    return false;
    
  };

  clearModel() {

    this.selectedModel = "";

    if(!this.isSerialPresent()){
      this.getSupplyList();
    }
  };

  anySupplyError(){	
				
    let supplyList = this.searchPipe.transform(this.newSupplies,true, ['selected']);    

    supplyList = this.searchPipe.transform(this.newSupplies,'Y', ['qtyError']); 

    if(supplyList.length == 0){
      return false;
    }else{
      return true;
    }

  }

  //This method may not be used
  totalItemsInCart(){
    var length = 0;
    if(this.selectedPrinter != undefined  || this.selectedPrinter != null){
    for(var acc in this.selectedPrinter.accessoryList){
      if (this.selectedPrinter.accessoryList[acc].selected){
      length ++;
      }
    }

      if (this.selectedPrinter.selectedCp && this.selectedPrinter.selectedCp !="0" ){
      length ++;
      }

    
    
  }
    
    return length;
    
  }


  isItemInCart(productId){
    if(this.orderService.getCart().billableSupplyMap && this.orderService.getCart().billableSupplyMap[productId+'_']){
      return true;
    }else{
      return false;
    }
  }

  /* updateFavoriteItem= function(param,favType){
		this.favParam.materialNumber =param.mfrItemCode;
		this.favParam.srcRole =param.source;
		
		if(favType == 'F'){
			param.favorite = 'Y';	
		}
		
		if(favType == 'UF'){
			param.favorite = 'N';
		}
		
		var favStatusUpdatePromise = this.supplyService.updateFavoriteItem(this.favParam).subscribe(
			data => {
				favStatusUpdatePromise = data;
			  });
	} */


   updateFavoriteItem(param,favType){
    var favParam ={
      materialNumber : param.mfrItemCode,
      srcRole : param.source
    };
    
    this.supplyService.updateFavoriteItem(favParam).subscribe(
      data => {
        if(data.favStatusFlag == 'S'){
          if(favType == 'F'){
            param.favorite = 'Y';	
          }
          if(favType == 'UF'){
            param.favorite = 'N';
          }
        }
      }
    );
    
  } 

  closeModal(modalType : string){
    if(modalType === 'price'){
      this.pricingModalRef.hide();
    }else if(modalType === 'multi'){
      this.multiSelectModalRef.hide();
    }else if(modalType === 'serial'){
      this.serialModalRef.hide();
    }else if(modalType === 'models'){
      this.modelsModalRef.hide();
    }else if(modalType === 'error'){
      this.errorModalRef.hide();
    }else if(modalType === 'success'){
      this.successModalRef.hide();
    }else if(modalType === 'toner'){
      this.tonerMsgModalRef.hide();
    }

  }

  get selectedProductCount(): number {
		return this.newSupplies.filter(supply => supply.selected===true).length;
	  }

  selectBreadCrumb(breadcrumb:any){

    
    //this.router.navigate(['supplies'], {queryParams : {category:category.name}})
    /* if(breadcrumb.route == 'supplies'){
      this.onCategoryChange(breadcrumb.name);
    }else{
      this.router.navigate([breadcrumb.route], {queryParams : {category:breadcrumb.name}})
    } */

    this.router.navigate([breadcrumb.route], {queryParams : {category:breadcrumb.name}})
    
  }  

  supplySelectedChange(supply: any){
      supply.selected = 'true';
  }

  onSupplySelectionChange(supply : any, isChecked: boolean){

    if(isChecked){
      supply.selected = true;
    }else{
      supply.selected = false;
    }

  }
  
}
