import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart-restart',
  templateUrl: './cart-restart.component.html',
  styleUrls: ['./cart-restart.component.css']
})
export class CartRestartComponent implements OnInit {
  
  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {

  }
  closeModal(accept) {
    this.activeModal.close(accept);
  }
}