import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { RouteGuardService } from '../service/common-service/route-guard.service';

const routes: Routes = [
  {
    path: '',
    component: OrderDetailComponent, 
    children: [{path:'orderdetail', component:OrderDetailComponent, data: { showHeader: true, showSidebar: false, showFooter: true }}]
  },
  { path: 'orderhistory', component: OrderHistoryComponent , canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderHistoryRoutingModule { }
