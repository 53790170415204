import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';

const routes: Routes = [
    //uncomment below for App component routing 
    {
      path: '',
      component: ShippingAddressComponent,
      children: [{ path: 'shipping/shipping-address', component: ShippingAddressComponent }],
    }
  
    //uncomment below for Module level routing uncomment below
    /* {
      path: '',
      component: ShippingAddressComponent,
      children: [{path:'shippingaddress', component:ShippingAddressComponent, data: { showHeader: true, showSidebar: false, showFooter: true }}]
    } */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShippingRoutingModule { }
