import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  cart:any;
  constructor() { }

  setCart(cart) {
		if (cart != null) {
			localStorage.setItem('cart', JSON.stringify(cart));
		}
	}

  getCart(){
    if (localStorage.getItem('cart') != null) {
			this.cart = JSON.parse(localStorage.getItem('cart'));
		}
		return this.cart;
  }
}
