import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupplieslandingpageComponent } from './supplieslandingpage/supplieslandingpage.component';
import { SupplyListingComponent } from './supply-listing/supply-listing.component';

const routes: Routes = [	
  
  //App component routing uncomment below
  {
    path: 'supplieslanding',
    component: SupplieslandingpageComponent,
    children: [{ path: 'supplies/supplieslandingpage', component: SupplieslandingpageComponent }],
  },
  {
    path: 'supplies',
    component: SupplyListingComponent,
    children: [{ path: 'supplies/supply-listing', component: SupplyListingComponent }],
  }

  //Module routing uncomment below

  /* {
    path: '',
    component: SupplieslandingpageComponent,
    children: [{path:'supplieslanding', component:SupplieslandingpageComponent, data: { showHeader: true, showSidebar: false, showFooter: true}}]
  },
  {
    path: '',
    component: SupplyListingComponent,
    children: [{path:'supplies', component:SupplyListingComponent, data: { showHeader: true, showSidebar: false, showFooter: true }}]
  } */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuppliesRoutingModule { }
