import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-continue-reorder-modal',
    templateUrl: './continue-reorder-modal.component.html'
  })
  export class ContinueReorderModalComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal) {

    }
    ngOnInit(): void {
        
    }
    continueReorder() {
        this.activeModal.close(true);
    }
    closeModal() {
        this.activeModal.close();
    }
  }