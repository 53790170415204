
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserIdleModule } from 'angular-user-idle';
import { BlockUIModule } from 'ng-block-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './common/login/login.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './layout/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './layout/header/header.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { MatDatepickerModule,MatNativeDateModule,MatInputModule} from '@angular/material';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { NumberFieldDirective } from './customdirectives/number-field.directive';
import { CurrencyFieldDirective } from './customdirectives/currency-field.directive';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HomeComponent } from './common/home/home.component';
import { MenuComponent } from './layout/menu/menu.component';
import { AccountModule } from './account/account.module';
import { LargeformatpartsModule } from './largeformatparts/largeformatparts.module';
import { SuppliesRoutingModule } from './supplies/supplies-routing.module';
import { SuppliesModule } from './supplies/supplies.module';
import { ContactusComponent } from './common/contactus/contactus.component';
import { FaqComponent } from './common/faq/faq.component';
import { RegisterComponent } from './common/register/register.component';
import { OrderHistoryModule } from './orderhistory/orderhistory.module';
import { SearchModule } from './search/search.module';
import { ShopCommonModule } from './shop-common/shop-common.module';
import { ShippingOptionsComponent } from './common/shipping-options/shipping-options.component';
import { ShippingFilterFilterPipe } from './pipe/business-unit-filter.pipe';
import { ShippingMtdTpFilterPipe } from './pipe/shipping-method-filter.pipe';

import { OrdercontractsuppliesModule } from './ordercontractsupplies/ordercontractsupplies.module';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';

import { CartComponent } from './common/cart/cart.component';

import { MeterreadsModule } from './meterreads/meterreads.module';
import { ContinueReorderModalComponent } from './orderhistory/order-history/continue-reorder-modal/continue-reorder-modal.component';
import { FavoritesModule } from './favorites/favorites.module';
import { ShippingModule } from './shipping/shipping.module';
import { CartRestartComponent } from './layout/header/cart-restart/cart-restart.component';
import { OfficeProductModalComponent } from './layout/menu/office-product-modal/office-product-modal';
import { BillingModule } from './billing/billing.module';
import { AdminMessageComponent } from './common/login/admin-message/admin-message-modal';
import { CartHelperService } from './shipping/cart-helper-service';
import { MycsaLoginComponent } from './common/mycsa-login/mycsa-login.component';
import { BlockTemplateComponent } from './shop-common/block-template.component';
import { TwoFactorAuthComponent } from './common/two-factor-auth/two-factor-auth.component';




@NgModule({
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
     // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 7200 (120 minutes), `timeout` is 60 (1 minute) 
    // and `ping` is 10 (10 seconds).
    UserIdleModule.forRoot({idle: 3600, timeout: 60, ping: 10}),
    ReactiveFormsModule,
   BrowserAnimationsModule,
   MaterialModule,
   MatNativeDateModule,
   MatInputModule,
   MatDatepickerModule,  
   Ng2SearchPipeModule,
   NgbModule,
   AccountModule,
   LargeformatpartsModule,
   OrderHistoryModule,
   SuppliesModule,
   SearchModule,
   ShopCommonModule,
   OrdercontractsuppliesModule,
   MeterreadsModule,
   FavoritesModule,
   ShippingModule,
   BillingModule,
   BlockUIModule.forRoot({
    template: BlockTemplateComponent
  })
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    LoginComponent,
    DashboardComponent,   
    FooterComponent,
    HeaderComponent,
    CartRestartComponent,
    ContactusComponent,
    FaqComponent,
    SideNavComponent,  
    NumberFieldDirective,
    AccessDeniedComponent,
    CurrencyFieldDirective,
    RegisterComponent,
    ShippingOptionsComponent,
    ShippingFilterFilterPipe,
    ShippingMtdTpFilterPipe,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    CartComponent,
    ContinueReorderModalComponent,
    OfficeProductModalComponent,
    AdminMessageComponent,
    MycsaLoginComponent,   
    TwoFactorAuthComponent


	//OrderHistoryComponent
  ],
  providers: [
    DatePipe, Title,CurrencyPipe,  NgbActiveModal,CartHelperService
    // {
    //    provide:HTTP_INTERCEPTORS, useClass: HttpInterceptorBasicAuthService, multi:true
    //  }
  ],
  entryComponents: [LoginComponent,AdminMessageComponent,RegisterComponent, ContinueReorderModalComponent, CartRestartComponent, OfficeProductModalComponent,BlockTemplateComponent], 
  bootstrap: [AppComponent]
})
export class AppModule { }


