
import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-login',
    templateUrl: './admin-message-modal.html',
    styleUrls: ['./admin-message-modal.css']
  })
  export class AdminMessageComponent implements OnInit {
    @Input() public messages;
    constructor(
      public activeModal: NgbActiveModal,
      private sanitizer: DomSanitizer
    ) {
    }
  
    ngOnInit(): void {
    }
    closeModal() {
      this.activeModal.close();
    }
    updateText(msg){
      return this.sanitizer.bypassSecurityTrustHtml(msg);
    }


}