import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/app.util';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderService } from 'src/app/service/common-service/order.service';
import { Observable } from 'rxjs';
import { LoginComponent } from '../login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loggedUserName: any;
  userFullName: string;
  constructor(private orderService:OrderService,private http: HttpClient,private util: Util,private modalService: NgbModal,
    private loginAuthenticationService: LoginAuthenticationService,
    ){}
  baseUrl: string;
  cartInfo: any={};
  listing:any={};
  productSizeDetails:any={};
   canonMenuRequestDto ={};
  ngOnInit(): void {
    this.getContactInfo().subscribe(
      data => {
        localStorage.setItem('contactPhone',data.cPhone);
        localStorage.setItem('contactEmail',data.cEmail);
      } 
    )

    if(!sessionStorage.userName){
      if(!this.modalService.hasOpenModals()){
        const loginModalRef = this.modalService.open(LoginComponent);
      
        loginModalRef.result.then(val => {
          if (val) {
            this.loggedUserName = val;
            this.userFullName = this.loginAuthenticationService.getAuthenticatedUserFullName();
          }
        })
        }
    
      }
    
  }  
  getContactInfo(): Observable<any> {
    let baseUrl = this.util.getBaseUrl();
  return this.http.get(baseUrl+'contact.htm/getContactInfo');

  }
 

hasPartsRole() {

  let cartData = this.orderService.getCart();

  if(cartData.lfsPartsRole =='Y'){
    return true;
  } 

  return false;
    
};



  
}
