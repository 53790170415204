import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Util } from 'src/app/app.util';
import { csaShopAdminUser } from 'src/app/common/bean/csaShopAdminUser';
import { userDetails } from 'src/app/common/bean/userDetails';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { Router } from '@angular/router';
import { OrderService } from './order.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
const httpJsonOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/json')
};
@Injectable({
  providedIn: 'root'
})

export class LoginAuthenticationService {
  defaultUrl: string;
  userFullName: string = "";
  userName: string = "";
  isloggedIn: boolean;
  userRoles: any;
  status: string = "";
  statusFlag: string = "";
  constructor(private http: HttpClient, 
    private menuService: MenuService,
    private orderService: OrderService,
    private util: Util, 
    private router: Router,  
    public activeModal: NgbActiveModal) {
    this.defaultUrl = this.util.getBaseUrl();
  }

  getAuthenticatedUser() {
    this.userName = sessionStorage.getItem('userName');
    console.log("username in login" + this.userName);
    return this.userName;
  }

  getAuthenticatedUserFullName() {
    this.userFullName = sessionStorage.getItem('userFullName');
    return this.userFullName;
  }
  isUserLoggedIn() {
    let user = sessionStorage.getItem('userName');
    return !(user === null);
  }
  logout() {

    const httpOptions = {
      headers: new HttpHeaders({
      }).set('Content-Type', 'application/json')
    };
    return this.http.get(this.defaultUrl + 'logout').subscribe(data => {
      console.log("logout url successoutput :" + data);

      sessionStorage.clear();
      localStorage.clear();
      //sessionStorage.removeItem('userName');
      //sessionStorage.removeItem('userFullName');
      //localStorage.removeItem('tempCart');
      //localStorage.removeItem('cart');
      //localStorage.removeItem('showRestartButton');
      this.orderService.clearLocalStorage(false);
      this.menuService.getMenu({}).subscribe(() => { });
      this.menuService.defaultCommonGuld();
      //Commented below as preowned is not in scope for now
      //this.menuService.defaultCommonHomegfpdb();
      this.menuService.defaultCommonProductgpsd();
      this.router.navigate(['']);
      //localStorage.removeItem("twoFactorAuthCode");
      //localStorage.removeItem("otpRequestTime");      
            
       this.orderService.setLogout(true);
      

    });
  }


  executeJDBCAuthenticationService(username, password) {
    //  let beanUrl = this.defaultUrl + "login";
    let beanUrl = this.defaultUrl + "login?pageName=home&popup=y";

    const body = {
      username, password
    }
    return this.http.post<csaShopAdminUser>(beanUrl, body, httpJsonOptions)
      .pipe(
        map(
          data => {

            if(data && data.authenticated){
              this.userName = data.fullName;
              sessionStorage.setItem('userName', data.fullName);
              sessionStorage.setItem('userFullName', data.firstName +  ' ' + data.lastName);
              this.menuService.defaultCommonGuld();
              this.menuService.defaultCommonHomegfpdb();
              this.menuService.defaultCommonProductgpsd();
            }
            
            return data;
          }
        )
      );
  }

  getAdminMssages():Observable<any>{
    let beanUrl = this.defaultUrl + "account.htm/getAdminMessages";
    return this.http.get(beanUrl, httpJsonOptions).pipe(
      map(data =>{
        console.log(data);
    }));
  }

  executeLogout(username, password) {
    //  let beanUrl = this.defaultUrl + "login";
    let beanUrl = this.defaultUrl + "login?pageName=home&popup=y";

    const body = {
      username, password
    }
    return this.http.post<csaShopAdminUser>(beanUrl, body, httpJsonOptions).subscribe(
      data => {
        this.userName = data.fullName;
        sessionStorage.setItem('userName', data.fullName);
        sessionStorage.setItem('userFullName', data.firstName +  ' ' + data.lastName);
      });

  }


  registerUser(param: userDetails): Observable<any> {
    let initialUrl = this.defaultUrl + "register.htm/ru";

    return this.http
      .post(initialUrl, param, httpJsonOptions)
      .pipe(catchError(this.handleError("registerUser")))
  }

  resetPassword = function (data) {
    // var resetPasswordPromise = http.postJsonData('common.htm/resetPassword', data);
    let initialUrl = this.defaultUrl + "common.htm/resetPassword";

    return this.http
      .post(initialUrl, data, httpJsonOptions)
      .pipe(catchError(this.handleError("resetPassword")))
  }
  forgotPassword(): Observable<any> {
    let url = this.defaultUrl + 'fp';

    return this.http.post(url, '', httpJsonOptions).pipe(catchError(this.util.handleError("forgotPassword")));
  }
  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  verifyTwoFactorAuthCode(oData) : Observable<any> {
    let url = this.defaultUrl + 'verifyTwoFactorAuthCode';   
    return this.http
      .post(url , oData, httpJsonOptions)
      .pipe(catchError(this.handleError("verifyTwoFactorAuthCode")));
  }

  twoFactorAuthentication() : Observable<any> {
    let url = this.defaultUrl + 'twoFactorAuthentication';   
    return this.http
      .post(url , '', httpJsonOptions)
      .pipe(catchError(this.handleError("twoFactorAuthentication")));
  }

  generateVerCodeAndSendEmail() : Observable<any> {
    let url = this.defaultUrl + 'generateVerCodeAndSendEmail';   
    return this.http
      .post(url , '', httpJsonOptions)
      .pipe(catchError(this.handleError("generateVerCodeAndSendEmail")));
  }


}

export class AuthResponse {

  constructor(public authenticated: boolean, public userName: string, public userFullName: string) {
  }
}

export class ResetResponse {

  constructor(public status: string, public statusFlag: string) {
  }


}