import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrdercontractsuppliesService } from '../../ordercontractsupplies.service';

@Component({
  selector: 'app-meterread-modal',
  templateUrl: './meterread-modal.component.html',
  styleUrls: ['./meterread-modal.component.css']
})
export class MeterreadModalComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  noCurrentReadError:boolean=false;
  currentReadlessError:boolean=false;
  @Output() accept: EventEmitter<String> = new EventEmitter<String>();
  @Input() public meterReads;

  constructor( public activeModal: NgbActiveModal,private service: OrdercontractsuppliesService) { }
  genericErrMsg : string = '';
  
  ngOnInit() {
  }

  @Output() accepted = new EventEmitter<string>();

  closeModal() {
    this.activeModal.close();
  }

  saveMeterReads(){
    let errorExist:boolean = false;
    if(this.meterReads){
      for(let met of this.meterReads){
        met.equipmentNumber = met.serialNumber;
        for(let count of met.counters){
          count.errorMsg=null;
          if(count.currentRead == null){
            count.errorMsg ="Supplies Counter Read is required.";
            errorExist= true;
          }
          
          this.currentReadlessError= false;
          if(count.currentRead  !=null && count.currentRead < count.previousRead){
            this.currentReadlessError= true;
          }
      
          if(count && count.readDetails.lastReadingDt){
            count.readDetails.lastReadingDt =  new Date((count.readDetails.lastReadingDt)).getTime();
          }
  
          if(count && count.readDetails.frstReadingDt){
            count.readDetails.lastReadingDt =  new Date((count.readDetails.lastReadingDt)).getTime();
          }
        }
      }
    }
    if(errorExist || this.currentReadlessError){
      return;
    }
   
    this.service.saveMeterReads(this.meterReads).subscribe((results:any) => {
      console.log("dave meterread results"+results);
      if(results.status === 'S'){
        this.accept.emit('accept');
        this.activeModal.close(true);
      }else{
        this.genericErrMsg = results.errorMessage;
      }
    });
  
  }
  

}