import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shippingMtdTpFilter'
})
export class ShippingMtdTpFilterPipe implements PipeTransform {

  transform(items: any[], keyword:any): any[] {

    if(!items) return [];

    if(!keyword) return items;

    return items.filter(
        item => item.shippingMtdTp === keyword
    );
  }
}
