import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {
  baseUrl = '';
  constructor(private http: HttpClient, private util: Util) {
    this.baseUrl = this.util.getBaseUrl();
  }
  isEmptyObj(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  getFormattedAddress = (addr) => {
    let address = "";

    if (addr != null) {
      if (addr.address1) {
        address = addr.address1;
      }

      if (addr.address2) {
        address += ", " + addr.address2;
      }
      if (addr.city) {
        address += ", " + addr.city;
      }
      if (addr.city) {
        address += ", " + addr.state;
      }
      if (addr.zipcode) {
        address += " - " + addr.zipcode;
      }
      if (addr.zipCode) {
        address += " - " + addr.zipCode;
      }
    }
    return address;
  }
  changePasswordReq() {
    return this.http.get(this.baseUrl + 'common.htm/changePasswordRequired');
  }
  getPageUrl = function () {
    return this.http.get(this.baseUrl + 'common.htm/gpu');
  }

  getMyCSAURL(lookupName) : Observable<any>{
    let url = this.baseUrl + 'common.htm/lookupVal';

    return this.http.post(url, lookupName, httpOptions).pipe(catchError(this.util.handleError("getMyCSAURL")));
  }
}
