import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AccountSelectionComponent } from './account-selection/account-selection.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
//import { SearchFilterPipe } from '../pipe/search-filter.pipe';
import { ModalModule } from 'ngx-bootstrap';
import { ShopCommonModule } from '../shop-common/shop-common.module';

@NgModule({
  declarations: [AccountSelectionComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    NgxPaginationModule,
    ShopCommonModule,
    ModalModule.forRoot()
  ],
  providers: [
    
  ]

})
export class AccountModule { }
