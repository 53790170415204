import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { megaMenu } from 'src/app/common/megaMenu';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { LargeformatpartsService } from '../largeformatparts.service';
import { favParam } from '../model/favParam';
import { OrderService } from 'src/app/service/common-service/order.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-largeformatparts-selection',
  templateUrl: './largeformatparts-selection.component.html',
  styleUrls: ['./largeformatparts-selection.component.css']
})
export class LargeformatpartsSelectionComponent implements OnInit {

	@BlockUI() blockUI : NgBlockUI;
	
  newParts = [];
	productGroups=[];
	contactPhone = localStorage.getItem("contactPhone") ? localStorage.getItem("contactPhone") : '';
	notFoundMsg = 'No Results Found. Please verify your search criteria or for further assistance call '+ this.contactPhone;
	partsLoaded = false;
	partPriceErrMsg = '';	
	partsPerPage = '25';	
  search = '';
	searchText = '';
  pleaseWaitCls=false;
  selectedProdGroup: any='';
	displayFavorite = "favorite == 'Y'" ? true : false;	
	partsSearchFilter = '';//globalSearchFilter;
  totalRecords = -1;	
	favParam:favParam = new favParam();
	//category = category;
	prodSelectedCount;
	processingRequest: string;
	//cart: {};
	cart = this.orderService.getCart();
	partsForm: any;
	selectedSupplyCategory;
	breadcrumbList;
	megaMenu:any[];
	//Pagination related
	pageNum : number = 1;
	recPerPage = 25;

	@ViewChild('editModal') editModal : TemplateRef<any>; // Note: TemplateRef
	//@ViewChild('myModal') myModal;


  constructor(private route : ActivatedRoute,private menuService: MenuService,private service: LargeformatpartsService,private orderService:OrderService, private router : Router, private modalService: NgbModal) { }

  ngOnInit() {
	
    this.route.queryParams.subscribe(
      params => {
        //this.category = params['category'];
        this.partsSearchFilter = params['itemCd'];

		this.getMenu();
		this.getLFSPartList('');
		this.getLFSPartsProductGroup();

      }

    );

  }

  getMenu(){
	let menuItems = this.menuService.getMenuItems();
	this.megaMenu = menuItems.menu;
  }
  sort(colName) {
    this.newParts.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
	}

  getLFSPartList (action){		
		this.pleaseWaitCls = true;
		
		this.newParts = [];
		this.totalRecords = -1;
		
		if(action != 'search'){
			this.search = '';
			this.searchText = '';
		}
	
		
		var param = {
			"group":	this.selectedProdGroup != null ? this.selectedProdGroup : '',
			"displayFavorite" :'N',
			"itemCode" : this.partsSearchFilter
		}
		
		let data = this.service.getPartList(param).subscribe(
      data => {
        
        this.newParts = data;
        this.totalRecords = this.newParts != null? this.newParts.length : 0;

        for (var part of this.newParts){
          if(this.partsSearchFilter && this.newParts.length == 1){
             part.selected = true;
          }
        }
        this.partsLoaded = true;
		this.pleaseWaitCls = false;
      });
    }

	getLFSPartsProductGroup = function(){		
		let data = this.service.getLFSPartsProductGroup().subscribe(
		data=>{				
				this.productGroups = data;	
			  }
		);
	}

	updateFavoriteItem= function(param,favType){
		this.favParam.materialNumber =param.mfrItemCode;
		this.favParam.srcRole =param.source;
		
		if(favType == 'F'){
			param.favorite = 'Y';	
		}
		
		if(favType == 'UF'){
			param.favorite = 'N';
		}
		
		var favStatusUpdatePromise = this.service.updateFavoriteItem(this.favParam).subscribe(
			data => {
				favStatusUpdatePromise = data;
			  });
	}
	
	anyPartSelected(){
		var selectedParts = this.newParts.filter(np=>np.selected===true);
		this.prodSelectedCount = selectedParts.length;
	//	var selectedParts = $filter('filter')($scope.newParts, { selected : true });
		
		if(selectedParts.length > 0){
			return false;
		}else{
			return true;
		}
	}
	
	clearFilter(){
		this.selectedProdGroup = '';
		this.getLFSPartList('');	
		this.route.queryParams.subscribe(
			params => {
			  //this.category = params['category'];
			  this.partsSearchFilter = params['itemCd'];
	  
			  this.getMenu();
			  this.getLFSPartList('');
			  this.getLFSPartsProductGroup();
	  
			}
	  
		  );			
	};

	anyPartError(){	
		var partList = this.newParts.filter(np=>(np.selected===true&&np.qtyError==='Y'));
		
		if(partList.length == 0){
			return false;
		}else{
			return true;
		}

	}

	get productesSelected(): number {
		return this.newParts.filter(np=>np.selected===true).length;
	  }

	getMultiplePriceOrGetPriceAndAdd(addToCart){
		this.blockUI.start();

		this.partPriceErrMsg = '';
		this.processingRequest = 'processingRequest';
		
		var selectedParts = this.newParts.filter(np=>np.selected===true);
		
		var addToCartPartList = [];
			
		this.cart = this.orderService.getCart();
			
			var param = {
					"part" : JSON.stringify(selectedParts),
					"shipType" : "STD",
					"cart" : JSON.stringify(this.cart)
			}
			
			var pricePromise = this.service.getMultiplePartPrice(param).subscribe(
				data => {
					this.processingRequest ='';
					
					var priceDetails = data;
					
					var priceSelectedParts = priceDetails.selectedItems;
					
					var partList = priceDetails.partList;

					var errorPartList = priceDetails.partsErrorItems;

					var existingSuperSededPart;
					
					//update the price and supersede details for the selected items
					for(var i=0; i<selectedParts.length; i++){
						var part = selectedParts[i];
						
						for(var j=0;j<priceSelectedParts.length; j++){
							var partPrice = priceSelectedParts[j];
							this.newParts.filter(np=>np.selected===true)
							//If selected part is superseded
							if(partPrice.supersedeMerchandiseCode != '' && part.itemCd == partPrice.itemCd){
								//This is to get the ful part list as the part listing might be filtered and the superseded part is not part of that filtered list
								var superSededPartList = partList.filter(pl=>pl.origMerchandiseCode===partPrice.supersedeMerchandiseCode);
								
								//This is update the part price incase the superseded part is part of the currently filtered list
								var existingSuperSededPartList = this.newParts.filter(np=>np.origMerchandiseCode===partPrice.supersedeMerchandiseCode);

								if(superSededPartList && superSededPartList.length == 1 ){
									part.price = partPrice.price;
									part.supersedeMerchandiseCode = partPrice.supersedeMerchandiseCode;
									part.supersedeDesc = partPrice.supersedeDesc;
									
									var superSededPart;
									superSededPart = superSededPartList[0];
									superSededPart.price = partPrice.price;
									superSededPart.supersedeMessage = part.itemCd + " superseded to " + superSededPart.itemCd;
									//part.supersedeWeight = superSededPart.weight;
									addToCartPartList.push(superSededPart);
								}
								
								if(existingSuperSededPartList && existingSuperSededPartList.length == 1 ){
								
									existingSuperSededPart = existingSuperSededPartList[0];
									existingSuperSededPart.price = partPrice.price;
									existingSuperSededPart.supersedeMessage = part.itemCd + " superseded to " + superSededPart.itemCd;
								}
							}
							//selected part is not superseded
							else if(partPrice.supersedeMerchandiseCode == '' && part.itemCd == partPrice.itemCd){
								part.price = partPrice.price;
								part.supersedeMerchandiseCode = partPrice.supersedeMerchandiseCode;
								part.supersedeDesc = partPrice.supersedeDesc;
								part.supersedeWeight = '';
								addToCartPartList.push(part);
							}
						}
					}
					
					//If user selected to add to cart
					if(addToCart){

						this.cart = priceDetails.cart;
						this.orderService.setCart(this.cart);
						
						
						//add the selected items
						for(i=0; i<addToCartPartList.length; i++){
							var currPart = addToCartPartList[i];
							currPart.selected = false;
							this.addLFSPartsToCart(currPart);	
						}
					}
					
					//if the price is not available for any selected parts
					if(errorPartList.length > 0){
						this.partPriceErrMsg = 'Sorry could not complete that action. Please call 1-866-472-7876 for assistance for item(s) <br>' + errorPartList.join("<br>");
						//$("#pricingErrorMsgModal").modal('show');
						(<any>$("#pricingErrorMsgModal")).modal("show");
						//this.modalService.open(this.editModal);

					//	this.myModal.nativeElement.class = 'modal fade show';
					}

					this.blockUI.stop();
				});	
	}

	addLFSPartsToCart(part) {

		part.qtyError = 'N';
		part.qtyErrMsg = '';
		var cartPart = null;
		
		cartPart = this.orderService.getItemFromCart(part,'LFSPARTS');
		
		//Validate qty
		
		//Check if selected qty > min
		if((cartPart == null && part.selectedQty < part.minReqQuantity) || (cartPart != null && (cartPart.selectedQty + part.selectedQty) < part.minReqQuantity)){
			part.qtyError = 'Y';
			part.qtyErrMsg = 'Please select minimum ' + part.minReqQuantity;
			return false;
		}
		
		//Check if the selected qty < max
		if((cartPart == null && part.selectedQty > part.maxQuantity) || (cartPart != null && (cartPart.selectedQty + part.selectedQty) > part.maxQuantity)){
			part.qtyError = 'Y';
			part.qtyErrMsg = 'Please select maximum ' + part.maxQuantity;
			return false;
		}
		
		//Check if multiples is selected
		if (part.selectedQty % part.orderIncrements != 0){
			part.qtyError = 'Y';
			part.qtyErrMsg = 'Enter multiples of ' + part.orderIncrements;
			return false;
		}
		
		//Get Price Details
		
		var data = {
				"machine" : this.orderService.getMachine()
		}
		var addedSuccessfully = this.orderService.addSupplyToCart(part, data, '');
		
		if(!addedSuccessfully){
			part.qtyError = 'Y';
			part.qtyErrMsg = 'Please select maximum ' + part.maxQuantity;
		}
		
	}

	validateQty(part,index){	
		console.log("part.selectedQty:" + part.selectedQty);
		part.qtyError = 'N';
		part.qtyErrMsg = '';
	//	this.partsForm['QTY_' + index].$setValidity("validQty", true);
		if (part.selectedQty < 0 ){
		//	this.partsForm['QTY_' + index].$setValidity("validQty", false);
			part.qtyError = 'Y';
			part.qtyErrMsg = 'Enter multiples of ' + part.orderIncrements;
		}
		if (part.selectedQty % part.orderIncrements != 0){
		//	this.partsForm['QTY_' + index].$setValidity("validQty", false);
			part.qtyError = 'Y';
			part.qtyErrMsg = 'Enter multiples of ' + part.orderIncrements;
		}
		//Check if the selected qty < max
		else if(part.selectedQty > part.maxQuantity){
		//	this.partsForm['QTY_' + index].$setValidity("validQty", false);
			part.qtyError = 'Y';
			part.qtyErrMsg = 'Please select maximum ' + part.maxQuantity;
			return false;
		}
		
		if(part.qtyError == 'N' && !part.selected){
			part.selected = true;	
		}

	}

	
	// breadcrumbList ={};
	category = "LARGE_FORMAT_PARTS";
	
	// $watch(function () { 
        
    //     return this.orderService.getMenu(); 

	//     }, function (newValue, oldValue) {
	//         if (newValue != null) {
	//             $scope.megaMenu= newValue;
	            
	//             //var selectedSupplies = $filter('filter')($scope.newSupplies, { selected : true });
	//             var menuList = $filter('filter')($scope.megaMenu, {'name' : $scope.category}, true);
	            
	//             if(menuList.length > 0){
	//                 $scope.selectedSupplyCategory = menuList[0];
	//                 $scope.breadcrumbList = $scope.selectedSupplyCategory.breadcrumbList;
	//             }
	//         }
	//     }
	// );
	
	 ngDoCheck() {
		//this.megaMenu = this.orderService.getMenu();
		
	            //var selectedSupplies = $filter('filter')($scope.newSupplies, { selected : true });
	            var menuList = this.megaMenu.filter(mm=>mm.name===this.category);
	            
	            if(menuList.length > 0){
	                this.selectedSupplyCategory = menuList[0];
	                this.breadcrumbList = this.selectedSupplyCategory.breadcrumbList;
	            }
	 	
	 }

	//not used
	// $scope.isLFSOrPPSUser = function(){
	// 	var isLFSOrPPSUser = 'N';
	// 	if($scope.cart.lfsSupplyRole == 'Y' || $scope.cart.lfsPartsRole == 'Y' || $scope.cart.ppsSupplyRole == 'Y') {
	// 		isLFSOrPPSUser = 'Y';
	// 	}
	// 	return isLFSOrPPSUser;
	// }

	// $scope.clear = function () {
	// 	window.location.href='parts.htm?view=parts';
	// };

	// $scope.searchParts = function(){
	// 	$scope.selectedProdGroup = '';
	// 	$scope.getLFSPartList('search');
	// 	$scope.search = $scope.searchText;		
	// }
	
	// $scope.getPriceOrGetPriceAndAdd = function(part, addToCart){
		
	// 	$scope.partPriceErrMsg = '';
	// 	$scope.processingRequest = 'processingRequest';

	// 	if(part.price > 0 && addToCart && part.supersedeMerchandiseCode){
			
	// 		//If part is superseded we need to add super seded part to cart
	// 		if(part.supersedeMerchandiseCode != ''){
	// 			var superSededPartList = $filter('filter')($scope.newParts, { origMerchandiseCode : part.supersedeMerchandiseCode });
				
	// 			if(superSededPartList){
	// 				superSededPart = superSededPartList[0];
	// 				superSededPart.price = part.price;
	// 				part = superSededPart;
	// 			}

	// 		}
			
	// 		$scope.addLFSPartsToCart(part);	
			
			
	// 		hidePleaseWait();
	// 		$scope.processingRequest ='';
	// 	}else{
			
	// 		var cart = orderService.getCart();
			
	// 		var param = {
	// 				"accountDetails" : angular.toJson($scope.cart.accountInfo),
	// 				"part" : angular.toJson(part),
	// 				"orderType" : part.source,
	// 				"shipType" : "STD"	
	// 		}
			
	// 		var pricePromise = partsService.getPartPrice(param);
			
	// 		pricePromise.then(
	// 			function(results){
	// 				hidePleaseWait();
	// 				$scope.processingRequest ='';
	// 				var superSededPart = {};
	// 				if(results.data.success == 'N'){
	// 					$scope.partPriceErrMsg = 'Sorry could not complete that action. Please call 1-866-472-7876 for assistance.';
	// 					$("#pricingErrorMsgModal").modal('show');
	// 				}else{
	// 					console.log('price:' + results.data.price);
	// 					console.log('supersede merch code:' + results.data.ssMerchCode);
	// 					console.log('supersede desc:' + results.data.ssDesc);
						
	// 					part.price = results.data.price;
	// 					part.supersedeMerchandiseCode = results.data.ssMerchCode;
	// 					part.supersedeDesc = results.data.ssDesc;
						
	// 					if(part.supersedeMerchandiseCode != ''){
	// 						var superSededPartList = $filter('filter')($scope.newParts, { origMerchandiseCode : part.supersedeMerchandiseCode });
							
	// 						if(superSededPartList){
	// 							superSededPart = superSededPartList[0];
	// 							superSededPart.price = part.price;
	// 							superSededPart.supersedeMessage = part.itemCd + " superseded to " + superSededPart.itemCd;
	// 							//part.supersedeWeight = superSededPart.weight;
	// 						}

	// 					}else{
	// 						part.supersedeWeight = '';
	// 					}
						
						
	// 					if(addToCart){
	// 						if(angular.equals(superSededPart, {})){
	// 							$scope.addLFSPartsToCart(part);
	// 						}else{
	// 							$scope.addLFSPartsToCart(superSededPart);
	// 						}
							
	// 					}
	// 				}
						
	// 			},
	// 			function(reason){
	// 				console.log(reason);
	// 				$scope.processingRequest ='';
	// 				hidePleaseWait();
	// 			}
	// 		);
	// 	}
	// }

	// $scope.onFocusQty= function(part){
	// 	$scope.placeholder = part.selectedQty;
	// 	part.selectedQty = "";
	// }

	// $scope.onBlurQty= function(part){
	// 	if(part.selectedQty == ""){
	// 		part.selectedQty = $scope.placeholder;
	// 	}else{
	// 		$scope.placeholder = part.selectedQty;
	// 	}
	// }

 	

}
