import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { OrderService } from 'src/app/service/common-service/order.service';



declare var $: any;

@Component({
  selector: 'app-shipping-options',
  templateUrl: './shipping-options.component.html',
  styleUrls: ['./shipping-options.component.css']
})
export class ShippingOptionsComponent implements OnInit {
  shippingForm: FormGroup;
  cart = {} as any;
  shippingMethods = {} as any;
  carrierOptions = {} as any;
  deliveryServices = {} as any;
  cartShippingOptions = {} as any;
  selectedCpcObj = {} as any;
  selectedEpObj = {} as any;
  selectedPpsObj = {} as any;
  selectedLfspObj = {} as any;
  selectedLfsObj = {} as any;
  selectedAzcoObj = {} as any;
  carrierAcctReqd = '';
  overweight = '';
  overweightCpc = '';
  overweightLfs = '';
  overweightEps = '';
  overweightLfps = '';
  overweightPps = '';
  overweightAzco = '';
  hzmtFlag = 'N';
  hzmtPartsFlag = 'N';
  totalCpcWeight = 0;
  totalLfsWeight = 0;
  totalEpsWeight = 0;
  totalAzcoWeight = 0;
  totalLfpsWeight = 0;
  totalPpsWeight = 0;
  isEmptyCpcSupplyMap = false;
  isEmptyEpSupplyMap = false;
  isEmptyLfsSupplyMap = false;
  isEmptyPpsSupplyMap = false;
  isEmptyLfsPartsSupplyMap = false;
  isEmptyAzcoSupplyMap = false;
  CarrierCode = '';
  shipMtdCustPONum = '';
  CarrierName = '';
  CarrierSvcLvlCode = '';
  CarrierLIFNR = '';
  LFSSuppliesDeliveryLabels = [];
  LFSPartsDeliveryLabels = [];
  PPSSuppliesDeliveryLabels = [];
  ADDR_TYPE_CPC = "CPC";
  ADDR_TYPE_EP = "EP";
  ADDR_TYPE_LFS = "LFS";
  ADDR_TYPE_PPS = "PPS";
  ADDR_TYPE_LFP = "LFP";
  ADDR_TYPE_AZCO = "AZCO";
  shipMethod = 'STD';
  grandTax = 0;
  billSupplyShipCharge = null;
  formSelectedCpcObj: FormGroup;
  formSelectedLfsObj: FormGroup;
  formSelectedEpObj: FormGroup;
  formSelectedAzcoObj: FormGroup;
  formSelectedLfspObj: FormGroup;
  formSelectedPpsObj: FormGroup;
  constructor(
    private fb: FormBuilder,
    private commonService: CommonUtilsService,
    private orderService: OrderService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.shippingForm = this.fb.group({
      selectedCpcObj: this.fb.group({
        shippingMethod: [''],
        carrier: [''],
        carrierAccount: [''],
        additionalShippingInstructions: [''],
      }),
      selectedLfsObj: this.fb.group({
        shippingMethod: [''],
        carrier: [''],
        carrierAccount: [''],
        additionalShippingInstructions: ['']
      }),
      selectedEpObj: this.fb.group({
        shippingMethod: [''],
        carrier: [''],
        carrierAccount: [''],
        additionalShippingInstructions: ['']
      }),
      selectedAzcoObj: this.fb.group({
        shippingMethod: [''],
        carrier: [''],
        carrierAccount: [''],
        additionalShippingInstructions: ['']
      }),
      selectedLfspObj: this.fb.group({
        shippingMethod: [''],
        carrier: [''],
        carrierAccount: [''],
        additionalShippingInstructions: ['']
      }),
      selectedPpsObj: this.fb.group({
        shippingMethod: [''],
        deliveryContactName: [''],
        deliveryContactPhone: [''],
        additionalShippingInstructions: ['']
      }),
    })
    this.orderService.getLFSShippingMethod().subscribe(response => {
      this.shippingMethods = response.shipLfsMthd;
      this.carrierAcctReqd = response.carrierAcctReqd;
      this.carrierOptions = response.shipLfsCarrier;
      this.deliveryServices = response.shipLfsDelSrvc;
      console.log("this.shippingMethods", this.shippingMethods);
      console.log("this.selectedCpcObj", this.selectedCpcObj);
      console.log("this.deliveryServices", this.deliveryServices);
      this.formSelectedCpcObj = this.shippingForm.get('selectedCpcObj') as FormGroup;
      this.formSelectedLfsObj = this.shippingForm.get('selectedLfsObj') as FormGroup;
      this.formSelectedEpObj = this.shippingForm.get('selectedEpObj') as FormGroup;
      this.formSelectedAzcoObj = this.shippingForm.get('selectedAzcoObj') as FormGroup;
      this.formSelectedLfspObj = this.shippingForm.get('selectedLfspObj') as FormGroup;
      this.formSelectedPpsObj = this.shippingForm.get('selectedPpsObj') as FormGroup;



      if (localStorage.getItem('cart') != null) {
        var cart = JSON.parse(localStorage.getItem('cart'));
        if (cart.cpcSupplyMap) {
          var item = null;
          var cpcsTotal = 0;
          for (var id in cart.cpcSupplyMap) {
            item = cart.cpcSupplyMap[id].items[0];
            cpcsTotal += parseFloat(item.weight) * parseFloat(item.selectedQty);
            if (item.hzmt == '1') {
              this.hzmtFlag = "Y";
            }
          }
          this.totalCpcWeight = cpcsTotal;
          if (this.totalCpcWeight > 150) {
            this.overweightCpc = 'S';
          }
        }

        if (cart.epSupplyMap) {
          var item = null;
          var epsTotal = 0;
          for (var id in cart.epSupplyMap) {
            item = cart.epSupplyMap[id].items[0];
            epsTotal += parseFloat(item.weight) * parseFloat(item.selectedQty);
            if (item.hzmt == '1') {
              this.hzmtFlag = "Y";
            }

          }
          this.totalEpsWeight = epsTotal;
          if (this.totalEpsWeight > 150) {
            this.overweightEps = 'S';
          }
        }

        if (cart.azcoSupplyMap) {
          var item = null;
          var azcoTotal = 0;
          for (var id in cart.azcoSupplyMap) {
            item = cart.azcoSupplyMap[id].items[0];
            azcoTotal += parseFloat(item.weight) * parseFloat(item.selectedQty);
            if (item.hzmt == '1') {
              this.hzmtFlag = "Y";
            }
          }
          this.totalAzcoWeight = azcoTotal;
          if (this.totalAzcoWeight > 150) {
            this.overweightAzco = 'S';
          }
        }

        if (cart.lfsSupplyMap) {
          var item = null;
          var lfsTotal = 0;
          for (var id in cart.lfsSupplyMap) {
            item = cart.lfsSupplyMap[id].items[0];
            lfsTotal += parseFloat(item.weight) * parseFloat(item.selectedQty);
            if (item.hzmt == '1') {
              this.hzmtFlag = "Y";
            }
          }
          this.totalLfsWeight = lfsTotal;
          if (this.totalLfsWeight > 150) {
            this.overweightLfs = 'S';
          }
        }

        if (cart.lfsPartsMap) {
          var item = null;
          var lfpsTotal = 0;
          this.hzmtPartsFlag = 'N';
          for (var id in cart.lfsPartsMap) {
            item = cart.lfsPartsMap[id].items[0];
            lfpsTotal += parseFloat(item.weight) * parseFloat(item.selectedQty);
            if (item.hzmt == '1') {
              this.hzmtPartsFlag = "Y";
            }
          }
          this.totalLfpsWeight = lfpsTotal;
          if (this.totalLfpsWeight > 150) {
            this.overweightLfps = 'S';
          }
        }

        if (cart.ppsSupplyMap) {
          var item = null;
          var ppsTotal = 0;
          for (var id in cart.ppsSupplyMap) {
            item = cart.ppsSupplyMap[id].items[0];
            this.selectedPpsObj.deliveryContactName = cart.ppsSupplyMap[id].shipMthdDeliveryContactName;
            this.selectedPpsObj.deliveryContactPhone = cart.ppsSupplyMap[id].shipMthdDeliveryContactPhone;

            ppsTotal += parseFloat(item.weight) * parseFloat(item.selectedQty);
          }
          this.totalPpsWeight = ppsTotal;
          if (this.totalPpsWeight > 150) {
            this.overweightPps = 'S';
          }
        }


      }

      if (sessionStorage.getItem('ShippingOptions') != null) {
        var cartShippingOptions = JSON.parse(sessionStorage.getItem('ShippingOptions'));
        if (cartShippingOptions.lfsSupplyShippingOptions) {
          this.selectedLfsObj = cartShippingOptions.lfsSupplyShippingOptions;
        }
        if (cartShippingOptions.lfsPartsShippingOptions) {
          this.selectedLfspObj = cartShippingOptions.lfsPartsShippingOptions;
        }
        if (cartShippingOptions.cpcSupplyShippingOptions) {
          this.selectedCpcObj = cartShippingOptions.cpcSupplyShippingOptions;
        }
        if (cartShippingOptions.epSupplyShippingOptions) {
          this.selectedEpObj = cartShippingOptions.epSupplyShippingOptions;
        }
        if (cartShippingOptions.ppsSupplyShippingOptions) {
          this.selectedPpsObj = cartShippingOptions.ppsSupplyShippingOptions;

        }
        if (cartShippingOptions.azcoSupplyShippingOptions) {
          this.selectedAzcoObj = cartShippingOptions.azcoSupplyShippingOptions;
        }
      }

      console.log('final form : ', this.shippingForm.controls);
      console.log('LFSSuppliesDeliveryLabels : ', this.LFSSuppliesDeliveryLabels);
      if (sessionStorage.getItem('shippingOptions') == null || this.selectedCpcObj.shippingMethod == null || this.selectedEpObj.shippingMethod == null || this.selectedLfsObj.shippingMethod == null || this.selectedLfspObj.shippingMethod == null || this.selectedPpsObj.shippingMethod == null) {
        for (let sm in this.shippingMethods) {
          if (this.shippingMethods[sm].shippingMtd == "Ship Best Method" && this.shippingMethods[sm].businessUnit == "LFS Supplies") {
            if (this.selectedCpcObj.shippingMethod == undefined) {
              this.selectedCpcObj.shippingMethod = this.shippingMethods[sm];
            }
            if (this.selectedEpObj.shippingMethod == undefined) {
              this.selectedEpObj.shippingMethod = this.shippingMethods[sm];
            }
            if (this.selectedLfsObj.shippingMethod == undefined) {
              this.selectedLfsObj.shippingMethod = this.shippingMethods[sm];
            }
            if (this.selectedAzcoObj.shippingMethod == undefined) {
              this.selectedAzcoObj.shippingMethod = this.shippingMethods[sm];
            }
          }
          if (this.shippingMethods[sm].shippingMtd == "Standard" && this.shippingMethods[sm].businessUnit == "LFS Parts & PPS Supplies") {
            if (this.selectedPpsObj.shippingMethod == undefined) {
              this.selectedPpsObj.shippingMethod = this.shippingMethods[sm];
            }
            if (this.selectedLfspObj.shippingMethod == undefined) {
              this.selectedLfspObj.shippingMethod = this.shippingMethods[sm];
            }
          }
        }
        this.selectedCpcObj.carrier = this.selectedCpcObj.carrier || {};
        this.selectedLfsObj.carrier = this.selectedLfsObj.carrier || {};
        this.selectedEpObj.carrier = this.selectedEpObj.carrier || {};
        this.selectedAzcoObj.carrier = this.selectedAzcoObj.carrier || {};
        this.selectedLfspObj.carrier = this.selectedLfspObj.carrier || {};

        this.shippingForm.patchValue({
          selectedCpcObj: { 
            shippingMethod: this.selectedCpcObj.shippingMethod.shippingMtd, carrier: this.selectedCpcObj.carrier.carrierName, 
            carrierAccount: this.selectedCpcObj.carrierAccount, additionalShippingInstructions: this.selectedCpcObj.additionalShippingInstructions },
          selectedLfsObj: { 
            shippingMethod: this.selectedLfsObj.shippingMethod.shippingMtd, carrier: this.selectedLfsObj.carrier.carrierName, 
            carrierAccount: this.selectedLfsObj.carrierAccount, additionalShippingInstructions: this.selectedLfsObj.additionalShippingInstructions },
          selectedEpObj: { 
            shippingMethod: this.selectedEpObj.shippingMethod.shippingMtd, carrier: this.selectedEpObj.carrier.carrierName , 
            carrierAccount: this.selectedEpObj.carrierAccount, additionalShippingInstructions: this.selectedEpObj.additionalShippingInstructions },
          selectedAzcoObj: {
             shippingMethod: this.selectedAzcoObj.shippingMethod.shippingMtd, carrier: this.selectedAzcoObj.carrier.carrierName,
             carrierAccount: this.selectedAzcoObj.carrierAccount, additionalShippingInstructions: this.selectedAzcoObj.additionalShippingInstructions },
          selectedLfspObj: {
             shippingMethod: this.selectedLfspObj.shippingMethod.shippingMtd, carrier: this.selectedLfspObj.carrier.carrierName,
             carrierAccount: this.selectedLfspObj.carrierAccount, additionalShippingInstructions: this.selectedLfspObj.additionalShippingInstructions },
          selectedPpsObj: {
            shippingMethod: this.selectedPpsObj.shippingMethod.shippingMtd, deliveryContactName: this.selectedPpsObj.deliveryContactName,
            deliveryContactPhone: this.selectedPpsObj.deliveryContactPhone, carrierAccount: this.selectedPpsObj.carrierAccount, 
            additionalShippingInstructions: this.selectedPpsObj.additionalShippingInstructions
          }

        });
      }

      this.deliveryServices.map((service) => {
        if (service.businessUnit.indexOf('LFS Supplies') !== -1) {
          this.formSelectedCpcObj.addControl(service.delSrvcValue.replace(' ', '_'), new FormControl(this.selectedCpcObj[service.delSrvcValue]));
          this.formSelectedLfsObj.addControl(service.delSrvcValue.replace(' ', '_'), new FormControl(this.selectedLfsObj[service.delSrvcValue]));
          this.formSelectedEpObj.addControl(service.delSrvcValue.replace(' ', '_'), new FormControl(this.selectedEpObj[service.delSrvcValue]));
          this.formSelectedAzcoObj.addControl(service.delSrvcValue.replace(' ', '_'), new FormControl(this.selectedAzcoObj[service.delSrvcValue]));
          this.LFSSuppliesDeliveryLabels.push(service.delSrvcValue);
        }
        if (service.businessUnit.indexOf('LFS Parts') !== -1) {
          this.formSelectedLfspObj.addControl(service.delSrvcValue.replace(' ', '_'), new FormControl(this.selectedLfspObj[service.delSrvcValue]));
          this.LFSPartsDeliveryLabels.push(service.delSrvcValue);
        }
        if (service.businessUnit.indexOf('PPS Supplies') !== -1) {
          this.formSelectedPpsObj.addControl(service.delSrvcValue.replace(' ', '_'), new FormControl(this.selectedPpsObj[service.delSrvcValue]));
          this.PPSSuppliesDeliveryLabels.push(service.delSrvcValue);
        }
      })

    })
    this.cart = this.orderService.getCart();
    this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
    this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
    this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
    this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
    this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
    this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);


    
  }
  get dsOptions() {
    const abc = (<FormGroup>(<FormArray>(<FormGroup>this.shippingForm.get('selectedCpcObj')).get('deliveryServices')).controls[0]).controls;
    return abc;
  }
  getPriceOrGetPriceTaxFreight = function (mapObject, addrType, orderType, shipMethod) {
    this.partPriceErrMsg = '';
    this.processingRequest = 'processingRequest';
    var param = {
      "accountDetails": this.cart.accountInfo,
      "mapObject": mapObject,
      "orderType": orderType,
      "shipType": shipMethod
    }
    this.orderService.getPriceOrGetPriceTaxFreight(param).subscribe(
      (results) => {
        ////TODO: hidePleaseWait();
        this.processingRequest = '';
        if (results.success == 'N') {
          this.partPriceErrMsg = 'Sorry could not complete that action. Please call 1-866-472-7876 for assistance.';
          ////TODO: $("#pricingErrorMsgModal").modal('show');
        } else {
          console.log("TaxFreight API Response");
          console.log('price:' + results);
          var ordTotDealTaxAmt = results.ordTotDealTaxAmt;
          var ordTotDealChrgAmt = results.ordTotDealChrgAmt;
          console.log("Tax : " + ordTotDealTaxAmt + " , Shipping : " + ordTotDealChrgAmt);
          this.cart = this.orderService.getCart();
          var keyTax = "machineTax";
          var keyShipping = "shippingCharges";

          if (addrType == this.ADDR_TYPE_LFP) {
            for (let itemKey in this.cart.lfsPartsMap) {
              (this.cart.lfsPartsMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.lfsPartsMap[itemKey])[keyShipping] = ordTotDealChrgAmt;
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();

          } else if (addrType == this.ADDR_TYPE_LFS) {
            for (let itemKey in this.cart.lfsSupplyMap) {
              (this.cart.lfsSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.lfsSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();

          } else if (addrType == this.ADDR_TYPE_PPS) {
            for (let itemKey in this.cart.ppsSupplyMap) {
              (this.cart.ppsSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.ppsSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_EP) {
            for (let itemKey in this.cart.epSupplyMap) {
              (this.cart.epSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.epSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_CPC) {
            for (let itemKey in this.cart.cpcSupplyMap) {
              (this.cart.cpcSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.cpcSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_AZCO) {
            for (let itemKey in this.cart.azcoSupplyMap) {
              (this.cart.azcoSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.azcoSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          }
        }
      },
      (reason) => {
        console.log(reason);
        this.processingRequest = '';
        ////TODO: hidePleaseWait();
      }
    );
  }
  getGrandTaxAndShippingFromLocalStorage() {
    this.grandTax = this.orderService.grandTax();
    this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
  }
  proceedToShippping() {
    if (!this.isEmptyCpcSupplyMap) {
      this.selectedCpcObj.CarrierErr = 'N';
      this.selectedCpcObj.CarrierAccountErr = 'N';
      this.selectedCpcObj.carrierAccount = this.shippingForm.value.selectedCpcObj.carrierAccount;
      this.selectedCpcObj.additionalShippingInstructions = this.shippingForm.value.selectedCpcObj.additionalShippingInstructions;
      if (this.selectedCpcObj.shippingMethod && this.selectedCpcObj.shippingMethod.shippingMtd == 'Carrier Collect') {
        if (Object.keys(this.selectedCpcObj.carrier).length === 0) {
          this.selectedCpcObj.CarrierErr = 'Y';
          return;
        }
        if (!this.selectedCpcObj.carrierAccount) {
          this.selectedCpcObj.CarrierAccountErr = 'Y';
          return;
        }
        this.CarrierCode = this.selectedCpcObj.carrier.carrierCode;
        this.shipMtdCustPONum = this.selectedCpcObj.carrierAccount;
        this.CarrierName = this.selectedCpcObj.carrier.carrierName;
        this.CarrierSvcLvlCode = this.selectedCpcObj.carrier.carrierSvcLvlCdl;
        this.CarrierLIFNR = this.selectedCpcObj.carrier.carrierLIFNR;

      } else if (this.selectedCpcObj.shippingMethod && this.selectedCpcObj.shippingMethod.shippingMtd == 'Will Call' && this.carrierAcctReqd == 'Y') {
        if (!this.selectedCpcObj.additionalShippingInstructions) {
          this.selectedCpcObj.WillCallCarrierAcctErr = 'Y';
          return;
        }
      } else if (this.selectedCpcObj.shippingMethod.carrier) {
        this.CarrierCode = this.selectedCpcObj.shippingMethod.carrierCode;
        this.CarrierSvcLvlCode = this.selectedCpcObj.shippingMethod.CarrierSvcLvlCode;
        this.CarrierLIFNR = this.selectedCpcObj.carrier.carrierLIFNR;
        this.shipMtdCustPONum = null;
      }

      let cpcDeliverySrc = "";
      if (this.selectedCpcObj.shippingMethod) {
        for (let ds in this.deliveryServices) {
          for (let key in this.shippingForm.value.selectedCpcObj) {
            if (this.deliveryServices[ds].delSrvcValue && this.deliveryServices[ds].delSrvcValue.replace(' ', '_') == key && this.shippingForm.value.selectedCpcObj[key]) {
              cpcDeliverySrc += this.deliveryServices[ds].delSrvcValue + ',';
              this.selectedCpcObj[this.deliveryServices[ds].delSrvcValue] = true;
            }
          }
        }

        cpcDeliverySrc = cpcDeliverySrc.replace(/,\s*$/, "");

        for (let itemKey in this.cart.cpcSupplyMap) {
          if (this.selectedCpcObj.shippingMethod && this.selectedCpcObj.shippingMethod.shippingMtd == 'Carrier Collect') {
            (this.cart.cpcSupplyMap[itemKey])["shippingMethodCode"] = this.selectedCpcObj.carrier.carrierLIFNR + '*' + this.selectedCpcObj.shippingMethod.shippingMtd;
          } else {
            (this.cart.cpcSupplyMap[itemKey])["shippingMethodCode"] = this.selectedCpcObj.shippingMethod.shpSvcLvlCdl + '*' + this.selectedCpcObj.shippingMethod.shippingMtd;
          }

          // (this.cart.cpcSupplyMap[itemKey])["shippingMethodCode"]= this.selectedCpcObj.shippingMethod.shpSvcLvlCdl+'*'+this.selectedCpcObj.shippingMethod.shippingMtd;
          (this.cart.cpcSupplyMap[itemKey])["shipMthdFrieghtCode"] = this.selectedCpcObj.shippingMethod.freight_code;
          (this.cart.cpcSupplyMap[itemKey])["shipMtdCarrierCode"] = this.CarrierCode;
          (this.cart.cpcSupplyMap[itemKey])["shipMthdCarrierName"] = this.CarrierName;
          (this.cart.cpcSupplyMap[itemKey])["shipMthdCarrierSvcLvlCd"] = this.CarrierSvcLvlCode;
          (this.cart.cpcSupplyMap[itemKey])["shipMthdCarrierLIFNR"] = this.CarrierLIFNR;
          (this.cart.cpcSupplyMap[itemKey])["shipMtdCustPONum"] = this.shipMtdCustPONum;
          (this.cart.cpcSupplyMap[itemKey])["shipMthdAddInst"] = this.selectedCpcObj.additionalShippingInstructions;
          (this.cart.cpcSupplyMap[itemKey])["shipMthdDeliverySource"] = cpcDeliverySrc;
          //(this.cart.cpcSupplyMap[itemKey].carrier=this.selectedEpObj.carrier; 
        }
      }

      //this.cart.cpcSupplyShippingOptions = this.selectedCpcObj;
      this.cartShippingOptions.cpcSupplyShippingOptions = this.selectedCpcObj;
      //this.getPriceOrGetPriceTaxFreight(this.cart.cpcSupplyMap,ADDR_TYPE_CPC,'CPC',shipMethod);
    }
    if (!this.isEmptyEpSupplyMap) {
      this.selectedEpObj.CarrierErr = 'N';
      this.selectedEpObj.CarrierAccountErr = 'N';
      this.selectedEpObj.carrierAccount = this.shippingForm.value.selectedEpObj.carrierAccount;
      this.selectedEpObj.additionalShippingInstructions = this.shippingForm.value.selectedEpObj.additionalShippingInstructions;
      if (this.selectedEpObj.shippingMethod && this.selectedEpObj.shippingMethod.shippingMtd && this.selectedEpObj.shippingMethod.shippingMtd == 'Carrier Collect') {
        if (Object.keys(this.selectedEpObj.carrier).length === 0) {
          this.selectedEpObj.CarrierErr = 'Y';
          return;
        }
        if (!this.selectedEpObj.carrierAccount) {
          this.selectedEpObj.CarrierAccountErr = 'Y';
          return;
        }
        this.CarrierCode = this.selectedEpObj.carrier.carrierCode;
        this.shipMtdCustPONum = this.selectedEpObj.carrierAccount;
        this.CarrierName = this.selectedEpObj.carrier.carrierName;
        this.CarrierSvcLvlCode = this.selectedEpObj.carrier.carrierSvcLvlCdl;
        this.CarrierLIFNR = this.selectedEpObj.carrier.carrierLIFNR;

      } else if (this.selectedEpObj.shippingMethod && this.selectedEpObj.shippingMethod.shippingMtd == 'Will Call' && this.carrierAcctReqd == 'Y') {
        if (!this.selectedEpObj.additionalShippingInstructions) {
          this.selectedEpObj.WillCallCarrierAcctErr = 'Y';
          return;
        }
      } else if (this.selectedEpObj.shippingMethod.carrier) {
        this.CarrierCode = this.selectedEpObj.shippingMethod.carrierCode;
        this.CarrierSvcLvlCode = this.selectedEpObj.shippingMethod.CarrierSvcLvlCode;
        this.CarrierLIFNR = this.selectedEpObj.carrier.carrierLIFNR;
        this.shipMtdCustPONum = null;
      }

      if (this.selectedEpObj.shippingMethod) {
        var epDeliverySrc = "";
        for (let ds in this.deliveryServices) {
          for (let key in this.shippingForm.value.selectedEpObj) {
            if (this.deliveryServices[ds].delSrvcValue && this.deliveryServices[ds].delSrvcValue.replace(' ', '_') == key && this.shippingForm.value.selectedEpObj[key]) {
              epDeliverySrc += this.deliveryServices[ds].delSrvcValue + ',';
              this.selectedEpObj[this.deliveryServices[ds].delSrvcValue] = true;
            }
          }
        }
        epDeliverySrc = epDeliverySrc.replace(/,\s*$/, "");

        for (let itemKey in this.cart.epSupplyMap) {
          // (this.cart.epSupplyMap[itemKey])["shipMethod"]= this.selectedEpObj.shippingMethod.shpSvcLvlCdl+'*'+this.selectedEpObj.shippingMethod.shippingMtd;
          if (this.selectedEpObj.shippingMethod && this.selectedEpObj.shippingMethod.shippingMtd == 'Carrier Collect') {
            (this.cart.epSupplyMap[itemKey])["shippingMethodCode"] = this.selectedEpObj.carrier.carrierLIFNR + '*' + this.selectedEpObj.shippingMethod.shippingMtd;
          } else {
            (this.cart.epSupplyMap[itemKey])["shippingMethodCode"] = this.selectedEpObj.shippingMethod.shpSvcLvlCdl + '*' + this.selectedEpObj.shippingMethod.shippingMtd;
          }

          (this.cart.epSupplyMap[itemKey])["shipMthdFrieghtCode"] = this.selectedEpObj.shippingMethod.freight_code;
          (this.cart.epSupplyMap[itemKey])["shipMtdCarrierCode"] = this.CarrierCode;
          (this.cart.epSupplyMap[itemKey])["shipMthdCarrierName"] = this.CarrierName;
          (this.cart.epSupplyMap[itemKey])["shipMthdCarrierSvcLvlCd"] = this.CarrierSvcLvlCode;
          (this.cart.epSupplyMap[itemKey])["shipMthdCarrierLIFNR"] = this.CarrierLIFNR;
          (this.cart.epSupplyMap[itemKey])["shipMtdCustPONum"] = this.shipMtdCustPONum;
          (this.cart.epSupplyMap[itemKey])["shipMthdAddInst"] = this.selectedEpObj.additionalShippingInstructions;
          (this.cart.epSupplyMap[itemKey])["shipMthdDeliverySource"] = epDeliverySrc;
        }
      }

      //this.cart.epSupplyShippingOptions = this.selectedEpObj;
      this.cartShippingOptions.epSupplyShippingOptions = this.selectedEpObj;
      //this.getPriceOrGetPriceTaxFreight(this.cart.epSupplyMap,ADDR_TYPE_EP,'EP',shipMethod);
    }
    if (!this.isEmptyLfsSupplyMap) {
      this.selectedLfsObj.CarrierErr = 'N';
      this.selectedLfsObj.CarrierAccountErr = 'N';
      this.selectedLfsObj.carrierAccount = this.shippingForm.value.selectedLfsObj.carrierAccount;
      this.selectedLfsObj.additionalShippingInstructions = this.shippingForm.value.selectedLfsObj.additionalShippingInstructions;
      if (this.selectedLfsObj.shippingMethod && this.selectedLfsObj.shippingMethod.shippingMtd == 'Carrier Collect') {
        if (Object.keys(this.selectedLfsObj.carrier).length === 0) {
          this.selectedLfsObj.CarrierErr = 'Y';
          return;
        }
        if (!this.selectedLfsObj.carrierAccount) {
          this.selectedLfsObj.CarrierAccountErr = 'Y';
          return;
        }
        this.CarrierCode = this.selectedLfsObj.carrier.carrierCode;
        this.shipMtdCustPONum = this.selectedLfsObj.carrierAccount;
        this.CarrierName = this.selectedLfsObj.carrier.carrierName;
        this.CarrierSvcLvlCode = this.selectedLfsObj.carrier.carrierSvcLvlCdl;
        this.CarrierLIFNR = this.selectedLfsObj.carrier.carrierLIFNR;
      } else if (this.selectedLfsObj.shippingMethod && this.selectedLfsObj.shippingMethod.shippingMtd == 'Will Call' && this.carrierAcctReqd == 'Y') {
        if (!this.selectedLfsObj.additionalShippingInstructions) {
          this.selectedLfsObj.WillCallCarrierAcctErr = 'Y';
          return;
        }
      } else if (this.selectedLfsObj.shippingMethod.carrier) {
        this.CarrierCode = this.selectedLfsObj.shippingMethod.carrierCode;
        this.CarrierSvcLvlCode = this.selectedLfsObj.shippingMethod.CarrierSvcLvlCode;
        this.CarrierLIFNR = this.selectedLfsObj.carrier.carrierLIFNR;
        this.shipMtdCustPONum = null;
      }
      if (this.selectedLfsObj.shippingMethod) {
        var lfsDeliverySrc = "";
        for (let ds in this.deliveryServices) {
          for (let key in this.shippingForm.value.selectedLfsObj) {
            if (this.deliveryServices[ds].delSrvcValue && this.deliveryServices[ds].delSrvcValue.replace(' ', '_') == key && this.shippingForm.value.selectedLfsObj[key]) {
              lfsDeliverySrc += this.deliveryServices[ds].delSrvcValue + ',';
              this.selectedLfsObj[this.deliveryServices[ds].delSrvcValue] = true;
            }
          }
        }
        lfsDeliverySrc = lfsDeliverySrc.replace(/,\s*$/, "");
        for (let itemKey in this.cart.lfsSupplyMap) {
          if (this.selectedLfsObj.shippingMethod && this.selectedLfsObj.shippingMethod.shippingMtd == 'Carrier Collect') {
            (this.cart.lfsSupplyMap[itemKey])["shippingMethodCode"] = this.selectedLfsObj.carrier.carrierLIFNR + '*' + this.selectedLfsObj.shippingMethod.shippingMtd;
          } else {
            (this.cart.lfsSupplyMap[itemKey])["shippingMethodCode"] = this.selectedLfsObj.shippingMethod.shpSvcLvlCdl + '*' + this.selectedLfsObj.shippingMethod.shippingMtd;
          }

          (this.cart.lfsSupplyMap[itemKey])["shipMthdFrieghtCode"] = this.selectedLfsObj.shippingMethod.freight_code;
          (this.cart.lfsSupplyMap[itemKey])["shipMtdCarrierCode"] = this.CarrierCode;
          (this.cart.lfsSupplyMap[itemKey])["shipMthdCarrierName"] = this.CarrierName;
          (this.cart.lfsSupplyMap[itemKey])["shipMthdCarrierSvcLvlCd"] = this.CarrierSvcLvlCode;
          (this.cart.lfsSupplyMap[itemKey])["shipMthdCarrierLIFNR"] = this.CarrierLIFNR;
          (this.cart.lfsSupplyMap[itemKey])["shipMtdCustPONum"] = this.shipMtdCustPONum;
          (this.cart.lfsSupplyMap[itemKey])["shipMthdAddInst"] = this.selectedLfsObj.additionalShippingInstructions;
          (this.cart.lfsSupplyMap[itemKey])["shipMthdDeliverySource"] = lfsDeliverySrc;

          //(this.cart.cpcSupplyMap[itemKey].carrier=this.selectedEpObj.carrier; 
        }
      }
      //this.cart.lfsSupplyShippingOptions = this.selectedLfsObj;
      this.cartShippingOptions.lfsSupplyShippingOptions = this.selectedLfsObj;
      //this.getPriceOrGetPriceTaxFreight(this.cart.lfsSupplyMap, this.ADDR_TYPE_LFS, 'LFS', this.shipMethod);
    }
    if (!this.isEmptyAzcoSupplyMap) {
      this.selectedAzcoObj.CarrierErr = 'N';
      this.selectedAzcoObj.CarrierAccountErr = 'N';
      this.selectedAzcoObj.carrierAccount = this.shippingForm.value.selectedAzcoObj.carrierAccount;
      this.selectedAzcoObj.additionalShippingInstructions = this.shippingForm.value.selectedAzcoObj.additionalShippingInstructions;
      if (this.selectedAzcoObj.shippingMethod && this.selectedAzcoObj.shippingMethod.shippingMtd == 'Carrier Collect') {
        if (Object.keys(this.selectedAzcoObj.carrier).length === 0) {
          this.selectedAzcoObj.CarrierErr = 'Y';
          return;
        }
        if (!this.selectedAzcoObj.carrierAccount) {
          this.selectedAzcoObj.CarrierAccountErr = 'Y';
          return;
        }
        this.CarrierCode = this.selectedAzcoObj.carrier.carrierCode;
        this.shipMtdCustPONum = this.selectedAzcoObj.carrierAccount;
        this.CarrierName = this.selectedAzcoObj.carrier.carrierName;
        this.CarrierSvcLvlCode = this.selectedAzcoObj.carrier.carrierSvcLvlCdl;
        this.CarrierLIFNR = this.selectedAzcoObj.carrier.carrierLIFNR;
      } else if (this.selectedAzcoObj.shippingMethod && this.selectedAzcoObj.shippingMethod.shippingMtd == 'Will Call' && this.carrierAcctReqd == 'Y') {
        if (!this.selectedAzcoObj.additionalShippingInstructions) {
          this.selectedAzcoObj.WillCallCarrierAcctErr = 'Y';
          return;
        }
      } else if (this.selectedAzcoObj.shippingMethod.carrier) {
        this.CarrierCode = this.selectedAzcoObj.shippingMethod.carrierCode;
        this.CarrierSvcLvlCode = this.selectedAzcoObj.shippingMethod.CarrierSvcLvlCode;
        this.CarrierLIFNR = this.selectedAzcoObj.carrier.carrierLIFNR;
        this.shipMtdCustPONum = null;

      }
      if (this.selectedAzcoObj.shippingMethod) {
        var lfsDeliverySrc = "";
        for (let ds in this.deliveryServices) {
          for (let key in this.shippingForm.value.selectedAzcoObj) {
            if (this.deliveryServices[ds].delSrvcValue && this.deliveryServices[ds].delSrvcValue.replace(' ', '_') == key && this.shippingForm.value.selectedAzcoObj[key]) {
              lfsDeliverySrc += this.deliveryServices[ds].delSrvcValue + ',';
              this.selectedAzcoObj[this.deliveryServices[ds].delSrvcValue] = true;
            }
          }
        }
        lfsDeliverySrc = lfsDeliverySrc.replace(/,\s*$/, "");
        for (let itemKey in this.cart.azcoSupplyMap) {
          if (this.selectedAzcoObj.shippingMethod && this.selectedAzcoObj.shippingMethod.shippingMtd == 'Carrier Collect') {
            (this.cart.azcoSupplyMap[itemKey])["shippingMethodCode"] = this.selectedAzcoObj.carrier.carrierLIFNR + '*' + this.selectedAzcoObj.shippingMethod.shippingMtd;
          } else {
            (this.cart.azcoSupplyMap[itemKey])["shippingMethodCode"] = this.selectedAzcoObj.shippingMethod.shpSvcLvlCdl + '*' + this.selectedAzcoObj.shippingMethod.shippingMtd;
          }

          (this.cart.azcoSupplyMap[itemKey])["shipMthdFrieghtCode"] = this.selectedAzcoObj.shippingMethod.freight_code;
          (this.cart.azcoSupplyMap[itemKey])["shipMtdCarrierCode"] = this.CarrierCode;
          (this.cart.azcoSupplyMap[itemKey])["shipMthdCarrierName"] = this.CarrierName;
          (this.cart.azcoSupplyMap[itemKey])["shipMthdCarrierSvcLvlCd"] = this.CarrierSvcLvlCode;
          (this.cart.azcoSupplyMap[itemKey])["shipMthdCarrierLIFNR"] = this.CarrierLIFNR;
          (this.cart.azcoSupplyMap[itemKey])["shipMtdCustPONum"] = this.shipMtdCustPONum;
          (this.cart.azcoSupplyMap[itemKey])["shipMthdAddInst"] = this.selectedAzcoObj.additionalShippingInstructions;
          (this.cart.azcoSupplyMap[itemKey])["shipMthdDeliverySource"] = lfsDeliverySrc;
        }
      }
      this.cartShippingOptions.azcoSupplyShippingOptions = this.selectedAzcoObj;
      //this.getPriceOrGetPriceTaxFreight(this.cart.azcoSupplyMap, this.ADDR_TYPE_AZCO, 'AZCO', this.shipMethod);
    }
    if (!this.isEmptyPpsSupplyMap) {
      this.selectedPpsObj.deliveryContactNameErr = 'N';
      this.selectedPpsObj.deliveryContactPhoneErr = 'N';
      this.selectedPpsObj.carrierAccount = this.shippingForm.value.selectedPpsObj.carrierAccount;
      this.selectedPpsObj.additionalShippingInstructions = this.shippingForm.value.selectedPpsObj.additionalShippingInstructions;
      this.selectedPpsObj.deliveryContactName = this.shippingForm.value.selectedPpsObj.deliveryContactName;
      this.selectedPpsObj.deliveryContactPhone = this.shippingForm.value.selectedPpsObj.deliveryContactPhone;
      
      if (!this.selectedPpsObj.deliveryContactName) {
        this.selectedPpsObj.deliveryContactNameErr = 'Y';
        return;
      }
      if (!this.selectedPpsObj.deliveryContactPhone) {
        this.selectedPpsObj.deliveryContactPhoneErr = 'Y';
        return;
      }

      if (this.selectedPpsObj.shippingMethod && this.selectedPpsObj.shippingMethod.shippingMtd == 'Will Call' && this.carrierAcctReqd == 'Y') {
        if (!this.selectedPpsObj.additionalShippingInstructions) {
          this.selectedPpsObj.WillCallCarrierAcctErr = 'Y';
          return;
        }
      }
      if (this.selectedPpsObj.shippingMethod) {
        var ppsDeliverySrc = "";
        for (let ds in this.deliveryServices) {
          for (let key in this.shippingForm.value.selectedPpsObj) {
            if (this.deliveryServices[ds].delSrvcValue && this.deliveryServices[ds].delSrvcValue.replace(' ', '_') == key && this.shippingForm.value.selectedPpsObj[key]) {
              ppsDeliverySrc += this.deliveryServices[ds].delSrvcValue + ',';
              this.selectedPpsObj[this.deliveryServices[ds].delSrvcValue] = true;
            }
          }
        }
        ppsDeliverySrc = ppsDeliverySrc.replace(/,\s*$/, "");
        for (let itemKey in this.cart.ppsSupplyMap) {
          (this.cart.ppsSupplyMap[itemKey])["shippingMethodCode"] = this.selectedPpsObj.shippingMethod.shpSvcLvlCdl + '*' + this.selectedPpsObj.shippingMethod.shippingMtd;
          (this.cart.ppsSupplyMap[itemKey])["shipMthdFrieghtCode"] = this.selectedPpsObj.shippingMethod.freight_code;
          (this.cart.ppsSupplyMap[itemKey])["shipMtdCarrierCode"] = this.CarrierCode;
          (this.cart.ppsSupplyMap[itemKey])["shipMtdCustPONum"] = this.shipMtdCustPONum;
          (this.cart.ppsSupplyMap[itemKey])["shipMthdAddInst"] = this.selectedPpsObj.additionalShippingInstructions;
          (this.cart.ppsSupplyMap[itemKey])["shipMthdDeliverySource"] = ppsDeliverySrc;
          (this.cart.ppsSupplyMap[itemKey])["shipMthdDeliveryContactName"] = this.selectedPpsObj.deliveryContactName;
          (this.cart.ppsSupplyMap[itemKey])["shipMthdDeliveryContactPhone"] = this.selectedPpsObj.deliveryContactPhone;
        }
      }
      this.cartShippingOptions.ppsSupplyShippingOptions = this.selectedPpsObj;
    }
    if (!this.isEmptyLfsPartsSupplyMap) {
      this.selectedLfspObj.CarrierErr = 'N';
      this.selectedLfspObj.CarrierAccountErr = 'N';
      this.selectedLfspObj.carrierAccount = this.shippingForm.value.selectedLfspObj.carrierAccount;
      this.selectedLfspObj.additionalShippingInstructions = this.shippingForm.value.selectedLfspObj.additionalShippingInstructions;
      if (this.selectedLfspObj.shippingMethod && this.selectedLfspObj.shippingMethod.shippingMtd == 'Carrier Collect') {
        if (Object.keys(this.selectedLfspObj.carrier).length === 0) {
          this.selectedLfspObj.CarrierErr = 'Y';
          return;
        }
        if (!this.selectedLfspObj.carrierAccount) {
          this.selectedLfspObj.CarrierAccountErr = 'Y';
          return;
        }
        this.CarrierCode = this.selectedLfspObj.carrier.carrierCode;
        this.shipMtdCustPONum = this.selectedLfspObj.carrierAccount;
        this.CarrierSvcLvlCode = this.selectedLfspObj.carrier.carrierSvcLvlCdl;
        this.CarrierLIFNR = this.selectedLfspObj.carrier.carrierLIFNR;
        this.CarrierName = this.selectedLfspObj.carrier.carrierName;

      } else if (this.selectedLfspObj.shippingMethod && this.selectedLfspObj.shippingMethod.shippingMtd == 'Will Call' && this.carrierAcctReqd == 'Y') {
        if (!this.selectedLfspObj.additionalShippingInstructions) {
          this.selectedLfspObj.WillCallCarrierAcctErr = 'Y';
          return;
        }
      } else if (this.selectedLfspObj.shippingMethod.carrier) {
        this.CarrierCode = this.selectedLfspObj.shippingMethod.carrierCode;
        this.CarrierName = this.selectedLfspObj.carrier.carrierName;
        this.CarrierSvcLvlCode = this.selectedLfspObj.carrier.CarrierSvcLvlCode;
        this.CarrierLIFNR = this.selectedLfspObj.carrier.carrierLIFNR;
        this.shipMtdCustPONum = null;

      }
      if (this.selectedLfspObj.shippingMethod) {
        var lfspDeliverySrc = "";
        for (let ds in this.deliveryServices) {
          for (let key in this.shippingForm.value.selectedLfspObj) {
            if (this.deliveryServices[ds].delSrvcValue && this.deliveryServices[ds].delSrvcValue.replace(' ', '_') == key && this.shippingForm.value.selectedLfspObj[key]) {
              lfspDeliverySrc += this.deliveryServices[ds].delSrvcValue + ',';
              this.selectedLfspObj[this.deliveryServices[ds].delSrvcValue] = true;
            }
          }
        }
        lfspDeliverySrc = lfspDeliverySrc.replace(/,\s*$/, "");
        for (let itemKey in this.cart.lfsPartsMap) {
          if (this.selectedLfspObj.shippingMethod && this.selectedLfspObj.shippingMethod.shippingMtd == 'Carrier Collect') {
            (this.cart.lfsPartsMap[itemKey])["shippingMethodCode"] = this.selectedLfspObj.carrier.carrierLIFNR + '*' + this.selectedLfspObj.shippingMethod.shippingMtd;
          } else {
            (this.cart.lfsPartsMap[itemKey])["shippingMethodCode"] = this.selectedLfspObj.shippingMethod.shpSvcLvlCdl + '*' + this.selectedLfspObj.shippingMethod.shippingMtd;
          }
          (this.cart.lfsPartsMap[itemKey])["shipMthdFrieghtCode"] = this.selectedLfspObj.shippingMethod.freight_code;
          (this.cart.lfsPartsMap[itemKey])["shipMtdCarrierCode"] = this.CarrierCode;
          (this.cart.lfsPartsMap[itemKey])["shipMthdCarrierName"] = this.CarrierName;
          (this.cart.lfsPartsMap[itemKey])["shipMthdCarrierSvcLvlCd"] = this.CarrierSvcLvlCode;
          (this.cart.lfsPartsMap[itemKey])["shipMthdCarrierLIFNR"] = this.CarrierLIFNR;
          (this.cart.lfsPartsMap[itemKey])["shipMtdCustPONum"] = this.shipMtdCustPONum;
          (this.cart.lfsPartsMap[itemKey])["shipMthdAddInst"] = this.selectedLfspObj.additionalShippingInstructions;
          (this.cart.lfsPartsMap[itemKey])["shipMthdDeliverySource"] = lfspDeliverySrc;
        }
      }
      this.cartShippingOptions.lfsPartsShippingOptions = this.selectedLfspObj;
    }
    console.log('my modified values are: ', this.cart);
    localStorage.setItem('cart', JSON.stringify(this.cart));
    sessionStorage.setItem('ShippingOptions', JSON.stringify(this.cartShippingOptions));

    this.router.navigate(['shippingaddress']);
  }
  getShippingMethod(option) {
    let selectedOption = {};
    this.shippingMethods.forEach(method => {
      if (method.shippingMtd === option) {
        selectedOption = method;
      }
    });
    return selectedOption;
  }

  resetCarrier(event, obj) {
    const option = event.currentTarget.value;
    if (obj == 'cpc') {
      if (option != 'Carrier Collect') {
        this.selectedCpcObj.carrier = {};
        this.selectedCpcObj.carrierAccount = "";
        this.shippingForm.patchValue({
          selectedCpcObj: { carrierAccount: '', carrier: '' },
        });
      }
      this.selectedCpcObj.shippingMethod = this.getShippingMethod(option);
    }
    if (obj == 'ep') {
      if (option != 'Carrier Collect') {
        this.selectedEpObj.carrier = {};
        this.selectedEpObj.carrierAccount = "";
        this.shippingForm.patchValue({
          selectedEpObj: { carrierAccount: '', carrier: '' },
        });
      }
      this.selectedEpObj.shippingMethod = this.getShippingMethod(option);
    }
    if (obj == 'pps') {
      if (option != 'Carrier Collect') {
        this.selectedPpsObj.carrier = {};
        this.selectedPpsObj.carrierAccount = "";
        this.shippingForm.patchValue({
          selectedPpsObj: { carrierAccount: '', carrier: '' },
        });
      }
      this.selectedPpsObj.shippingMethod = this.getShippingMethod(option);

    }
    if (obj == 'lfsp') {
      if (option != 'Carrier Collect') {
        this.selectedLfspObj.carrier = {};
        this.selectedLfspObj.carrierAccount = "";
        this.shippingForm.patchValue({
          selectedLfspObj: { carrierAccount: '', carrier: '' },
        });
      }
      this.selectedLfspObj.shippingMethod = this.getShippingMethod(option);
    }
    if (obj == 'lfs') {
      if (option != 'Carrier Collect') {
        this.selectedLfsObj.carrier = {};
        this.selectedLfsObj.carrierAccount = "";
        this.shippingForm.patchValue({
          selectedLfsObj: { carrierAccount: '', carrier: '' },
        });
      }
      this.selectedLfsObj.shippingMethod = this.getShippingMethod(option);
    }
    if (obj == 'azco') {
      if (option != 'Carrier Collect') {
        this.selectedAzcoObj.carrier = {};
        this.selectedAzcoObj.carrierAccount = "";
        this.shippingForm.patchValue({
          selectedAzcoObj: { carrierAccount: '', carrier: '' },
        });
      }
      this.selectedAzcoObj.shippingMethod = this.getShippingMethod(option);
    }
  }

  getCarrier(option) {
    let selectedOption = {};
    this.carrierOptions.forEach(method => {
      if (method.carrierName === option) {
        selectedOption = method;
      }
    });
    return selectedOption;
  }
  updateSelectedCarrier(event, obj) {
    const option = event.currentTarget.value;
    if (obj == 'cpc') {
      this.selectedCpcObj.carrier = this.getCarrier(option);
    }
    if (obj == 'ep') {
      this.selectedEpObj.carrier = this.getCarrier(option);
    }
    if (obj == 'pps') {
      this.selectedPpsObj.carrier = this.getCarrier(option);
    }
    if (obj == 'lfsp') {
      this.selectedLfspObj.carrier = this.getCarrier(option);
    }
    if (obj == 'lfs') {
      this.selectedLfsObj.carrier = this.getCarrier(option);
    }
    if (obj == 'azco') {
      this.selectedAzcoObj.carrier = this.getCarrier(option);
    }
  }

}
