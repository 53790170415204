import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchFilterPipe } from '../pipe/search-filter.pipe';
import { ModalModule } from 'ngx-bootstrap';
import {MatDatepickerModule} from '@angular/material';
import { OrderHistoryRoutingModule } from './orderhistory-routing.module';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './order-history/CustomDateParserFormatter';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from '../shop-common/block-template.component';
import { ReorderComponent } from './reorder/reorder.component';


@NgModule({
  declarations: [OrderDetailComponent, OrderHistoryComponent, ReorderComponent],
  imports: [
    OrderHistoryRoutingModule,
    CommonModule,
    FormsModule,
    NgxPaginationModule,
			    MatDatepickerModule,
    ModalModule.forRoot(),
	    NgbModule,
      BlockUIModule.forRoot({
        template: BlockTemplateComponent
      })
  ],
  providers: [
    SearchFilterPipe,
    CustomDateParserFormatter
  ],
  bootstrap: [OrderHistoryComponent]
})
export class OrderHistoryModule { }
