import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartReviewComponent } from './cart-review/cart-review.component';
import { ShippingRoutingModule } from './shipping-routing.module';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { ShopCommonModule } from '../shop-common/shop-common.module';
import { CartConfirmComponent } from './cart-confirm/cart-confirm.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from '../shop-common/block-template.component';
@NgModule({
  declarations: [ShippingAddressComponent,CartReviewComponent,CartConfirmComponent],
  imports: [
    CommonModule,
    ShopCommonModule,
    ShippingRoutingModule,
    NgxPaginationModule,
    FormsModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    })
  ],
  entryComponents :[BlockTemplateComponent]
})
export class ShippingModule { }
