export class userDetails {
    
  companyName: string;
    registerId: string;
    firstName:string;
    lastName:string;    
    email:string;
    phone: string;
    serial:string;
    account: string;
    error:any;
    errorExist:boolean; 

    constructor(){
      this.companyName = '';
       this.registerId = '';        
        this.firstName = '';
        this.lastName = '';       
        this.email = '';
        this.phone = '';
        this.serial = '';
        this.account = '';
        this.error = {};
        this.errorExist = false;
      }
}