import { NgModule, LOCALE_ID } from '@angular/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { AccountService } from 'src/app/account/account.service';
import { SupplyService } from 'src/app/supplies/supply.service';
import { LargeformatpartsService } from 'src/app/largeformatparts/largeformatparts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { ContinueReorderModalComponent } from './continue-reorder-modal/continue-reorder-modal.component';
import { OrderService } from 'src/app/service/common-service/order.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css'],
})
export class OrderHistoryComponent implements OnInit {

  @ViewChild('orderNumber') orderNumber: ElementRef;
  @ViewChild('shopReferenceNumber') shopReferenceNumber: ElementRef;
  @ViewChild('orderReferenceNumber') orderReferenceNumber: ElementRef;
  @ViewChild('fromDateModel') fromDate: ElementRef;
  @ViewChild('toDateModel') toDate: ElementRef;
  @ViewChild('contentloader') contentloader: ElementRef;
  @BlockUI() blockUI : NgBlockUI;
  orderHistoryList = [];
  selectedOrder: any;
  showInnerIndex = -1;
  showWaitDiv = false;
  toDateErr = false;
  reorderErrorMessage = "";
  reorderNumber = "";
  accMsgCls = "";
  cart = this.orderService.getCart();
  isEmptyAcctInfo = false;
  isEmptyCpcSupplyMap = false;
  isEmptyEpSupplyMap = false;
  isEmptyAzcoSupplyMap = false;
  isEmptyLfsSupplyMap = false;
  isEmptyPpsSupplyMap = false;
  isEmptyLfsPartsSupplyMap = false;
  isEmptyContractSupplyMap = false;
  isEmptyBillableSupplyMap = false;
  isEmptyPreOwnedMachine = false;
  bContinueReorder = false;
  processingRequest = '';
  reorderItemCnt = 0;
  reorderType = '';
  priceErrMsg = '';
  private subscription: Subscription;
  //myVar;
  selectedSortBy: any;
  selectedSortOrder: any;

  //Pagination related
  pageNum: number = 1;
  recPerPage = 10;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private loginAuthenticationService: LoginAuthenticationService,
    private orderService: OrderService,
    private util: Util,
    private titleService: Title,
    private accountService: AccountService,
    private supplyService: SupplyService,
    private modalService: NgbModal,
    private partsService: LargeformatpartsService,
    private commonService: CommonUtilsService) { }

  ngOnInit() {
    this.selectedSortBy = 'ORDER_NUMBER';
    this.selectedSortOrder = 'D';
    this.getOrderHistoryList();
    this.isEmptyAcctInfo = this.commonService.isEmptyObj(this.cart.accountInfo);
    this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
    this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
    this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
    this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
    this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
    this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
    this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
    this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
    this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  clearSearch() {
    //window.alert('Clear button clicked !');
    this.orderNumber.nativeElement.value = "";
    this.shopReferenceNumber.nativeElement.value = "";
    this.orderReferenceNumber.nativeElement.value = "";
    this.fromDate.nativeElement.value = "";
    this.toDate.nativeElement.value = "";
    this.selectedSortBy = "";
    this.selectedSortOrder = "";

    this.getOrderHistoryList();
    this.isEmptyAcctInfo = this.commonService.isEmptyObj(this.cart.accountInfo);
    this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
    this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
    this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
    this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
    this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
    this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
    this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
    this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
    this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
  }

  navigateOrderDetailPg(orderNumber, event) {
    //window.location.href = 'supplies.htm?view=orderDetails&orderNumber='+orderNumber;
    //this.router.navigate(['/orderdetail']) ;		
	if(event != null) {
		event.preventDefault();
	}
	
    this.router.navigateByUrl('/orderdetail?orderNumber=' + orderNumber);
  }

  setSortOrder(sortBy, sortOrder) {
    this.selectedSortBy = sortBy;
    this.selectedSortOrder = sortOrder;
    this.getOrderHistoryList();

  }

  getOrderHistoryList() {
    //window.alert('Search button clicked !');

    if (!(this.toDate.nativeElement.value == "" && this.fromDate.nativeElement.value == "")) {
      if (this.toDate.nativeElement.value == "" || this.fromDate.nativeElement.value == "") {
        this.toDateErr = true;
        return;
      }
    }

    if (this.toDate.nativeElement.value != "" && this.fromDate.nativeElement.value != "") {
      var toDateStr = formatDate(this.toDate.nativeElement.value, 'yyyy-MM-dd', 'en_US');
      var fromDateStr = formatDate(this.fromDate.nativeElement.value, 'yyyy-MM-dd', 'en_US');

      if (toDateStr < fromDateStr) {
        this.toDateErr = true;
        return;
      } else {
        this.toDateErr = false;
      }
    }
	
    var param = {
      "srch_order_num": this.orderNumber.nativeElement.value,
      "srch_po_num": this.orderReferenceNumber.nativeElement.value,
      "srch_shop_ref_num": this.shopReferenceNumber.nativeElement.value,
      "srch_from_date_num":  this.fromDate.nativeElement.value,
      "srch_to_date_num":    this.toDate.nativeElement.value,
      "orderCol": this.selectedSortBy,
      "orderDir": this.selectedSortOrder
    };

    //var orderHistoryPromise = orderHistoryService.getOrderHistoryList(param);
    console.log(param);
    this.showWaitDiv = true;

    this.orderService.getOrderHistoryList(param).subscribe(data => {
      this.showWaitDiv = false;
      if (data != null) {
        this.orderHistoryList = data.jsonOrderInquiry;
        console.log('orderHistoryList', this.orderHistoryList);
        this.contentloader.nativeElement.classList.add('pleaseWaitCls');
      }
    });

  } // getOrderHistoryList()

  exportOrderHistory() {
	
    //window.alert('export button clicked !');
	
    if (!(this.toDate.nativeElement.value == "" && this.fromDate.nativeElement.value == "")) {
      if (this.toDate.nativeElement.value == "" || this.fromDate.nativeElement.value == "") {
        this.toDateErr = true;
        return;
      }
    }

    if (this.toDate.nativeElement.value != "" && this.fromDate.nativeElement.value != "") {
      var toDateStr = formatDate(this.toDate.nativeElement.value, 'yyyy-MM-dd', 'en_US');
      var fromDateStr = formatDate(this.fromDate.nativeElement.value, 'yyyy-MM-dd', 'en_US');

      if (toDateStr < fromDateStr) {
        this.toDateErr = true;
        return;
      } else {
        this.toDateErr = false;
      }
    }
	
    var param = {
      "srch_order_num": this.orderNumber.nativeElement.value,
      "srch_po_num": this.orderReferenceNumber.nativeElement.value,
      "srch_shop_ref_num": this.shopReferenceNumber.nativeElement.value,
      "srch_from_date_num":   this.fromDate.nativeElement.value,
      "srch_to_date_num": 	  this.toDate.nativeElement.value,
      "orderCol": this.selectedSortBy,
      "orderDir": this.selectedSortOrder
    };

    console.log(param);
    this.showWaitDiv = true;

    this.orderService.getOrderHistoryExport(param).subscribe(data => {
      this.showWaitDiv = false;
      if (data != null) {

        console.log('orderHistoryExport', data);

        this.orderService.jsonToExcel(data.data, 'Order History', true);

        //this.contentloader.nativeElement.classList.add('pleaseWaitCls');
      }
    });

  }  // exportOrderHistory()

  getTrackingInfo(orderNumber: string, selIdx: number, refDivEl: HTMLElement) {
    // window.alert('getTrackingInfo() clicked !'  );
    //console.log(refDivEl);

    if (refDivEl.children[0].classList.contains('fa-plus-circle')) {
      refDivEl.children[0].classList.remove("fa-plus-circle");
      refDivEl.children[0].classList.add("fa-minus-circle");
      refDivEl.children[0].classList.remove("color-green");
      refDivEl.children[0].classList.add("color-red");
      this.showInnerIndex = selIdx;
      refDivEl.parentElement.parentElement.parentElement.children[1];

    } else {
      refDivEl.children[0].classList.remove("fa-minus-circle");
      refDivEl.children[0].classList.add("fa-plus-circle");
      refDivEl.children[0].classList.remove("color-red");
      refDivEl.children[0].classList.add("color-green");

      this.showInnerIndex = -1;
      return;
    }

    var trackingShipment = {
      "order_num": orderNumber
    }

    const orderList = this.orderHistoryList.filter((obj) => {
      return obj.strOrdNum === orderNumber;
    });

    if (orderList.length > 0) {
      this.selectedOrder = orderList[0];
    }

    if (!this.selectedOrder.trackInfoAvailable) {
      this.orderService.getOrderTrackingInfo(trackingShipment).subscribe(data => {
        if (data != null) {
          var orderTrackDetails = data;

          for (let i in this.selectedOrder.partDetailsList) {
            var trackList = [];
            for (let j in orderTrackDetails) {
              if (this.selectedOrder.partDetailsList[i].mdseCd == orderTrackDetails[j].mdse) {
                trackList.push(orderTrackDetails[j]);
              }
            }
            this.selectedOrder.partDetailsList[i].trackInfoList = trackList;
          }
          this.selectedOrder.trackInfoAvailable = 'Y';
        }
        //this.showInnerIndex = selIdx;
      });


    } // if trackinfo not available

  }  // getTrackingInfo()



}
