import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdercontractsuppliesSelectionComponent } from './ordercontractsupplies-selection/ordercontractsupplies-selection.component';

const routes: Routes = [
  {
    path: '',
    component: OrdercontractsuppliesSelectionComponent,
    children: [{ path: 'ordercontractsupplies', component: OrdercontractsuppliesSelectionComponent }],
  }
  // {

  //   path: '',

  //   component: OrdercontractsuppliesSelectionComponent,

  //   children: [{path:'ordercontractsupplies', component:OrdercontractsuppliesSelectionComponent, data: { showHeader: true, showSidebar: false, showFooter: true}}]

  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdercontractsuppliesRoutingModule { }
