import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PaginationInstance } from 'ngx-pagination';
import { OrderService } from 'src/app/service/common-service/order.service';
import { OrdercontractsuppliesService } from '../ordercontractsupplies.service';

import { MeterreadModalComponent } from './meterread-modal/meterread-modal.component';

@Component({
	selector: 'app-ordercontractsupplies-selection',
	templateUrl: './ordercontractsupplies-selection.component.html',
	styleUrls: ['./ordercontractsupplies-selection.component.css'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class OrdercontractsuppliesSelectionComponent implements OnInit {
	@BlockUI() blockUI : NgBlockUI;
	machineFilter = {};
	machineDetailList: any = [];
	machineTotalCount = 0;   // Total number of items in all pages. initialize as a zero  
	listLoaded = false;
	sortKey = 'serial_number';
	sortReverse = false;
	totalRecords = -1;
	selectedUsersCount;
	selectedPerPageCount = 10; //Per Page Records count. Default 10
	pageIndex = 1;   // Current page number. First page is 1.-->
	//maxSize = 8;     // Limit number for pagination display number.  


	userAccountList;
	//machineModelList = {}; // This will be Free Text Field

	serialNumber = "";
	selectedModel = "";
	location = "";
	pleaseWaitCls = false;
	srhSerialNum: string;
	srchModelNum: string;
	srchInstLocn: string;
	pageNum: number = 1;

	public maxSize = 6;
	public config: PaginationInstance = {
		id: 'Pagination',
		itemsPerPage: 10,
		currentPage: 1,
	};

	public labels = {
		previousLabel: '',
		nextLabel: '',
		screenReaderPaginationLabel: 'Pagination',
		screenReaderPageLabel: 'page',
		screenReaderCurrentLabel: `You're on page`
	}



	constructor(private router: Router, private service: OrdercontractsuppliesService, private orderService: OrderService,
		private modalService: NgbModal) { }

	ngOnInit() {
		this.loadAcctsAndModel();
	}
	updateRecPerPage(number: number) {
		this.selectedPerPageCount = number;
		this.config.itemsPerPage = number;
		this.searchMachine();
	}
	onPageChange(number: number) {
		this.config.currentPage = number;
		this.pageIndex = number;
		this.searchMachine();
	}

	onPageBoundsCorrection(number: number) {
		this.config.currentPage = number;
	}


	getSelectedAccountCount() {
		var count = 0;
		for (let i in this.userAccountList) {
			if (this.userAccountList[i].enabled) {
				count++;
			}
		}
		this.selectedUsersCount = count;
		return count;
	}

	// get accountsSelected(): number {
	// 	return this.userAccountList.filter(ua=>ua.enabled===true).length;
	//   }

	populateSerialNumDetails(machine) {
		this.blockUI.start();

		var paramObj = {
			"serialNumber": machine.serialNumber,
			"contractNumber": machine.contractNum,
			"contractId": machine.contractId
		}
		this.service.getSerialMeterReadsData(paramObj.serialNumber, paramObj.contractId, paramObj.contractNumber).subscribe(
			data => {
				if (data.meterreads) {
					if (data.meterreads[0].actionRequired == 'Y') {
						this.blockUI.stop();
						const modalRef = this.modalService.open(MeterreadModalComponent);
						modalRef.componentInstance.meterReads = data.meterreads;
						modalRef.result.then((isAccepted) => {
							//var objPromise = this.service.getCSPurchaseDetails(paramObj);
							if (isAccepted) {
								this.redirectToSupplies(paramObj,machine);
							}
						})
					} else {
						this.redirectToSupplies(paramObj,machine);
					}
				}
				this.blockUI.stop();
			}
		)
	}
	redirectToSupplies = function(paramObj,machine) {
		this.blockUI.start();
		this.service.getCSPurchaseDetails(paramObj).subscribe(
			data => {
				if (data.status == 'S') {
					if (data.message != '') {
						$('#dmsg').html(data.message);
						(<any>$("#displayDealerMsg")).modal("show");
					} else {
						this.orderService.setMachine(machine);
						this.blockUI.stop();
						this.router.navigate(['supplies'], { queryParams: { category: "CONTRACT_SUPPLIES" } });
						// /window.location.href = "supplies.htm?view=supplies&category=CONTRACT_SUPPLIES";
					}
				} else {
					console.log('There was an error getting the details.');
				}
			},
			function (error) {
				console.log(error);
			});
	}

	//Prepare filter object 
	getMachineFilter = function () {
		var filter = this.machineFilter;
		filter.orderByFieldName = this.sortKey;
		if (this.sortReverse) {
			filter.orderBy = "desc";
		} else {
			filter.orderBy = "asc";
		}
		filter.startRange = ((this.pageIndex - 1) * parseInt(this.selectedPerPageCount)) + 1;
		filter.endRange = (this.pageIndex * parseInt(this.selectedPerPageCount));

		filter.userAccountList = this.userAccountList;
		filter.serialNumber = this.srhSerialNum;
		filter.selectedModel = this.selectedModel;
		filter.location = this.location;
		filter.dealerFlag = 'N';
		return filter;
	}

	clearSearch() {
		var filter = this.machineFilter;
		this.sortKey = 'serial_number';
		this.sortReverse = false;

		this.selectedPerPageCount = 10; //Per Page Records count. Default 10
		this.pageIndex = 1;   // Current page number. First page is 1.-->

		this.srhSerialNum = "";
		//  this.srchModelNum="";
		//  this.srchInstLocn="";
		this.selectedModel = "";
		this.location = "";
		for (let i in this.userAccountList) {
			this.userAccountList[i].enabled = true;
		}
		this.searchMachine();
	}

	//Search machine based on the filter criteria
	searchMachine() {
		this.pleaseWaitCls = true;
		//showPleaseWait();
		this.machineDetailList = [];
		this.service.getAllMachineListAndCount(this.getMachineFilter()).subscribe(
			data => {
				this.machineTotalCount = data.totalRecords;
				this.totalRecords = data.totalRecords;
				this.config = { ...this.config, totalItems: this.totalRecords };
				this.machineDetailList = data.machineDetailList;
				this.listLoaded = true;
				this.pleaseWaitCls = false;
				//hidePleaseWait();
			}
		);
	}

	loadAcctsAndModel() {
		//	showPleaseWait();

		//	var userAccountsAndModelPromise = this.service.getUserAccountsAndModel();
		let data = this.service.getUserAccountsAndModel().subscribe(
			data => {
				this.userAccountList = data.userAccountList;
				//	this.machineModelList = result.data.machineModelList;  // This will be Free Text Field
				//To load data initially.
				this.searchMachine();
			}
		);
	}

	//sorting field and order (asc/desc)
	sort(key) {
		//showPleaseWait();
		this.sortReverse = (this.sortKey == key) ? !this.sortReverse : this.sortReverse;
		this.sortKey = key;
		this.searchMachine();
	}


}

	