import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {

  transform(addr: any): string {
    var address = "";
    	
    if(addr != null){
    		
      if(addr.companyName){
        address += " " + addr.companyName;
      }
          
      if(addr.address1){
        address += ", " + "<br>" + addr.address1;
      }
          
      if(addr.address2){
        address += ", "  + "<br>" + addr.address2;
      }

      if(addr.city){
        address += ", "  + "<br>" + addr.city;
      }

      if(addr.city){
        address += ", " + addr.state;
      }

      if(addr.zipcode){
        address += " - " + addr.zipcode;
      }
    }
    return address;
  }

}
