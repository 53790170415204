import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlobalsearchComponent } from './globalsearch/globalsearch.component';
import { RouteGuardService } from '../service/common-service/route-guard.service';

const routes: Routes = [
   { path: 'globalsearch', component: GlobalsearchComponent , canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GlobalSearchRoutingModule { }
