import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class CartHelperService {
  private shippingAddressSubmit$ = new BehaviorSubject<any>({});
  selectShippingAddressSubmitFlag$ = this.shippingAddressSubmit$.asObservable();

  private billingAddressSubmit$ = new BehaviorSubject<any>({});
  selectBillingAddressubmitFlag$ = this.shippingAddressSubmit$.asObservable();
  constructor() {}

  setShipping(submitFlag: any) {
    this.shippingAddressSubmit$.next(submitFlag);
  }

  
  setBilling(submitFlag: any) {
    this.billingAddressSubmit$.next(submitFlag);
  }



  private shippingAddress = new Subject<any>();
  
  private billingAddress = new Subject<any>();

  sendShipping(message: string) {
      this.shippingAddress.next(message);
  }



  sendBilling(message: string) {
    this.billingAddress.next(message);
}



  getShipping(): Observable<any> {
      return this.shippingAddress.asObservable();
  }

  getBilling(): Observable<any> {
    return this.billingAddress.asObservable();
}

}