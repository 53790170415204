import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {
    externalUrl = 'https://mycanonbusiness.usa.canon.com/cpImages/preOwn/html/FAQs.html';
    faqHtml;
    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal
    ) {

    }
    ngOnInit(): void {
        this.modalService.dismissAll();
        this.http.get(this.externalUrl, { responseType: 'text' }).subscribe(data => {
            this.faqHtml = this.sanitizer.bypassSecurityTrustHtml(data);
        })
    }


}
