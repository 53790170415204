import { ViewportScroller } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { ShopConstants } from 'src/app/common/shopConstants';
import { FormatPhonePipe } from 'src/app/pipe/format-phone.pipe';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { OrderService } from 'src/app/service/common-service/order.service';
import { CartHelperService } from '../cart-helper-service';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.css']
})
export class ShippingAddressComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  
  ADDR_TYPE_CPC : string ="CPC";
 	ADDR_TYPE_EP : string ="EP";
 	ADDR_TYPE_LFS : string ="LFS";
 	ADDR_TYPE_PPS : string ="PPS";
 	ADDR_TYPE_LFP : string ="LFP";
 	ADDR_TYPE_AZCO : string ="AZCO";

  // LFS/PPS
  @ViewChild('shipToModal') shipToTemplate:TemplateRef<any>;
  @ViewChild('newAddressConfirmModal') newAddressConfirmTemplate:TemplateRef<any>;

  //Billable
  @ViewChild('existingSupplyAddrListModal') existingSupplyAddrListTemplate:TemplateRef<any>;
  @ViewChild('newAddressSupplyModal') newAddressSupplyTemplate:TemplateRef<any>;
  
  //Preowned
  @ViewChild('existingAddrListModal') existingProductAddrListTemplate:TemplateRef<any>;
  @ViewChild('newAddressProductModal') newAddressProductTemplate:TemplateRef<any>;

  @ViewChild('maintenanceMarketingModal') maintenanceMarketingTemplate:TemplateRef<any>;

  @ViewChild('printerModal') printerTemplate:TemplateRef<any>;

  
  
  shipToModalRef: BsModalRef;
  newAddressConfirmModalRef: BsModalRef;

  existingSupplyAddrListModalRef : BsModalRef;
  newAddressSupplyModalRef : BsModalRef;

  existingProductAddrListModalRef : BsModalRef;
  newAddressProductModalRef : BsModalRef;
  
  maintenanceMarketingModalRef : BsModalRef;

  printerModalRef : BsModalRef;

  totalCpcItems : number = 0; 
  totalLfsItems : number = 0;
  totalEpItems : number = 0;
  totalAzcoItems : number = 0;
  totalLfpItems : number = 0;
  totalPpsItems : number = 0;
  totalContractSupplyItems : number = 0;
  totalBillableSupplyItems: number = 0;
  totalPrewownedItems: number = 0;
  totalBillableItems: number = 0;
  totalItems : number = 0;
  totalMaint : number = 0;
  totalContractBillableItems : number = 0;

  subTotal : number = 0;
  grandTax : number = 0;
  grandTotal : number = 0;

  isPrinterItem : boolean = false; 

  cart : any = {};

  defaultAddressesAndRoles : any = {};
  isNewAddress: boolean = false;
  lfsPpsAddrType: string = '';
  itemCdShipToPopup: string = '';
  isPreOwned: any;
  showLoader: boolean = false;
  existingAddressList: any;

  //Pagination related
  pageNum : number = 1;
  recPerPage = 10;

  //Search related
  search: string;
  searchFilterPropertyNames : string[] = ['firstName', 'lastName', 'companyName', 'address1', 'address2', 'attentionTo', 'city', 'phone', 'state', 'zipcode'];

  saveAddrAttnIndex = -1;

  errorAddrAttnIndex = -1;

  newAddress: any = {};
  isSameBillableAddr: boolean = false;
  firstBillableAddress: string = '';
  firstContractSupplyAddress: string = '';
  calculateBSFreightAndTax: boolean = true;

  isEmptyContractSupplyMap: boolean;
  isEmptyBillableSupplyMap: boolean;
  isEmptyPreOwnedMachine: boolean;
  isEmptyCpcSupplyMap: boolean;
  isEmptyEpSupplyMap: boolean;
  isEmptyLfsSupplyMap: boolean;
  isEmptyPpsSupplyMap: boolean;
  isEmptyLfsPartsSupplyMap: boolean;
  isEmptyAzcoSupplyMap: boolean;

  

  billSupplyShipCharge : number = 0;

  states : any[] = ShopConstants.states

  shippingMethods : string = "";

  psnExist : boolean = false;
  isSameContractSupplyAddr: boolean = false;

  shipMethod : string = 'STD';
  serialNumOrItemCode: string = '';
  supplyType: string = '';

  selectedPsn: number = 0;
  selectedProduct : any = {};
  selectedMachineSupplyDetails : any = {};
  selectedSupplyKey: string = '';
  
  errorAttnLblHdr : string = "";
	errorGenericHdr : string = "";

  shippingAddrproductId :  number = 0;
	shippingAddrglobalZipcode : string = "";

  attentionDetailsHeader :any = {};

  showSuccessMsg : boolean = false;
  selectedPrinter: any = {};
  selectedKey: string = '';

  shppingSubscription: Subscription;
  private destroy = new Subject();

  constructor(private orderService : OrderService, private accountService : AccountService,
              private commonService : CommonUtilsService, private modalService: BsModalService,
              private router : Router, private scroller: ViewportScroller,
              private formatPhonePipe:FormatPhonePipe,
              private cartHelperService:CartHelperService) { }

  ngOnInit() {
    this.loadInitialData();

      }

  ngDestroy() {
    this.destroy.next();
  }

  //   ngOnDestroy() {
  //     // unsubscribe to ensure no memory leaks
  //     this.shppingSubscription.unsubscribe();
  // }
  loadInitialData(){
   
    this.showLoader = true;

    this.blockUI.start();

    this.newAddress.state = '';

    this.totalItems = this.orderService.totalItems();

    if (this.totalItems == 0) {
      this.router.navigate(['supplieslanding'], {queryParams : {category:'SUPPLIES'}});
    }

    this.subTotal = this.orderService.subTotal();
		this.grandTax = this.orderService.grandTax();
		this.grandTotal = this.orderService.grandTotal();

    this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();

    this.cart = this.orderService.getCart();

    this.totalCpcItems = this.orderService.totalCpcItems();
    this.totalLfsItems = this.orderService.totalLfsItems();
    this.totalEpItems = this.orderService.totalEpItems();
    this.totalAzcoItems = this.orderService.totalAzcoItems();
    this.totalLfpItems = this.orderService.totalLfpItems();
    this.totalPpsItems = this.orderService.totalPpsItems();
    this.totalContractSupplyItems = this.orderService.totalCSItems();
    this.totalBillableSupplyItems = this.orderService.totalBSItems();
	  this.totalPrewownedItems = this.orderService.totalPDItems();
    this.totalBillableItems = this.totalBillableSupplyItems + this.totalPrewownedItems;;

    this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
    this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
    this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
    
    this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
    this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
    this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
    this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
    this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
    this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);

    this.isPrinterItem = this.orderService.isPrinterItems();

    this.onLoadFunction();

    var lfsPpsUser=false;
	
	  var lfsSupplyRole = this.cart["lfsSupplyRole"];
	  var lfsPartsRole = this.cart["lfsPartsRole"];
	  var ppsSupplyRole = this.cart["ppsSupplyRole"];

	  if((lfsSupplyRole && lfsSupplyRole=="Y" ) || (lfsPartsRole && lfsPartsRole=="Y" ) ||
			  (ppsSupplyRole && ppsSupplyRole=="Y" )) {
			lfsPpsUser=true;   
    }

    /* forkjoin({
      accountData : this.accountService.getDefaultAccount(),
      shipMethodData : this.orderService.getShippingMethods(),
      userPSNData : this.orderService.isUserPsnExist()
    }).subscribe(
      
        ({accountData, shipMethodData, userPSNData}) => {
          this.defaultAddressesAndRoles = accountData;
        },
        (error) => {
          console.log(error);
        }
      
    ) */

    this.accountService.getDefaultAccount().subscribe(
      data => {
        console.log(data);
        
        this.defaultAddressesAndRoles = data.accountAddressDetail;

      });

    this.orderService.getShippingMethods().subscribe(
      data => {
        this.shippingMethods = data;
        console.log('Shipping Methods : ',this.shippingMethods);
        var shipMethodDetails = '';
        if(data != null &&  data.length > 0){
          shipMethodDetails = data[0].shippingMethodCode + '*' +  data[0].shippingMethod;
          this.cart = this.orderService.setDefaultShippingMethod(shipMethodDetails);
        }
      }
    );
	

    this.orderService.isUserPsnExist().subscribe(
      response => {
        if (response.data == 'Y') {
          this.psnExist = true;
        }else{
          if(lfsPpsUser){
            this.psnExist = true;
          }
        }
        console.log('User PSN exist : ', this.psnExist);
        if(this.psnExist){
          this.cart = this.onAttnLoad();
        }

        this.cart = this.orderService.updatePrimaryAddress(this.cart.primaryShipAddrSameBillAddrFlag, this.cart.primaryShippingAddress, "N1");
      
        //Calculate charges for Billable supplies only if there are no other LFS/PPS items
        //If there are Billable items along with LFS/PPS items then we will calculate billable charges after we get details from S21 API. 
        if(this.isEmptyCpcSupplyMap && this.isEmptyEpSupplyMap && this.isEmptyLfsSupplyMap 
          && this.isEmptyPpsSupplyMap && this.isEmptyLfsPartsSupplyMap && this.isEmptyAzcoSupplyMap
          && (!this.isEmptyBillableSupplyMap || !this.isEmptyPreOwnedMachine)){
            this.calculateBillSuppShipChargeAndTax();	
        }
		
        this.isSameBillableAddr = this.checkSameAddress('B');
        this.isSameContractSupplyAddr = this.checkSameAddress('C');

        this.blockUI.stop();
        //hidePleaseWait();
      }
    );
	  

    this.isSameBillableAddr = this.checkSameAddress('B');
	  this.isSameContractSupplyAddr = this.checkSameAddress('C');

    if(!this.isEmptyCpcSupplyMap)
      this.getPriceOrGetPriceTaxFreight(this.cart.cpcSupplyMap,this.ADDR_TYPE_CPC,'CPC',this.shipMethod);
    if(!this.isEmptyLfsSupplyMap)
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsSupplyMap,this.ADDR_TYPE_LFS,'LFS',this.shipMethod);
    if(!this.isEmptyEpSupplyMap)
      this.getPriceOrGetPriceTaxFreight(this.cart.epSupplyMap,this.ADDR_TYPE_EP,'EP',this.shipMethod);
    if(!this.isEmptyPpsSupplyMap)
      this.getPriceOrGetPriceTaxFreight(this.cart.ppsSupplyMap,this.ADDR_TYPE_PPS,'PPS',this.shipMethod);
    if(!this.isEmptyLfsPartsSupplyMap)
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsPartsMap,this.ADDR_TYPE_LFP,'LFSPARTS',this.shipMethod);
    if(!this.isEmptyAzcoSupplyMap)
      this.getPriceOrGetPriceTaxFreight(this.cart.azcoSupplyMap,this.ADDR_TYPE_AZCO,'AZCO',this.shipMethod);  

    this.showLoader = false;
  }

  showAddressPopUp(addressType, accountNumber,selectedLfsPpsAddrType,sItemCd, isPreOwned){

    this.search = '';
		
    this.existingAddressList = [];
    
    this.showLoader = true;

    this.shipToModalRef = this.modalService.show(this.shipToTemplate, { class: 'shipToModal' });


    this.isNewAddress = false; 
		this.lfsPpsAddrType = selectedLfsPpsAddrType;
	 	this.itemCdShipToPopup = sItemCd;
	 	
    console.log("lfsPpsAddrType : "+ this.lfsPpsAddrType);
		
		this.isPreOwned = isPreOwned;
		
    this.accountService.getExistingAddressList(addressType, accountNumber).subscribe(
      data => {
        this.existingAddressList = data;
        this.showLoader = false;
      }
    );
		
	}

  handleMissingImage(supply: any){
    supply.image = supply.defaultImg;
  }

  handleMissingPreOwnedImage(supply: any){
    supply.image = 'https://mycanonbusiness.usa.canon.com/cpImages/preOwn/img/no-image.jpg';
  }
  

  selectAddress(address,newAddrFlag){
		
		var keyShippingAddr="shippingExistingAddress";
	
    if(newAddrFlag && newAddrFlag=="Y"){
			keyShippingAddr="shippingNewAddress";
		}
	
    var keyShippingNewAddressFlg="shippingNewAddressFlg";  
	
    address.attentionDetails=address["attentionTo"]; // // 'attentionTo' is 'attentionDetails' in java obj  
	
    var shipMethod="STD";
		if(this.lfsPpsAddrType == this.ADDR_TYPE_LFP) {
      for(var itemKey in this.cart.lfsPartsMap){

        (this.cart.lfsPartsMap[itemKey])[keyShippingAddr]=address;
        (this.cart.lfsPartsMap[itemKey])[keyShippingNewAddressFlg]=newAddrFlag;  

        if(address["attentionTo"] != null || address["attentionTo"] != '')
          (this.cart.lfsPartsMap[itemKey])["attentionDetails"]=address["attentionTo"];
      }
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsPartsMap,this.ADDR_TYPE_LFP,'LFSPARTS',shipMethod);
				 
		}else if (this.lfsPpsAddrType == this.ADDR_TYPE_LFS) {
      for(var itemKey in this.cart.lfsSupplyMap){ 
        (this.cart.lfsSupplyMap[itemKey])[keyShippingAddr]=address;
        (this.cart.lfsSupplyMap[itemKey])[keyShippingNewAddressFlg]=newAddrFlag; 

        if(address["attentionTo"] != null || address["attentionTo"] != '')
          (this.cart.lfsSupplyMap[itemKey])["attentionDetails"]=address["attentionTo"];
      }
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsSupplyMap,this.ADDR_TYPE_LFS,'LFS',shipMethod);
			 
		 
		}else if (this.lfsPpsAddrType == this.ADDR_TYPE_PPS) {  
      for(var itemKey in this.cart.ppsSupplyMap){ 
        (this.cart.ppsSupplyMap[itemKey])[keyShippingAddr]=address;
        (this.cart.ppsSupplyMap[itemKey])[keyShippingNewAddressFlg]=newAddrFlag; 

        if(address["attentionTo"] != null || address["attentionTo"] != '')
          (this.cart.ppsSupplyMap[itemKey])["attentionDetails"]=address["attentionTo"];
		    	  
      }
      this.getPriceOrGetPriceTaxFreight(this.cart.ppsSupplyMap,this.ADDR_TYPE_PPS,'PPS',shipMethod);
		 
		}else if (this.lfsPpsAddrType == this.ADDR_TYPE_EP) {
      for(var itemKey in this.cart.epSupplyMap){ 
        (this.cart.epSupplyMap[itemKey])[keyShippingAddr]=address;
        (this.cart.epSupplyMap[itemKey])[keyShippingNewAddressFlg]=newAddrFlag;

        if(address["attentionTo"] != null || address["attentionTo"] != '')
          (this.cart.epSupplyMap[itemKey])["attentionDetails"]=address["attentionTo"];
      }
      this.getPriceOrGetPriceTaxFreight(this.cart.epSupplyMap,this.ADDR_TYPE_EP,'EP',shipMethod);
		 
		}else if (this.lfsPpsAddrType == this.ADDR_TYPE_AZCO) {
      for(var itemKey in this.cart.azcoSupplyMap){ 
        (this.cart.azcoSupplyMap[itemKey])[keyShippingAddr]=address;
        (this.cart.azcoSupplyMap[itemKey])[keyShippingNewAddressFlg]=newAddrFlag;

        if(address["attentionTo"] != null || address["attentionTo"] != '')
          (this.cart.azcoSupplyMap[itemKey])["attentionDetails"]=address["attentionTo"];
   	  
      }
      this.getPriceOrGetPriceTaxFreight(this.cart.azcoSupplyMap,this.ADDR_TYPE_AZCO,'AZCO',shipMethod);
		 
		}else if (this.lfsPpsAddrType == this.ADDR_TYPE_CPC) {
      for(var itemKey in this.cart.cpcSupplyMap){ 
        (this.cart.cpcSupplyMap[itemKey])[keyShippingAddr]=address;
        (this.cart.cpcSupplyMap[itemKey])[keyShippingNewAddressFlg]=newAddrFlag; 

        if(address["attentionTo"] != null || address["attentionTo"] != '')
          (this.cart.cpcSupplyMap[itemKey])["attentionDetails"]=address["attentionTo"];
		    	  
      }
      this.getPriceOrGetPriceTaxFreight(this.cart.cpcSupplyMap,this.ADDR_TYPE_CPC,'CPC',shipMethod);
		}
		else if (this.lfsPpsAddrType == "BS") {
			if(!this.isPreOwned){
				if(!this.isEmptyBillableSupplyMap){
					for(itemKey in this.cart.billableSupplyMap){
							(this.cart.billableSupplyMap[itemKey])[keyShippingAddr]=address;
							(this.cart.billableSupplyMap[itemKey])[keyShippingNewAddressFlg]=newAddrFlag; 
							if(address["attentionTo"]){
								(this.cart.billableSupplyMap[itemKey])["attentionDetails"]=address["attentionTo"];
							}else {
								(this.cart.billableSupplyMap[itemKey])["attentionDetails"]='';
							} 
					}
				}
			}
			if(!this.isEmptyPreOwnedMachine && this.isPreOwned){
				for(itemKey in this.cart.productMap){
					var tmpItmeCd=(this.cart.productMap[itemKey])["ItemCode"];
					if((this.cart.productMap[itemKey])[keyShippingAddr] == null || (this.cart.productMap[itemKey])[keyShippingAddr] == '' )
	      		  	{
		      		  	if( tmpItmeCd == this.itemCdShipToPopup){ 
		      		  		(this.cart.productMap[itemKey])[keyShippingAddr]=this.cart.accountInfo.defaultShipTo;
		      		  		(this.cart.productMap[itemKey])[keyShippingNewAddressFlg]='N';  
		      		  		if(address["attentionTo"] != null || address["attentionTo"] != '')
		      		  			(this.cart.productMap[itemKey])["attentionDetails"]=address["attentionTo"];
		      		  	}
	      		  	}else{
	      		  		if( tmpItmeCd==this.itemCdShipToPopup){ 
	      		  			(this.cart.productMap[itemKey])[keyShippingAddr]=address;
	      		  			(this.cart.productMap[itemKey])[keyShippingNewAddressFlg]='N';  
	      		  			if(address["attentionTo"] != null || address["attentionTo"] != '')
	      		  			(this.cart.productMap[itemKey])["attentionDetails"]=address["attentionTo"];
	      		  		}
	      		  	}
				}
			}
			this.isSameBillableAddr = this.checkSameAddress('B');
		}
		//console.log("lfsPpsAddrType : "+$scope.lfsPpsAddrType);
		this.orderService.setCart(this.cart);

    this.shipToModalRef.hide();

		this.onLoadFunction();
		
	}

  saveAttentionAndAddressLine(indexParam, address){
		this.showLoader = true;

		let selectedAddr = address;
    selectedAddr.dropShipFlag = 'Y'

    let param = {
      acctAddress: selectedAddr,
      operationFlag : 'U',
      soldToAcctNumber : this.defaultAddressesAndRoles.defaultSoldTo.accountNumber
    }

		this.accountService.saveAttentionAndAddressLine(JSON.stringify(param)).subscribe(
      data => {
        console.log(data);
        this.saveAddrAttnIndex = -1;
        this.errorAddrAttnIndex = -1;
        //this.deleteAddrAttnIndex = -1;


        if(data.acctAddress.errorExist){
          console.log('error');
          this.errorAddrAttnIndex = indexParam;
        }

        if(data.status === 'S'){
          this.accountService.getExistingAddressList('SH', this.defaultAddressesAndRoles.defaultSoldTo.accountNumber).subscribe(
            data => {
              this.existingAddressList = data;
              this.existingAddressList[indexParam*this.pageNum].dropShipFlag="Y";
              this.saveAddrAttnIndex = indexParam;
              this.showLoader = false;
            }
          )
        }
      }   
    )
		
	}

  shipToPageChange(pgNo){
    this.pageNum = pgNo;
    this.saveAddrAttnIndex = -1;
    this.errorAddrAttnIndex = -1;
  }
  
  openNewAddress(){
		this.isNewAddress = true;
    this.newAddress = {};
    this.newAddress.state = '';
	}

  validateNewAddress(){
		var isNewAddressExist = false;
		for(var i in this.existingAddressList){
			 if(this.existingAddressList[i].address1 == this.newAddress.address1 && this.existingAddressList[i].city == this.newAddress.city 
					 && this.existingAddressList[i].state == this.newAddress.state 
					 && this.existingAddressList[i].zipcode == this.newAddress.zipcode){				 
				 isNewAddressExist = true;
				 break;
			 }
		 }
		
		if(isNewAddressExist){
      this.newAddressConfirmModalRef = this.modalService.show(this.newAddressConfirmTemplate);
		}else{
			this.createAndSelect();
		}
	}

  clearAddress(){
    this.newAddress = {};
    this.newAddress.state = '';
		
	}

  openExistingAddress(){
		this.isNewAddress = false;
	}

  closePopup(){
    this.shipToModalRef.hide();
		this.isNewAddress = false;
	}

  closeConfirm(){
    this.newAddressConfirmModalRef.hide();
    this.shipToModalRef.hide();
    this.isNewAddress = false;
 }

 createAndSelect(){
	//	console.log("New Address : " + $scope.newAddress);

    this.showLoader = true;
		
    if(this.newAddressConfirmModalRef){
    this.newAddressConfirmModalRef.hide();
    }

    let shipToAddr = this.newAddress;
    shipToAddr.dropShipFlag = 'Y';

    var param = {
      "acctAddress" : shipToAddr,
      "operationFlag" : "I",
      "soldToAcctNumber" : this.defaultAddressesAndRoles.defaultSoldTo.accountNumber
    }
		
    this.accountService.createShippingAddress(param).subscribe(
      
      data => {
        let address = data.acctAddress;

        if(!address.errorExist && data.status == 'S'){
          this.isNewAddress = false;
          address.locationId = this.defaultAddressesAndRoles.defaultShipTo.locationId;
          address.accountNumber = this.defaultAddressesAndRoles.defaultShipTo.accountNumber; 
          address.dropShipFlag='Y';

          this.selectAddress(address, 'Y');

          this.shipToModalRef.hide();
         
        }else{
          this.newAddress = address;
        }

        this.showLoader = false;

      }
    ) 
		
	}

  onLoadFunction() {
		
		this.cart = this.orderService.getCart();
		
    for(var tempId in this.cart.billableSupplyMap) {
			if(this.cart.billableSupplyMap[tempId].attentionDetails) {
				this.attentionDetailsHeader.attn = this.cart.billableSupplyMap[tempId].attentionDetails; 
				this.attentionDetailsHeader.shippingInstruction = this.cart.billableSupplyMap[tempId].shippingInstruction; 
				break;
			}else {
				this.attentionDetailsHeader.attn = null; 
				break;
			}
		}
	}

  checkSameAddress(itemType){

		var count = 0;
		var isSameAddress = true;

		if(itemType == 'B' && this.cart.billableSupplyMap){ //Billable Items
			for(var itemKey in this.cart.billableSupplyMap){
        var newAddrFlag = (this.cart.billableSupplyMap[itemKey])["shippingNewAddressFlg"];
        var keyShippingAddr="shippingExistingAddress";

        if(newAddrFlag && newAddrFlag=="Y"){
          keyShippingAddr="shippingNewAddress";
        }

        if(count == 0){
          this.firstBillableAddress = (this.cart.billableSupplyMap[itemKey])[keyShippingAddr];
        }else {
          var isSameAddressTemp = this.orderService.compareAddress(this.firstBillableAddress, (this.cart.billableSupplyMap[itemKey])[keyShippingAddr]);
          if(!isSameAddressTemp){
            isSameAddress = false;
            break;
          }
        }
        count++;
	    }

			for(var itemKey in this.cart.productMap){
				var newAddrFlag = (this.cart.productMap[itemKey])["shippingNewAddressFlg"];
				var keyShippingAddr="shippingExistingAddress";
		  		if(newAddrFlag && newAddrFlag=="Y"){
		  			keyShippingAddr="shippingNewAddress";
		  		}
		    	if(count == 0){
		    		this.firstBillableAddress = (this.cart.productMap[itemKey])[keyShippingAddr];
		    	}else {
		    		var isSameAddressTemp = this.orderService.compareAddress(this.firstBillableAddress, (this.cart.productMap[itemKey])[keyShippingAddr]);
		    		if(!isSameAddressTemp){
		    			isSameAddress = false;
		    			break;
		    		}
		    	}
			}
		}else if(itemType == 'C' && this.cart.contractSupplyMap){ //Contract supplies
			
			for(itemKey in this.cart.contractSupplyMap){
        var newAddrFlag = (this.cart.contractSupplyMap[itemKey])["shippingNewAddressFlg"];
        var keyShippingAddr="shippingExistingAddress";

        if(newAddrFlag && newAddrFlag=="Y"){
          keyShippingAddr="shippingNewAddress";
        }
        
        if(count == 0){
          this.firstContractSupplyAddress = (this.cart.contractSupplyMap[itemKey])[keyShippingAddr];
        }else {
          var isSameAddressTemp = this.orderService.compareAddress(this.firstContractSupplyAddress, 
              (this.cart.contractSupplyMap[itemKey])[keyShippingAddr]);
          if(!isSameAddressTemp){
            isSameAddress = false;
            break;
          }
        }
        count++;
      }
			
		}
		return isSameAddress;	
	}

  getPriceOrGetPriceTaxFreight(mapObject, addrType,orderType,shipMethod){
		//TODO
		//$scope.partPriceErrMsg = '';
		//$scope.processingRequest = 'processingRequest';
 			
		 	
    var param = {
      "accountDetails": JSON.stringify(this.cart.accountInfo),
      "mapObject": JSON.stringify(mapObject),
        "orderType" :orderType,
        "shipType" : shipMethod	
    }

    this.orderService.getPriceOrGetPriceTaxFreight(param).subscribe(
      (data:any) => {
        console.log(data);

        /* if(data.success == 'N'){
          $scope.partPriceErrMsg = 'Sorry could not complete that action. Please call 1-866-472-7876 for assistance.';
          $("#pricingErrorMsgModal").modal('show');
        }else{ */
          console.log("TaxFreight API Response");
          console.log('price:' + data);
          var ordTotDealTaxAmt= data.ordTotDealTaxAmt;
          var ordTotDealChrgAmt= data.ordTotDealChrgAmt;
          console.log("Tax : "+ordTotDealTaxAmt +" , Shipping : "+ordTotDealChrgAmt);
          this.cart = this.orderService.getCart();
          var keyTax="machineTax";
          var keyShipping="shippingCharges";

          if(addrType == this.ADDR_TYPE_LFP) {
            for(var itemKey in this.cart.lfsPartsMap){
              (this.cart.lfsPartsMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.lfsPartsMap[itemKey])[keyShipping] = ordTotDealChrgAmt;  
            }
            this.orderService.setCart(this.cart);  
            this.getGrandTaxAndShippingFromLocalStorage();
          }else if (addrType == this.ADDR_TYPE_LFS) {
            for(var itemKey in this.cart.lfsSupplyMap){ 
              (this.cart.lfsSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.lfsSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;
            }
            this.orderService.setCart(this.cart);  
            this.getGrandTaxAndShippingFromLocalStorage();  
          }else if (addrType == this.ADDR_TYPE_PPS) {  
            for(var itemKey in this.cart.ppsSupplyMap){ 
              (this.cart.ppsSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.ppsSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;  
            }
            this.orderService.setCart(this.cart);  
            this.getGrandTaxAndShippingFromLocalStorage();
          }else if (addrType == this.ADDR_TYPE_EP) {
            for(var itemKey in this.cart.epSupplyMap){ 
                (this.cart.epSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
                (this.cart.epSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;  
            }
            this.orderService.setCart(this.cart);  
            this.getGrandTaxAndShippingFromLocalStorage();
          }else if (addrType == this.ADDR_TYPE_CPC) {
            for(var itemKey in this.cart.cpcSupplyMap){ 
              (this.cart.cpcSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.cpcSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;  
            } 
            this.orderService.setCart(this.cart);  
            this.getGrandTaxAndShippingFromLocalStorage();
          }else if (addrType == this.ADDR_TYPE_AZCO) {
            for(var itemKey in this.cart.azcoSupplyMap){ 
                (this.cart.azcoSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
                (this.cart.azcoSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;  
            }
            this.orderService.setCart(this.cart);  
            this.getGrandTaxAndShippingFromLocalStorage();
          }
            
          //If there are Billable items along with LFS/PPS items then we will calculate billable charges after we get details from S21 API. 
          if(this.calculateBSFreightAndTax && (!this.isEmptyBillableSupplyMap || !this.isEmptyPreOwnedMachine)){
            this.calculateBillSuppShipChargeAndTax();	
            this.calculateBSFreightAndTax = false;
          }

          this.orderService.notifyOrderSummary();
                  
        //}
        //$scope.processingRequest ='';
        this.showLoader = false;
      }
    );	
    
			
  }

  getGrandTaxAndShippingFromLocalStorage(){  

    this.grandTax = this.orderService.grandTax();
    this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
    console.log("tax set in local storage : "+this.grandTax);

  }	

  calculateBillSuppShipChargeAndTax() {

		this.orderService.calculateBillSuppShipChargeAndTax(this.cart).subscribe(
      data => {
        this.cart = data;
        this.orderService.setCart(this.cart);
        this.postProcessOfAction();
        this.cart = this.orderService.updateAllSelectedMaintenance();

        if (this.totalItems == 0) {
          this.router.navigate(['supplieslanding'], {queryParams : {category:'SUPPLIES'}});
        }
      }

    )
		
	}

  postProcessOfAction(){
		this.cart = this.orderService.getCart();
		this.subTotal = this.orderService.subTotal();
		this.totalMaint = this.orderService.totalMaintenance();
		this.grandTax = this.orderService.grandTax();
		this.grandTotal = this.orderService.grandTotal();
		this.totalContractSupplyItems=this.orderService.totalCSItems();
		this.totalBillableSupplyItems=this.orderService.totalBSItems();
		
		this.totalItems = this.orderService.totalItems();
		this.totalPrewownedItems=this.orderService.totalPDItems();
		this.totalBillableItems=this.orderService.totalBSItems()+this.totalPrewownedItems;
		this.totalContractBillableItems=this.totalBillableItems+this.totalContractSupplyItems;
		this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
		this.cart = this.orderService.updateAllSelectedMaintenance();
	}

  onAttnLoad() {
		var cart = this.orderService.getCart();
		for ( var itemCode in cart.contractSupplyMap) {
			this.cart = this.orderService.onAttnChange(itemCode, cart.contractSupplyMap[itemCode].attentionDetails);
			break;
		}
		return cart;
	}

  showExistingSupplyAddrList(serialNumOrItemCode, supplyType){

		this.orderService.setCart(this.cart);

    this.existingAddressList = [];
    
    this.existingSupplyAddrListModalRef = this.modalService.show(this.existingSupplyAddrListTemplate);
		
		this.showLoader = true;
		this.orderService.getExistingAddressList().subscribe(
      data => {
        console.log('Inside showExistingSupplyAddrList');
        this.existingAddressList = data;
        this.serialNumOrItemCode = serialNumOrItemCode;
        this.supplyType = supplyType;
        this.cart = this.orderService.updateAllSelectedMaintenance();
        this.showLoader = false;
      }
    );
	}

  selectSupplyShippingAddrHdr(serialNumOrItemCode, supplyType, address){
		this.cart = this.orderService.getCart();

		if(supplyType != null && supplyType != 'undefined'){
			if(supplyType == 'CONTRACT' && this.cart.contractSupplyMap != null){

        console.log('Inside selectSupplyShippingAddrHdr ' + serialNumOrItemCode);
        
				var contractSupply = this.cart.contractSupplyMap[serialNumOrItemCode];
				
				if(contractSupply != null){
					contractSupply.shippingExistingAddress = address;
					contractSupply.shippingNewAddress = null;
					contractSupply.shippingNewAddressFlg = 'N';
					contractSupply.diffShippingAddressFlag = 'N';
				}

        this.orderService.setCart(this.cart);

        this.cart = this.orderService.updateAllSelectedMaintenance();
				this.isSameContractSupplyAddr = this.checkSameAddress('C');

				this.existingSupplyAddrListModalRef.hide();
			} else if(supplyType == 'BILLABLE' && this.cart.billableSupplyMap != null){
					for(var itemKey in this.cart.billableSupplyMap){
						this.cart.billableSupplyMap[itemKey].shippingExistingAddress = address;
						this.cart.billableSupplyMap[itemKey].shippingNewAddress = null;
						this.cart.billableSupplyMap[itemKey].shippingNewAddressFlg = 'N';
						this.cart.billableSupplyMap[itemKey].diffShippingAddressFlag = 'N';
					}

				  this.orderService.setCart(this.cart);

          this.cart = this.orderService.updateAllSelectedMaintenance();
				  this.isSameBillableAddr =this.checkSameAddress('B');
				  this.existingSupplyAddrListModalRef.hide();
			}
		} 
		
	}

  showSupplyAddressPopUpHdr(psn,supplyType, isNewSupplyAddress){
		this.selectedPsn = psn;
		this.selectedProduct  = null;
		this.supplyType = supplyType;
    this.search = '';
		
		if(isNewSupplyAddress){
      if(this.existingSupplyAddrListModalRef){
        this.existingSupplyAddrListModalRef.hide();
      }
      

			var machineDetailsHdr;
			var supplyKeyHdr;
			
			for(var itemKey in this.cart.billableSupplyMap){
				machineDetailsHdr = this.cart.billableSupplyMap[itemKey];
				supplyKeyHdr = itemKey;
			}
			
			this.showNewAddrSupplyPopUpHdr(supplyKeyHdr,machineDetailsHdr, psn);
		}else{
      if(this.newAddressSupplyModalRef){
        this.newAddressSupplyModalRef.hide();
      }
      
			this.showExistingSupplyAddrListHdr(supplyType);
		}
	}

  showExistingSupplyAddrListHdr(supplyType){
		this.orderService.setCart(this.cart);

    this.existingAddressList = [];
    this.existingSupplyAddrListModalRef = this.modalService.show(this.existingSupplyAddrListTemplate);
		this.showLoader = true;
		
    this.orderService.getExistingAddressList().subscribe(
      data => {
        this.existingAddressList = data;
        //$scope.serialNumOrItemCode = serialNumOrItemCode;
        this.supplyType = supplyType;
        this.cart = this.orderService.updateAllSelectedMaintenance();
        this.showLoader = false;
      }
    );
		
	}

  updateSupplyAddress(id, machineSupplyDetails, supplyItem, psn) {
		console.log(id + ' ' + machineSupplyDetails + ' '+ supplyItem + ' ' + psn);
		this.cart = this.orderService.updateSupplyAddress(id, machineSupplyDetails, supplyItem, psn);
		this.cart = this.orderService.updateAllSelectedMaintenance();
	}

  clearSupplyAddress() {
		this.selectedMachineSupplyDetails.shippingNewAddress = {};
	}

  showNewAddrSupplyPopUpHdr(supplyKey,supplyDetail, psn){
		this.selectedSupplyKey = supplyKey;
		this.selectedMachineSupplyDetails = supplyDetail;
		this.selectedPsn = psn;
		this.selectedProduct  = null;
		for(var itemKey in this.cart.billableSupplyMap){
			this.cart.billableSupplyMap[itemKey].shippingNewAddressFlg = 'Y';
      this.cart.billableSupplyMap[itemKey].shippingNewAddress = {};
		}
		this.orderService.setCart(this.cart);
		this.selectedMachineSupplyDetails = this.cart.billableSupplyMap[supplyKey];
    this.newAddressSupplyModalRef = this.modalService.show(this.newAddressSupplyTemplate);
		
		this.cart = this.orderService.updateAllSelectedMaintenance();
	}

  validateAndUpdateSupplyAddress(id, machineSupplyDetails, supplyItem, psn) {
		console.log(id + ' ' + machineSupplyDetails + ' ' + supplyItem + ' '+ psn );
		
    this.showLoader = true;
		
		this.cart = this.orderService.updateSupplyAddress(id, machineSupplyDetails, supplyItem, psn); 

		this.orderService.validateSupplyAddress(id, this.cart.billableSupplyMap[id]).subscribe(
      data => {
        machineSupplyDetails = data;
        console.log('Reponse Supply data : ', machineSupplyDetails);
        this.cart = this.orderService.updateSupplyAddress(id, machineSupplyDetails, supplyItem, psn);
        this.selectedMachineSupplyDetails  = machineSupplyDetails;
			  this.calculateBillSuppShipCharge();
			  this.onLoadFunction();

        if(this.isNotNull(machineSupplyDetails) 
					&& (this.isNull(machineSupplyDetails.errorExist) || (this.isNotNull(machineSupplyDetails.errorExist) && !machineSupplyDetails.errorExist))
					&& (this.isNotNull(machineSupplyDetails.shippingNewAddress) && (this.isNull(machineSupplyDetails.shippingNewAddress.errorExist) || (this.isNotNull(machineSupplyDetails.shippingNewAddress.errorExist)
						&& !machineSupplyDetails.shippingNewAddress.errorExist )))){
				  this.newAddressSupplyModalRef.hide();
			  }
			  this.isSameBillableAddr = this.checkSameAddress('B');
      }
    ) 
      
		
	}

  calculateBillSuppShipCharge() {
		this.orderService.calculateBillSuppShipCharge(this.cart).subscribe(
      data => {
        this.cart = data;
        this.orderService.setCart(this.cart);
        this.postProcessOfAction();
        this.cart = this.orderService.updateAllSelectedMaintenance();
        //hidePleaseWait();
        if (this.totalItems == 0) {
          this.router.navigate(['supplieslanding'], {queryParams : {category:'SUPPLIES'}});
        }
      }
    ); 
		
	}

  isNotNull(obj){
		var isNotNull = false;
		if(obj != null && obj != 'undefined')
		{
			isNotNull = true;
		}
		return isNotNull;
	}

  isNull(obj){
		var isNull = false;
		if(obj == null || obj == 'undefined')
		{
			isNull = true;
		}
		return isNull;
	}

  closeModal(modalType : string){
    if(modalType === 'existingSupplyAddr'){
      this.existingSupplyAddrListModalRef.hide();
      this.isNewAddress = false;
    }else if(modalType === 'existingAddrList'){
      this.existingProductAddrListModalRef.hide();
    }else if(modalType === 'newAddressProduct'){
      this.newAddressProductModalRef.hide();
    }else if(modalType === 'maintenanceMarketing'){
      this.maintenanceMarketingModalRef.hide();
    }else if(modalType === 'newAddressSupply'){
      this.newAddressSupplyModalRef.hide();
    }else if(modalType === 'printer'){

    }
  }

  onBlurAttnDetails(attnDetails, shippingInstruction) {
		
		if(this.cart.billableSupplyMap){
			for ( var tempId in this.cart.billableSupplyMap) {
				//$scope.billableAttentionDetails = "";
				
				this.cart.billableSupplyMap[tempId].attentionDetails = attnDetails;
				this.cart.billableSupplyMap[tempId].shippingInstruction = shippingInstruction;
			
			}
			this.orderService.setCart(this.cart);
		}
		
	}

  showProductAddressPopUp(id, product, psn, globalZipcode, isNewAddress){
		this.selectedProduct  = product;
		this.selectedPsn = psn;
		this.selectedSupplyKey = "";
		this.selectedMachineSupplyDetails = null;
		this.shippingAddrproductId = id;
		this.shippingAddrglobalZipcode = globalZipcode;
		
		if(isNewAddress){
      this.existingProductAddrListModalRef.hide();

			this.showNewAddrProductPopUp(id, product, psn);
		}else{
      this.newAddressProductModalRef.hide();
			this.showExistingAddrList(id, globalZipcode);
		}
	}

  showNewAddrProductPopUp(id, product, psn){
		this.selectedProduct  = product;
		this.selectedPsn = psn;
	  this.selectedSupplyKey = "";
		this.selectedMachineSupplyDetails = null;
				
		this.cart = this.orderService.showProductNewAddressFlg(id, product);
		this.selectedProduct = this.cart.productMap[id];

    this.newAddressProductModalRef = this.modalService.show(this.newAddressProductTemplate);

		this.cart = this.orderService.updateAllSelectedMaintenance();
	}

  showExistingAddrList(id, globalZipcode){
		this.orderService.setCart(this.cart);
    this.existingProductAddrListModalRef = this.modalService.show(this.existingProductAddrListTemplate);
		
		this.showLoader = true;
		this.orderService.getExistingAddressList().subscribe(
      data => {
        this.existingAddressList = data;
        this.shippingAddrproductId = id;
        this.shippingAddrglobalZipcode = globalZipcode;
        this.cart = this.orderService.updateAllSelectedMaintenance();
        this.showLoader = false;
      }
    );
	}

  removeFromCart(productId) {
		this.showLoader = true;
		this.orderService.removeProduct(productId);
		this.postProcessOfAction();
		this.showLoader = false;
		this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
		this.isSameBillableAddr = this.checkSameAddress('B');
		if(this.orderService.totalItems() == 0){
			this.router.navigate(['supplieslanding'], {queryParams : {category:'SUPPLIES'}});
		}
	}

  openMainModel(m, product, maintanenceIndex, productIndex){
    console.log(this.cart);
    this.cart.productMap[product.Id].selectedMaintenanceIndex = maintanenceIndex;
    this.cart.productMap[product.Id].totalCopies = this.orderService.totalCopies(product, maintanenceIndex);
    localStorage.setItem('cart', JSON.stringify(this.cart));
    if (m.termTo == -1){
      //TODO
       //sc.p = product;
       this.selectedProduct = product;
       this.maintenanceMarketingModalRef = this.modalService.show(this.maintenanceMarketingTemplate);
    }
  }

	selectShippingAddr(id, globalZipcode, address){
		this.cart = this.orderService.getCart();
		this.cart.primaryShippingAddressFlag = 'N';
		var product = {};
		for ( var tempId in this.cart.productMap) {
			if (tempId == id) {
				console.log(tempId + " " + id);
				this.cart.productMap[tempId].shippingExistingAddress = address;
				this.cart.productMap[tempId].shippingNewAddress = null;
				this.cart.productMap[tempId].shippingNewAddressFlg = 'N';
				this.cart.productMap[tempId].diffShippingAddressFlag = 'N';
				product = this.cart.productMap[tempId];
			}
			
		}
		this.orderService.setCart(this.cart);
		this.validateExistingAddress(id, product, globalZipcode);
		this.isSameBillableAddr = this.checkSameAddress('B');
		this.cart = this.orderService.updateAllSelectedMaintenance();

    this.existingProductAddrListModalRef.hide();

	}

  updateAddress(id, product, psn) {
		console.log(id + ' ' + product + ' ' + psn);
		this.cart = this.orderService.updateAddress(id, product, psn);
		this.cart = this.orderService.updateAllSelectedMaintenance();
	}

  validateAndUpdateAddress(id, product, psn, globalZipCode) {
		console.log(id + ' ' + product + ' ' + psn + ' ' + globalZipCode);
		this.showLoader = true;

		this.cart = this.orderService.updateAddress(id, product, psn); 

		this.orderService.validateAddress(id, product, globalZipCode).subscribe(
      data => {
        product = data;
        console.log('Reponse Preowned data : ', product);
        this.cart = this.orderService.updateAddress(id, product, psn); 
        
        this.selectedProduct = this.cart.productMap[id];
        if(this.isNotNull(product) 
            && (this.isNull(product.errorExist) || (this.isNotNull(product.errorExist) && !product.errorExist))
            && (this.isNotNull(product.shippingNewAddress) && (this.isNull(product.shippingNewAddress.errorExist) || (this.isNotNull(product.shippingNewAddress.errorExist)
              && !product.shippingNewAddress.errorExist )))){
          this.newAddressProductModalRef.hide();      
        }
        this.isSameBillableAddr = this.checkSameAddress('B');
        this.cart = this.orderService.updateAllSelectedMaintenance();
        this.showLoader = false;
      }
    ) 
		
	}

  clearProductAddress() {
		this.selectedProduct.shippingNewAddress = {};
	}

	addMaint(modalProduct, modalProductMaintenance, maintanenceIndex) {
		this.cart.productMap[modalProduct.Id].maintenance = modalProductMaintenance;
		this.cart.productMap[modalProduct.Id].selectedMaintenanceIndex = maintanenceIndex;
		this.cart.productMap[modalProduct.Id].totalCopies = this.orderService.totalCopies(modalProduct, maintanenceIndex);
		localStorage.setItem('cart', JSON.stringify(this.cart));
    this.maintenanceMarketingModalRef.hide();
	}

  updateSupplyPhone(id, machineSupplyDetails, supplyItem, psn) {
    //TODO
		/* $("input[name='supplyPhone']").keyup(function() {
			$(this).val($(this).val().replace(/[^\d]+/g, '').replace(/^(\d{3})(\d{3})(\d{4})+$/, "($1)$2-$3"));
		}); */
    
    var formattedPhone = this.formatPhonePipe.transform(machineSupplyDetails.shippingNewAddress.phone);
    machineSupplyDetails.shippingNewAddress.phone = formattedPhone;

    
		console.log(id + ' ' + machineSupplyDetails + ' ' + psn);
		this.cart = this.orderService.updateSupplyAddress(id, machineSupplyDetails, supplyItem, psn);
		this.cart = this.orderService.updateAllSelectedMaintenance(); 
	}

  updatePrimaryAddress(primaryShipAddrSameBillAddrFlag, shippingNewAddress, isProceed) {
		//this.showLoader = true;
		this.blockUI.start();
		
    console.log(shippingNewAddress);
		
    this.cart.primaryShippingAddress = shippingNewAddress;
		
		this.orderService.setCart(this.cart);
		
		this.orderService.validatePrimaryAddress(shippingNewAddress).subscribe(
      data => {
        this.cart = data;
        if(this.psnExist || (!this.psnExist && this.cart.primaryShippingAddress && !this.cart.primaryShippingAddress.errorExist)){
          this.cart = this.orderService.updatePrimaryAddress(primaryShipAddrSameBillAddrFlag, this.cart.primaryShippingAddress, "N1");
          this.callCheckOut(isProceed);
          this.onLoadFunction();
        }else{
          //this.showLoader = false;
          this.blockUI.stop();
        }
      }
    );
	}

  callCheckOut(isProceed) {
		
    this.orderService.onShippingCheckOut('cartBilling',this.shippingAddrglobalZipcode).subscribe(
      data => {
        this.cart = data;
        console.log('callCheckout Reponse Data : ', this.cart);
        this.orderService.setCart(this.cart);
        this.postProcessOfAction();
        this.focusOnElement();
        this.isSameBillableAddr = this.checkSameAddress('B');
        this.isSameContractSupplyAddr = this.checkSameAddress('C');
        this.validateHdrErr();
        //this.showLoader = false;
        this.blockUI.stop();
        if(isProceed == 'Y' && this.cart.errorExist ==  false){
           this.router.navigate(['billingaddress']);
        }
      }
    );
		
	}

  validateHdrErr(){
		this.cart = this.orderService.getCart();
		for ( var tempId in this.cart.billableSupplyMap) {
	
			if(this.cart.billableSupplyMap[tempId].error) {
				
				this.errorAttnLblHdr = this.cart.billableSupplyMap[tempId].error.genericAttnLbl; 
				this.errorGenericHdr = this.cart.billableSupplyMap[tempId].error.generic; 
				
			} 
			
			if(this.errorAttnLblHdr){
				this.attentionDetailsHeader.attn = "";
			}
	
		}
		
	}

  focusOnElement(){

    var cart = this.orderService.getCart();
    var errorFound = false;
    var elementName = "";
    
    //TODO
    //$location.hash("anchorBegining");

    if(cart.error != null && cart.error.generic != null){
      errorFound = true;
      elementName = "anchorCartErrGen";
    } 

    var supply =null;
    var index = 0;
    
    if(!errorFound && cart.contractSupplyMap != null ){
      for ( var tempId in cart.contractSupplyMap) {
        supply = cart.contractSupplyMap[tempId]
        if(supply != null) {
          if(supply.shippingNewAddress != null && supply.shippingNewAddress.error != null){
            if(supply.shippingNewAddress.error.generic){
              errorFound = true;
                elementName = "anchorConSupplyShipNewAddrErrGen-"+index;
                break;
            }
          }else if(supply.error != null){
            if(supply.error.genericShipMeth != null){
              errorFound = true;
                elementName = "anchorConSupplyShipMethErrGen-"+index;
                break;
            } else if(supply.error.genericAttnLbl != null ){
              errorFound = true;
                elementName = "anchorConSupplyAttnLblErrGen-"+index;
                break;
            } else if(supply.error.generic != null ){
              errorFound = true;
                elementName = "anchorConSupplyErrGen-"+index;
                break;
            }
          }
        }
        index++;
      }
    }
    
    if(!errorFound && cart.primaryShippingAddress != null && cart.primaryShippingAddress.error != null) {
      if(cart.primaryShippingAddress.error.firstName != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrFN";
      }else if(cart.primaryShippingAddress.error.lastName != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrLN";
      }else if(cart.primaryShippingAddress.error.companyName != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrCN";
      }else if(cart.primaryShippingAddress.error.address1 != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrAddr1";
      }else if(cart.primaryShippingAddress.error.city != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrCity";
      }else if(cart.primaryShippingAddress.error.state != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrState";
      }else if(cart.primaryShippingAddress.error.zipcode != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrZip";
      }else if(cart.primaryShippingAddress.error.email != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrEmail";
      }else if(cart.primaryShippingAddress.error.phone != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrPhone";
      }else if(cart.primaryShippingAddress.error.attentionDetails != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrAttentionDetails";
      }else if(cart.primaryShippingAddress.error.generic != null){
        errorFound = true;
        elementName = "anchorCartPriShipAddrErrGen";
      }
    }
    if(!errorFound && cart.error != null && cart.error.genericPriShip != null){
      errorFound = true;
      elementName = "anchorCartErrGenPriShip";
    }
    
    index = 0;
    if(!errorFound && cart.billableSupplyMap != null ){
      for ( var tempId in cart.billableSupplyMap) {
        supply = cart.billableSupplyMap[tempId]
        if(supply != null) {
          if(supply.shippingNewAddress != null && supply.shippingNewAddress.error != null){
            if(supply.shippingNewAddress.error.generic){
              errorFound = true;
                elementName = "anchorBillSupplyShipNewAddrErrGen-"+index;
                break;
            }
          }
          if(supply.error != null){
            if(supply.error.genericShipMeth != null){
              errorFound = true;
                elementName = "anchorBillSupplyShipMethErrGen-"+index;
                break;
            } else if(supply.error.genericAttnLbl != null ){
              errorFound = true;
                elementName = "anchorBillSupplyAttnLblErrGen-"+index;
                break;
            } else if(supply.error.generic != null ){
              errorFound = true;
                elementName = "anchorBillSupplyErrGen-"+index;
                break;
            }
          }
        }
        index++;
      }
    }
    
    var product = null;
    index = 0;
    if(!errorFound && cart.productMap != null ){
      for ( var tempId in cart.productMap) {
        product = cart.productMap[tempId]
        if(product != null) {
          if( product.disclaimer != null ){
            if( product.disclaimer.genericShip != null){
              errorFound = true;
                elementName = "anchorProdDisclGenShip-"+index;
                break;
            }else if(product.disclaimer.genericNoMaint != null){
              errorFound = true;
                elementName = "anchorProdDisclGenNoMaint-"+index;
                break;
            }else if(product.disclaimer.genericDeclMaint != null){
              errorFound = true;
                elementName = "anchorProdDisclDeclMaint-"+index;
                break;
            }else if(product.disclaimer.genericMaintAvl != null){
              errorFound = true;
                elementName = "anchorProdDisclGenMaintAvl-"+index;
                break;
            }else if(product.disclaimer.genericMaintConf != null){
              errorFound = true;
                elementName = "anchorProdDisclGenMaintConf-"+index;
                break;
            } 
          }
          if(product.shippingNewAddress != null && product.shippingNewAddress.error != null){
            if(product.shippingNewAddress.error.generic){
              errorFound = true;
                elementName = "anchorProdShipNewAddrErrGen-"+index;
                break;
            }
          }
          if(product.error != null){
            if(product.error.genericMaint != null){
              errorFound = true;
                elementName = "anchorProdErrGenMaint-"+index;
                break;
            }else if(product.error.generic != null ){
              errorFound = true;
                elementName = "anchorProdErrGen-"+index;
                break;
            }
          }
        }
        index++;
      }
    }
    
    if(errorFound){
      this.gotoAnchor(elementName);
    }
    
  }

  gotoAnchor(x) {
    this.scroller.scrollToAnchor(x);

    //this.router.navigate([], { fragment: x });

    /* var newHash = x;
    if ($location.hash() !== newHash) {
        // set the $location.hash to `newHash` and
      // $anchorScroll will automatically scroll to it
      $anchorScroll.yOffset = 160; 
      $location.hash(x);
      $anchorScroll();
      
    } else {
      // call $anchorScroll() explicitly,
      // since $location.hash hasn't changed
      $anchorScroll.yOffset = 160; 
      $anchorScroll();
    } */
   };

   primaryShipAddrSameBillAddrFlag(){
	  this.orderService.setCart(this.cart);
		this.orderService.removeBillingAddress();
		this.cart = this.orderService.getCart();
		this.cart = this.orderService.updateAllSelectedMaintenance();
	}


  openPrinterModal(serialNumOrItemCode, printer) {
		
		this.selectedPrinter = printer;
		this.selectedKey = serialNumOrItemCode;
		
		console.log("selected printer",this.selectedPrinter + ":" + this.selectedKey);
		
    this.printerModalRef = this.modalService.show(this.printerTemplate);
		
	}

  clickCarePak(inventoryItemId) {
		
		var carePackList = this.selectedPrinter.carePackList;

		for(var id in carePackList){
			if(carePackList[id].inventoryItemId == inventoryItemId){
				carePackList[id].selected = true;
			}else{
				carePackList[id].selected = false;
			}
		}
		this.selectedPrinter.carePackList = carePackList;
		console.log("$scope.selectedPrinter.carePackList",this.selectedPrinter.carePackList);
		console.log("selected printer",this.selectedPrinter);
  }

  updatePrinterToCart(selectedKey, selectedPrinter){
		//$scope.errMsg = '';
		this.showSuccessMsg = false;
		
		var addedSuccessfully = this.orderService.updatePrinterToCart(selectedKey, selectedPrinter);
		
		this.cart = this.orderService.getCart();
		this.postProcessOfAction();
				
		if(addedSuccessfully){
			this.showSuccessMsg = true;
		}else {
			this.showSuccessMsg = false;
		}
		
	}

  updatePhone(id, product, psn) {
    //TODO
		/* $("input[name='phone']").keyup(function() {
			$(this).val($(this).val().replace(/[^\d]+/g, '').replace(/^(\d{3})(\d{3})(\d{4})+$/, "($1)$2-$3"));
		}); */

    var formattedPhone = this.formatPhonePipe.transform(product.shippingNewAddress.phone);
    product.shippingNewAddress.phone = formattedPhone

		console.log(id + ' ' + product + ' ' + psn);
		this.cart = this.orderService.updateAddress(id, product, psn);
		this.cart = this.orderService.updateAllSelectedMaintenance();
	}

	onAttnChange(itemCode, attnDetail) {
		this.cart = this.orderService.onAttnChange(itemCode, attnDetail);
	};

  validateExistingAddress(id, product, globalZipCode) {
		console.log(id + ' ' + product + ' ' + globalZipCode);
		this.showLoader = true;
		this.orderService.validateAddress(id, product, globalZipCode).subscribe(
      data => {
        product = data;
        console.log('Reponse Data : ', product);
        this.cart = this.orderService.updateProduct(id, product);
        this.cart = this.orderService.updateAllSelectedMaintenance();
        this.showLoader = false;
      }
    ); 
		
	}

  
  
}
