import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '../search/globalsearch/globalsearch.service';
import { OrderService } from '../service/common-service/order.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css'],
})
export class FavoritesComponent implements OnInit {
  @ViewChild('suppliesPerPage') suppliesPerPage: ElementRef;
  @ViewChild('partsPerPage') partsPerPage: ElementRef;
  @ViewChild('printersPerPage') printersPerPage: ElementRef;
  showWaitDiv = false;
  searchData;
  callType = 'S';
  partsLoaded = false;
  entriesPerPage: any[] = [
    { value: 10, viewValue: 10 },
    { value: 25, viewValue: 25 },
    { value: 50, viewValue: 50 },
    { value: 100, viewValue: 100 },
  ];

  suppliesPageNum: number = 1;
  selSuppliesPerPage = 10;

  partsPageNum: number = 1;
  selPartsPerPage = 10;
  selPrintersPerPage = 10;
  constructor(private router: Router,
    private searchService: SearchService,
    private viewportScroller: ViewportScroller,
    private orderService: OrderService
  ) { }

  ngOnInit() {
    this.getSearchData();
  }
  isLFSOrPPSUser = () => {
    let isLFSOrPPSUser = 'N';
    const cart = this.orderService.getCart();
    if (cart.lfsSupplyRole == 'Y' || cart.lfsPartsRole == 'Y' || cart.ppsSupplyRole == 'Y') {
      isLFSOrPPSUser = 'Y';
    }
    return isLFSOrPPSUser;
  }
  getSearchData = () => {
    const cart = this.orderService.getCart();
    const accountNumber = cart.accountInfo ? (cart.accountInfo.defaultSoldTo ? cart.accountInfo.defaultSoldTo.accountNumber : '' ): '';
    const locationNumber = cart.accountInfo ? (cart.accountInfo.defaultSoldTo ? cart.accountInfo.defaultSoldTo.locationId : '' ): '';
   let param:any= {};
   param.callType ='F',
   param.accountNumber =accountNumber,
   param.locationNumber=locationNumber,
   param.isLFSOrPPSUser=this.isLFSOrPPSUser();
   param.searchText='';

    this.showWaitDiv = true;
    this.searchService.getSearchResults(param).subscribe(data => {
      this.showWaitDiv = false;
      if (data != null) {
        console.log('searchKeyword()-->', data);
        this.searchData = data;

        if ((this.searchData.partList.length + this.searchData.printerList.length + this.searchData.supplyList.length) == 1) {
          // redirect 
          var navurl = "";
          if (this.searchData.partList.length > 0) {
            navurl = this.searchData.partList[0].url;
          } else if (this.searchData.printerList.length > 0) {
            navurl = this.searchData.printerList[0].url;
          } else if (this.searchData.supplyList.length > 0) {
            navurl = this.searchData.supplyList[0].url;
          }
          console.log("Navigating to URL : " + navurl);
          this.router.navigateByUrl('/' + navurl);
        }
        this.partsLoaded = true;
      } else {
        console.log("NO data");
      }
    });
  }
  gotoAnchor(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
  gotoUrl(navurl: string): void {
    this.router.navigateByUrl('/' + navurl);
  }
  onPartsPerPageChange() {
    this.selPartsPerPage = this.partsPerPage.nativeElement.value;
  }
  onPrintersPerPageChange() {
    this.selPrintersPerPage = this.printersPerPage.nativeElement.value;
  }
  onSuppliesPerPageChange() {
    this.selSuppliesPerPage = this.suppliesPerPage.nativeElement.value;
  }

  mouseOverLoadImg = (event) => {
    let productImage = $(event.target).next();
    $(productImage).addClass("show-image");
    $(event.target).addClass("product-name-active");
  }

  mouseLeaveUnLoadImg = (event) => {
    let productImage = $(event.target).next();
    $(productImage).removeClass("show-image");
    $(event.target).removeClass("product-name-active");
  }


}
