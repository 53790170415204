import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { FormatAddressPipe } from 'src/app/pipe/format-address.pipe';

const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl: string;
  cart = {} as any;
  deleteTempCart:boolean =false;
  menu;
  private orderUpdateNotify = new Subject<any>();
  private cartCountSubject = new Subject<any>();
  private logoutSubject= new Subject<any>();
  private autoLoginSubject = new Subject<any>();
  private sessionTimeoutSubject = new Subject<any>();

  notifyObservable$ = this.orderUpdateNotify.asObservable();
  cartCountObservable$ = this.cartCountSubject.asObservable();
  logoutObservable$ = this.logoutSubject.asObservable();
  autoLoginObservable = this.autoLoginSubject.asObservable();
  sessionTimeoutObservable = this.sessionTimeoutSubject.asObservable();

  constructor(private http: HttpClient, private util: Util,private formatAddressPipe:FormatAddressPipe,
    private menuService:MenuService) {
    this.baseUrl = this.util.getBaseUrl();
  }
  notifyOrderSummary() {
    this.orderUpdateNotify.next();
  }
  getCart() {
    if (localStorage.getItem('cart') != null) {
      this.cart = JSON.parse(localStorage.getItem('cart'));
    }
    return this.cart;
  }

  setCart(cart) {
    if (cart != null) {
      this.cart = cart;
      localStorage.setItem('cart', JSON.stringify(cart));
      this.cartCountSubject.next();
    }
  }

  getItemFromCart(supply, orderType) {
    var supplyFound = false;
    var serialNumber = "";

    //If billable and CPC supply and order type selected is CPC
    if (supply.cpc && supply.cpc == 'Y' && orderType == 'CPC') {

      if (this.cart.cpcSupplyMap == null || this.cart.cpcSupplyMap == 'undefined') {
        return null;
      }

      if (this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
        return null;
      } else {
        return this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items[0];
      }
    }

    //If billable and Easy Pac supply and order type selected is Easy Pac
    else if (supply.easyPac && supply.easyPac == 'Y' && orderType == 'EP') {

      if (this.cart.epSupplyMap == null || this.cart.epSupplyMap == 'undefined') {
        return null;
      }

      if (this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
        return null;
      } else {
        return this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items[0];
      }
    }
    //If billable and AZ/CO supply and order type selected is AZCO
    else if (supply.azcoInk && supply.azcoInk == 'Y' && orderType == 'AZCO') {

      if (this.cart.azcoSupplyMap == null || this.cart.azcoSupplyMap == 'undefined') {
        return null;
      }

      if (this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
        return null;
      } else {
        return this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items[0];
      }
    }
    //If billable and Private Label supply
    else if (supply.privateLabel == 'Y' || supply.source == 'LFS' || orderType == 'LFS') {

      if (this.cart.lfsSupplyMap == null || this.cart.lfsSupplyMap == 'undefined') {
        return null;
      }

      if (this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
        return null;
      } else {
        return this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items[0];
      }
    }
    else if (supply.source == 'PPS') {

      if (this.cart.ppsSupplyMap == null || this.cart.ppsSupplyMap == 'undefined') {
        return null;
      }

      if (this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
        return null;
      } else {
        return this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items[0];
      }

    } else if (supply.source == 'LFSPARTS') {

      if (this.cart.lfsPartsMap == null || this.cart.lfsPartsMap == 'undefined') {
        return null;
      }

      if (this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber] == null || this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
        return null;
      } else {
        return this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items[0];
      }
    } else {

      if (this.cart.billableSupplyMap == null || this.cart.billableSupplyMap == 'undefined') {
        return null;
      }

      if (this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
        return null;
      } else {
        return this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber].items[0];
      }
    }
  }
  getSequenceNum() {
    if (sessionStorage.getItem("sequenceNumber") === null) {
      return "";
    } else {
      return sessionStorage.getItem("sequenceNumber");
    }
  }
  getMachine() {
    var machine = {};
    if (sessionStorage.getItem('machine') != null) {
      machine = JSON.parse(sessionStorage.getItem('machine'));
    }
    return machine;
  }

  setMachine(machine) {
    if (machine != null) {
      sessionStorage.setItem('machine', JSON.stringify(machine));
      let initialUrl = this.baseUrl + "shipping.htm/iupe";
      return this.http
        .post(initialUrl, machine, httpOptions)
        .pipe(catchError(this.handleError("setMachine")));
    }
  }
  getBillableSupplyAddress(cart) {
    //var cart = this.getCart();
    var address = null;
    for (var tempId in cart.billableSupplyMap) {
      if (cart.billableSupplyMap[tempId].shippingExistingAddress) {
        address = cart.billableSupplyMap[tempId].shippingExistingAddress;
        break;
      } else if (cart.billableSupplyMap[tempId].shippingNewAddress) {
        address = cart.billableSupplyMap[tempId].shippingNewAddress;
        break;
      }

    }
    return address;
  }

  addSupplyToCart(supply, data, orderType) {
    var isValid = true;
    var supplyFound = false;
    let clrTonerSelQty = 0;
    var bwTonerSelQty = 0;
    var wasteTonerSelQty = 0;
    var serialNumber = "";
    

    if (data.machine.serialNumber != null && data.machine.serialNumber != 'undefined') {
      serialNumber = data.machine.serialNumber;
    }

    if (localStorage.getItem('cart') != null) {
      this.cart = JSON.parse(localStorage.getItem('cart'));
    }

    if (this.cart.billableSupplyMap == null || this.cart.billableSupplyMap == 'undefined') {
      this.cart.billableSupplyMap = {};
    }

    if (this.cart.contractSupplyMap == null || this.cart.contractSupplyMap == 'undefined') {
      this.cart.contractSupplyMap = {};
    }

    if (this.cart.cpcSupplyMap == null || this.cart.cpcSupplyMap == 'undefined') {
      this.cart.cpcSupplyMap = {};
    }

    if (this.cart.epSupplyMap == null || this.cart.epSupplyMap == 'undefined') {
      this.cart.epSupplyMap = {};
    }

    if (this.cart.lfsSupplyMap == null || this.cart.lfsSupplyMap == 'undefined') {
      this.cart.lfsSupplyMap = {};
    }

    if (this.cart.ppsSupplyMap == null || this.cart.ppsSupplyMap == 'undefined') {
      this.cart.ppsSupplyMap = {};
    }

    if (this.cart.lfsPartsMap == null || this.cart.lfsPartsMap == 'undefined') {
      this.cart.lfsPartsMap = {};
    }
    if (this.cart.azcoSupplyMap == null || this.cart.azcoSupplyMap == 'undefined') {
      this.cart.azcoSupplyMap = {};
    }

    if (supply.b_c_flag == 'B') {

      //If billable and Arizona/Colorado supply and order type selected is AZCO
      if (((supply.azcoInk && supply.azcoInk == 'Y')) && orderType == 'AZCO') {
        if (this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
          this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber] = {};
          this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber] = data.machine;
          this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items = [];
          this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items.push(supply);
        } else {
          var totalQty = this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty + supply.selectedQty;

          if (totalQty > this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items[0].maxQuantity) {
            isValid = false;
          } else {
            this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty += supply.selectedQty;
            this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items[0].price = supply.price;
          }

        }
      }
      //If billable and CPC supply and order type selected is CPC
      else if (((supply.cpc && supply.cpc == 'Y') || (supply.CPC && supply.CPC == 'Y')) && orderType == 'CPC') {
        if (this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
          this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber] = {};
          this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber] = data.machine;
          this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items = [];
          this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items.push(supply);
        } else {
          var totalQty = this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty + supply.selectedQty;

          if (totalQty > this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items[0].maxQuantity) {
            isValid = false;
          } else {
            this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty += supply.selectedQty;
            this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items[0].price = supply.price;
          }

        }
      }
      //If billable and Easy Pac supply and order type selected is Easy Pac
      else if (supply.easyPac && supply.easyPac == 'Y' && orderType == 'EP') {
        if (this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
          this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber] = {};
          this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber] = data.machine;
          this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items = [];
          this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items.push(supply);
        } else {
          var totalQty = this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty + supply.selectedQty;

          if (totalQty > this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items[0].maxQuantity) {
            isValid = false;
          } else {
            this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty += supply.selectedQty;
            this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items[0].price = supply.price;
          }
        }
      }
      //If billable and Private Label supply
      else if (supply.privateLabel == 'Y' || supply.source == 'LFS' || orderType == 'LFS') {
        if (this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
          this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber] = {};
          this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber] = data.machine;
          this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items = [];
          this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items.push(supply);
        } else {
          var totalQty = this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty + supply.selectedQty;

          if (totalQty > this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].maxQuantity) {
            isValid = false;
          } else {
            this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty += supply.selectedQty;
            this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].price = supply.price;
          }

        }
      } else if (supply.source == 'PPS') {
        if (this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
          this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber] = {};
          this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber] = data.machine;
          this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items = [];
          this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items.push(supply);
        } else {
          var totalQty = this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty + supply.selectedQty;

          if (totalQty > this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].maxQuantity) {
            isValid = false;
          } else {
            this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty += supply.selectedQty;
            this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].price = supply.price;

          }
        }
      } else if (supply.source == 'LFSPARTS') {
        if (this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber] == null || this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
          this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber] = {};
          this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber] = data.machine;
          this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items = [];
          this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items.push(supply);
        } else {
          var totalQty = this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty + supply.selectedQty;

          if (totalQty > this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items[0].maxQuantity) {
            isValid = false;
          } else {
            this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty += supply.selectedQty;
            this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items[0].price = supply.price;
          }
        }
      } else {
        if (this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber] == null || this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber] == 'undefined') {
          this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber] = {};

          if (!this.getBillableSupplyAddress(this.cart)) {
            this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber] = data.machine;
          }

          this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber].items = [];
          this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber].items.push(supply);
        } else {
          if (supply.productCategory == "PR") {
            this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber].items[0] = supply;
            localStorage.setItem('cart', JSON.stringify(this.cart));
            return;
          }
          this.cart.billableSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty += supply.selectedQty;

        }

      }

    } else if (supply.b_c_flag == 'C') {
      if (this.cart.contractSupplyMap[serialNumber] == null || this.cart.contractSupplyMap[serialNumber] == 'undefined') {
        this.cart.contractSupplyMap[serialNumber] = {};
        this.cart.contractSupplyMap[serialNumber] = data.machine;
        this.cart.contractSupplyMap[serialNumber].items = [];
        this.cart.contractSupplyMap[serialNumber].items.push(supply);
      } else {

        for (var i = 0, len = this.cart.contractSupplyMap[serialNumber].items.length; i < len; i++) {
          var qty = this.cart.contractSupplyMap[serialNumber].items[i].selectedQty;
          var supCatg = this.cart.contractSupplyMap[serialNumber].items[i].supplyCategory;
          var itemCd = this.cart.contractSupplyMap[serialNumber].items[i].itemCd;
          if (itemCd == supply.itemCd) {
            qty = parseInt(qty) + parseInt(supply.selectedQty);
          }
          if ((supCatg == '02' || supCatg == '03' || supCatg == '04') && (supply.supplyCategory == '02' || supply.supplyCategory == '03' || supply.supplyCategory == '04')
            && this.cart.contractSupplyMap[serialNumber].items[i].minReqQuantity != "Y" && supply.minReqQuantity != "Y") {
            var clrTonerVal = this.cart.contractSupplyMap[serialNumber].items[i].dispQuantity;
            clrTonerSelQty = clrTonerSelQty + qty;
            //alert("clrTonerSelQty" + clrTonerSelQty + "clrTonerVal" + clrTonerVal);
            if (clrTonerSelQty > clrTonerVal) {
              isValid = false;
              break;
            }

          } else if (supCatg == '01' && supply.supplyCategory == '01' && this.cart.contractSupplyMap[serialNumber].items[i].minReqQuantity != "Y"
            && supply.minReqQuantity != "Y") {
            var bwTonerVal = this.cart.contractSupplyMap[serialNumber].items[i].dispQuantity;
            //bwTonerSelQty = parseInt(bwTonerSelQty)+parseInt(itemQty);
            bwTonerSelQty = bwTonerSelQty + qty;
            //alert('bwTonerSelQty view: '+bwTonerSelQty+' bwTonerVal: '+bwTonerVal);
            if (bwTonerSelQty > bwTonerVal) {
              isValid = false;
              break;
            }

          }
          else if ((supCatg != '01' && supCatg != '02' && supCatg != '03' && supCatg != '04')
            && (supply.supplyCategory != '01' && supply.supplyCategory != '02' && supply.supplyCategory != '03' && supply.supplyCategory != '04')
            && (itemCd == supply.itemCd)
            && this.cart.contractSupplyMap[serialNumber].items[i].minReqQuantity != "Y" && supply.minReqQuantity != "Y") {
            var wasteTonerVal = this.cart.contractSupplyMap[serialNumber].items[i].dispQuantity;
            wasteTonerSelQty = wasteTonerSelQty + qty;
            if (wasteTonerSelQty > wasteTonerVal) {
              isValid = false;
              break;
            }

          }
        }

        if (isValid) {
          for (var i = 0, len = this.cart.contractSupplyMap[serialNumber].items.length; i < len; i++) {
            if (this.cart.contractSupplyMap[serialNumber].items[i].itemCd == supply.itemCd) {
              this.cart.contractSupplyMap[serialNumber].items[i].selectedQty += supply.selectedQty;
              supplyFound = true;
              break;
            }
          }
          if (!supplyFound) {
            if (supply.supplyCategory == '01') {
              var bwTonerVal = supply.dispQuantity;
              bwTonerSelQty = bwTonerSelQty + supply.selectedQty;
              if (bwTonerSelQty > bwTonerVal) {
                isValid = false;
              }
            } else if (supply.supplyCategory == '02' || supply.supplyCategory == '03' || supply.supplyCategory == '04') {
              var clrTonerVal = supply.dispQuantity;
              clrTonerSelQty = clrTonerSelQty + supply.selectedQty;
              if (clrTonerSelQty > clrTonerVal) {
                isValid = false;
              }
            }
            if (isValid) {
              this.cart.contractSupplyMap[serialNumber].items.push(supply);
            }
          }
        }
      }
    }
    if (isValid) {
      this.setCart(this.cart);
      //localStorage.setItem('cart', JSON.stringify(this.cart));
      console.log('added product', this.cart);
    }

    return isValid;
  }

  setMenu(menu) {
    this.menu = menu;
  }

  getMenu() {
    return this.menu;
  }

  totalPDItems() {
    var length = 0;
    var cart = this.getCart();
    if(this.getTempCart() != null){
      cart = this.getTempCart();
    }
    if (cart.productMap != null && cart.productMap != 'undefined') {
      length = Object.keys(cart.productMap).length;
    }
    return length;
  }

  totalBSItems() {
    var length = 0;
    var cart = this.getCart();
    if(this.getTempCart() != null){
      cart = this.getTempCart();
    }
    if (cart.billableSupplyMap != null && cart.billableSupplyMap != 'undefined') {
      length = Object.keys(cart.billableSupplyMap).length;
    }
    return length;
  }

  totalCSItems() {
    var length = 0;
    var cart = this.getCart();
    if(this.getTempCart() != null){
      cart = this.getTempCart();
    }
    if (cart.contractSupplyMap != null && cart.contractSupplyMap != 'undefined') {
      for (var serialNo in cart.contractSupplyMap) {
        length += parseFloat(cart.contractSupplyMap[serialNo].items.length);
      }
      //length = Object.keys(cart.contractSupplyMap).length;
    }
    return length;
  }

  removeSerialFromCart(serialNum) {
    if (localStorage.getItem('cart') != null) {
      this.cart = JSON.parse(localStorage.getItem('cart'));
    }

    if (this.cart.contractSupplyMap[serialNum] != null) {
      delete this.cart.contractSupplyMap[serialNum];
    }

    localStorage.setItem('cart', JSON.stringify(this.cart));

    if (this.totalBillableItems() == 0) {
      this.cart.primaryShippingAddressFlag = 'N';
      localStorage.setItem('cart', JSON.stringify(this.cart));
    }

  }

  totalBillableItems() {
    var plength = 0;
    var blength = 0;
    var cart = this.getCart();
    if (cart.productMap != null && cart.productMap != 'undefined') {
      plength = Object.keys(cart.productMap).length;
    }

    if (cart.billableSupplyMap != null && cart.billableSupplyMap != 'undefined') {
      blength = Object.keys(cart.billableSupplyMap).length;
    }
    return plength + blength;
  }
  hasLFSSupplyRole() {
    var cart = this.getCart();
    if (cart.lfsSupplyRole && cart.lfsSupplyRole == 'Y') {
      return true;
    } else {
      return false;
    }
  }

  hasOrderSupplyRole() {
    var cart = this.getCart();
    return cart && cart.orderSupplyRole && cart.orderSupplyRole == 'Y';
  }

  hasPPSSupplyRole() {
    var cart = this.getCart();

    if (cart.ppsSupplyRole && cart.ppsSupplyRole == 'Y') {
      return true;
    } else {
      return false;
    }
  }

  hasLFSPartsRole() {
    var cart = this.getCart();

    if (cart.lfsPartsRole && cart.lfsPartsRole == 'Y') {
      return true;
    } else {
      return false;
    }
  }

  totalItems() {
    var plength = 0;
    var blength = 0;
    var clength = 0;
    var cpcLength = 0;
    var epLength = 0;
    var lfsLength = 0;
    var ppsLength = 0;
    var partsLength = 0;
    var azcoLength = 0;

    var cart = this.getCart();
    
    if (cart.productMap != null && cart.productMap != 'undefined') {
      plength = Object.keys(cart.productMap).length;
    }

    if (cart.billableSupplyMap != null && cart.billableSupplyMap != 'undefined') {
      blength = Object.keys(cart.billableSupplyMap).length;
    }

    if (cart.contractSupplyMap != null && cart.contractSupplyMap != 'undefined') {
      for (var id in cart.contractSupplyMap) {
        clength += parseFloat(cart.contractSupplyMap[id].items.length);
      }

    }
    if (cart.cpcSupplyMap != null && cart.cpcSupplyMap != 'undefined') {
      cpcLength = Object.keys(cart.cpcSupplyMap).length;
    }
    if (cart.epSupplyMap != null && cart.epSupplyMap != 'undefined') {
      epLength = Object.keys(cart.epSupplyMap).length;
    }
    if (cart.azcoSupplyMap != null && cart.azcoSupplyMap != 'undefined') {
      azcoLength = Object.keys(cart.azcoSupplyMap).length;
    }
    if (cart.lfsSupplyMap != null && cart.lfsSupplyMap != 'undefined') {
      lfsLength = Object.keys(cart.lfsSupplyMap).length;
    }
    if (cart.ppsSupplyMap != null && cart.ppsSupplyMap != 'undefined') {
      ppsLength = Object.keys(cart.ppsSupplyMap).length;
    }
    if (cart.lfsPartsMap != null && cart.lfsPartsMap != 'undefined') {
      partsLength = Object.keys(cart.lfsPartsMap).length;
    }



    return plength + blength + clength + cpcLength + epLength + lfsLength + ppsLength + partsLength + azcoLength;

  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getShippingInstructions(param) {
    let url = this.baseUrl + 'shipping.htm/gsi';
    return this.http.post(url, JSON.stringify(param), httpOptions).pipe(catchError(this.util.handleError("getShippingInstructions")));
  }

  removeMachine() {
    if (sessionStorage.getItem('machine') != null) {
      sessionStorage.removeItem('machine');
    }
  }

  /* cart start */
  subTotal() {
    var total = 0;
    var cart = this.getCart();
    if(this.getTempCart() != null){
      cart = this.getTempCart();
    }
    if (cart.productMap) {
      for (var id in cart.productMap) {
        total += parseFloat(cart.productMap[id].totalPrice) + parseFloat(cart.productMap[id].totalAddOnPrice);
      }
    }

    if (cart.billableSupplyMap) {
      var item = null;
      for (var id in cart.billableSupplyMap) {
        item = cart.billableSupplyMap[id].items[0];
        total += parseFloat(item.price) * parseFloat(item.selectedQty);

        if (item.productCategory == 'PR') {
          if (item.accessoryList) {
            for (var index in item.accessoryList) {
              var accessory = item.accessoryList[index];
              if (accessory.selected == true) {
                total += parseFloat(accessory.listPrice) * parseFloat(item.selectedQty);
              }
            }
          }
          if (item.carePackList) {
            for (var index in item.carePackList) {
              var carePak = item.carePackList[index];
              if (carePak.selected == true) {
                total += parseFloat(carePak.listPrice) * parseFloat(item.selectedQty);
                break;
              }
            }
          }
          if (item.deliveryList) {
            for (var index in item.deliveryList) {
              var delivery = item.deliveryList[index];
              total += parseFloat(delivery.listPrice) * parseFloat(item.selectedQty);
            }
          }
        }
      }
    }

    if (cart.cpcSupplyMap) {
      var item = null;
      for (var id in cart.cpcSupplyMap) {
        item = cart.cpcSupplyMap[id].items[0];
        total += parseFloat(item.price) * parseFloat(item.selectedQty);
      }
    }

    if (cart.epSupplyMap) {
      var item = null;
      for (var id in cart.epSupplyMap) {
        item = cart.epSupplyMap[id].items[0];
        total += parseFloat(item.price) * parseFloat(item.selectedQty);
      }
    }

    if (cart.azcoSupplyMap) {
      var item = null;
      for (var id in cart.azcoSupplyMap) {
        item = cart.azcoSupplyMap[id].items[0];
        total += parseFloat(item.price) * parseFloat(item.selectedQty);
      }
    }

    if (cart.lfsSupplyMap) {
      var item = null;
      for (var id in cart.lfsSupplyMap) {
        item = cart.lfsSupplyMap[id].items[0];
        total += parseFloat(item.price) * parseFloat(item.selectedQty);
      }
    }

    if (cart.ppsSupplyMap) {
      var item = null;
      for (var id in cart.ppsSupplyMap) {
        item = cart.ppsSupplyMap[id].items[0];
        total += parseFloat(item.price) * parseFloat(item.selectedQty);
      }
    }

    if (cart.lfsPartsMap) {
      var item = null;
      for (var id in cart.lfsPartsMap) {
        item = cart.lfsPartsMap[id].items[0];
        total += parseFloat(item.price) * parseFloat(item.selectedQty);
      }
    }
    return total;
  }

  grandTax() {
    var total = 0;
    var cart = this.getCart();
    if(this.getTempCart() != null){
      cart = this.getTempCart();
    }
    if (cart.productMap) {
      for (var id in cart.productMap) {
        total += parseFloat(cart.productMap[id].machineTax);
      }
    }

    if (cart.billableSupplyMap) {
      for (var id in cart.billableSupplyMap) {
        total += parseFloat(cart.billableSupplyMap[id].machineTax);
      }
    }
    if (cart.lfsPartsMap) {
      var lfsPartsTaxSet = false;
      for (var id in cart.lfsPartsMap) {
        if (!lfsPartsTaxSet) {
          lfsPartsTaxSet = true
          var tmpTotal = parseFloat(cart.lfsPartsMap[id].machineTax);
          if (tmpTotal)
            total += tmpTotal;
        }
      }
    }
    if (cart.ppsSupplyMap) {
      var ppsTaxSet = false;
      for (var id in cart.ppsSupplyMap) {
        if (!ppsTaxSet) {
          ppsTaxSet = true;
          var tmpTotal = parseFloat(cart.ppsSupplyMap[id].machineTax);
          if (tmpTotal)
            total += tmpTotal;
        }
      }
    }
    if (cart.lfsSupplyMap) {
      var lfsTaxSet = false;
      for (var id in cart.lfsSupplyMap) {
        if (!lfsTaxSet) {
          lfsTaxSet = true;
          var tmpTotal = parseFloat(cart.lfsSupplyMap[id].machineTax);
          if (tmpTotal)
            total += tmpTotal;

        }
      }
    }

    if (cart.epSupplyMap) {
      var epTaxSet = false;
      for (var id in cart.epSupplyMap) {
        if (!epTaxSet) {
          epTaxSet = true;
          var tmpTotal = parseFloat(cart.epSupplyMap[id].machineTax);
          if (tmpTotal)
            total += tmpTotal;
        }
      }
    }

    if (cart.cpcSupplyMap) {
      var cpcTaxSet = false;
      for (var id in cart.cpcSupplyMap) {
        if (!cpcTaxSet) {
          cpcTaxSet = true;
          var tmpTotal = parseFloat(cart.cpcSupplyMap[id].machineTax);
          if (tmpTotal)
            total += tmpTotal;
        }
      }
    }

    if (cart.azcoSupplyMap) {
      var azcoTaxSet = false;
      for (var id in cart.azcoSupplyMap) {
        if (!azcoTaxSet) {
          azcoTaxSet = true;
          var tmpTotal = parseFloat(cart.azcoSupplyMap[id].machineTax);
          if (tmpTotal)
            total += tmpTotal;
        }
      }
    }
    return total;
  }

  getTempCart() {
    if (localStorage.getItem('tempCart') != null) {
      var cart = JSON.parse(localStorage.getItem('tempCart'));
      return cart;
    }
  }

  setTempCart(cart) {
    if (cart != null) {
      localStorage.setItem('tempCart', JSON.stringify(cart));
    }
  }

  clearLocalStorage(setAcctDetails) {
    localStorage.removeItem("cart");
    const localCart = this.getCart();
    const globalZipCode = localCart.globalZipCode ? localCart.globalZipCode : "";
    const globalCity = localCart.globalCity ? localCart.globalCity : "";
    const globalState = localCart.globalState ? localCart.globalState : "";
    const accountInfo = localCart.accountInfo ? localCart.accountInfo : {};

    let cart = {} as any;
    cart.globalZipCode = globalZipCode;
    cart.globalCity = globalCity;
    cart.globalState = globalState;

    if(setAcctDetails){
      cart.accountInfo = accountInfo;
    }
    
    this.setCart(cart);

    this.menuService.defaultCommonGuld();
    //Commented below as preowned is not in scope for now
    //this.menuService.defaultCommonHomegfpdb();
    //this.defaultCommonHomegfpdb();
    this.menuService.defaultCommonProductgpsd();
    
    //this.setCart(cart);

  }
  getBillSupplyShipCharge() {
    var shipCharge = 0;
    var cart = this.getCart();
    if (this.getCart().billableSupplyShippingCharge) {
      shipCharge += parseFloat(this.getCart().billableSupplyShippingCharge);
    }

    if (this.getTempCart() != null) {
      cart = this.getTempCart();

      if (this.getTempCart().billableSupplyShippingCharge) {
        shipCharge += parseFloat(this.getTempCart().billableSupplyShippingCharge);
      }
    }

    if (cart.lfsPartsMap) {
      var lfsPartsShippingSet = false;
      for (var id in cart.lfsPartsMap) {
        if (!lfsPartsShippingSet) {
          lfsPartsShippingSet = true
          var tmpTotal = parseFloat(cart.lfsPartsMap[id].shippingCharges);
          if (tmpTotal)
            shipCharge += tmpTotal;
        }
      }
    }
    if (cart.ppsSupplyMap) {
      var ppsShippingSet = false;
      for (var id in cart.ppsSupplyMap) {
        if (!ppsShippingSet) {
          ppsShippingSet = true;
          var tmpTotal = parseFloat(cart.ppsSupplyMap[id].shippingCharges);
          if (tmpTotal)
            shipCharge += tmpTotal;
        }
      }
    }
    if (cart.lfsSupplyMap) {
      var lfsShippingSet = false;
      for (var id in cart.lfsSupplyMap) {
        if (!lfsShippingSet) {
          lfsShippingSet = true;
          var tmpTotal = parseFloat(cart.lfsSupplyMap[id].shippingCharges);
          if (tmpTotal)
            shipCharge += tmpTotal;

        }
      }
    }

    if (cart.epSupplyMap) {
      var epShippingSet = false;
      for (var id in cart.epSupplyMap) {
        if (!epShippingSet) {
          epShippingSet = true;
          var tmpTotal = parseFloat(cart.epSupplyMap[id].shippingCharges);
          if (tmpTotal)
            shipCharge += tmpTotal;
        }
      }
    }

    if (cart.cpcSupplyMap) {
      var cpcShippingSet = false;
      for (var id in cart.cpcSupplyMap) {
        if (!cpcShippingSet) {
          cpcShippingSet = true;
          var tmpTotal = parseFloat(cart.cpcSupplyMap[id].shippingCharges);
          if (tmpTotal)
            shipCharge += tmpTotal;
        }
      }
    }

    if (cart.azcoSupplyMap) {
      var azcoShippingSet = false;
      for (var id in cart.azcoSupplyMap) {
        if (!azcoShippingSet) {
          azcoShippingSet = true;
          var tmpTotal = parseFloat(cart.azcoSupplyMap[id].shippingCharges);
          if (tmpTotal)
            shipCharge += tmpTotal;
        }
      }
    }

    return shipCharge;
  }
  grandTotal() {
    var total = 0;
    var cart = this.getCart();
    var grandSubTotal = this.subTotal();
    var grandTax = this.grandTax();
    var grandShipping = this.getBillSupplyShipCharge();

    total = grandSubTotal + grandTax + grandShipping;
    if (cart.billableSupplyOrderDiscount) {
      total -= parseFloat(cart.billableSupplyOrderDiscount);
    }
    return total;
  }
  totalMaintenance() {
    var total = 0;
    var cart = this.getCart();
    if(this.getTempCart() != null){
      cart = this.getTempCart();
    }
    for (var id in cart.productMap) {
      if (cart.productMap[id].maintenance) {
        total += parseFloat(cart.productMap[id].maintenance.price);
      }
    }
    return total;

  }
  subTotalWithSupplies() {
    var total = 0;
    var cart = this.getCart();
    for (var id in cart.productMap) {
      total += parseFloat(cart.productMap[id].totalPrice) + parseFloat(cart.productMap[id].totalAddOnPrice);
    }
    for (var id in cart.billableSupplyMap) {
      total += parseFloat(cart.billableSupplyMap[id].items[0].price) * parseFloat(cart.billableSupplyMap[id].items[0].selectedQty);
    }
    return total;
  }
  grandTaxWithSupplies() {
    var total = 0;
    var cart = this.getCart();
    for (var id in cart.billableSupplyMap) {
      total += cart.billableSupplyMap[id].items[0].tax;
    }
    return total;
  }
  grandTotalWithSupplies() {
    var total = 0;
    var grandSubTotalWithSupplies = this.subTotalWithSupplies();
    var grandTaxWithSupplies = this.grandTaxWithSupplies();
    total = grandSubTotalWithSupplies + grandTaxWithSupplies;
    return total;
  }
  isPrinterItems() {
    var cart = this.getCart();
    var pitem;

    if (this.getTempCart() != null) {
      cart = this.getTempCart();
    }

    if (cart.billableSupplyMap) {
      var item = null;
      for (var id in cart.billableSupplyMap) {
        item = cart.billableSupplyMap[id].items[0];
        if (item.productCategory == 'PR') {
          pitem = true;
          return pitem;
        }
        else pitem = false;
      }
    }
  }
  totalCpcItems() {
    var length = 0;
    var cart = this.getCart();
    if (this.getTempCart() != null) {
      cart = this.getTempCart();
    }
    if (cart.cpcSupplyMap != null && cart.cpcSupplyMap != 'undefined') {
      length = Object.keys(cart.cpcSupplyMap).length;
    }
    return length;
  }

  totalEpItems() {
    var length = 0;
    var cart = this.getCart();
    if (this.getTempCart() != null) {
      cart = this.getTempCart();
    }
    if (cart.epSupplyMap != null && cart.epSupplyMap != 'undefined') {
      length = Object.keys(cart.epSupplyMap).length;
    }
    return length;
  }

  totalLfsItems() {
    var length = 0;
    var cart = this.getCart();
    if (this.getTempCart() != null) {
      cart = this.getTempCart();
    }
    if (cart.lfsSupplyMap != null && cart.lfsSupplyMap != 'undefined') {
      length = Object.keys(cart.lfsSupplyMap).length;
    }
    return length;
  }

  totalPpsItems() {
    var length = 0;
    var cart = this.getCart();
    if (this.getTempCart() != null) {
      cart = this.getTempCart();
    }
    if (cart.ppsSupplyMap != null && cart.ppsSupplyMap != 'undefined') {
      length = Object.keys(cart.ppsSupplyMap).length;
    }
    return length;
  }

  totalLfpItems() {
    var length = 0;
    var cart = this.getCart();
    if (this.getTempCart() != null) {
      cart = this.getTempCart();
    }
    if (cart.lfsPartsMap != null && cart.lfsPartsMap != 'undefined') {
      length = Object.keys(cart.lfsPartsMap).length;
    }
    return length;
  }

  totalAzcoItems() {
    var length = 0;
    var cart = this.getCart();
    if (this.getTempCart() != null) {
      cart = this.getTempCart();
    }
    if (cart.azcoSupplyMap != null && cart.azcoSupplyMap != 'undefined') {
      length = Object.keys(cart.azcoSupplyMap).length;
    }
    return length;
  }
  getPriceOrGetPriceTaxFreight(param) {
    return this.http.post(this.baseUrl + 'shipping.htm/gptf', param);
  }
  calculateBillSuppShipChargeAndTax(cart) {
    return this.http.post(this.baseUrl + 'shipping.htm/calBSupShipChrgTax', cart);
  }
  getLFSShippingMethod = function () {
    return this.http.get(this.baseUrl + 'shipping.htm/glfssm');
  }
  getPrinterTotalPrice(printerItem) {
    var priceTotal = 0;
    var accessory = null;
    var carePak = null;
    var delivery = null;
    if (printerItem != null) {
      priceTotal = printerItem.price;
      if (printerItem.accessoryList) {
        for (var index in printerItem.accessoryList) {
          accessory = printerItem.accessoryList[index];
          if (accessory.selected == true) {
            priceTotal = priceTotal + accessory.listPrice;
          }
        }
      }
      if (printerItem.carePackList) {
        for (var index in printerItem.carePackList) {
          carePak = printerItem.carePackList[index];
          if (carePak.selected == true) {
            priceTotal = priceTotal + carePak.listPrice;
            break;
          }
        }
      }
      if (printerItem.deliveryList) {
        for (var index in printerItem.deliveryList) {
          delivery = printerItem.deliveryList[index];
          priceTotal = priceTotal + delivery.listPrice;
        }
      }
    }
    return priceTotal;
  }
  removeProduct(productId) {
    if (localStorage.getItem('cart') != null) {
      this.cart = JSON.parse(localStorage.getItem('cart'));
    }
    delete this.cart.productMap[productId];
    if (this.totalItems() == 0) {
      this.cart.primaryShippingAddressFlag = 'N';
    }
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }
  convertNullToEmpty(inputString) {
    if (!inputString) {
      inputString = "";
    }
    return inputString;
  }
  compareAddress(firstAddress, secondAddress) {
    var isSameAddress = false;
    var isSameAddress1 = false;
    var isSameAddress2 = false;
    var isSameCity = false;
    var isSameState = false;
    var isSameZip = false;
    if (firstAddress && secondAddress) {
      if (this.convertNullToEmpty(firstAddress.address1).replace(/\s/g, "").toUpperCase() ==
        this.convertNullToEmpty(secondAddress.address1).replace(/\s/g, "").toUpperCase()) {
        isSameAddress1 = true;
      }
      if (this.convertNullToEmpty(firstAddress.address2).replace(/\s/g, "").toUpperCase() ==
        this.convertNullToEmpty(secondAddress.address2).replace(/\s/g, "").toUpperCase()) {
        isSameAddress2 = true;
      }
      if (this.convertNullToEmpty(firstAddress.city).replace(/\s/g, "").toUpperCase() ==
        this.convertNullToEmpty(secondAddress.city).replace(/\s/g, "").toUpperCase()) {
        isSameCity = true;
      }
      if (this.convertNullToEmpty(firstAddress.state).replace(/\s/g, "").toUpperCase() ==
        this.convertNullToEmpty(secondAddress.state).replace(/\s/g, "").toUpperCase()) {
        isSameState = true;
      }
      if (this.convertNullToEmpty(firstAddress.zipcode).replace(/\s/g, "").toUpperCase() ==
        this.convertNullToEmpty(secondAddress.zipcode).replace(/\s/g, "").toUpperCase()) {
        isSameZip = true;
      }
      if (isSameAddress1 && isSameAddress2 && isSameCity && isSameState && isSameZip) {
        isSameAddress = true;
      }
    }
    return isSameAddress;
  }
  removeSupply(serialNumOrItemCode, itemCd, supplyType) {
    var cart = this.getCart();
    if (supplyType != null && supplyType == 'CONTRACT' && cart.contractSupplyMap[serialNumOrItemCode] != null) {
      var items = cart.contractSupplyMap[serialNumOrItemCode].items;
      if (items != null && items.length > 1) {
        for (var item in items) {
          if (itemCd == items[item].itemCd) {
            items.splice(item, 1);
            cart.contractSupplyMap[serialNumOrItemCode].items = items;
          }
        }
      } else if (items != null && items.length == 1) {
        delete cart.contractSupplyMap[serialNumOrItemCode];
      }
    } else if (supplyType != null && supplyType == 'BILLABLE' && cart.billableSupplyMap[serialNumOrItemCode] != null) {
      var items = cart.billableSupplyMap[serialNumOrItemCode].items;
      if (items != null && items.length == 1) {
        delete cart.billableSupplyMap[serialNumOrItemCode];
      }
    } else if (supplyType != null && supplyType == 'CPC' && cart.cpcSupplyMap[serialNumOrItemCode] != null) {
      var items = cart.cpcSupplyMap[serialNumOrItemCode].items;
      if (items != null && items.length == 1) {
        delete cart.cpcSupplyMap[serialNumOrItemCode];
      }
    } else if (supplyType != null && supplyType == 'EP' && cart.epSupplyMap[serialNumOrItemCode] != null) {
      var items = cart.epSupplyMap[serialNumOrItemCode].items;
      if (items != null && items.length == 1) {
        delete cart.epSupplyMap[serialNumOrItemCode];
      }
    } else if (supplyType != null && supplyType == 'AZCO' && cart.azcoSupplyMap[serialNumOrItemCode] != null) {
      var items = cart.azcoSupplyMap[serialNumOrItemCode].items;
      if (items != null && items.length == 1) {
        delete cart.azcoSupplyMap[serialNumOrItemCode];
      }
    } else if (supplyType != null && supplyType == 'LFS' && cart.lfsSupplyMap[serialNumOrItemCode] != null) {
      var items = cart.lfsSupplyMap[serialNumOrItemCode].items;
      if (items != null && items.length == 1) {
        delete cart.lfsSupplyMap[serialNumOrItemCode];
      }
    } else if (supplyType != null && supplyType == 'PPS' && cart.ppsSupplyMap[serialNumOrItemCode] != null) {
      var items = cart.ppsSupplyMap[serialNumOrItemCode].items;
      if (items != null && items.length == 1) {
        delete cart.ppsSupplyMap[serialNumOrItemCode];
      }
    } else if (supplyType != null && supplyType == 'LFP' && cart.lfsPartsMap[serialNumOrItemCode] != null) {
      var items = cart.lfsPartsMap[serialNumOrItemCode].items;
      if (items != null && items.length == 1) {
        delete cart.lfsPartsMap[serialNumOrItemCode];
      }
    }
    if (this.totalBillableItems() == 0) {
      cart.primaryShippingAddressFlag = 'N';
      cart.billableSupplyShippingCharge = 0;
      cart.billableSupplyOrderDiscount = 0;
      cart.promoDetails = null;
    }
    this.setCart(cart);
  }
  calculateBillSuppShipCharge(cart) {
    return this.http.post(this.baseUrl + 'shipping.htm/calBSupShipChrg', cart);
  }
  updatePrinterToCart(selectedKey, selectedPrinter) {
    var cart = null;
    var status = false;
    if (localStorage.getItem('cart') != null) {
      cart = JSON.parse(localStorage.getItem('cart'));
    }
    if (cart.billableSupplyMap[selectedKey] != null) {
      cart.billableSupplyMap[selectedKey].items[0] = selectedPrinter;
      status = true;
    }
    this.setCart(cart)
    return status;
  }
  calculateBillSuppTax(supply) {
    return this.http.post(this.baseUrl + 'shipping.htm/calBSupTax', supply);
  }
  updateItemInCart(supply, data, type = '') {
    var isValid = true;
    var supplyFound = false;
    var clrTonerSelQty = 0;
    var bwTonerSelQty = 0;
    var qty = 0;
    var serialNumber = "";
    var wasteTonerSelQty = 0;
    var cart = this.getCart();
    if (localStorage.getItem('cart') != null) {
      cart = JSON.parse(localStorage.getItem('cart'));
    }
    if (cart.billableSupplyMap == null || cart.billableSupplyMap == 'undefined') {
      cart.billableSupplyMap = {};
    }
    if (cart.contractSupplyMap == null || cart.contractSupplyMap == 'undefined') {
      cart.contractSupplyMap = {};
    }
    if (cart.cpcSupplyMap == null || cart.cpcSupplyMap == 'undefined') {
      cart.cpcSupplyMap = {};
    }
    if (isValid && type == 'CPC') {
      for (var i = 0, len = cart.cpcSupplyMap[data].items.length; i < len; i++) {
        if (cart.cpcSupplyMap[data].items[i].itemCd == supply.itemCd) {
          cart.cpcSupplyMap[data].items[i].selectedQty = supply.selectedQty;
          break;
        }
      }
    }
    if (supply.b_c_flag == 'B' && type != 'CPC') {
      if (cart.billableSupplyMap[data] == null || cart.billableSupplyMap[data] == 'undefined') {
        alert("Please select valid quantity");
      } else {
        cart.billableSupplyMap[data].items[0].selectedQty = supply.selectedQty;
        var item = cart.billableSupplyMap[data].items[0];
        if (item.productCategory == 'PR') {
          if (item.accessoryList) {
            for (var index in item.accessoryList) {
              if (item.accessoryList[index].selected == true) {
                item.accessoryList[index].quantity = supply.selectedQty;
              }
            }
          }
          if (item.carePackList) {
            for (var index in item.carePackList) {
              if (item.carePackList[index].selected == true) {
                item.carePackList[index].quantity = supply.selectedQty;
                break;
              }
            }
          }
          if (item.deliveryList) {
            for (var index in item.deliveryList) {
              item.deliveryList[index].quantity = supply.selectedQty;
            }
          }
        }
      }
    } else if (supply.b_c_flag == 'C') {
      if (data.serialNumber != null && data.serialNumber != 'undefined') {
        serialNumber = data.serialNumber;
      }
      else {
        serialNumber = supply.serialNumber;
      }
      console.log("Serial Number: " + serialNumber);
      if (cart.contractSupplyMap[serialNumber] == null || cart.contractSupplyMap[serialNumber] == 'undefined') {
        alert("Please select valid quantity");
					        isValid = false;
      } else {
        for (var i = 0, len = cart.contractSupplyMap[serialNumber].items.length; i < len; i++) {
          if (cart.contractSupplyMap[serialNumber].items[i].itemCd == supply.itemCd) {
            qty = 0;
          }
          else
            qty = cart.contractSupplyMap[serialNumber].items[i].selectedQty;
          var supCatg = cart.contractSupplyMap[serialNumber].items[i].supplyCategory;
          var itemCd = cart.contractSupplyMap[serialNumber].items[i].itemCd;
          if (itemCd == supply.itemCd) {
            qty = qty + parseInt(supply.selectedQty);
          }
          if ((supCatg == '02' || supCatg == '03' || supCatg == '04') && (supply.supplyCategory == '02' || supply.supplyCategory == '03' || supply.supplyCategory == '04')
            && cart.contractSupplyMap[serialNumber].items[i].minReqQuantity != "Y" && supply.minReqQuantity != "Y") {
            var clrTonerVal = cart.contractSupplyMap[serialNumber].items[i].dispQuantity;
            clrTonerSelQty = clrTonerSelQty + qty;
            if (clrTonerSelQty > parseInt(clrTonerVal)) {
              isValid = false;
              break;
            }
          } else if (supCatg == '01' && supply.supplyCategory == '01' && cart.contractSupplyMap[serialNumber].items[i].minReqQuantity != "Y"
            && supply.minReqQuantity != "Y") {
            var bwTonerVal = cart.contractSupplyMap[serialNumber].items[i].dispQuantity;
            bwTonerSelQty = bwTonerSelQty + qty;
            if (bwTonerSelQty > parseInt(bwTonerVal)) {
              isValid = false;
              break;
            }
          } else if ((supCatg != '01' && supCatg != '02' && supCatg != '03' && supCatg != '04') &&
            (supply.supplyCategory != '01' && supply.supplyCategory != '02' && supply.supplyCategory != '03' && supply.supplyCategory != '04')
            && (itemCd == supply.itemCd)
            && cart.contractSupplyMap[serialNumber].items[i].minReqQuantity != "Y" && supply.minReqQuantity != "Y") {
            var wasteTonerVal = cart.contractSupplyMap[serialNumber].items[i].dispQuantity;
            wasteTonerSelQty = wasteTonerSelQty + qty;
            if (wasteTonerSelQty > parseInt(wasteTonerVal)) {
              isValid = false;
              break;
            }
          }
        }
        if (isValid) {
          for (var i = 0, len = cart.contractSupplyMap[serialNumber].items.length; i < len; i++) {
            if (cart.contractSupplyMap[serialNumber].items[i].itemCd == supply.itemCd) {
              cart.contractSupplyMap[serialNumber].items[i].selectedQty = supply.selectedQty;
              supplyFound = true;
              break;
            }
          }
        }
      }
    }
    if (isValid) {
      this.setCart(cart);
      console.log('added product', cart);
    }
    return isValid;
  }
  getBillableSupply(id) {
    var cart = this.getCart();
    var supply = null;
    for (var tempId in cart.billableSupplyMap) {
      if (tempId == (id + "_")) {
        supply = cart.billableSupplyMap[tempId];
        break;
      }
    }
    return supply;
  }
  /* cart end */




  //////////////// order histrory //////////////

  getOrderHistoryList(data): Observable<any> {
    let data1 = JSON.stringify(data);
    let initialUrl = this.baseUrl + "gohi";
    return this.http
      .post(initialUrl, data1, httpOptions)
      .pipe(catchError(this.handleError("getOrderTrackingInfo")))
  }


  getOrderTrackingInfo(data): Observable<any> {
    let data1 = JSON.stringify(data);
    let initialUrl = this.baseUrl + "goti";
    return this.http
      .post(initialUrl, data1, httpOptions)
      .pipe(catchError(this.handleError("getOrderTrackingInfo")))
  }

  getOrderDetailInfo(data): Observable<any> {
    let data1 = JSON.stringify(data);
    let initialUrl = this.baseUrl + "gohid";
    return this.http
      .post(initialUrl, data1, httpOptions)
      .pipe(catchError(this.handleError("getOrderDetailInfo")))
  }


  getOrderHistoryExport(data): Observable<any> {
    let data1 = JSON.stringify(data);
    let initialUrl = this.baseUrl + "gohie";
    return this.http
      .post(initialUrl, data1, httpOptions)
      .pipe(catchError(this.handleError("getOrderHistoryExport")))
  }

  getOrderHistoryLabels() {

    var orderHistoryLabels = ["OrderNumber", "OrderedDate", "ShopReferenceNumber", "PoNumber", "OrderStatus", "Carrier", "ShipMethod", "TrackingNumber", "CustomerName", "AttentionLabel",
      "ShipAddress1", "ShipAddress2", "ShipCity", "ShipState", "ShipPostalCode", "ShipCountry", "BillAddress1", "BillAddress2", "BillCity",
      "BillState", "BillPostalCode", "BillCountry", "SerialNumber", "EquipmentId", "PartNumber", "ProductDescription", "Quantity", "ShipQty",
      "UnitPrice", "ExtPrice", "OrderSubtotal", "Freight", "Tax", "OrderTotal", "InvoiceNumber"];

    return orderHistoryLabels;
  }


  jsonToExcel(JSONData, outputFileName, ShowLabel) {

    //alert("inside jsonToExcel");

    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

    var lbls;
    var CSV;
    var custArr;
    var bCustom = false;
    if (outputFileName == "Order History") {
      lbls = this.getOrderHistoryLabels();
      bCustom = true;
    } else {
      lbls = arrData[0];
    }

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";
      //This loop will extract the label from 1st index of on array
      if (bCustom) {
        for (var index in lbls) {
          //Now convert each value to string and comma-seprated
          var lbl = lbls[index];
          lbl = this.camel2title(lbl);    // TO DO :
          row += lbl + ',';
        }
      } else {
        for (var index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          index = this.camel2title(index);  // TO DO :
          row += index + ',';
        }
      }
      row = row.slice(0, -1);
      //append Label row with line break

      if (row.indexOf("Po Number") > -1) {
        row = row.replace("Po Number", "PO Number");
      }

      CSV += row + '\r\n';

    } // showLabel

    if (bCustom) {

      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = "";
        //2nd loop will extract each column and convert it in string comma-seprated
        var oData = arrData[i];

        for (var k in lbls) {

          //Skip "" when '=' is appended for preserving leading zeros
          var arrDataVal = oData[lbls[k]];
          //console.log(arrDataVal);
          //check for null and assign "" string
          if (null == arrDataVal)
            arrDataVal = "";
          if (!isNaN(arrDataVal))
            arrDataVal = arrDataVal.toString();
          if (arrDataVal.startsWith('=')) {
            row += arrDataVal + ',';
            continue;
          }

          row += '"' + arrDataVal + '",';

        } // for k
        row.slice(0, row.length - 1);
        //add a line break after each row
        CSV += row + '\r\n';
      } // for i

    } else {
      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = "";
        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {

          //Skip "" when '=' is appended for preserving leading zeros
          var arrDataVal = (arrData[i][index]);
          //check for null and assign "" string
          if (null == arrDataVal)
            arrDataVal = "";
          if (!isNaN(arrDataVal))
            arrDataVal = arrDataVal.toString();
          if (arrDataVal.startsWith('=')) {
            row += arrDataVal + ',';
            continue;
          }
          row += '"' + arrDataVal + '",';
        }
        row.slice(0, row.length - 1);
        //add a line break after each row
        CSV += row + '\r\n';
      } // for loop 

    } // esle 

    if (CSV == '') {
      alert("Invalid data");
      return;
    }

    //this trick will generate a temp "a" tag
    var link = document.createElement("a");
    link.id = "lnkDwnldLnk";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);

    //var csv = CSV;  
    var blob = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });

    //Generate a file name
    var fileName = outputFileName;
    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += outputFileName.replace(/ /g," ");

    if (true) { // IE 10+
     // navigator.msSaveBlob(blob, fileName + ".csv")
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var csvUrl = window.URL.createObjectURL(blob);
        link.setAttribute("href", csvUrl);
        link.setAttribute("download", fileName + ".csv");
        link.style.display = "visibility:hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } // if          
    } // else 


  } // jsonToExcel()


  camel2title(camelCase) {
    return camelCase
      // inject space before the upper case letters
      .replace(/([A-Z])/g, function (match) {
        return " " + match;
      })
      // replace first char with upper case
      .replace(/^./, function (match) {
        return match.toUpperCase();
      });
  }

  getReorderAccountInfo(ordNum) {
    const oData = {
      "orderNumber": ordNum
    };
    return this.http
      .post(this.baseUrl + 'reorder.htm/grai', oData, httpOptions)
      .pipe(catchError(this.handleError("setMachine")));
  }
  getReorderDetails(ordNum) {
    const oData = {
      "orderNumber": ordNum
    };
    return this.http
      .post(this.baseUrl + 'reorder.htm/grd', oData, httpOptions)
      .pipe(catchError(this.handleError("setMachine")));
  }



 

  





  updateAllSelectedMaintenance(){
		var cart = this.getCart();

		for(var productKey in cart.productMap){
			var mlist = cart.productMap[productKey].maintenanceList;
			for(var mIndex in mlist){
				if(mlist[mIndex].termTo == cart.productMap[productKey].maintenance.termTo){
					cart.productMap[productKey].maintenance = mlist[mIndex];
				}
			}
		}
		this.setCart(cart);
		return cart;
	}

 

  isUserPsnExist() : Observable<any> {
    return this.http.post(this.baseUrl + 'shipping.htm/iupe', '');
	}

  updatePrimaryAddress(primaryShipAddrSameBillAddrFlag, shippingNewAddress, psn) {
		var cart = this.getCart();
		/*if(shippingNewAddress != null && shippingNewAddress != 'undefined' && !cart.myCSAUserName){*/
		if(shippingNewAddress != null && shippingNewAddress != 'undefined' && (cart.lfsSupplyRole == 'N' && cart.lfsPartsRole == 'N' && cart.ppsSupplyRole == 'N')){
			if(!shippingNewAddress.errorExist){
				cart.primaryShippingAddressFlag = 'Y';
				shippingNewAddress.psn = psn;
				shippingNewAddress.shippingFullAddress = this.formatAddressPipe.transform(shippingNewAddress);
				cart.primaryShippingAddress = shippingNewAddress;
				for ( var tempId in cart.productMap) {
					cart.productMap[tempId].shippingNewAddressFlg = 'Y';
					if(!cart.productMap[tempId].shippingNewAddress || cart.productMap[tempId].shippingNewAddress.psn == psn){
						cart.productMap[tempId].shippingNewAddress = {};
						cart.productMap[tempId].shippingNewAddress = shippingNewAddress;
						cart.productMap[tempId].shippingExistingAddress = null;
						cart.productMap[tempId].attentionDetails = shippingNewAddress.attentionDetails;
						//cart.productMap[tempId].diffShippingAddressFlag = 'N';
					}else{
						cart.productMap[tempId].shippingNewAddress.companyName = shippingNewAddress.companyName;
						cart.productMap[tempId].shippingNewAddress.shippingFullAddress = this.formatAddressPipe.transform(cart.productMap[tempId].shippingNewAddress);
					}
				}
				for ( var tempId in cart.billableSupplyMap) {
					cart.billableSupplyMap[tempId].shippingNewAddressFlg = 'Y';
					if(!cart.billableSupplyMap[tempId].shippingNewAddress || cart.billableSupplyMap[tempId].shippingNewAddress.psn == psn){
						cart.billableSupplyMap[tempId].shippingNewAddress = {};
						cart.billableSupplyMap[tempId].shippingNewAddress = shippingNewAddress;
						cart.billableSupplyMap[tempId].shippingExistingAddress = null;
						cart.billableSupplyMap[tempId].attentionDetails = shippingNewAddress.attentionDetails;
						//cart.billableSupplyMap[tempId].diffShippingAddressFlag = 'N';
					}else{
						cart.billableSupplyMap[tempId].shippingNewAddress.companyName = shippingNewAddress.companyName;
						cart.billableSupplyMap[tempId].shippingNewAddress.shippingFullAddress = this.formatAddressPipe.transform(cart.billableSupplyMap[tempId].shippingNewAddress);
					}
				}	
			}
		}else {
			cart.primaryShippingAddress = null;
			cart.primaryShippingAddressFlag = 'N';
			for ( var tempId in cart.productMap) {
				if(cart.productMap[tempId].shippingNewAddress && cart.productMap[tempId].shippingNewAddressFlg == 'Y' && cart.productMap[tempId].shippingNewAddress.companyName != null){
					cart.productMap[tempId].shippingNewAddress.companyName = cart.myCSAUserCompanyName;
					cart.productMap[tempId].shippingNewAddress.shippingFullAddress = this.formatAddressPipe.transform(cart.productMap[tempId].shippingNewAddress);
				}
			}
			var billableAddress = this.getBillableSupplyAddress(cart);
			for ( var tempId in cart.billableSupplyMap) {
				if(cart.lfsSupplyRole == 'N' && cart.lfsPartsRole == 'N' && cart.ppsSupplyRole == 'N' && billableAddress){
					
					if(billableAddress.psn.startsWith('N') && !cart.billableSupplyMap[tempId].shippingNewAddress){
						cart.billableSupplyMap[tempId].shippingNewAddress = billableAddress;	
						cart.billableSupplyMap[tempId].shippingNewAddressFlg = 'Y';
						cart.billableSupplyMap[tempId].shippingExistingAddress = null;
					}else if(!billableAddress.psn.startsWith('N') && !cart.billableSupplyMap[tempId].shippingExistingAddress){
						cart.billableSupplyMap[tempId].shippingExistingAddress = billableAddress;
						cart.billableSupplyMap[tempId].shippingNewAddressFlg = 'N';
						cart.billableSupplyMap[tempId].shippingNewAddress = null;
					}
					
					
				}else{
					if(cart.billableSupplyMap[tempId].shippingNewAddress && cart.billableSupplyMap[tempId].shippingNewAddressFlg == 'Y' && cart.billableSupplyMap[tempId].shippingNewAddress.companyName != null){
						cart.billableSupplyMap[tempId].shippingNewAddress.companyName = cart.myCSAUserCompanyName;
						cart.billableSupplyMap[tempId].shippingNewAddress.shippingFullAddress = this.formatAddressPipe.transform(cart.billableSupplyMap[tempId].shippingNewAddress);
					}
				}
			}
		}
		this.setCart(cart);
		return cart;
	}

  onAttnChange(itemCode, attnDetails) {
    var cart = this.getCart();
    if(attnDetails!= null){
      for ( var tempId in cart.billableSupplyMap) {
        if(cart.billableSupplyMap[tempId].attentionDetails == null || cart.billableSupplyMap[tempId].attentionDetails == ''){
          cart.billableSupplyMap[tempId].attentionDetails = attnDetails;
        }
      }
      for ( var tempId in cart.contractSupplyMap) {
        if(itemCode == tempId || cart.contractSupplyMap[tempId].attentionDetails == null || cart.contractSupplyMap[tempId].attentionDetails == ''){
          cart.contractSupplyMap[tempId].attentionDetails = attnDetails;
        }
      }
      this.setCart(cart);
    }
    return cart;
  }

  getExistingAddressList(){
    return this.http.post(this.baseUrl + 'shipping.htm/gsal', '');
	}

  updateSupplyAddress = function(id, machineSupplyDetails, supplyItem, psn) {
		var cart = this.getCart();
		if(id != null && id != '' && id!= 'undefined'){			
			if (machineSupplyDetails != null) {
				if(machineSupplyDetails.shippingNewAddress != null && machineSupplyDetails.shippingNewAddress != 'undefined'){
					// ship to moved to header
					if(cart.myCSAUserName){
						machineSupplyDetails.shippingNewAddress.companyName = cart.myCSAUserCompanyName;
					}
				}
				
				cart.billableSupplyMap[id] = machineSupplyDetails;
				// ship to moved to hdr 
				for(var itemKey in cart.billableSupplyMap){
					cart.billableSupplyMap[itemKey].shippingNewAddress = machineSupplyDetails.shippingNewAddress;
					cart.billableSupplyMap[itemKey].companyName = cart.myCSAUserCompanyName;
					cart.billableSupplyMap[itemKey].shippingExistingAddress = null;
					cart.billableSupplyMap[itemKey].shippingNewAddressFlg = 'Y';
					if(cart.billableSupplyMap[itemKey].shippingNewAddress){
						cart.billableSupplyMap[itemKey].shippingNewAddress.psn = 'N'+psn;
					}
					
					if(cart.billableSupplyMap[itemKey].shippingNewAddress != null && cart.billableSupplyMap[itemKey].shippingNewAddress != 'undefined')
					{
						cart.billableSupplyMap[itemKey].shippingNewAddress.shippingFullAddress = this.formatAddressPipe.transform(cart.billableSupplyMap[itemKey].shippingNewAddress);
					}
					
					if(machineSupplyDetails.shippingNewAddress && machineSupplyDetails.shippingNewAddress.attentionDetails){
						cart.billableSupplyMap[itemKey].attentionDetails = machineSupplyDetails.shippingNewAddress.attentionDetails;
					}
					
				}
			}
			this.setCart(cart);
		}
		return cart;
	}

  validateSupplyAddress(id, supply) {
    return this.http.post(this.baseUrl + 'shipping.htm/vaddSup', supply);
	}


 




validatePrimaryAddress(primaryShippingAddress) {
  var cart = this.getCart();

  return this.http.post(this.baseUrl + 'shipping.htm/vps', cart);
  
}

onShippingCheckOut = function(pageName, globalZipCode) {
  var cart = this.getCart();

  return this.http.post(this.baseUrl + 'shipping.htm/vaes?pageName='+pageName+'&globalZipCode='+globalZipCode, cart);

}

removeBillingAddress = function(){
  var cart = this.getCart();
  
  delete cart.billingAddress;
  localStorage.setItem('cart', JSON.stringify(cart));
  
  sessionStorage.removeItem("sequenceNumber");
}

getShippingMethods = function(){

  return this.http.post(this.baseUrl + 'shipping.htm/gsm', '');
 
}

setDefaultShippingMethod = function(shippingMethodDetails ){
  var cart = this.getCart();
  var item = null;
  if(cart.billableSupplyMap){
    for ( var id in cart.billableSupplyMap) {
      item = cart.billableSupplyMap[id];
      if(item.shippingMethodCode == null || item.shippingMethodCode == 'undefined' || item.shippingMethodCode == '')
      {
        item.shippingMethodCode = shippingMethodDetails;
      }
      cart.billableSupplyMap[id] = item;
    }
  }
  this.setCart(cart);
  return cart;
}

  

  
  
  


updateAddress(id, product, psn) {
  var cart = this.getCart();
  for ( var tempId in cart.productMap) {
    if (tempId == id) {
      if(product.shippingNewAddress != null && product.shippingNewAddress != 'undefined'){
        product.shippingNewAddress.psn = 'N'+psn+'';
        if(cart.myCSAUserName){
          product.shippingNewAddress.companyName = cart.myCSAUserCompanyName;
        }
      }
      product.shippingExistingAddress = null;
      product.shippingNewAddressFlg = 'Y';
      if(product.shippingNewAddress != null && product.shippingNewAddress != 'undefined'){
        product.shippingNewAddress.shippingFullAddress = this.formatAddressPipe.transform(product.shippingNewAddress);
      }
      cart.productMap[tempId] = product;
      cart.productMap[tempId].attentionDetails = product.shippingNewAddress.attentionDetails; 
    }
    
  }

  this.setCart(cart);
  return cart;
}

validateAddress(id, product, globalZipCode) {

  return this.http.post(this.baseUrl + 'shipping.htm/vadd?globalZipCode='+globalZipCode, product);

}

showProductNewAddressFlg(id, product){
  var cart = this.getCart();
  if (product != null && product != 'undefined') {
    product.shippingNewAddressFlg = 'Y';
    cart.productMap[id] = product;
  }
  this.setCart(cart);
  return cart;
}
	




updateProduct(id, product){
  var cart = this.getCart();
  for ( var tempId in cart.productMap) {
    if (tempId == id) {
      cart.productMap[tempId] = product;
    }
  }
  this.setCart(cart);
  return cart;
}


updateBillingAddress(address) {
  var cart = this.getCart();

  cart.billingAddress = address;
  this.setCart(cart);
  return cart;
}

saveSequenceNum(seqNum){
  sessionStorage.setItem("sequenceNumber", seqNum);
}


totalCopies(product,index){
  if(product && product.maintenanceList[index]){
    var total = product.maintenanceList[index].bwAllowanceVol + product.maintenanceList[index].clrAllowanceVol;
    return total;
  }
}

setLogout(logout){
  this.logoutSubject.next(logout);
}


deleteTempCartFromLS(considerTempCart){
  this.deleteTempCart =considerTempCart;
}

sendAutoLoginMessage(message){
  this.autoLoginSubject.next(message);
}

sendSessionTimeoutMessage(message){
  this.sessionTimeoutSubject.next(message);
}

}
