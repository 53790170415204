import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';
import { LocalStorageService } from '../service/common-service/local-storage.service';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  baseUrl: string;

  constructor(private http:HttpClient, private util:Util, private localStorageService : LocalStorageService) {
    this.baseUrl = this.util.getBaseUrl();
  }

  getDefaultAccount() : Observable<any>{
    let url = this.baseUrl + 'account.htm/getDefAccount';

    return this.http.post(url, '', httpOptions).pipe(catchError(this.util.handleError("getDefaultAccount")));
  }
  /* getUpdatedAccount() : Observable<any>{
    let url = this.baseUrl + 'account.htm/getDefAccount/';

    return this.http.post(url, '', httpOptions).pipe(catchError(this.util.handleError("getDefaultAccount")));
  } */

  getExistingAddressList(addressType : string, accountNumber: string) : Observable<any>{
    let url = this.baseUrl + 'account.htm/gAcctAddList?addressType='+addressType+'&accountNumber='+accountNumber;

		return this.http.post(url, '', httpOptions).pipe(catchError(this.util.handleError("getExistingAddressList")));
	}

  getBillToAndShipTo(address : any) : Observable<any>{
    let url = this.baseUrl + 'account.htm/gBillAndShip';

		return this.http.post(url, address, httpOptions).pipe(catchError(this.util.handleError("getBillToAndShipTo")));
	}

  saveAttentionAndAddressLine = function(address){
    let url = this.baseUrl + 'account.htm/saveAttnAndAddrLine';

    return this.http.post(url, address, httpOptions).pipe(catchError(this.util.handleError("saveAttentionAndAddressLine")));
	}

  saveAttentionDefaultAddr(address : any) : Observable<any>{
    let url = this.baseUrl + 'account.htm/saveAttnDefaultShip';

		return this.http.post(url, address, httpOptions).pipe(catchError(this.util.handleError("saveAttentionDefaultAddr")));
	}

  deleteAddress(address : any) : Observable<any>{
    let url = this.baseUrl + 'account.htm/deleteAddress';

		return this.http.post(url, address, httpOptions).pipe(catchError(this.util.handleError("deleteAddress")));
	}

  createShippingAddress(address : any) : Observable<any>{
    let url = this.baseUrl + 'account.htm/createShip';

		return this.http.post(url, address, httpOptions).pipe(catchError(this.util.handleError("createShippingAddress")));
	}

  acceptAndProcess(address : any) : Observable<any>{
    let url = this.baseUrl + 'account.htm/acceptAndProceed';

		return this.http.post(url, JSON.stringify(address), httpOptions).pipe(catchError(this.util.handleError("acceptAndProcess")));
	}

  setAccountInfo(accountDetails){
    var cart = this.localStorageService.getCart();
    cart.accountInfo = accountDetails;
    this.localStorageService.setCart(cart);
  }
  
}
