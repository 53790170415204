import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuppliesRoutingModule } from './supplies-routing.module';
//import { SupplieslandingpageComponent } from './supplieslandingpage/supplieslandingpage.component';
//import { SearchFilterPipe } from '../pipe/search-filter.pipe';
import { SupplyListingComponent } from './supply-listing/supply-listing.component';
import { FormsModule } from '@angular/forms';
import { ShopCommonModule } from '../shop-common/shop-common.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SupplieslandingpageComponent } from './supplieslandingpage/supplieslandingpage.component';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from '../shop-common/block-template.component';


@NgModule({
  declarations: [SupplyListingComponent, SupplieslandingpageComponent],
  imports: [
    CommonModule,
    FormsModule,
    SuppliesRoutingModule,
    ShopCommonModule,
    NgxPaginationModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    })
  ],
  entryComponents :[BlockTemplateComponent],
  providers: [
    
  ]
})
export class SuppliesModule { }
