import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Address } from 'src/app/common/bean/address';
import { SearchFilterPipe } from 'src/app/pipe/search-filter.pipe';
import { AccountService } from 'src/app/account/account.service';
import { Router } from '@angular/router';
import { ShopConstants } from 'src/app/common/shopConstants';
import { Subject } from 'rxjs';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { OrderService } from 'src/app/service/common-service/order.service';
import { LocalStorageService } from 'src/app/service/common-service/local-storage.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';


@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.css']
})
export class AccountSelectionComponent implements OnInit {

  @ViewChild('shipToModal') shipToTemplate:TemplateRef<any>;
  shipToModalRef: BsModalRef;

  //Default SoldTo, BillTo and ShipTo address
  //billTo : any = {};
  // soldTo : any = {};
  //shipTo : any = {};

  billTo : Address = new Address();
  soldTo : Address = new Address();
  shipTo : Address = new Address();

  //Flags to indicate if additional address exists
  moreSoldToExist: true;
	moreBillToExist: true;
	moreShipToExist: true

  lfsSuppliesDefault: boolean;
	ppsSuppliesDefault: boolean;
	lfsPartsDefault: boolean;

  //Flags to indicate what access user has
	custEasyPac: true;
	custCpc: true;
	custPrivateLbl: true;
	custStandardOrder: true;
	custArc: true;

	custEzPacType: 1;

	expediteLfsParts: false;
	expeditePpsSupplies: false;
	tonerContactPsn: string;

	custAzco: true;
	
  //List of address to be displayed in the popup
  existingAddressList: any[];

  showLoader : any = true;

  showLoaderPopup : any = false;

  //Pagination related
  pageNum : number = 1;
  recPerPage = 10;

  //Search related
  search: string;
  searchFilterPropertyNames : string[] = ['firstName', 'lastName', 'companyName', 'address1', 'address2', 'attentionTo', 'city', 'phone', 'state', 'zipcode'];

  //new address related
  isNewAddress: boolean = false;

  newAddress: any = {};

  newAddressExist = false;

  states : any[] = ShopConstants.states

  attnChanged : any = false;

  //Additional Ship to related
  saveAddrAttnIndex = -1;

  errorAddrAttnIndex = -1;

  deleteAddrAttnIndex = -1;

  displayAttnSavedMsg = false;

  modalRef: BsModalRef;

  private acceptAuthorised = new Subject<any>();
  constructor(private accountService : AccountService, private searchPipe : SearchFilterPipe, private modalService: BsModalService, private router : Router, private orderService: OrderService,
    private menuService :MenuService, private loginAuthenticationService: LoginAuthenticationService, ) { }

  ngOnInit() {
    
    this.getDefaultAccountInfo();
    this.newAddress.state = '';
    
  }

  //Default account details
  getDefaultAccountInfo(){
    this.showLoader = true;
    this.accountService.getDefaultAccount().subscribe(
      data => {
        console.log(data);

        if(data.status == "S"){
          this.setDetails(data.accountAddressDetail);
        }else{
          this.loginAuthenticationService.logout();
          this.router.navigate(['home']);
        }
        

        this.showLoader = false;
      }
    )
  }

  setDetails(data){
      this.billTo = data.defaultBillTo || {};
      this.soldTo = data.defaultSoldTo || {};
      this.shipTo = data.defaultShipTo || {};

      this.moreSoldToExist = data.moreSoldToExist;
      this.moreBillToExist = data.moreBillToExist;
      this.moreShipToExist = data.moreShipToExist;

      this.lfsSuppliesDefault = data.lfsSuppliesDefault;
      this.ppsSuppliesDefault = data.ppsSuppliesDefault;
      this.lfsPartsDefault = data.lfsPartsDefault;

      //Flags to indicate what access user has
      this.custEasyPac = data.custEasyPac;
      this.custCpc = data.custCpc;
      this.custPrivateLbl = data.custPrivateLbl;
      this.custStandardOrder = data.custStandardOrder;
      this.custArc = data.custArc;

      this.custEzPacType = data.custEzPacType;

      this.expediteLfsParts = data.expediteLfsParts;
      this.expeditePpsSupplies = data.expeditePpsSupplies;
      this.tonerContactPsn = data.tonerContactPsn;

      this.custAzco = data.custAzco;

  }

  //Get Sold To/Bill To/Ship To address list
  getAddressList(addressType : string, accountNumber: string){
    this.showLoaderPopup = true;
    this.search = '';
    this.saveAddrAttnIndex = -1;
    this.errorAddrAttnIndex = -1;
    this.deleteAddrAttnIndex = -1;
    this.existingAddressList = [];

    if(addressType === 'SH'){
      this.isNewAddress = false;
      this.clearAddress();
      this.shipToModalRef = this.modalService.show(this.shipToTemplate, { class: 'shipToModal' });
    }

    this.accountService.getExistingAddressList(addressType, accountNumber).subscribe(
      data => {
        console.log(data);
        this.existingAddressList = data;
        //console.log('this.existingAddressList:' + this.existingAddressList);
        this.showLoaderPopup = false;
      }
    )
  }

  getAddressListShip(addressType : string, accountNumber: string){
    this.showLoaderPopup = true;
    this.search = '';
    this.saveAddrAttnIndex = -1;
    this.errorAddrAttnIndex = -1;
    this.deleteAddrAttnIndex = -1;

    this.shipToModalRef = this.modalService.show(this.shipToTemplate);

    this.accountService.getExistingAddressList(addressType, accountNumber).subscribe(
      data => {
        console.log(data);
        this.existingAddressList = data;
        //console.log('this.existingAddressList:' + this.existingAddressList);
        this.showLoaderPopup = false;
      }
    )
  }

  getDetails(){
    let param = {
      defaultSoldTo : this.soldTo,
      defaultBillTo : this.billTo,
      defaultShipTo : this.shipTo,
      moreSoldToExist : this.moreSoldToExist,
      moreBillToExist : this.moreBillToExist,
      moreShipToExist : this.moreShipToExist,
      lfsSuppliesDefault : this.lfsSuppliesDefault,
      ppsSuppliesDefault : this.ppsSuppliesDefault,
      lfsPartsDefault : this.lfsPartsDefault,
      custEasyPac : this.custEasyPac,
      custCpc : this.custCpc,
      custPrivateLbl : this.custPrivateLbl,
      custStandardOrder : this.custStandardOrder,
      custArc : this.custArc,
      custEzPacType : this.custEzPacType,
      expediteLfsParts : this.expediteLfsParts,
      expeditePpsSupplies : this.expeditePpsSupplies,
      tonerContactPsn : this.tonerContactPsn,
      custAzco : this.custAzco

    }

    return param;

  }
  acceptAndProcess(){

    let param = this.getDetails();

    this.accountService.acceptAndProcess(param).subscribe(
      data => {

        console.log(data);

        this.setDetails(data.accountDetail);

        if(!data.accountDetail.defaultShipTo.errorExist){
          this.accountService.setAccountInfo(data.accountDetail);
          this.menuService.acceptAndProceed.next(true);
          localStorage.setItem('showRestartButton','true');

          this.router.navigateByUrl(data.redirectRoute);
          		
        }else{
          $("#defaultAttn").hide();
        }
      }
    )
  }

  //Save attention details for default ship to address
  saveAttentionDefaultAddr(){
    this.displayAttnSavedMsg = false;

    let shipToAddr = this.shipTo;
    shipToAddr.dropShipFlag = 'Y'

    let param = {
      'acctAddress': shipToAddr,
    }

    this.accountService.saveAttentionDefaultAddr(JSON.stringify(param)).subscribe(
      data => {
        this.shipTo = data.acctAddress;

        if(data.status === 'S'){
          this.displayAttnSavedMsg = true;
        }
      }
    )
  }

  //Select Sold To/Bill To/Ship To
  selectAddress(addrType: string, addr : any){
    if(addrType === "S"){
      let param = {
        'selSoldTo': JSON.stringify(addr),
        'moreShipToExist' : this.moreSoldToExist
      }
      
      this.accountService.getBillToAndShipTo(JSON.stringify(param)).subscribe(
        data => {
          console.log(data);
          
          this.setDetails(data);
        } 
      )
    }else if(addrType === 'B'){
      this.billTo = addr;
    }else if(addrType === 'SH'){
      if(!addr.locationId){
        addr.locationId=this.shipTo.locationId;
      }
      this.shipTo = addr;
      this.shipToModalRef.hide();
    }

  }

  //Open new address modal
  openNewAddress(){
    this.isNewAddress = true;
    this.newAddress = {state:''};
  }

  validateNewAddress(template:TemplateRef<any>){
    
		for(var i in this.existingAddressList){
			 if(this.existingAddressList[i].address1 == this.newAddress.address1 
          && this.existingAddressList[i].city == this.newAddress.city 
          && this.existingAddressList[i].state == this.newAddress.state 
          && this.existingAddressList[i].zipcode == this.newAddress.zipcode){				 
        this.newAddressExist = true;
        break;
      }
    }
		
    //this.newAddressExist = true;
    if(!this.newAddressExist){
      this.createAndSelect();
		}else{

      this.modalRef = this.modalService.show(template);
    }

  }

  createAndSelect(){

    let shipToAddr = this.newAddress;
    shipToAddr.dropShipFlag = 'Y';

    var param = {
      "acctAddress" : shipToAddr,
      "operationFlag" : "I",
      "soldToAcctNumber" : this.soldTo.accountNumber
    }
    
    this.accountService.createShippingAddress(param).subscribe(
      
        data => {
          let address = data.acctAddress;

          if(!address.errorExist && data.status == 'S'){
            this.newAddressExist = false;
            
            address.locationId = this.shipTo.locationId;
            address.accountNumber = this.shipTo.accountNumber; 
            address.dropShipFlag='Y';
           
            this.shipTo = address;
            this.shipToModalRef.hide();
          }else{
            this.newAddress = address;
          }

          if(this.modalRef){
            this.modalRef.hide();
          }
      }
    )

  }

  clearAddress(){
    this.newAddress = {};
    this.newAddress.state = '';
  }

  openExistingAddress(){
    this.isNewAddress = false;
  }

  closePopup(addrType: string){
    //this.isNewAddress = false;
    if(addrType === 'SH'){
      this.shipToModalRef.hide();
    }else if(addrType === 'CONFIRM'){
      this.modalRef.hide();
    }
  }

  //Save attention and address 2 details on the ship to popup
  saveAttentionAndAddressLine(index, addr){

    let selectedAddr = addr;
    selectedAddr.dropShipFlag = 'Y'

    let param = {
      acctAddress: selectedAddr,
      operationFlag : 'U',
      soldToAcctNumber : this.soldTo.accountNumber
    }

    this.accountService.saveAttentionAndAddressLine(JSON.stringify(param)).subscribe(
      data => {
        console.log(data);
        this.saveAddrAttnIndex = -1;
        this.errorAddrAttnIndex = -1;
        this.deleteAddrAttnIndex = -1;


        if(data.acctAddress.errorExist){
          console.log('error');
          this.errorAddrAttnIndex = index;
        }

        if(data.status === 'S'){
          this.accountService.getExistingAddressList('SH', this.soldTo.accountNumber).subscribe(
            data => {
              this.existingAddressList = data;
              this.saveAddrAttnIndex = index;
            }
          )
        }
      }   
    )
    //timeout pending
  }

  deleteAttentionAndAddressLineConfirm(index){
    this.deleteAddrAttnIndex = index;
  }

  deleteAddrOrAddLineAndAttn(address){

    
    if(address.newAddress){

      this.accountService.deleteAddress(address).subscribe(

        data => {
          if(data.status === 'S'){
              var addressList = this.existingAddressList;

              addressList = this.searchPipe.transform(addressList,this.search,this.searchFilterPropertyNames);

              addressList.forEach((value,index)=>{
                if(value.locationId==address.locationId && value.newAddressId == address.newAddressId) {
                  addressList.splice(index,1);
                }
            });
							//addressList.splice(index, 1);
            this.existingAddressList = addressList;
            this.deleteAddrAttnIndex = -1;
          }
        }

      )
		}else{
      //Do we need the else part as we display delete only is address is new
      //Just ported code from shop just incase it is needed
      address.address2 = address.oldAddress2;
      address.attentionTo = address.oldAttentionTo;
				
      this.saveAttentionAndAddressLine(this.deleteAddrAttnIndex, address);
      this.deleteAddrAttnIndex = -1;
    }
  }
  
  hideConfirm(){
    this.deleteAddrAttnIndex = -1;

  }

  setAttn(){
    if(!this.attnChanged){
      var fn="";
      var ln="";
      var attn="";
      fn = this.newAddress.firstName;
      ln = this.newAddress.lastName;
      if(fn){
        attn=(ln)?fn+" "+ln:fn;
      }else if(ln){
        attn=ln;
      }
      this.newAddress.attentionTo=attn;
    }
  }

  checkAttn(){
    if(this.newAddress.attentionTo){
      this.attnChanged=true;
      }else{
      this.attnChanged=false;
         
      }
  }

  getShipAddress(){
    //If shop2 this was called on change event of the search input
    //If we need to call this method on change event of the search text 
    // we can call getAddressList('SH', soldTo.accountNumber)
  }

 
  
  shipToPageChange(pgNo){
    this.pageNum = pgNo;
    this.deleteAddrAttnIndex = -1;
    this.saveAddrAttnIndex = -1;
    this.errorAddrAttnIndex = -1;
  }

}
