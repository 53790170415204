import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LargeformatpartsSelectionComponent } from './largeformatparts-selection/largeformatparts-selection.component';

const routes: Routes = [
  // {
  //   path: 'largeformatparts-selection',
  //   component: LargeformatpartsSelectionComponent,
  //   children: [{ path: 'largeformatparts/largeformatparts-selection', component: LargeformatpartsSelectionComponent }],
  // } 
  {
 
    path: '',

    component: LargeformatpartsSelectionComponent,

    children: [{path:'largeformatparts', component:LargeformatpartsSelectionComponent, data: { showHeader: true, showSidebar: false, showFooter: true}}]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LargeformatpartsRoutingModule { }
