import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingAddressComponent } from './billing-address/billing-address.component';

const routes: Routes = [

  //uncomment below for App component routing 
  {
    path: '',
    component: BillingAddressComponent,
    children: [{ path: 'billing/billingaddress', component: BillingAddressComponent }],
  }

  //uncomment below for Module level routing uncomment below
  /* {
    path: '',
    component: BillingAddressComponent,
    children: [{path:'billingaddress', component:BillingAddressComponent, data: { showHeader: true, showSidebar: false, showFooter: true }}]
  } */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
