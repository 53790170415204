import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { OrdercontractsuppliesRoutingModule } from './ordercontractsupplies-routing.module';
import { OrdercontractsuppliesSelectionComponent } from './ordercontractsupplies-selection/ordercontractsupplies-selection.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MeterreadModalComponent } from './ordercontractsupplies-selection/meterread-modal/meterread-modal.component';

import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from '../shop-common/block-template.component';
import { ShopCommonModule } from '../shop-common/shop-common.module';

@NgModule({
  declarations: [OrdercontractsuppliesSelectionComponent, MeterreadModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    OrdercontractsuppliesRoutingModule,
   ShopCommonModule,
    NgxPaginationModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    })
  ],

  entryComponents: [MeterreadModalComponent,BlockTemplateComponent]
 
})
export class OrdercontractsuppliesModule { }
