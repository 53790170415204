
import { NgModule } from '@angular/core';

import { Routes, RouterModule, UrlSegment } from '@angular/router';

import { LoginComponent } from './common/login/login.component';

import { RouteGuardService } from './service/common-service/route-guard.service';
import { HomeComponent } from './common/home/home.component';
import { ContactusComponent } from './common/contactus/contactus.component';
import { FaqComponent } from './common/faq/faq.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { ShippingOptionsComponent } from './common/shipping-options/shipping-options.component';

import { OrderHistoryComponent } from './orderhistory/order-history/order-history.component';
import { OrderDetailComponent } from './orderhistory/order-detail/order-detail.component';
import { GlobalsearchComponent } from './search/globalsearch/globalsearch.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { CartComponent } from './common/cart/cart.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { CartReviewComponent } from './shipping/cart-review/cart-review.component';
import { CartConfirmComponent } from './shipping/cart-confirm/cart-confirm.component';
import { MycsaLoginComponent } from './common/mycsa-login/mycsa-login.component';
import { TwoFactorAuthComponent } from './common/two-factor-auth/two-factor-auth.component';

export function nonAngularAppURL(url: UrlSegment[]) {
  console.log('I am in nonAngularAppURL **url.length: ' + url.length);
  //return url.length === 1 && url[0].path.indexOf('.htm') > -1 ? ({consumed: url}) : null;
  //If there is no # in the URL the size of url param is 0
  //http://localhost:8070/csaShop/#/contactus/1/2/3 - size of url is 4
  //http://localhost:8070/csaShop/#/contactus - size of url is 1
  //http://localhost:8070/csaShop/login.htm - size of url is 0
  //http://localhost:8070/csaShop - size of url is 0

  return url.length === 0 ?  ({consumed: url}) : null;
}

const routes: Routes = [

  { matcher: nonAngularAppURL, component: HomeComponent,data:{ showHeader: true, showSidebar: false, showFooter: true} },
  { path: '', component: HomeComponent,data:{ showHeader: true, showSidebar: false, showFooter: true} },
  { path: 'contactus', component: ContactusComponent,data:{ showHeader: true, showSidebar: false, showFooter: true} },
  { path: 'faq', component: FaqComponent,data:{ showHeader: true, showSidebar: false, showFooter: true} },

  { path: 'login', component: LoginComponent,  data: { showHeader: false, showSidebar: false, showFooter: false} },

  { path: 'dashboard', component: DashboardComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'shipping', component: ShippingOptionsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'home', component: HomeComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'cart', component: CartComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  
  { path:'orderdetail', component:OrderDetailComponent, data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'orderhistory', component: OrderHistoryComponent , canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'globalsearch', component: GlobalsearchComponent , data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'resetPassword', component: ResetPasswordComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'favorites', component: FavoritesComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'cartreview', component: CartReviewComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'cartconfirm', component: CartConfirmComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  { path: 'twoFactorAuth', component: TwoFactorAuthComponent, canActivate:[RouteGuardService], data: { showHeader: false, showSidebar: false, showFooter: false }},
  
  {
    path: 'account',
    loadChildren: () =>
    import(`./account/account.module`).then((m) => m.AccountModule),
    data: { showHeader: true, showSidebar: false, showFooter: true }
  },
  {
    path: '',
    loadChildren: () =>
    import(`./supplies/supplies.module`).then((m) => m.SuppliesModule),
    data: { showHeader: true, showSidebar: false, showFooter: true }
  },
  {
    path: 'ordercontractsupplies',
    loadChildren: () =>
      import(`./ordercontractsupplies/ordercontractsupplies.module`).then((m) => m.OrdercontractsuppliesModule),
      data: { showHeader: true, showSidebar: false, showFooter: true}
  },
  {
    path: 'shippingaddress',
    loadChildren: () =>
      import(`./shipping/shipping.module`).then((m) => m.ShippingModule),
      data: { showHeader: true, showSidebar: false, showFooter: true}
  },
  {
    path: 'billingaddress',
    loadChildren: () =>
      import(`./billing/billing.module`).then((m) => m.BillingModule),
      data: { showHeader: true, showSidebar: false, showFooter: true}
  },


    
  {
    path: 'largeformatparts',
    loadChildren: () =>
      import(`./largeformatparts/largeformatparts.module`).then((m) => m.LargeformatpartsModule),
      data: { showHeader: true, showSidebar: false, showFooter: true,breadCrumb: "Large Format Parts" }
  },

  { path: 'redirectFromCSA', component: MycsaLoginComponent, data: { showHeader: true, showSidebar: false, showFooter: true }},


  //{ path: 'orderhistory', component: OrderHistoryComponent , canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }},
  //{ path: 'orderdetail', component: OrderDetailComponent , canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: false, showFooter: true }}
 
  /* {
    path: '**',
    component: ContactusComponent,
    data: { showHeader: true, showSidebar: false, showFooter: true }
  } */

  //Tnis route should always be at the end. Do not add routes below this
  //If we enter a route that is not existent then we redirect the user to home page
  {
    path: '**',
    redirectTo : ''
  }
  
];



@NgModule({

   imports: [RouterModule.forRoot(routes,{ useHash: true, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled',

       scrollOffset: [0, 64]})],

  //imports: [RouterModule.forRoot(routes, { useHash: true })],

  exports: [RouterModule]

})

export class AppRoutingModule {





 }




