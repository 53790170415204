import { Component, OnInit } from '@angular/core';
import { LoginAuthenticationService } from '../../service/common-service/login-authentication.service';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/service/common-service/order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminMessageComponent } from '../login/admin-message/admin-message-modal';
import { MenuService } from 'src/app/layout/menu/menu.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  userName: string | null;
  pageName: string | null;
  showLoader : any = true;
  oldPassword: string ="";
  newPassword: string ="";
  confirmPassword: string ="";
  messageColor: string ="";
  message: string;
  constructor(private loginAuthenticationService:LoginAuthenticationService, 
    private router : Router, private orderService: OrderService,
    private modalService: NgbModal,  private menuService: MenuService,) { }

  ngOnInit() {
      this.userName = sessionStorage.getItem('userName');
      this.pageName = sessionStorage.getItem('route');
  }


 resetPassword()
{
  var inputParams = { oldPassword:this.oldPassword, 
    newPassword : this.newPassword, confirmPassword : this.confirmPassword};
  var mediumRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g");    
   var enoughRegex = new RegExp("(?=.{8,}).*", "g");
   
   var noError = false;
   if (false == enoughRegex.test(this.newPassword)) {
     this.messageColor  = "alert alert-danger";
     this.message = "Password should have minimum 8 characters.";  
   }else if(this.newPassword != this.confirmPassword){
     this.messageColor  = "alert alert-danger";
     this.message = "New password & Confirm password should be same.";
   } else if (mediumRegex.test(this.newPassword)) {  
     this.messageColor = "alert alert-success";
     this.message = "Strong password";
     noError = true;
   } else {
     this.messageColor  = "alert alert-danger";
     this.message = "Password should have 1 Uppercase, 1 Lowercase , 1 Number";  
   }
   if(noError)
   {
      this.loginAuthenticationService.resetPassword(inputParams).subscribe
     (data =>{
      console.log(data);
      console.log("Reset component data:"+data);    
      if(data.success == true){
        //window.location.href=data.pageName;

        this.menuService.updateMenu(this.loginAuthenticationService.getAuthenticatedUser())
                .subscribe(() => {

                  this.router.navigateByUrl(data.pageName);

                  this.orderService.sendAutoLoginMessage('autologin');
                  this.loginAuthenticationService.getAdminMssages().subscribe((messages: any) => {
                    if (messages && messages.length > 0) {
                      const modalRef = this.modalService.open(AdminMessageComponent, {size: 'lg'});
                      modalRef.componentInstance.messages = messages;                    
                    }
                  })
                })
        
      }   
     else
     {
      this.messageColor  = "alert alert-danger";
      this.message = data.errorList[0];
     }
   },
    error=>{
      console.log(error);
     
    }
     );


   }
}

}
