import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


@Injectable({
	  providedIn: "root"
})
export class Util {
	
	getBaseUrl() {
	      var re = new RegExp(/^.*\//);
	      var baseUrl = re.exec(window.location.href);
	      console.log("Base Url : " + baseUrl[0]);
	     // return  baseUrl[0];
	       var  pgUrl = window.location.origin;
	        var link = pgUrl+'/canonShop/';
	        console.log("link : " + link);    
	        if(window.location.port == '4200')
	        	 return "http://localhost:8070/canonShop/";
	        else
	        	return link;
 
	  }

	handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
	  
}