import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { OrderService } from 'src/app/service/common-service/order.service';
import { AdminMessageComponent } from '../login/admin-message/admin-message-modal';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-mycsa-login',
  templateUrl: './mycsa-login.component.html',
  styleUrls: ['./mycsa-login.component.css']
})
export class MycsaLoginComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;
  username : string
  constructor(private route : ActivatedRoute, private loginAuthenticationService: LoginAuthenticationService, 
    private menuService: MenuService, private router : Router,
    private modalService: NgbModal, private orderService : OrderService
              ) { }

  /* ngOnInit() {
    this.username = this.loginAuthenticationService.getAuthenticatedUser();
    this.menuService.updateMenu(this.username.trim()).subscribe(data => {          
      this.router.navigate([data.route]);
      this.orderService.sendAutoLoginMessage('autologin');
      this.loginAuthenticationService.getAdminMssages().subscribe((messages: any) => {
        if (messages && messages.length > 0) {
          const modalRef = this.modalService.open(AdminMessageComponent, {size: 'lg'});
          modalRef.componentInstance.messages = messages;

        }
      })
    })
  }  */

   ngOnInit() {
    this.blockUI.start(); 

    this.loginAuthenticationService.executeJDBCAuthenticationService('', '').subscribe(
      data => {
        console.log('I am inside mycsa auto login component');
        if (data && data.authenticated == true) {
          //let addressDetail = this.http.get()
          this.menuService.updateMenu(data.userName.trim())
            .subscribe(() => {

              this.orderService.sendAutoLoginMessage('autologin');
              
              this.router.navigate([data.route]);
              this.loginAuthenticationService.getAdminMssages().subscribe((messages: any) => {
                if (messages && messages.length > 0) {
                  const modalRef = this.modalService.open(AdminMessageComponent, {size: 'lg'});
                  modalRef.componentInstance.messages = messages;
    
                }
                this.blockUI.stop();
              })
            })
        }else{
          this.blockUI.stop();
          this.router.navigate(['/']);
        }
      }
    )
    /* this.route.queryParams.subscribe(
      params => {
        let myCSA = params['mycsa'];

        if(myCSA === 'Y'){

          
        }
      }
    ) */
  } 

}
