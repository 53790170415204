import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterPipe } from '../pipe/search-filter.pipe';
import { OrderByPipe } from '../pipe/order-by.pipe';
import { FormatAddressPipe } from '../pipe/format-address.pipe';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { CheckoutStepsComponent } from './checkout-steps/checkout-steps.component';
import { RouterModule } from '@angular/router';
import { SplitPipe } from '../pipe/split.pipe';
import { FormatPhonePipe } from '../pipe/format-phone.pipe';
import { BlockTemplateComponent } from './block-template.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [SearchFilterPipe, OrderByPipe, FormatAddressPipe, CartSummaryComponent, CheckoutStepsComponent, SplitPipe, FormatPhonePipe, BlockTemplateComponent],
  imports: [
    CommonModule, RouterModule, NgbModule
  ],
  exports: [SearchFilterPipe, OrderByPipe, FormatAddressPipe, CartSummaryComponent, CheckoutStepsComponent, SplitPipe, FormatPhonePipe, BlockTemplateComponent],
  providers : [SearchFilterPipe, OrderByPipe, FormatAddressPipe, SplitPipe, FormatPhonePipe]
})
export class ShopCommonModule { }
