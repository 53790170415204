import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { OrderService } from 'src/app/service/common-service/order.service';

//import { cartItemsData } from './cart.modal';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  @BlockUI() blockUI : NgBlockUI;

  cart = {} as any;
  subTotal = 0;
  totalItems = 0;
  grandTax = 0;
  grandTotal = 0;
  totalMaint = 0;
  totalContractSupplyItems = null;
  totalBillableSupplyItems = null;
  totalPrewownedItems = null;
  totalBillableItems = null;
  totalContractBillableItems = null;
  subTotalWithSupplies = null;
  grandTaxWithSupplies = null;
  grandTotalWithSupplies = null;
  totalLfsItems = null;
  isPrinterItem = null;
  totalCpcItems = null;
  totalEpItems = null;
  totalPpsItems = null;
  totalLfpItems = null;
  totalAzcoItems = null;
  isEmptyCpcSupplyMap = false;
  isEmptyEpSupplyMap = false;
  isEmptyLfsSupplyMap = false;
  isEmptyPpsSupplyMap = false;
  isEmptyLfsPartsSupplyMap = false;
  isEmptyAzcoSupplyMap = false;
  isEmptyContractSupplyMap = false;
  isEmptyBillableSupplyMap = false;
  isEmptyPreOwnedMachine = false;
  billSupplyShipCharge = null;
  checkOnlyBillableSupplyMap = false;
 // pName = 'supplies.htm?view=landingPage&category=SUPPLIES';
  ADDR_TYPE_CPC = "CPC";
  ADDR_TYPE_EP = "EP";
  ADDR_TYPE_LFS = "LFS";
  ADDR_TYPE_PPS = "PPS";
  ADDR_TYPE_LFP = "LFP";
  ADDR_TYPE_AZCO = "AZCO";
  shipMethod = "STD";
  keyShippingAddr = "shippingExistingAddress";
  keyShippingNewAddressFlg = "shippingNewAddressFlg";
  keyShippingNewAddress = "shippingNewAddress";
  partPriceErrMsg = '';
  processingRequest = '';
  isChangePasswordRequired = false;
  successMsg = '';
  invalidQty = '';
  firstBillableAddress = null;
  firstContractSupplyAddress = null;
  isSameBillableAddr = false;
  selectedPrinter = null;
  selectedKey = null;
  errMsg = '';
  qtyErrMsg = '';
  showSuccessMsg = false;
  placeholder = 0;
  itemCd = null;
serialNumber = null;

  constructor(
    private orderService: OrderService,
    private commonService: CommonUtilsService,
    private router:Router
  ) {
    // const localCart = this.orderService.getCart();
    // this.orderService.setCart({ ...localCart, ...cartItemsData });

  }
  ngOnInit(): void {
    this.totalItems = this.orderService.totalItems();
    if (this.totalItems == 0) {
      let queryParams: any = {};
      queryParams.searchTxt = 'SUPPLIES';
      this.router.navigate(['supplies'], { queryParams });
     

    }
    this.subTotal = this.orderService.subTotal();
    this.grandTax = this.orderService.grandTax();
    this.grandTotal = this.orderService.grandTotal();
    this.totalMaint = this.orderService.totalMaintenance();
    this.cart = this.orderService.getCart();
    this.totalContractSupplyItems = this.orderService.totalCSItems();
    this.totalBillableSupplyItems = this.orderService.totalBSItems();
    this.totalPrewownedItems = this.orderService.totalPDItems();
    this.totalBillableItems = this.orderService.totalBSItems() + this.totalPrewownedItems;
    this.totalContractBillableItems = this.totalBillableItems + this.totalContractSupplyItems;
    this.subTotalWithSupplies = this.orderService.subTotalWithSupplies();
    this.grandTaxWithSupplies = this.orderService.grandTaxWithSupplies();
    this.grandTotalWithSupplies = this.orderService.grandTotalWithSupplies();
    this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
    this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
    this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
    this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
    this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
    this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
    this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
    this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
    this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
    this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
    this.isPrinterItem = this.orderService.isPrinterItems();
    this.totalCpcItems = this.orderService.totalCpcItems();
    this.totalEpItems = this.orderService.totalEpItems();
    this.totalLfsItems = this.orderService.totalLfsItems();
    this.totalPpsItems = this.orderService.totalPpsItems();
    this.totalLfpItems = this.orderService.totalLfpItems();
    this.totalAzcoItems = this.orderService.totalAzcoItems();
    if (this.isEmptyCpcSupplyMap && this.isEmptyEpSupplyMap && this.isEmptyLfsSupplyMap && this.isEmptyPpsSupplyMap && this.isEmptyLfsPartsSupplyMap && this.isEmptyAzcoSupplyMap) {
      if (!this.isEmptyBillableSupplyMap || !this.isEmptyPreOwnedMachine) {
        this.checkOnlyBillableSupplyMap = true;
      }
    }
    if (this.cart.billingAddress == null && (this.cart.lfsSupplyRole == 'Y' || this.cart.lfsPartsRole == 'Y' || this.cart.ppsSupplyRole == 'Y')) {
      this.cart.billingAddress = this.cart.accountInfo.defaultBillTo;
      this.cart.paymentType = "INVOICE";
    }

    this.getAttentionDetails();
    this.orderService.setCart(this.cart);
    console.log("updated Cart:" + this.cart);
    if (!this.isEmptyCpcSupplyMap) {
      this.getPriceOrGetPriceTaxFreight(this.cart.cpcSupplyMap, this.ADDR_TYPE_CPC, 'CPC', this.shipMethod);
    }
    if (!this.isEmptyLfsSupplyMap) {
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsSupplyMap, this.ADDR_TYPE_LFS, 'LFS', this.shipMethod);
    }
    if (!this.isEmptyEpSupplyMap) {
      this.getPriceOrGetPriceTaxFreight(this.cart.epSupplyMap, this.ADDR_TYPE_EP, 'EP', this.shipMethod);
    }
    if (!this.isEmptyPpsSupplyMap) {
      this.getPriceOrGetPriceTaxFreight(this.cart.ppsSupplyMap, this.ADDR_TYPE_PPS, 'PPS', this.shipMethod);
    }
    if (!this.isEmptyLfsPartsSupplyMap) {
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsPartsMap, this.ADDR_TYPE_LFP, 'LFSPARTS', this.shipMethod);
    }
    if (!this.isEmptyAzcoSupplyMap) {
      this.getPriceOrGetPriceTaxFreight(this.cart.azcoSupplyMap, this.ADDR_TYPE_AZCO, 'AZCO', this.shipMethod);
    }
    this.calculateBillSuppShipChargeAndTax()
    this.changePasswordRequired();

  }
  changePasswordRequired() {
    this.commonService.changePasswordReq().subscribe(
      (result: any) => {
        if (result == true) {
          this.isChangePasswordRequired = true;
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }
  calculateBillSuppShipChargeAndTax() {
    var calculateBillSuppShipChargeAndTaxPromise = this.orderService.calculateBillSuppShipChargeAndTax(this.cart);
    calculateBillSuppShipChargeAndTaxPromise.subscribe((result) => {
      this.cart = result;
      this.orderService.setCart(this.cart);
      this.orderService.notifyOrderSummary();
      //// TODO: hidePleaseWait();
      if (this.totalItems == 0) {
        let queryParams: any = {};
        queryParams.searchTxt = 'SUPPLIES';
        this.router.navigate(['supplies'], { queryParams });
      }
    });
  }
  getAttentionDetails() {
    for (var itemKey in this.cart.lfsPartsMap) {
      if (((this.cart.lfsPartsMap[itemKey])[this.keyShippingAddr] == null || (this.cart.lfsPartsMap[itemKey])[this.keyShippingAddr] == '')
        && ((this.cart.lfsPartsMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.lfsPartsMap[itemKey])[this.keyShippingNewAddress] == '')) {
        (this.cart.lfsPartsMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
        (this.cart.lfsPartsMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
      }

      if ((this.cart.lfsPartsMap[itemKey])[this.keyShippingNewAddressFlg] == 'Y') {
        if ((this.cart.lfsPartsMap[itemKey])[this.keyShippingNewAddress]["attentionDetails"] == null || (this.cart.lfsPartsMap[itemKey])[this.keyShippingNewAddress]["attentionDetails"] == '') {
          (this.cart.lfsPartsMap[itemKey])[this.keyShippingNewAddress]["attentionDetails"] = this.cart.accountInfo.defaultShipTo.attentionTo;
        }
      } else {
        if ((this.cart.lfsPartsMap[itemKey])[this.keyShippingAddr]["attentionDetails"] == null || (this.cart.lfsPartsMap[itemKey])[this.keyShippingAddr]["attentionDetails"] == '') {
          (this.cart.lfsPartsMap[itemKey])[this.keyShippingAddr]["attentionDetails"] = this.cart.accountInfo.defaultShipTo.attentionTo;
        }
      }
    }
    for (var itemKey in this.cart.lfsSupplyMap) {
      if (((this.cart.lfsSupplyMap[itemKey])[this.keyShippingAddr] == null || (this.cart.lfsSupplyMap[itemKey])[this.keyShippingAddr] == '')
        && ((this.cart.lfsSupplyMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.lfsSupplyMap[itemKey])[this.keyShippingNewAddress] == '')) {
        (this.cart.lfsSupplyMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
        (this.cart.lfsSupplyMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
      }

      if ((this.cart.lfsSupplyMap[itemKey])["attentionDetails"] == null || (this.cart.lfsSupplyMap[itemKey])["attentionDetails"] == '') {
        (this.cart.lfsSupplyMap[itemKey])[this.keyShippingAddr]["attentionDetails"] = this.cart.accountInfo.defaultShipTo.attentionTo;
      }
    }
    for (var itemKey in this.cart.ppsSupplyMap) {
      if (((this.cart.ppsSupplyMap[itemKey])[this.keyShippingAddr] == null || (this.cart.ppsSupplyMap[itemKey])[this.keyShippingAddr] == '')
        && ((this.cart.ppsSupplyMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.ppsSupplyMap[itemKey])[this.keyShippingNewAddress] == '')) {
        (this.cart.ppsSupplyMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
        (this.cart.ppsSupplyMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
      }
      if ((this.cart.ppsSupplyMap[itemKey])["attentionDetails"] == null || (this.cart.ppsSupplyMap[itemKey])["attentionDetails"] == '') {
        (this.cart.ppsSupplyMap[itemKey])[this.keyShippingAddr]["attentionDetails"] = this.cart.accountInfo.defaultShipTo.attentionTo;
      }
    }
    for (var itemKey in this.cart.epSupplyMap) {
      if (((this.cart.epSupplyMap[itemKey])[this.keyShippingAddr] == null || (this.cart.epSupplyMap[itemKey])[this.keyShippingAddr] == '')
        && ((this.cart.epSupplyMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.epSupplyMap[itemKey])[this.keyShippingNewAddress] == '')) {
        (this.cart.epSupplyMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
        (this.cart.epSupplyMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
      }
      if ((this.cart.epSupplyMap[itemKey])["attentionDetails"] == null || (this.cart.epSupplyMap[itemKey])["attentionDetails"] == '') {
        (this.cart.epSupplyMap[itemKey])[this.keyShippingAddr]["attentionDetails"] = this.cart.accountInfo.defaultShipTo.attentionTo;
      }
    }
    for (var itemKey in this.cart.cpcSupplyMap) {
      if (((this.cart.cpcSupplyMap[itemKey])[this.keyShippingAddr] == null || (this.cart.cpcSupplyMap[itemKey])[this.keyShippingAddr] == '')
        && ((this.cart.cpcSupplyMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.cpcSupplyMap[itemKey])[this.keyShippingNewAddress] == '')) {
        (this.cart.cpcSupplyMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
        (this.cart.cpcSupplyMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
      }
      if ((this.cart.cpcSupplyMap[itemKey])["attentionDetails"] == null || (this.cart.cpcSupplyMap[itemKey])["attentionDetails"] == '') {
        (this.cart.cpcSupplyMap[itemKey])[this.keyShippingAddr]["attentionDetails"] = this.cart.accountInfo.defaultShipTo.attentionTo;
      }
    }
    for (var itemKey in this.cart.azcoSupplyMap) {
      if (((this.cart.azcoSupplyMap[itemKey])[this.keyShippingAddr] == null || (this.cart.azcoSupplyMap[itemKey])[this.keyShippingAddr] == '')
        && ((this.cart.azcoSupplyMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.azcoSupplyMap[itemKey])[this.keyShippingNewAddress] == '')) {
        (this.cart.azcoSupplyMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
        (this.cart.azcoSupplyMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
      }
      if ((this.cart.azcoSupplyMap[itemKey])["attentionDetails"] == null || (this.cart.azcoSupplyMap[itemKey])["attentionDetails"] == '') {
        (this.cart.azcoSupplyMap[itemKey])[this.keyShippingAddr]["attentionDetails"] = this.cart.accountInfo.defaultShipTo.attentionTo;
      }
    }
    if ((this.cart.lfsSupplyRole == 'Y' || this.cart.lfsPartsRole == 'Y' || this.cart.ppsSupplyRole == 'Y')) {
      for (var itemKey in this.cart.billableSupplyMap) {
        if (((this.cart.billableSupplyMap[itemKey])[this.keyShippingAddr] == null || (this.cart.billableSupplyMap[itemKey])[this.keyShippingAddr] == '')
          && ((this.cart.billableSupplyMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.billableSupplyMap[itemKey])[this.keyShippingNewAddress] == '')) {
          (this.cart.billableSupplyMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
          (this.cart.billableSupplyMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
        }
      }
      for (var itemKey in this.cart.productMap) {
        if (((this.cart.productMap[itemKey])[this.keyShippingAddr] == null || (this.cart.productMap[itemKey])[this.keyShippingAddr] == '')
          && ((this.cart.productMap[itemKey])[this.keyShippingNewAddress] == null || (this.cart.productMap[itemKey])[this.keyShippingNewAddress] == '')) {
          (this.cart.productMap[itemKey])[this.keyShippingAddr] = this.cart.accountInfo.defaultShipTo;
          (this.cart.productMap[itemKey])[this.keyShippingNewAddressFlg] = 'N';
        }
      }
    }
  }
  getGrandTaxAndShippingFromLocalStorage() {
    this.grandTax = this.orderService.grandTax();
    this.billSupplyShipCharge = this.orderService.getBillSupplyShipCharge();
    console.log("tax set in local storage : " + this.grandTax);
    console.log("shipping set in local storage : " + this.billSupplyShipCharge);
  }
  getPriceOrGetPriceTaxFreight(mapObject, addrType, orderType, shipMethod) {
    this.partPriceErrMsg = '';
    this.processingRequest = 'processingRequest';

    var param = {
      "accountDetails": JSON.stringify(this.cart.accountInfo),
      "mapObject": JSON.stringify(mapObject),
      "orderType": orderType,
      "shipType": shipMethod
    }

    var priceTaxShippingPromise = this.orderService.getPriceOrGetPriceTaxFreight(param);

    priceTaxShippingPromise.subscribe(
      (results: any) => {
        //// TODO: hidePleaseWait();
        this.processingRequest = '';

        if (results.success == 'N') {
          this.partPriceErrMsg = 'Sorry could not complete that action. Please call 1-866-472-7876 for assistance.';
          //// TODO: $("#pricingErrorMsgModal").modal('show');
        } else {
          console.log("TaxFreight API Response");
          console.log('price:' + results);
          var ordTotDealTaxAmt = results.ordTotDealTaxAmt;
          var ordTotDealChrgAmt = results.ordTotDealChrgAmt;
          var priceList = results.orderLinePriceList || [];
          console.log("Tax : " + ordTotDealTaxAmt + " , Shipping : " + ordTotDealChrgAmt);
          this.cart = this.orderService.getCart();
          var keyTax = "machineTax";
          var keyShipping = "shippingCharges";
          if (addrType == this.ADDR_TYPE_LFP) {
            for (var itemKey in this.cart.lfsPartsMap) {
              (this.cart.lfsPartsMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.lfsPartsMap[itemKey])[keyShipping] = ordTotDealChrgAmt;

              for (var j = 0; j < priceList.length; j++) {
                var priceData = priceList[j];
                if (itemKey == (priceData.mdseCode + '_')) {
                  var newPrice = priceData.dealNetUnitPrcAmt
                  console.log(' price:' + newPrice);
                  (this.cart.lfsPartsMap[itemKey])["items"][0].price = newPrice;
                }
              }
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_LFS) {
            for (var itemKey in this.cart.lfsSupplyMap) {
              (this.cart.lfsSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.lfsSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;

              for (var j = 0; j < priceList.length; j++) {
                var priceData = priceList[j];
                if (itemKey == (priceData.mdseCode + '_')) {
                  var newPrice = priceData.dealNetUnitPrcAmt
                  console.log(' price:' + newPrice);
                  (this.cart.lfsSupplyMap[itemKey])["items"][0].price = newPrice;
                }
              }
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_PPS) {
            for (var itemKey in this.cart.ppsSupplyMap) {
              (this.cart.ppsSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.ppsSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;

              for (var j = 0; j < priceList.length; j++) {
                var priceData = priceList[j];
                if (itemKey == (priceData.mdseCode + '_')) {
                  var newPrice = priceData.dealNetUnitPrcAmt
                  console.log(' price:' + newPrice);
                  (this.cart.ppsSupplyMap[itemKey])["items"][0].price = newPrice;
                }
              }
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_EP) {
            for (var itemKey in this.cart.epSupplyMap) {
              (this.cart.epSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.epSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;

              for (var j = 0; j < priceList.length; j++) {
                var priceData = priceList[j];
                if (itemKey == (priceData.mdseCode + '_')) {
                  var newPrice = priceData.dealNetUnitPrcAmt
                  console.log(' price:' + newPrice);
                  (this.cart.epSupplyMap[itemKey])["items"][0].price = newPrice;
                }
              }
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_CPC) {
            for (var itemKey in this.cart.cpcSupplyMap) {
              (this.cart.cpcSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.cpcSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;

              for (var j = 0; j < priceList.length; j++) {
                var priceData = priceList[j];
                if (itemKey == (priceData.mdseCode + '_')) {
                  var newPrice = priceData.dealNetUnitPrcAmt
                  console.log(' price:' + newPrice);
                  (this.cart.cpcSupplyMap[itemKey])["items"][0].price = newPrice;
                }
              }
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          } else if (addrType == this.ADDR_TYPE_AZCO) {
            for (var itemKey in this.cart.azcoSupplyMap) {
              (this.cart.azcoSupplyMap[itemKey])[keyTax] = ordTotDealTaxAmt;
              (this.cart.azcoSupplyMap[itemKey])[keyShipping] = ordTotDealChrgAmt;

              for (var j = 0; j < priceList.length; j++) {
                var priceData = priceList[j];
                if (itemKey == (priceData.mdseCode + '_')) {
                  var newPrice = priceData.dealNetUnitPrcAmt
                  console.log(' price:' + newPrice);
                  (this.cart.azcoSupplyMap[itemKey])["items"][0].price = newPrice;
                }
              }
            }
            this.orderService.setCart(this.cart);
            this.getGrandTaxAndShippingFromLocalStorage();
          }
        }

      },
      (reason) => {
        console.log(reason);
        this.processingRequest = '';
        //// TODO: hidePleaseWait();
      }
    );
  }
  validateQty(item, index) {
    //item.value.items[0].selectedQty = value;
    const supply = item.value.items[0];
    this.successMsg = '';
    if (supply.selectedQty % supply.orderIncrements == 0) {
      //alert("Entered");
      //// TODO: this.billableSuppliesForm['QTY_' + index].$setValidity("validQty", true);
    }
    else {
      //// TODO: this.billableSuppliesForm['QTY_' + index].$setValidity("validQty", false);
    }
  }
  Range(start, supply) {
    var result = [];
    var maxVal = supply.maxQuantity;
    var mulVal = supply.orderIncrements;
    if (maxVal > 0) {
      for (var i = 1; i <= maxVal; i++) {
        var tmpQty = i * mulVal;
        if (((mulVal < maxVal) && (tmpQty <= maxVal)) || mulVal == maxVal) {
          result.push(tmpQty);
        } else if ((mulVal > maxVal - 1) && (tmpQty <= mulVal)) {
          result.push(tmpQty);
        }
      }
    }
    return result;
  };
  scrollToPgTop() {
    $('html, body').animate({
      scrollTop: $("#top").offset().top - 60
    }, 200);
  }
  getPrinterTotalPrice(item) {
    return this.orderService.getPrinterTotalPrice(item);
  }
  postProcessOfAction() {
    this.cart = this.orderService.getCart();
    this.subTotal = this.orderService.subTotal();
    this.totalMaint = this.orderService.totalMaintenance();
    this.totalItems = this.orderService.totalItems();
    this.grandTax = this.orderService.grandTax();
    this.grandTotal = this.orderService.grandTotal();
    this.totalContractSupplyItems = this.orderService.totalCSItems();
    this.totalBillableSupplyItems = this.orderService.totalBSItems();
    this.totalPrewownedItems = this.orderService.totalPDItems();
    this.totalBillableItems = this.orderService.totalBSItems() + this.totalPrewownedItems;
    this.totalContractBillableItems = this.totalBillableItems + this.totalContractSupplyItems;
    this.isEmptyContractSupplyMap = this.commonService.isEmptyObj(this.cart.contractSupplyMap);
    this.isEmptyBillableSupplyMap = this.commonService.isEmptyObj(this.cart.billableSupplyMap);
    this.isEmptyPreOwnedMachine = this.commonService.isEmptyObj(this.cart.productMap);
    this.orderService.notifyOrderSummary();
  }
  removeFromCart(productId) {
    this.successMsg = '';
    this.invalidQty = '';
    this.orderService.removeProduct(productId);
    this.totalItems = this.orderService.totalItems();
    this.isPrinterItem = this.orderService.isPrinterItems();
    this.postProcessOfAction();
    this.isSameBillableAddr = this.checkSameAddress('B');
    if (this.totalItems == 0) {
      let queryParams: any = {};
      queryParams.searchTxt = 'SUPPLIES';
      this.router.navigate(['supplies'], { queryParams });
    }
    this.scrollToPgTop();
    this.orderService.notifyOrderSummary();
  }
  checkSameAddress(itemType) {
    var count = 0;
    var isSameAddress = true;
    if (itemType == 'B' && this.cart.billableSupplyMap) { //Billable Items
      for (var itemKey in this.cart.billableSupplyMap) {
        var newAddrFlag = (this.cart.billableSupplyMap[itemKey])["shippingNewAddressFlg"];
        var keyShippingAddr = "shippingExistingAddress";
        if (newAddrFlag && newAddrFlag == "Y") {
          keyShippingAddr = "shippingNewAddress";
        }
        if (count == 0) {
          this.firstBillableAddress = (this.cart.billableSupplyMap[itemKey])[keyShippingAddr];
        } else {
          var isSameAddressTemp = this.orderService.compareAddress(this.firstBillableAddress, (this.cart.billableSupplyMap[itemKey])[keyShippingAddr]);
          if (!isSameAddressTemp) {
            isSameAddress = false;
            break;
          }
        }
        count++;

      }
      for (var itemKey in this.cart.productMap) {
        var newAddrFlag = (this.cart.productMap[itemKey])["shippingNewAddressFlg"];
        var keyShippingAddr = "shippingExistingAddress";
        if (newAddrFlag && newAddrFlag == "Y") {
          keyShippingAddr = "shippingNewAddress";
        }
        if (count == 0) {
          this.firstBillableAddress = (this.cart.productMap[itemKey])[keyShippingAddr];
        } else {
          var isSameAddressTemp = this.orderService.compareAddress(this.firstBillableAddress, (this.cart.productMap[itemKey])[keyShippingAddr]);
          if (!isSameAddressTemp) {
            isSameAddress = false;
            break;
          }
        }
      }
    } else if (itemType == 'C' && this.cart.contractSupplyMap) { //Contract supplies			
      for (var itemKey in this.cart.contractSupplyMap) {
        var newAddrFlag = (this.cart.contractSupplyMap[itemKey])["shippingNewAddressFlg"];
        var keyShippingAddr = "shippingExistingAddress";
        if (newAddrFlag && newAddrFlag == "Y") {
          keyShippingAddr = "shippingNewAddress";
        }
        if (count == 0) {
          this.firstContractSupplyAddress = (this.cart.contractSupplyMap[itemKey])[keyShippingAddr];
        } else {
          var isSameAddressTemp = this.orderService.compareAddress(this.firstContractSupplyAddress,
            (this.cart.contractSupplyMap[itemKey])[keyShippingAddr]);
          if (!isSameAddressTemp) {
            isSameAddress = false;
            break;
          }
        }
        count++;
      }
    }
    return isSameAddress;
  }
  calculateBillSuppShipCharge() {
    var calculateBillSuppShipChargePromise = this.orderService.calculateBillSuppShipCharge(this.cart);
    calculateBillSuppShipChargePromise.subscribe((result: any) => {
      this.cart = result;
      this.orderService.setCart(this.cart);
      this.postProcessOfAction();
      //// TODO: hidePleaseWait();
    }, (reason) => {
      console.log(reason);
      //// TODO: hidePleaseWait();
    });
  };
  removeSuppliesFromCart(serialNumOrItemCode, itemCd, supplyType, selectedQty) {
    this.successMsg = '';
    this.invalidQty = '';
    this.orderService.removeSupply(serialNumOrItemCode, itemCd, supplyType);
    this.cart = this.orderService.getCart();
    var shipMethod = 'STD';
    if (supplyType == 'CPC') {
      this.getPriceOrGetPriceTaxFreight(this.cart.cpcSupplyMap, this.ADDR_TYPE_CPC, 'CPC', shipMethod);
    } else if (supplyType == 'LFS') {
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsSupplyMap, this.ADDR_TYPE_LFS, 'LFS', shipMethod);
    } else if (supplyType == 'EP') {
      this.getPriceOrGetPriceTaxFreight(this.cart.epSupplyMap, this.ADDR_TYPE_EP, 'EP', shipMethod);
    } else if (supplyType == 'PPS') {
      this.getPriceOrGetPriceTaxFreight(this.cart.ppsSupplyMap, this.ADDR_TYPE_PPS, 'PPS', shipMethod);
    } else if (supplyType == 'LFP') {
      this.getPriceOrGetPriceTaxFreight(this.cart.lfsPartsMap, this.ADDR_TYPE_LFP, 'LFSPARTS', shipMethod);
    } else if (supplyType == 'AZCO') {
      this.getPriceOrGetPriceTaxFreight(this.cart.azcoSupplyMap, this.ADDR_TYPE_PPS, 'AZCO', shipMethod);
    }
    this.totalItems = this.orderService.totalItems();
    if (this.totalItems > 0) {
      this.calculateBillSuppShipCharge();
      this.totalCpcItems = this.orderService.totalCpcItems();
      this.totalEpItems = this.orderService.totalEpItems();
      this.totalLfsItems = this.orderService.totalLfsItems();
      this.totalPpsItems = this.orderService.totalPpsItems();
      this.totalLfpItems = this.orderService.totalLfpItems();
      this.totalAzcoItems = this.orderService.totalAzcoItems();
      this.isPrinterItem = this.orderService.isPrinterItems();
      this.isEmptyCpcSupplyMap = this.commonService.isEmptyObj(this.cart.cpcSupplyMap);
      this.isEmptyEpSupplyMap = this.commonService.isEmptyObj(this.cart.epSupplyMap);
      this.isEmptyLfsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsSupplyMap);
      this.isEmptyPpsSupplyMap = this.commonService.isEmptyObj(this.cart.ppsSupplyMap);
      this.isEmptyLfsPartsSupplyMap = this.commonService.isEmptyObj(this.cart.lfsPartsMap);
      this.isEmptyAzcoSupplyMap = this.commonService.isEmptyObj(this.cart.azcoSupplyMap);
    } else {
      let queryParams: any = {};
      queryParams.searchTxt = 'SUPPLIES';
      this.router.navigate(['supplies'], { queryParams });
    }
    this.scrollToPgTop();
    this.orderService.notifyOrderSummary();
  }
  openPrinterModal(serialNumOrItemCode, printer) {
    this.selectedPrinter = printer;
    this.selectedKey = serialNumOrItemCode;
    console.log("selected printer", this.selectedPrinter + ":" + this.selectedKey);
    //// TODO: $("#printerModal").modal('show');
  }
  clickCarePak(inventoryItemId) {
    var carePackList = this.selectedPrinter.carePackList;
    for (var id in carePackList) {
      if (carePackList[id].inventoryItemId == inventoryItemId) {
        carePackList[id].selected = true;
      } else {
        carePackList[id].selected = false;
      }
    }
    this.selectedPrinter.carePackList = carePackList;
    console.log("this.selectedPrinter.carePackList", this.selectedPrinter.carePackList);
    console.log("selected printer", this.selectedPrinter);
  }
  updatePrinterToCart(selectedKey, selectedPrinter) {
    this.errMsg = '';
    this.showSuccessMsg = false;
    var addedSuccessfully = this.orderService.updatePrinterToCart(selectedKey, selectedPrinter);
    this.cart = this.orderService.getCart();
    var calculateBillSuppTaxPromise = this.orderService.calculateBillSuppTax(this.cart.billableSupplyMap[selectedKey]);
    calculateBillSuppTaxPromise.subscribe((result: any) => {
      var supply = result;
      console.log('Calculated item : ', supply);
      this.cart.billableSupplyMap[selectedKey] = supply;
      this.orderService.setCart(this.cart);
      this.postProcessOfAction();
    }, (reason) => {
      console.log(reason);
    });

    if (addedSuccessfully) {
      this.showSuccessMsg = true;
    } else {
      this.showSuccessMsg = false;
    }
  }
  totalItemsInCart(selectedPrinter) {
    var length = 0;
    if (selectedPrinter != undefined || selectedPrinter != null) {
      for (var acc in selectedPrinter.accessoryList) {
        if (selectedPrinter.accessoryList[acc].selected) {
          length++;
        }
      }
      if (selectedPrinter.selectedCp && selectedPrinter.selectedCp != "0") {
        length++;
      }
    }
    return length;
  }

  handleRemove(item) {
    item.displayRemoveOption = !item.displayRemoveOption;
  }
  isUserSignedIn() {
    if (this.orderService.getCart().myCSAUserName || sessionStorage.userName) {
      return true;
    }
    return false;

  }
  onFocusQty(item) {
    this.placeholder = item.value.items[0].selectedQty;
    item.value.items[0].selectedQty = item.value.items[0].selectedQty;
	  }
  onChangeQty(value,item, items) {
	    item.selectedQty = value;
    this.updateItemInCart(item,items);
  }
  onBlurQty(item) {
	    if (item.value.items[0].selectedQty == "") {

      item.value.items[0].selectedQty = this.placeholder;
    } else {
      this.placeholder = item.value.items[0].selectedQty;
    }
  }
  updateItemInCart(supply, data) {
	    //// TODO: showPleaseWait();

    this.errMsg = '';
    this.successMsg = '';
    this.invalidQty = '';
    if (supply.selectedQty > 0) {
      var addedSuccessfully = this.orderService.updateItemInCart(supply, data);
      if (!addedSuccessfully) {
        if (supply.supplyCategory == '01') {
          this.errMsg = supply.bwMsg + " " + supply.dispQuantity;
        } else if (supply.supplyCategory == '02' || supply.supplyCategory == '03' || supply.supplyCategory == '04') {
          this.errMsg = supply.clrMsg + " " + supply.dispQuantity;
        } else {
          this.errMsg = "Quantity selected exceeds a one month supply, please change the total quantity to a maximum of " + supply.dispQuantity;
        }
        //// TODO: hidePleaseWait();
        //// TODO: $("#errorMsgModal").modal('show');
      } else {
        this.itemCd = supply.itemCd;
        this.serialNumber = data.serialNumber;
        this.successMsg = "Updated Quantity Successfully";
        if (supply.b_c_flag == 'B') {
          var tempSupply = this.orderService.getBillableSupply(supply.itemCd);
          var calculateBillSuppTaxPromise = this.orderService.calculateBillSuppTax(tempSupply);
          calculateBillSuppTaxPromise.subscribe((result: any) =>{
            supply = result;

            console.log('Calculated item : ', supply);
            for (var tempId in this.cart.billableSupplyMap) {
              if (tempId == data) {
                this.cart.billableSupplyMap[tempId] = supply;
                this.orderService.setCart(this.cart);
                this.calculateBillSuppShipCharge();
              }
            }
            //// TODO: hidePleaseWait();
          }, function (reason) {
            //// TODO: hidePleaseWait();
            console.log(reason);
          });
        } else {
          //// TODO: hidePleaseWait();
        }
      }
    }
    else {
      //// TODO: hidePleaseWait();
      //alert("Please select qty 1 or more to update");
    }



  }
  updateLFSPartItemInCart(supply, index, lfsType) {
    //// TODO: showPleaseWait();
    this.qtyErrMsg = '';
    this.successMsg = '';
    this.invalidQty = '';
    var serialNumber = '';
    var successFlag = '';
    if (supply.selectedQty < supply.minReqQuantity) {
      supply.qtyError = 'Y';
      this.qtyErrMsg = 'Please select minimum ' + supply.minReqQuantity;
      this.itemCd = supply.itemCd;
      //// TODO: hidePleaseWait();
      //alert("supply.qtyErrMsg:"+supply.qtyErrMsg);				
      return false;
    }
    //Check if the selected qty < max
    if (supply.selectedQty > supply.maxQuantity) {
      supply.qtyError = 'Y';
      this.qtyErrMsg = 'Please select maximum ' + supply.maxQuantity;
      this.itemCd = supply.itemCd;
      //// TODO: hidePleaseWait();
      //alert("supply.qtyErrMsg:"+supply.qtyErrMsg);				
      return false;
    }
    //Check if multiples is selectedF
    if (supply.selectedQty % supply.orderIncrements != 0) {
      supply.qtyError = 'Y';
      this.qtyErrMsg = 'Enter multiples of ' + supply.orderIncrements;
      this.itemCd = supply.itemCd;
      //// TODO: hidePleaseWait();
      //alert("supply.qtyErrMsg:"+supply.qtyErrMsg);				
      return false;
    }
    if (this.qtyErrMsg == '') {
      this.itemCd = supply.itemCd;
      if (lfsType == 'CPC') {
        if (this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items[0].itemCd == supply.itemCd) {
          this.cart.cpcSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty = supply.selectedQty;
          this.orderService.setCart(this.cart);
          successFlag = 'Y';
          this.getPriceOrGetPriceTaxFreight(this.cart.cpcSupplyMap, this.ADDR_TYPE_CPC, 'CPC', this.shipMethod);
        }
      }
      if (lfsType == 'LFSS') {
        if (this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].itemCd == supply.itemCd) {
          this.cart.lfsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty = supply.selectedQty;
          this.orderService.setCart(this.cart);
          successFlag = 'Y';
          this.getPriceOrGetPriceTaxFreight(this.cart.lfsSupplyMap, this.ADDR_TYPE_LFS, 'LFS', this.shipMethod);
        }
      }
      if (lfsType == 'EP') {
        if (this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items[0].itemCd == supply.itemCd) {
          this.cart.epSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty = supply.selectedQty;
          this.orderService.setCart(this.cart);
          successFlag = 'Y';
          this.getPriceOrGetPriceTaxFreight(this.cart.epSupplyMap, this.ADDR_TYPE_EP, 'EP', this.shipMethod);
        }
      }
      if (lfsType == 'AZCO') {
        if (this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items[0].itemCd == supply.itemCd) {
          this.cart.azcoSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty = supply.selectedQty;
          this.orderService.setCart(this.cart);
          successFlag = 'Y';
          this.getPriceOrGetPriceTaxFreight(this.cart.azcoSupplyMap, this.ADDR_TYPE_AZCO, 'AZCO', this.shipMethod);
        }
      }
      if (lfsType == 'PPS') {
        if (this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].itemCd == supply.itemCd) {
          this.cart.ppsSupplyMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty = supply.selectedQty;
          this.orderService.setCart(this.cart);
          successFlag = 'Y';
          this.getPriceOrGetPriceTaxFreight(this.cart.ppsSupplyMap, this.ADDR_TYPE_PPS, 'PPS', this.shipMethod);
        }
      }
      if (lfsType == 'PARTS') {
        if (this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items[0].itemCd == supply.itemCd) {
          this.cart.lfsPartsMap[supply.itemCd + "_" + serialNumber].items[0].selectedQty = supply.selectedQty;
          this.orderService.setCart(this.cart);
          successFlag = 'Y';
          this.getPriceOrGetPriceTaxFreight(this.cart.lfsPartsMap, this.ADDR_TYPE_LFP, 'LFSPARTS', this.shipMethod);
        }
      }
      if (successFlag == 'Y'){
        this.successMsg = "Updated Quantity Successfully";
        this.orderService.notifyOrderSummary();
      }
    }
  }
 

}
