import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Util } from '../../app.util';

const httpOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};



@Injectable({
  providedIn: 'root'
})

export class ReviewService {
  defaultUrlNew: string;

  constructor(private http: HttpClient, private util: Util) {
    this.defaultUrlNew = this.util.getBaseUrl();
  }
  getInitialData(data) {
    return this.http.post(`${this.defaultUrlNew}review.htm/gicrd`, data, httpOptions);
  }

  submitOrder(data) {
    return this.http.post(`${this.defaultUrlNew}review.htm/so`, data, httpOptions);
  }

  printOrder(data) {
    return this.http.post(`${this.defaultUrlNew}review.htm/printOCHtml1`, data,  {observe: 'response', responseType: 'blob'});

   // return this.http.post(`${this.defaultUrlNew}review.htm/printOCHtml1`, data, httpOptions);
  }
}
