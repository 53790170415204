import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterComponent } from '../register/register.component';
import { HttpClient } from '@angular/common/http';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { AdminMessageComponent } from './admin-message/admin-message-modal';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserIdleService } from 'angular-user-idle';
import { OrderService } from 'src/app/service/common-service/order.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @BlockUI() blockUI : NgBlockUI;
  username: string = "";
  password: string = "";
  errorMessage = 'Invalid Credentials';
  invalidLogin = false;
  showModal = false;
  messageColor: string = "";
  message: string = "";
  errorList: string = "";
  btnSubmitted = false;
  loggedUserName: string;
  isChangePasswordRequired: boolean;
  showLoader :boolean =false;
  @Input() src;
  modalRef: any;
  constructor(private router: Router,
    private loginAuthenticationService: LoginAuthenticationService,
    private titleService: Title,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal, 
    private http: HttpClient,
    private menuService: MenuService,
    private userIdle: UserIdleService,
    private orderService : OrderService
  ) { }

  ngOnInit() {
    this.titleService.setTitle("MyCanon Business Shop Login");
    this.loggedUserName = this.loginAuthenticationService.getAuthenticatedUser();
    this.isChangePasswordRequired = false;
    this.errorMessage = '';

    this.userIdle.onTimerStart().subscribe(
      count => {
        //console.log("Time Starts" + count);
        //this.showModal = true;
      }
    );

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(
      () => {
        //if(!this.sessionTimeoutActivate){
	        console.log('Time is up!');
	        //alert("Time is Up");
	        this.userIdle.stopWatching();
	        //this.showModal = false;
	        this.orderService.sendSessionTimeoutMessage('session timeout');
	        //this.sessionTimeoutActivate = true;
        //}
      });


    this.orderService.logoutObservable$.subscribe(
      () => {
        this.userIdle.stopWatching();
        this.blockUI.stop();
      }
    )
  }

  userLogin() {
    //this.showLoader =true;
    this.blockUI.start();    
    this.loginAuthenticationService.executeJDBCAuthenticationService(this.username.trim(), this.password.trim()).subscribe
      (data => {
        console.log(data)
        console.log("LoginComponent userlogin Authenticate:" + data.authenticated);

        if (data.authenticated == true) {

          if (data.passChangeReqFlag == "Y") {
            sessionStorage.setItem("route", data.route);
            this.blockUI.stop(); 
            this.closeLoginModal(this.loggedUserName);
            this.router.navigate(['resetPassword']);
          }else {
            this.loginAuthenticationService.twoFactorAuthentication() .subscribe(
              dataAuthCode => {   
                var tfaSuccess = dataAuthCode.tfaSuccess;
                this.blockUI.stop(); 
                if(tfaSuccess == 'N'){
                      localStorage.setItem("twoFactorAuthCode", dataAuthCode.twoFactorAuthCode);
                      localStorage.setItem("otpRequestTime",  dataAuthCode.otpRequestTime);
                      this.errorMessage = '';       
                      this.router.navigate(['twoFactorAuth']);
                      this.closeLoginModal(this.loggedUserName);        
                      localStorage.setItem("route", data.route);   
                }else{
                      this.menuService.updateMenu(this.username.trim()).subscribe(() => {          
                        this.userIdle.startWatching();
                        this.errorMessage = '';
                        this.invalidLogin = false;
                        this.closeLoginModal(this.loggedUserName);                       
                        this.router.navigate([data.route]);
                        this.orderService.sendAutoLoginMessage('autologin');
                        this.loginAuthenticationService.getAdminMssages().subscribe((messages: any) => {
                          if (messages && messages.length > 0) {
                            const modalRef = this.modalService.open(AdminMessageComponent, {size: 'lg'});
                            modalRef.componentInstance.messages = messages;
              
                          }
                        })
                      })
                }
              }
            );
          }

          
         
        }
        else {
          this.errorMessage = '';
          this.invalidLogin = true;
          this.errorMessage = "Invalid username and/or password";
          this.blockUI.stop();
          // console.log("Error message:"+data.errorMessage);
        }
      },
        error => {
          console.log(error);
          this.errorMessage = 'Invalid username and/or password';
          this.invalidLogin = true;
          this.blockUI.stop();
        }
      );
 
 
  }
  forgotPassword() {
    this.loginAuthenticationService.forgotPassword().subscribe
      (data => {
        /* var pgUrl = window.location.hostname;
        pgUrl = pgUrl.replace('shop', 'mycsa'); */
        var pgUrl = data.myCSAURL;
        window.open(pgUrl, '_blank');
      },
        error => {
          console.log(error);

        }
      );
  }
  openRequestAcc() {
    this.modalService.dismissAll(LoginComponent);
    this.modalService.open(RegisterComponent);
  }
  closeLoginModal(userName = '') {
    this.activeModal.close(userName);
  }


}

