import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserIdleService } from 'angular-user-idle';
import { LoginAuthenticationService } from './service/common-service/login-authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
 @BlockUI() blockUI: NgBlockUI;   
  title = 'canonEpartners';
  invalidLogin=false;
  showModal = false;
  authenticated = false;
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  loggedUserName='';
  userAuthToken: string ='';

  constructor(private router:Router,private loginAuthenticationService:LoginAuthenticationService,private userIdle: UserIdleService,private activatedRoute: ActivatedRoute) { }
  ngOnInit()
  {
 
  //this.blockUI.start("Please wait...");
      this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader =this.activatedRoute.firstChild.snapshot.data['showHeader'];
         this.showSidebar = this.activatedRoute.firstChild.snapshot.data['showSidebar'];
        this.showFooter = this.activatedRoute.firstChild.snapshot.data['showFooter'];
        //this.showHeader =true;
        //this.showFooter = true;
      }
    });
    setTimeout(() => {
      //this.blockUI.stop();
    }, 2500);
	  
   
	}
  restart() {
    console.log('Timer reset done.');
    //this.showModal = false;
    window.location.reload();
    this.userIdle.resetTimer();
    this.showModal = false;
  }
}

