import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shippingFilter'
})
export class ShippingFilterFilterPipe implements PipeTransform {

  transform(items: any[], keywords: any): any[] {

    if (!items || items.length < 1 || Object.keys(items).length < 1) return [];

    if (!keywords) return items;

    return items.filter(
      item => {
        let canReturn = true;
        for (const [key, value] of Object.entries(keywords)) {
          //if (!value) { continue; }
          //if (key === 'businessUnit') {
            if(item[key].indexOf(value) === -1) {
              canReturn = false;
            }
/*          } else {
            if (item[key] == value) {
              canReturn = true
            }
          }*/
        }
        return canReturn;
      }
    );
  }
}
