import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(items:any[],direction:string,column:string,type:string) : any[]{
    let sortedItems : any = [];
  
    if(direction==="asc" ){
      if(column === ''){
        sortedItems = this.sortAscending(items, type);
      }else{
        sortedItems = this.sortObjectArrayAscending(items, column, type);
      }
    }else{
      if(column === ''){
        sortedItems = this.sortDescending(items, type);
      }
      sortedItems = this.sortObjectArrayDescending(items,column,type);
    }
 
    return sortedItems;
  
  }
  sortAscending(items:any, type:string){

    return items.sort(function(a:any,b:any){
  
      if(type === 'string'){
        if (a < b){
          return -1;
        }else if  (a > b){
          return 1;
        }else{
          return 0;
        }
      }
      else{
        return a-b;
      }
    });
  }

  sortDescending(items:any,type:string){
    return items.sort(function(a:any,b:any){
    
      if(type==='string'){
        if (a > b){
          return -1;
        }else if  (a > b){
          return 1;
        }else{
          return 0;
        }
      }
      else{
        return b-a;
      }
  });
  }

  sortObjectArrayAscending(items:any,column:string,type:string){

    return items.sort(function(a:any,b:any){
  
      if(type === 'string'){
        if (a[column].toUpperCase() < b[column].toUpperCase()){
          return -1;
        }else if  (a[column].toUpperCase() > b[column].toUpperCase()){
          return 1;
        }else{
          return 0;
        }
      }
      else{
        return a[column]-b[column];
      }
    });
  }
  sortObjectArrayDescending(items:any,column:string,type:string){
    return items.sort(function(a:any,b:any){
    
      if(type==='string'){
        if (a[column].toUpperCase() > b[column].toUpperCase()){
          return -1;
        }else if  (a[column].toUpperCase() > b[column].toUpperCase()){
          return 1;
        }else{
          return 0;
        }
      }
      else{
        return b[column]-a[column];
      }
  });
  }

}
