import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';[]
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/service/common-service/order.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {

  showWaitDiv = false;
  orderId = 0;
  orderHistoryHeaderDetails = [];  
  orderHistoryInvoiceDetail ;
  orderHistoryOrderLineDetails = [];

  constructor(private route:ActivatedRoute, private orderService: OrderService) { }

  ngOnInit() {
    this.orderId = this.route.snapshot.queryParams["orderNumber"]; 
    console.log(this.orderId);
    this.getOrderDetails(this.orderId);
  }

  getOrderDetails(orderId) {

    var param = {
			"orderId" : orderId
    };

    this.showWaitDiv = true;
    this.orderService.getOrderDetailInfo(param).subscribe(data => {
      this.showWaitDiv = false;
      if (data != null) {
        console.log(data);
        var response = data;
				this.orderHistoryHeaderDetails = response.orderHeaderDetails;
				this.orderHistoryInvoiceDetail = response.invoiceDetail;
				this.orderHistoryOrderLineDetails = response.orderLineDetails;

        //console.log('orderDetails', this.orderHistoryHeaderDetails);
      }
    });

  }

}
