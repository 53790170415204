import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

import { URLSearchParams } from 'url';
//import { BaseService } from '../base-service';
import { LocalStorageService } from '../service/common-service/local-storage.service';


@Injectable({
  providedIn: 'root'
})



export class SupplyService{

  httpOptions  = {
    headers: new HttpHeaders({      
    }).set('Content-Type', 'application/json')
  }; 

  baseUrl: string;

  constructor(private http:HttpClient, private util:Util, private localStorageService : LocalStorageService) {
    this.baseUrl = this.util.getBaseUrl();
  }

  getSerialNumberList() : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/mlistcnt';
    let param = {"startRange":1, "endRange" : 3000, "dealerFlag" : 'Y'};
    return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getSerialNumberList")));
  }

  getTonerMediaTypes(param) : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/gtmt';
    return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getTonerMediaTypes")));
  }

  getStapleTypes() : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/gst';
    return this.http.post(url, '', this.httpOptions).pipe(catchError(this.util.handleError("getStapleTypes")));
  }

  getLFSProductGroupSubgroups(param) : Observable<any>{
     let url = this.baseUrl + 'supplies.htm/lfspgsg';
     return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getLFSProductGroupSubgroups")));
    
  }

  getLFSubgroups(param) : Observable<any>{
     let url = this.baseUrl + 'supplies.htm/lfssg';
     return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getLFSubgroups")));
  }

  getSBModels(param) : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/gsbm';
    return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getSBModels")));
 }

  getPPSProductGroupSubgroups(param) : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/ppspgsg';
    return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getPPSProductGroupSubgroups")));
  }

  getModelsList(param) : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/gml';
    return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getModelsList")));
  }

  getSupplyList(param) : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/gsl';
    return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getSupplyList")));
  }

  getMultipleSupplyPrice(param) : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/gmsp';
    return this.http.post(url, JSON.stringify(param), this.httpOptions).pipe(catchError(this.util.handleError("getMultipleSupplyPrice")));
  }

  getCompatibleModels(param) : Observable<any>{
    let url = this.baseUrl + 'supplies.htm/gcm';
    return this.http.post(url, param, this.httpOptions).pipe(catchError(this.util.handleError("getCompatibleModels")));
  }

  /* updateFavoriteItem = function(param){
    let url = this.baseUrl + 'supplies.htm/supplies/ufi';
    let t = new URLSearchParams();
    t.set("aa", "11");
    return this.http.post(url, t.toString(), this.httpOptions).pipe(catchError(this.util.handleError("updateFavoriteItem")));
  } */

  updateFavoriteItem(param):Observable<any>{
    let initialUrl = this.baseUrl+"supplies.htm/supplies/ufis";
   return this.http
   .post(initialUrl,JSON.stringify(param),this.httpOptions)
   .pipe(catchError(this.util.handleError("updateFavoriteItem")))
  }

}
