import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { SearchFilterPipe } from 'src/app/pipe/search-filter.pipe';

@Component({
  selector: 'app-supplieslandingpage',
  templateUrl: './supplieslandingpage.component.html',
  styleUrls: ['./supplieslandingpage.component.css']
})
export class SupplieslandingpageComponent implements OnInit {
  selectedSupplyCategory: any;
  breadcrumbList: any;
  selectedParentId: any;

  search: string;
  searchFilterPropertyNames : string[] = ['id'];
  sub: any;
  subList: any;
  category = "SUPPLIES";
	clickedIdx: any=[];
	clickedIdx1: any=[];
	menuItems: any =[];
	
  

  constructor( private menuService: MenuService,private searchPipe : SearchFilterPipe,private route: ActivatedRoute,private service: MenuService, private router : Router,) { 
	// override the route reuse strategy
    /* this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    }; */
  }

  ngOnInit( ) {

    this.sub = this.route.queryParams.subscribe(params => {            
      this.category =  params['category'];  
	  this.getMenu();     
	});
  }
	

  
  megaMenu;
  megaMenu1;

	getMenu(){
		let menuItems = this.menuService.getMenuItems();
		this.megaMenu = menuItems.menu || [];
		this.loadInitialData();
		this.service.menuResponse.subscribe((results) => {
		if(results){
			this.megaMenu = results.menu;
		  console.log(this.menuItems.length);
		  this.loadInitialData();
		}
		
	  });
	}
	
	megaMenuFiltered(megaMenu,x){
    return megaMenu.filter(mm=>mm.parentId===x.id);
  }

  count=0;
  loadInitialData() {
	// this.megaMenu= [
	// 	{
	// 		"image": "fa-print",
	// 		"linkFlag": "Y",
	// 		"linkPage": "product.htm?view=landingPage",
	// 		"esslfsFlag": "LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": true,
	// 		"displayFlag": "Y",
	// 		"description": "Equipment",
	// 		"htmlData": "",
	// 		"landingPageFlag": true,
	// 		"breadcrumbList": [],
	// 		"parentId": -1,
	// 		"levelNum": 1,
	// 		"htmlFlag": "",
	// 		"sortOrder": 1,
	// 		"menuFilterFlag": "",
	// 		"name": "EQUIPMENT",
	// 		"id": 10,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:23"
	// 	},
	// 	{
	// 		"image": "fa-cubes",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=landingPage",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": true,
	// 		"displayFlag": "Y",
	// 		"description": "Supplies",
	// 		"htmlData": "",
	// 		"landingPageFlag": true,
	// 		"breadcrumbList": [],
	// 		"parentId": -1,
	// 		"levelNum": 1,
	// 		"htmlFlag": "",
	// 		"sortOrder": 2,
	// 		"menuFilterFlag": "",
	// 		"name": "SUPPLIES",
	// 		"id": 20,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:24"
	// 	},
	// 	{
	// 		"image": "fa-boxes",
	// 		"linkFlag": "Y",
	// 		"linkPage": "parts.htm?view=parts",
	// 		"esslfsFlag": "PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Large Format Parts",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [],
	// 		"parentId": -1,
	// 		"levelNum": 1,
	// 		"htmlFlag": "",
	// 		"sortOrder": 3,
	// 		"menuFilterFlag": "",
	// 		"name": "LARGE_FORMAT_PARTS",
	// 		"id": 30,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:25"
	// 	},
	// 	{
	// 		"image": "fa-heart",
	// 		"linkFlag": "Y",
	// 		"linkPage": "search.htm?view=search",
	// 		"esslfsFlag": "LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Favorites",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [],
	// 		"parentId": -1,
	// 		"levelNum": 1,
	// 		"htmlFlag": "",
	// 		"sortOrder": 4,
	// 		"menuFilterFlag": "",
	// 		"name": "FAVORITES",
	// 		"id": 40,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:26"
	// 	},
	// 	{
	// 		"image": "fa-receipt",
	// 		"linkFlag": "Y",
	// 		"linkPage": "home.htm?view=orderHistory",
	// 		"esslfsFlag": "LFS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Order History",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [],
	// 		"parentId": -1,
	// 		"levelNum": 1,
	// 		"htmlFlag": "",
	// 		"sortOrder": 5,
	// 		"menuFilterFlag": "",
	// 		"name": "ORDER_HISTORY",
	// 		"id": 50,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:27"
	// 	},
	// 	{
	// 		"image": "",
	// 		"linkFlag": "N",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": true,
	// 		"displayFlag": "Y",
	// 		"description": "My Favorites",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			}
	// 		],
	// 		"parentId": 20,
	// 		"levelNum": 2,
	// 		"htmlFlag": "",
	// 		"sortOrder": 1,
	// 		"menuFilterFlag": "",
	// 		"name": "MY_FAVORITES",
	// 		"id": 200,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:33"
	// 	},
	// 	{
	// 		"image": "",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": true,
	// 		"displayFlag": "Y",
	// 		"description": "Office Product & Systems",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			}
	// 		],
	// 		"parentId": 20,
	// 		"levelNum": 2,
	// 		"htmlFlag": "",
	// 		"sortOrder": 2,
	// 		"menuFilterFlag": "",
	// 		"name": "OFFICE_PRODUCT_SYSTEMS",
	// 		"id": 300,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:34"
	// 	},
	// 	{
	// 		"image": "",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": true,
	// 		"displayFlag": "Y",
	// 		"description": "Large Format Supplies",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			}
	// 		],
	// 		"parentId": 20,
	// 		"levelNum": 2,
	// 		"htmlFlag": "",
	// 		"sortOrder": 3,
	// 		"menuFilterFlag": "",
	// 		"name": "LARGE_FORMAT_SUPPLIES",
	// 		"id": 400,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:35"
	// 	},
	// 	{
	// 		"image": "images/supplies/favorite.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Favorite Products",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "MY_FAVORITES",
	// 				"link": "supplies.htm?view=supplies&category=MY_FAVORITES",
	// 				"description": "My Favorites"
	// 			}
	// 		],
	// 		"parentId": 200,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 1,
	// 		"menuFilterFlag": "",
	// 		"name": "FAVORITE_PRODUCTS",
	// 		"id": 2001,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:54"
	// 	},
	// 	{
	// 		"image": "images/supplies/staples.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Staples",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "OFFICE_PRODUCT_SYSTEMS",
	// 				"link": "supplies.htm?view=supplies&category=OFFICE_PRODUCT_SYSTEMS",
	// 				"description": "Office Product & Systems"
	// 			}
	// 		],
	// 		"parentId": 300,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 2,
	// 		"menuFilterFlag": "",
	// 		"name": "STAPLES",
	// 		"id": 3002,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:59"
	// 	},
	// 	{
	// 		"image": "images/supplies/bw-printer-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Black & White Copier Supplies",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "OFFICE_PRODUCT_SYSTEMS",
	// 				"link": "supplies.htm?view=supplies&category=OFFICE_PRODUCT_SYSTEMS",
	// 				"description": "Office Product & Systems"
	// 			}
	// 		],
	// 		"parentId": 300,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 3,
	// 		"menuFilterFlag": "",
	// 		"name": "BLACK_WHITE_PRINTER_SUPPLIES",
	// 		"id": 3003,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:60"
	// 	},
	// 	{
	// 		"image": "images/supplies/color-printer-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Color Copier Supplies",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "OFFICE_PRODUCT_SYSTEMS",
	// 				"link": "supplies.htm?view=supplies&category=OFFICE_PRODUCT_SYSTEMS",
	// 				"description": "Office Product & Systems"
	// 			}
	// 		],
	// 		"parentId": 300,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 4,
	// 		"menuFilterFlag": "",
	// 		"name": "COLOR_PRINTER_SUPPLIES",
	// 		"id": 3004,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:61"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "imageCLASS Supplies",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "OFFICE_PRODUCT_SYSTEMS",
	// 				"link": "supplies.htm?view=supplies&category=OFFICE_PRODUCT_SYSTEMS",
	// 				"description": "Office Product & Systems"
	// 			}
	// 		],
	// 		"parentId": 300,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 5,
	// 		"menuFilterFlag": "",
	// 		"name": "IMAGE_CLASS_SUPPLIES",
	// 		"id": 3005,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:62"
	// 	},
	// 	{
	// 		"image": "images/supplies/fax.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Fax",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "OFFICE_PRODUCT_SYSTEMS",
	// 				"link": "supplies.htm?view=supplies&category=OFFICE_PRODUCT_SYSTEMS",
	// 				"description": "Office Product & Systems"
	// 			}
	// 		],
	// 		"parentId": 300,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 6,
	// 		"menuFilterFlag": "",
	// 		"name": "FAX",
	// 		"id": 3006,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:63"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Hewlett Packard Toner & Inkjet Cartridges",
	// 		"htmlData": "",
	// 		"excludeCompModels": "Y",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "OFFICE_PRODUCT_SYSTEMS",
	// 				"link": "supplies.htm?view=supplies&category=OFFICE_PRODUCT_SYSTEMS",
	// 				"description": "Office Product & Systems"
	// 			}
	// 		],
	// 		"parentId": 300,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 7,
	// 		"menuFilterFlag": "",
	// 		"name": "HEWLETT_PACKARD_INK",
	// 		"id": 3007,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:64"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "GUEST,LFS,ESS,PPS,PARTS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Business Paper",
	// 		"htmlData": "",
	// 		"excludeCompModels": "Y",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "OFFICE_PRODUCT_SYSTEMS",
	// 				"link": "supplies.htm?view=supplies&category=OFFICE_PRODUCT_SYSTEMS",
	// 				"description": "Office Product & Systems"
	// 			}
	// 		],
	// 		"parentId": 300,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 8,
	// 		"menuFilterFlag": "",
	// 		"name": "OFFICE_MEDIA",
	// 		"id": 3008,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:65"
	// 	},
	// 	{
	// 		"image": "images/supplies/shop-by-application.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=landingPage",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": true,
	// 		"displayFlag": "Y",
	// 		"description": "Shop By Application",
	// 		"htmlData": "",
	// 		"landingPageFlag": true,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			}
	// 		],
	// 		"parentId": 400,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 3,
	// 		"menuFilterFlag": "",
	// 		"name": "SHOP_BY_APPLICATION",
	// 		"id": 4003,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:87"
	// 	},
	// 	{
	// 		"image": "images/supplies/shop-by-model.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=landingPage",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": true,
	// 		"displayFlag": "Y",
	// 		"description": "Shop By Model",
	// 		"htmlData": "",
	// 		"landingPageFlag": true,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			}
	// 		],
	// 		"parentId": 400,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 4,
	// 		"menuFilterFlag": "",
	// 		"name": "SHOP_BY_MODEL",
	// 		"id": 4004,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:88"
	// 	},
	// 	{
	// 		"image": "images/supplies/private-label.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Private Label",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			}
	// 		],
	// 		"parentId": 400,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 5,
	// 		"menuFilterFlag": "",
	// 		"name": "PRIVATE_LABEL",
	// 		"id": 4005,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:89"
	// 	},
	// 	{
	// 		"image": "images/supplies/easy-pac.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Easy Pac",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			}
	// 		],
	// 		"parentId": 400,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 6,
	// 		"menuFilterFlag": "",
	// 		"name": "EASY_PAC",
	// 		"id": 4006,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:90"
	// 	},
	// 	{
	// 		"image": "images/supplies/cost-per-copy.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Cost Per Copy",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			}
	// 		],
	// 		"parentId": 400,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 7,
	// 		"menuFilterFlag": "",
	// 		"name": "COST_PER_COPY",
	// 		"id": 4007,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:91"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Arizona/Colorado Inks",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			}
	// 		],
	// 		"parentId": 400,
	// 		"levelNum": 3,
	// 		"htmlFlag": "",
	// 		"sortOrder": 8,
	// 		"menuFilterFlag": "",
	// 		"name": "ARIZONA_INK",
	// 		"id": 4008,
	// 		"autoExpandFlag": "",
	// 		"status": "A",
	// 		"$$hashKey": "object:92"
	// 	},
	// 	{
	// 		"image": "images/supplies/engineering-bond-paper-film-and-vellum.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Engineering Bond Paper, Film & Vellum",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_APPLICATION",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_APPLICATION",
	// 				"description": "Shop By Application"
	// 			}
	// 		],
	// 		"parentId": 4003,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 1,
	// 		"menuFilterFlag": "",
	// 		"name": "PP",
	// 		"id": 40031,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/engineering-toner-toner-pearls.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Engineering Toner & Toner Pearls",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_APPLICATION",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_APPLICATION",
	// 				"description": "Shop By Application"
	// 			}
	// 		],
	// 		"parentId": 4003,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 2,
	// 		"menuFilterFlag": "",
	// 		"name": "TS",
	// 		"id": 40032,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/cad-ink-jet-media.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Cad Ink Jet Media",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_APPLICATION",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_APPLICATION",
	// 				"description": "Shop By Application"
	// 			}
	// 		],
	// 		"parentId": 4003,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 3,
	// 		"menuFilterFlag": "",
	// 		"name": "IJ",
	// 		"id": 40033,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/cad-ink-and-accessories.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Cad Ink & Accessories",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_APPLICATION",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_APPLICATION",
	// 				"description": "Shop By Application"
	// 			}
	// 		],
	// 		"parentId": 4003,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 4,
	// 		"menuFilterFlag": "",
	// 		"name": "CS",
	// 		"id": 40034,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/display-graphic-media.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Display Graphic Media",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_APPLICATION",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_APPLICATION",
	// 				"description": "Shop By Application"
	// 			}
	// 		],
	// 		"parentId": 4003,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 5,
	// 		"menuFilterFlag": "",
	// 		"name": "DG",
	// 		"id": 40035,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/display-graphics-inks-and-accessories.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Display Graphics Ink & Accessories",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_APPLICATION",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_APPLICATION",
	// 				"description": "Shop By Application"
	// 			}
	// 		],
	// 		"parentId": 4003,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 6,
	// 		"menuFilterFlag": "",
	// 		"name": "GR",
	// 		"id": 40036,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Arizona",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_MODEL",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_MODEL",
	// 				"description": "Shop By Model"
	// 			}
	// 		],
	// 		"parentId": 4004,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 1,
	// 		"menuFilterFlag": "",
	// 		"name": "ARIZONA",
	// 		"id": 40041,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "Colorado",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_MODEL",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_MODEL",
	// 				"description": "Shop By Model"
	// 			}
	// 		],
	// 		"parentId": 4004,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 2,
	// 		"menuFilterFlag": "",
	// 		"name": "COLORODO",
	// 		"id": 40042,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "ColorWave",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_MODEL",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_MODEL",
	// 				"description": "Shop By Model"
	// 			}
	// 		],
	// 		"parentId": 4004,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 3,
	// 		"menuFilterFlag": "",
	// 		"name": "COLORWAVE",
	// 		"id": 40043,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	},
	// 	{
	// 		"image": "images/supplies/contract-supplies.jpg",
	// 		"linkFlag": "Y",
	// 		"linkPage": "supplies.htm?view=supplies",
	// 		"esslfsFlag": "LFS",
	// 		"subMenuPresent": false,
	// 		"displayFlag": "Y",
	// 		"description": "PlotWave",
	// 		"htmlData": "",
	// 		"landingPageFlag": false,
	// 		"breadcrumbList": [
	// 			{
	// 				"name": "SUPPLIES",
	// 				"link": "supplies.htm?view=landingPage&category=SUPPLIES",
	// 				"description": "Supplies"
	// 			},
	// 			{
	// 				"name": "LARGE_FORMAT_SUPPLIES",
	// 				"link": "supplies.htm?view=supplies&category=LARGE_FORMAT_SUPPLIES",
	// 				"description": "Large Format Supplies"
	// 			},
	// 			{
	// 				"name": "SHOP_BY_MODEL",
	// 				"link": "supplies.htm?view=landingPage&category=SHOP_BY_MODEL",
	// 				"description": "Shop By Model"
	// 			}
	// 		],
	// 		"parentId": 4004,
	// 		"levelNum": 4,
	// 		"htmlFlag": "",
	// 		"sortOrder": 4,
	// 		"menuFilterFlag": "",
	// 		"name": "PLOTWAVE_TDS",
	// 		"id": 40044,
	// 		"autoExpandFlag": "",
	// 		"status": "A"
	// 	}
	// ];
	            var menuList = this.megaMenu.filter(mm=>mm.name===this.category);
	           this.count++;
	            if(menuList.length > 0 ){
                  this.selectedParentId = menuList[0].id;
                  
                  this.megaMenu1=this.megaMenu.filter(mm=>mm.parentId===this.selectedParentId);
	                this.selectedSupplyCategory = menuList[0] || {};
	                this.breadcrumbList = this.selectedSupplyCategory.breadcrumbList;
	            }
	 	
	 }
	 
		getSelectedParentId(){
 			return this.selectedParentId;
 		}

	RemoveElementFromArray(element: number) {
		this.clickedIdx.forEach((value,index)=>{
			if(value==element) this.clickedIdx.splice(index,1);
		});
	}

	onClick(selIdx){   
		if(!this.clickedIdx.includes(selIdx)){
			this.clickedIdx.push(selIdx); 
			var idx = this.clickedIdx.indexOf(selIdx);  
				
		}else{			
			this.RemoveElementFromArray(selIdx);
			var idx = this.clickedIdx.indexOf(selIdx);
		}  
		 
      }

	  onClick2(selIdx){   
		if(!this.clickedIdx1.includes(selIdx)){
			this.clickedIdx1.push(selIdx); 
			var idx = this.clickedIdx1.indexOf(selIdx);  
				
		}else{			
			this.RemoveElementFromArray(selIdx);
			var idx = this.clickedIdx1.indexOf(selIdx);
		}  
		
		 
      }
    
   
     
    
    subCategoryLandingPage=true;
 		isSubCategoryLandingPage() {
 			var subCategoryLandingPage = false;
 			for(let i in this.megaMenu){
 				if(this.selectedParentId == this.megaMenu[i].id){
 					if(this.megaMenu[i].parentId == -1){
 						subCategoryLandingPage = false;
 						break;
 					}
 					if(this.megaMenu[i].landingPageFlag && this.megaMenu[i].parentId != -1){
 						subCategoryLandingPage = true;
 						break;
 					}
 				}
 			}
      var mm = this.megaMenu1;
 			return subCategoryLandingPage;
 		}
	
	selectBreadCrumb(breadcrumb:any){

		//If we use the below commented code the URL is not updated and if we navigate back to the same URL
		//the component is not reloaded
		/* if(breadcrumb.route == 'supplieslanding'){
			this.category =  breadcrumb.name;  
	  		this.getMenu();
		}else{
			this.router.navigate([breadcrumb.route], {queryParams : {category:breadcrumb.name}})
		} */

		this.router.navigate([breadcrumb.route], {queryParams : {category:breadcrumb.name}})

}	

}
