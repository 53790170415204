import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AccountService } from 'src/app/account/account.service';
import { OrderService } from 'src/app/service/common-service/order.service';
import { ContinueReorderModalComponent } from '../order-history/continue-reorder-modal/continue-reorder-modal.component';
import { CommonUtilsService } from 'src/app/service/common-service/common-utils';
import { SupplyService } from 'src/app/supplies/supply.service';
import { LargeformatpartsService } from 'src/app/largeformatparts/largeformatparts.service';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-reorder',
  templateUrl: './reorder.component.html',
  styleUrls: ['./reorder.component.css']
})
export class ReorderComponent implements OnInit {

  @ViewChild('btnErrorMsgModal') btnErrorMsgModal: ElementRef;
  errDlgDisplay = false;

  @Input() orderId;
  @Input() isBillingAddress = false;

  @BlockUI() blockUI : NgBlockUI;
  reorderNumber : string = "";
  accMsgCls = "";
  cart = this.orderService.getCart();
  isEmptyAcctInfo = false;
  reorderErrorMessage = "";
  isEmptyCpcSupplyMap = false;
  isEmptyEpSupplyMap = false;
  isEmptyAzcoSupplyMap = false;
  isEmptyLfsSupplyMap = false;
  isEmptyPpsSupplyMap = false;
  isEmptyLfsPartsSupplyMap = false;
  isEmptyContractSupplyMap = false;
  isEmptyBillableSupplyMap = false;
  isEmptyPreOwnedMachine = false;  
  bContinueReorder = false;
  processingRequest = '';
  reorderItemCnt = 0;
  reorderType = '';
  priceErrMsg = '';
  constructor(private orderService: OrderService,  private accountService: AccountService,
    private modalService: NgbModal, private commonService: CommonUtilsService,
    private supplyService: SupplyService, private partsService: LargeformatpartsService,
    private router: Router) { }

  ngOnInit() {
  }

  reorder(){

    this.getReorderDetails(this.orderId, true);

  }

  closeErrDialog() {
    console.log(this.orderId);
	    this.errDlgDisplay = false;
  }

  closePriErrDialog() {
    this.processingRequest = '';
  }

  getReorderDetails(ordNum, bSetAccInfo){
    this.blockUI.start();
    this.reorderNumber = ordNum;
    this.accMsgCls = '';
    this.cart = this.orderService.getCart();
    if (this.isEmptyAcctInfo) {
      this.accountService.getDefaultAccount().subscribe(
        (result) => {
          const defaultAddressesAndRoles = result.accountAddressDetail || {};
          this.accountService.setAccountInfo(defaultAddressesAndRoles);
          setTimeout(() =>{
            this.isEmptyAcctInfo = false;
            this.getReorderDetails(this.reorderNumber, true);
          }, 500);
        },
        (reason) => {
          console.log(reason);
        });
      return false;
    }

    this.reorderErrorMessage = "";
    if (!this.isEmptyCpcSupplyMap || !this.isEmptyEpSupplyMap || !this.isEmptyAzcoSupplyMap || !this.isEmptyLfsSupplyMap
      || !this.isEmptyPpsSupplyMap || !this.isEmptyLfsPartsSupplyMap || !this.isEmptyContractSupplyMap
      || !this.isEmptyBillableSupplyMap || !this.isEmptyPreOwnedMachine) {
        
      if (!this.bContinueReorder) {
        this.blockUI.stop();
        this.showContinueReorderModal();
        return false;
      }
    }

    if (bSetAccInfo) {
      this.accMsgCls = 'accMsgCls';
      this.orderService.getReorderAccountInfo(this.reorderNumber).subscribe(
        (results: any) => {
          const accountDetails = results || {};
          // for test
          //var accountDetails;
          ////
          if (this.commonService.isEmptyObj(accountDetails)) {
            this.reorderErrorMessage = "Error while getting Reorder Details\n";
            this.errDlgDisplay = true;
            //this.btnErrorMsgModal.nativeElement.click();
            let elmBtnErrorMsgModal:HTMLElement = document.getElementById('btnErrorMsgModal'+this.orderId) as HTMLElement;
            elmBtnErrorMsgModal.setAttribute("data-target", "#errorMsgModal" + this.orderId );
            elmBtnErrorMsgModal.click();
			
            return false;
          } else {
            let sMsg = "";
            let bShowModal = false;
            if (accountDetails.defaultBillTo.activeFlag != "Y") {
              sMsg += "Bill To information not available\n";
              bShowModal = true;
            }
            if (accountDetails.defaultShipTo.activeFlag != "Y") {
              sMsg += "Ship To information not available\n";
              bShowModal = true;
            }
            if (accountDetails.defaultSoldTo.activeFlag != "Y") {
              sMsg += "Sold To information not available\n";
              bShowModal = true;
            }
            // for test 
            //sMsg = "Test error msg";  
            //bShowModal = true;
            /////
            if (bShowModal) {
              this.reorderErrorMessage = sMsg;
              ////TODO: $("#errorMsgModal").modal("show");
              let elmBtnErrorMsgModal:HTMLElement = document.getElementById('btnErrorMsgModal'+this.orderId) as HTMLElement;
              elmBtnErrorMsgModal.setAttribute("data-target", "#errorMsgModal" + this.orderId );
              elmBtnErrorMsgModal.click();
  
              return false;
            }
          }
          this.cart["accountInfo"] = accountDetails;
          this.cart.billingAddress = accountDetails.defaultBillTo;
          this.cart.billableSupplyMap = {};
          this.cart.productMap = {};
          this.cart.contractSupplyMap = {};
          this.cart.cpcSupplyMap = {};
          this.cart.epSupplyMap = {};
          this.cart.azcoSupplyMap = {};
          this.cart.lfsSupplyMap = {};
          this.cart.lfsPartsMap = {};
          this.cart.ppsSupplyMap = {};
          this.orderService.setCart(this.cart);
          this.getReorderDetails(this.reorderNumber, false);
        },
        (reason) => {
          console.log(reason);
          ////TODO: hidePleaseWait();
        }
      );
    } else {
      if (ordNum) {
        this.processingRequest = 'processingRequest';
      }
      this.orderService.getReorderDetails(this.reorderNumber).subscribe(
        (results: any) => {
          const objReorder = results || {};
          // for test 
          // var objReorder;
          /////
          if (this.commonService.isEmptyObj(objReorder)) {
            this.processingRequest = '';
            this.reorderErrorMessage = "All Items are not available in this Order.\n";
            ////TODO: $("#errorMsgModal").modal("show");
            let elmBtnErrorMsgModal:HTMLElement = document.getElementById('btnErrorMsgModal'+this.orderId) as HTMLElement;
            elmBtnErrorMsgModal.setAttribute("data-target", "#errorMsgModal" + this.orderId );
            elmBtnErrorMsgModal.click();

            return false;
          }
          for (var ordTp in objReorder) {
            // for test
            // ordTp = 'LFSPARTS';
            /////

            if (ordTp == 'LFS' || ordTp == 'EP' || ordTp == 'PPS' || ordTp == 'CPC' || ordTp == 'AZCO') {
              const roObjArr = objReorder[ordTp];
              this.reorderItemCnt = roObjArr.length;
              this.reorderType = ordTp;
              let cartSupplyList = [];
              for (const i in roObjArr) {
                const roObj = roObjArr[i];
                let cartSupply = roObj["cartSupply"];
                cartSupply.items[0].orderType = ordTp;
                cartSupplyList[i] = cartSupply;
              }

              let shippingOption = {} as any;
              if (ordTp == 'LFS') {
                shippingOption.lfsSupplyShippingOptions = results.shippingOptions;
              } else if (ordTp == 'EP') {
                shippingOption.epSupplyShippingOptions = results.shippingOptions;
              } else if (ordTp == 'AZCO') {
                shippingOption.azcoSupplyShippingOptions = results.shippingOptions;
              } else if (ordTp == 'PPS') {
                shippingOption.ppsSupplyShippingOptions = results.shippingOptions;
              } else if (ordTp == 'CPC') {
                shippingOption.cpcSupplyShippingOptions = results.shippingOptions;
              }

              sessionStorage.setItem('ShippingOptions', JSON.stringify(shippingOption));
              this.getMultiplePriceOrGetMultiplePriceAndAddSupply(cartSupplyList, ordTp);

            } else if (ordTp == 'LFSPARTS') {
              if (!this.cart.lfsPartsMap) {
                this.cart["lfsPartsMap"] = {};
              }
              const roObjArr = objReorder[ordTp];
              this.reorderItemCnt = roObjArr.length;
              this.reorderType = ordTp;

              let cartPartList = [] as any;
              for (const i in roObjArr) {
                const roObj = roObjArr[i];
                const objPart = roObj["part"];
                objPart.items[0].orderType = ordTp;
                cartPartList[i] = objPart;
              }

              let shippingOption = {} as any;
              shippingOption.lfsPartsShippingOptions = results.shippingOptions;
              sessionStorage.setItem('ShippingOptions', JSON.stringify(shippingOption));
              this.getMultiplePriceOrGetPriceAndAddPart(cartPartList);
            }
          }
        },
        (reason) => {
          console.log(reason);
          this.processingRequest = '';
        }
      );
    }
    this.blockUI.stop();
  }

  showContinueReorderModal(){
    const continueReorderModalRef = this.modalService.open(ContinueReorderModalComponent);
    continueReorderModalRef.result.then(val => {
     if (val){
      this.bContinueReorder=true;
      this.getReorderDetails(this.reorderNumber,true);
     }
    })
  }

  getMultiplePriceOrGetMultiplePriceAndAddSupply(supplyList, orderType){
    this.priceErrMsg = '';
    this.processingRequest = 'processingRequest';
    this.cart = this.orderService.getCart();
    let items = [] as any;
    for (let i = 0; i < supplyList.length; i++) {
      items[i] = supplyList[i].items[0];
    }
    const param = {
      "supply": JSON.stringify(items),
      "shipType": "STD",
      "cart": JSON.stringify(this.cart)
    }
    this.supplyService.getMultipleSupplyPrice(param).subscribe(
      (results) => {
        const priceDetails = results || {};
        const priceSelectedSupplies = priceDetails.selectedItems || [];
        const lfsItems = priceDetails.lfsErrorItems;
        const ppsItems = priceDetails.ppsErrorItems;
        if (lfsItems && lfsItems.length > 0) {
          this.priceErrMsg = 'Please call 1-800-323-4827 option 2 for assistance for item(s) <br>' + lfsItems.join("<br>");
        }
        if (ppsItems && ppsItems.length > 0) {
          if (lfsItems && lfsItems.length > 0) {
            this.priceErrMsg += "<br><br>";
          }
          this.priceErrMsg += 'Please call 1-866-472-7876 for assistance for item(s) <br>' + ppsItems.join("<br>");
        }

        // for test
        // this.priceErrMsg = 'Test price error';
        /////
        if (this.priceErrMsg != '') {
          //Restore the default account info
          this.accountService.getDefaultAccount().subscribe(
            (result) => {
              const defaultAddressesAndRoles = result.accountAddressDetail|| {};
              this.accountService.setAccountInfo(defaultAddressesAndRoles);
              
              ////TODO:$("#pricingErrorMsgModal").modal('show');
              let elmBtnErrorMsgModal:HTMLElement = document.getElementById('btnPriErrorMsgModal'+this.orderId) as HTMLElement;
              elmBtnErrorMsgModal.setAttribute("data-target", "#pricingErrorMsgModal" + this.orderId );
              elmBtnErrorMsgModal.click();
              
              ////TODO:hidePleaseWait();
            },
            (reason) => {
              console.log(reason);
            }
          );
        } else {
          for (let i = 0; i < supplyList.length; i++) {
            const supply = supplyList[i];
            for (let j = 0; j < priceSelectedSupplies.length; j++) {
              const supplyPrice = priceSelectedSupplies[j];
              if (supply.items[0].itemCd == supplyPrice.itemCd) {
                console.log('supply.itemCd:' + supply.itemCd + ' price:' + supplyPrice.price);
                supply.items[0].price = supplyPrice.price;
              }
            }
          }
          let bRedirect = false;
          for (let i = 0; i < supplyList.length; i++) {
            const currSupply = supplyList[i];
            if (i == (supplyList.length - 1)) {
              bRedirect = true;
            }
            if (currSupply.price != -1) {
              this.addSupplyToCart(currSupply.items[0], currSupply, orderType, bRedirect);
            }
          }

        }
      },
      (reason) => {
        console.log(reason);
        this.processingRequest = '';
        ////TODO: hidePleaseWait();
      }
    );
  }

  getMultiplePriceOrGetPriceAndAddPart(partList){
    this.priceErrMsg = '';
    this.processingRequest = 'processingRequest';
    let addToCartPartList = [] as any;
    this.cart = this.orderService.getCart();
    let items = [] as any;
    for (let i = 0; i < partList.length; i++) {
      items[i] = partList[i].items[0];
    }
    const param = {
      "part": JSON.stringify(items),
      "shipType": "STD",
      "cart": JSON.stringify(this.cart)
    }
    this.partsService.getMultiplePartPrice(param).subscribe(
      (results) => {
        ////TODO: hidePleaseWait();
        this.processingRequest = '';
        const priceDetails = results || {};
        const priceSelectedParts = priceDetails.selectedItems;
        const allPartList = priceDetails.partList;
        const errorPartList = priceDetails.partsErrorItems;
        if (errorPartList.length > 0) {
          //Restore the default account info
          this.accountService.getDefaultAccount().subscribe(
            (result) => {
              const defaultAddressesAndRoles = result.accountAddressDetail || {};
              this.accountService.setAccountInfo(defaultAddressesAndRoles);
              this.priceErrMsg = 'Sorry could not complete that action. Please call 1-866-472-7876 for assistance for item(s) <br>' + errorPartList.join("<br>");
              ////TODO:$("#pricingErrorMsgModal").modal('show');

              let elmBtnErrorMsgModal:HTMLElement = document.getElementById('btnPriErrorMsgModal'+this.orderId) as HTMLElement;
              elmBtnErrorMsgModal.setAttribute("data-target", "#pricingErrorMsgModal" + this.orderId );
              elmBtnErrorMsgModal.click();

              //this.processingRequest = '';
              ////TODO:hidePleaseWait();
            },
            (reason) => {
              console.log(reason);
            }
          );
        } else {
          for (let i = 0; i < partList.length; i++) {
            const part = partList[i];
            for (let j = 0; j < priceSelectedParts.length; j++) {
              const partPrice = priceSelectedParts[j];
              if (partPrice.supersedeMerchandiseCode != '' && part.items[0].itemCd == partPrice.itemCd) {
                const superSededPartList = allPartList.filter(part => part.origMerchandiseCode === partPrice.supersedeMerchandiseCode);
                if (superSededPartList && superSededPartList.length == 1) {
                  part.items[0].itemprice = partPrice.price;
                  part.items[0].supersedeMerchandiseCode = partPrice.supersedeMerchandiseCode;
                  part.items[0].supersedeDesc = partPrice.supersedeDesc;
                  let superSededPart = superSededPartList[0];
                  superSededPart.price = partPrice.price;
                  superSededPart.supersedeMessage = part.itemCd + " superseded to " + superSededPart.itemCd;
                  addToCartPartList.push(superSededPart);
                }
              } else if (partPrice.supersedeMerchandiseCode == '' && part.items[0].itemCd == partPrice.itemCd) {
                part.items[0].price = partPrice.price;
                part.items[0].supersedeMerchandiseCode = partPrice.supersedeMerchandiseCode;
                part.items[0].supersedeDesc = partPrice.supersedeDesc;
                part.items[0].supersedeWeight = '';
                addToCartPartList.push(part);
              }
            }
          }
          var bRedirect = false;
          for (let i = 0; i < addToCartPartList.length; i++) {
            const currPart = addToCartPartList[i];
            if (i == (addToCartPartList.length - 1)) {
              bRedirect = true;
            }
            this.addLFSPartsToCart(currPart.items[0], currPart, bRedirect);
          }
        }
      },
      (reason) => {
        console.log(reason);
        this.processingRequest = '';
        ////TOFO:hidePleaseWait();
      }
    );
  }

  addSupplyToCart(supply, cartSupply, orderType, bRedirect){
    const data = {
      "machine": {}
    }
    const addedSuccessfully = this.orderService.addSupplyToCart(supply, data, orderType);
    if (!addedSuccessfully) {
      if (supply.b_c_flag == 'B') {
        console.log("ERROR:  Please select maximum " + supply.maxQuantity);
      } else {
        console.log("ERROR : Quantity selected exceeds a one month supply, please change the total quantity to a maximum of " + supply.dispQuantity)
      }
    } else {
      this.cart = this.orderService.getCart();
      if (cartSupply["shippingNewAddressFlg"] == 'Y') {
        this.cart.accountInfo.tonerContactPsn = cartSupply["shippingNewAddress"]["psn"];
      } else {
        this.cart.accountInfo.tonerContactPsn = cartSupply["shippingExistingAddress"]["psn"];
      }
      if (orderType == "PPS") {
        for (let itemKey in this.cart.ppsSupplyMap) {
          const sKey = (cartSupply["items"][0])["itemCd"] + "_";
          if (itemKey == sKey) {
            (cartSupply["items"])[0] = this.cart.ppsSupplyMap[itemKey]["items"][0];
            this.cart.ppsSupplyMap[itemKey] = cartSupply;
            this.orderService.setCart(this.cart);
            this.cart = this.orderService.getCart();
            if (bRedirect) {
              this.checkRedirectToMaint();
            }
          }
        }
      } else if (orderType == "EP") {
        for (const itemKey in this.cart.epSupplyMap) {
          const sKey = (cartSupply["items"][0])["itemCd"] + "_";
          if (itemKey == sKey) {
            (cartSupply["items"])[0] = this.cart.epSupplyMap[itemKey]["items"][0];
            this.cart.epSupplyMap[itemKey] = cartSupply;
            this.orderService.setCart(this.cart);
            this.cart = this.orderService.getCart();
            if (bRedirect) {
              this.checkRedirectToMaint();
            }
          }
        }
      } else if (orderType == "AZCO") {
        for (const itemKey in this.cart.azcoSupplyMap) {
          const sKey = (cartSupply["items"][0])["itemCd"] + "_";
          if (itemKey == sKey) {
            (cartSupply["items"])[0] = this.cart.azcoSupplyMap[itemKey]["items"][0];
            this.cart.azcoSupplyMap[itemKey] = cartSupply;
            this.orderService.setCart(this.cart);
            this.cart = this.orderService.getCart();
            if (bRedirect) {
              this.checkRedirectToMaint();
            }
          }
        }
      } else if (orderType == "CPC") {
        for (const itemKey in this.cart.cpcSupplyMap) {
          const sKey = (cartSupply["items"][0])["itemCd"] + "_";
          if (itemKey == sKey) {
            (cartSupply["items"])[0] = this.cart.cpcSupplyMap[itemKey]["items"][0];
            this.cart.cpcSupplyMap[itemKey] = cartSupply;
            this.orderService.setCart(this.cart);
            this.cart = this.orderService.getCart();
            if (bRedirect) {
              this.checkRedirectToMaint();
            }
          }
        }
      } else if (orderType == "LFS") {
        for (const itemKey in this.cart.lfsSupplyMap) {
          const sKey = (cartSupply["items"][0])["itemCd"] + "_";
          if (itemKey == sKey) {
            (cartSupply["items"])[0] = this.cart.lfsSupplyMap[itemKey]["items"][0];
            this.cart.lfsSupplyMap[itemKey] = cartSupply;
            this.orderService.setCart(this.cart);
            this.cart = this.orderService.getCart();
            if (bRedirect) {
              this.checkRedirectToMaint();
            }
          }
        }
      }
    }
  }

  checkRedirectToMaint(){
    this.processingRequest = 'processingRequest';
    setTimeout(() => {
      if (this.reorderItemCnt > 0) {
        if (this.reorderType == "LFS") {
          if (this.reorderItemCnt == this.orderService.totalLfsItems()) {
            this.redirectToMaint();
          } else {
            this.checkRedirectToMaint();
          }
        } else if (this.reorderType == "EP") {
          if (this.reorderItemCnt == this.orderService.totalEpItems()) {
            this.redirectToMaint();
          } else {
            this.checkRedirectToMaint();
          }
        } else if (this.reorderType == "AZCO") {
          if (this.reorderItemCnt == this.orderService.totalAzcoItems()) {
            this.redirectToMaint();
          } else {
            this.checkRedirectToMaint();
          }
        } else if (this.reorderType == "PPS") {
          if (this.reorderItemCnt == this.orderService.totalPpsItems()) {
            this.redirectToMaint();
          } else {
            this.checkRedirectToMaint();
          }
        } else if (this.reorderType == "CPC") {
          if (this.reorderItemCnt == this.orderService.totalCpcItems()) {
            this.redirectToMaint();
          } else {
            this.checkRedirectToMaint();
          }
        } else if (this.reorderType == "LFSPARTS") {
          if (this.reorderItemCnt == this.orderService.totalLfpItems()) {
            this.redirectToMaint();
          } else {
            this.checkRedirectToMaint();
          }
        }
      }
    }, 1500);
  }

  redirectToMaint(){
    this.router.navigateByUrl('cart');
    this.blockUI.stop();
  }

  addLFSPartsToCart(part, objPart, bRedirect){
    part.qtyError = 'N';
    part.qtyErrMsg = '';
    let cartPart = this.orderService.getItemFromCart(part, 'LFSPARTS');
    //Validate qty   
    //Check if selected qty > min
    if ((cartPart == null && part.selectedQty < part.minReqQuantity) || (cartPart != null && (cartPart.selectedQty + part.selectedQty) < part.minReqQuantity)) {
      part.qtyError = 'Y';
      part.qtyErrMsg = 'Please select minimum ' + part.minReqQuantity;
      return false;
    }
    //Check if the selected qty < max
    if ((cartPart == null && part.selectedQty > part.maxQuantity) || (cartPart != null && (cartPart.selectedQty + part.selectedQty) > part.maxQuantity)) {
      part.qtyError = 'Y';
      part.qtyErrMsg = 'Please select maximum ' + part.maxQuantity;
      return false;
    }
    //Check if multiples is selected
    if (part.selectedQty % part.orderIncrements != 0) {
      part.qtyError = 'Y';
      part.qtyErrMsg = 'Enter multiples of ' + part.orderIncrements;
      return false;
    }
    //Get Price Details
    const data = {
      "machine": this.orderService.getMachine()
    }
    const addedSuccessfully = this.orderService.addSupplyToCart(part, data, '');
    if (addedSuccessfully) {
      this.cart = this.orderService.getCart();
      if (objPart["shippingNewAddressFlg"] == 'Y') {
        this.cart.accountInfo.tonerContactPsn = objPart["shippingNewAddress"]["psn"];
      } else {
        this.cart.accountInfo.tonerContactPsn = objPart["shippingExistingAddress"]["psn"];
      }
      for (let itemKey in this.cart.lfsPartsMap) {
        const sKey = (objPart["items"][0])["itemCd"] + "_";
        if (itemKey == sKey) {
          (objPart["items"])[0] = this.cart.lfsPartsMap[itemKey]["items"][0];
          this.cart.lfsPartsMap[itemKey] = objPart;
          this.orderService.setCart(this.cart);
          this.cart = this.orderService.getCart();
          if (bRedirect) {
            this.checkRedirectToMaint();
          }
        }
      }
    }
  }
}
