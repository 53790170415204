import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { userDetails } from '../bean/userDetails';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  showSuccess :any;
  
  tempUserDetails: any;
  userDetails :userDetails =new userDetails();

  constructor(public activeModal: NgbActiveModal,private modalService: NgbModal,private loginAuthenticationService:LoginAuthenticationService) { }

  ngOnInit() {
   
  }
  closeRegisterModal()
  {
    this.modalService.dismissAll(RegisterComponent);
  }
  registerUser(){
    
    let data = this.loginAuthenticationService.registerUser(this.userDetails).subscribe(
      data=>{
        this.tempUserDetails  = data;
         console.log("the data is"+this.tempUserDetails);
         if( this.tempUserDetails && ! this.tempUserDetails.errorExist){
          this.showSuccess = true;
          this.tempUserDetails ={};
          this.userDetails=this.tempUserDetails;
        }else {
          this.userDetails = this.tempUserDetails;
        }
      }
    );
  }

}
