
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable, Pipe } from '@angular/core';


@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {


    readonly DELIMITER = '-';

    format(date: NgbDateStruct): string {
      if (date === null) {
        return '';
      }
      const month = this.getMonthAbbreviation(date.month);
      return `${this.padZero(date.day)}${this.DELIMITER}${month}${this.DELIMITER}${date.year}`;
    }
  
    parse(value: string): NgbDateStruct | null {
      if (value) {
        const dateParts = value.trim().split(this.DELIMITER);
        if (dateParts.length === 3) {
          const year = parseInt(dateParts[2], 10);
          const month = this.getMonthNumber(dateParts[1]);
          const day = parseInt(dateParts[0], 10);
          return { year, month, day };
        }
      }
      return null;
    }
  
    private padZero(value: number): string {
      return value.toString().padStart(2, '0');
    }
  
    private getMonthAbbreviation(month: number): string {
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      return months[month - 1];
    }
  
    private getMonthNumber(monthName: string): number {
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      return months.findIndex(m => m === monthName.toUpperCase()) + 1;
    }
  
  }
 
  