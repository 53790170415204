import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchFilterPipe } from '../pipe/search-filter.pipe';
import { ModalModule } from 'ngx-bootstrap';
import { GlobalSearchRoutingModule } from './search-routing.module';
import { GlobalsearchComponent } from './globalsearch/globalsearch.component';

@NgModule({
  declarations: [GlobalsearchComponent],
  imports: [
	GlobalSearchRoutingModule,
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ModalModule.forRoot()
  ],
  providers: [
    SearchFilterPipe
  ]

})
export class SearchModule { }
