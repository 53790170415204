import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/app.util';

const httpOptions = {
  headers: new HttpHeaders({      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class EntermeterreadsService {
  defaultUrlNew: string;
  constructor(private http: HttpClient, private util : Util) {
    this.defaultUrlNew = this.util.getBaseUrl();
   }
 
  getMeterReadsData(cartData):Observable<any>{
    let initialUrl = this.defaultUrlNew+"meterreads.htm/getreads";
    return this.http
    .post(initialUrl,cartData,httpOptions)
    .pipe(catchError(this.handleError("getMeterReadsData")))
  }
  

  saveMeterReads(readsData):Observable<any>{
    let initialUrl = this.defaultUrlNew+"meterreads.htm/smr";
    return this.http
    .post(initialUrl,readsData,httpOptions)
    .pipe(catchError(this.handleError("saveMeterReads")))
  }
  
  meterReadsDue(cartData){    
    this.getMeterReadsData(cartData).subscribe(
      data => {
        var meterReadsDue = 'N'
            if(data.status == "S"){
              var meterReadsDetails = data.meterreads;
              
              if(typeof(meterReadsDetails) != 'undefined'){
              meterReadsDetails.forEach(function (meterReads, index) {
                console.log("Serial Number : " + meterReads.serialNumber);	
                if(meterReads.actionRequired == 'Y'){
                  meterReadsDue = 'Y';  
                }
              });				    			
              }
            }else if(data.status == "E"){
              console.log('Error getting meter reads details');
            }
        
        var pageName = meterReadsDue == 'Y' ?  'meterreads.htm' : 'product.htm?view=cartMaint';
        
        window.location.href = pageName;	
        
      }
  );
    }

    private handleError<T>(operation = "operation", result?: T) {
      return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        // TODO: better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);
  
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
  
}
