import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-contactus',
    templateUrl: './contactus.component.html'
})
export class ContactusComponent implements OnInit {
    externalUrl = 'https://mycanonbusiness.usa.canon.com/cpImages/preOwn/html/ContactUs-Guest.html';
    contactHtml;
    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {

    }
    ngOnInit(): void {
        this.http.get(this.externalUrl, { responseType: 'text' }).subscribe(data => {
            this.contactHtml = this.sanitizer.bypassSecurityTrustHtml(data);
        })
    }


}
